import { UserAddOutlined } from '@ant-design/icons';
import { Button, Card, notification, Space } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { apiRequest } from '../../service/api/apiRequest';

export const RecoveryWebsiteAccess = (props: { leadId: string }) => {
    const { leadId } = props;
    const [isLoading, setIsLoading] = useState(false);

    const openErrorNotification = (message: string) => {
        setIsLoading(false);
        notification.error({
            message: 'Oops, something went wrong!',
            description: message,
            placement: 'top',
        });
    };

    const openSuccessNotification = (message: string) => {
        setIsLoading(false);
        notification.success({
            message: 'Done!',
            description: message,
            placement: 'top',
            duration: 60
        });
    };

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card title="This action will create a new admin user on the website" size="small">
                <Button
                    type="primary"
                    shape="round"
                    icon={<UserAddOutlined/>}
                    loading={isLoading}
                    block
                    onClick={async (): Promise<void> => {
                        try {
                            setIsLoading(true);
                            const r = await apiRequest.post<RecoveryAccessResponse>(
                                '/leads/recovery-access',
                                { leadId }
                            );
                            openSuccessNotification(`User created successfully! Please, copy this link: ${r.data.message}`);
                        } catch (error) {
                            openErrorNotification((error as RecoveryAccessErrorResponse)?.response?.data?.message ?? 'Failed to create a user');
                        }
                    }}
                >Create a new user</Button>
            </Card>
        </Space>
    );
};

type RecoveryAccessErrorResponse = AxiosError<{
    statusCode: number,
    message: string
}>;

type RecoveryAccessResponse = {
    message: string
};
