import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorType } from '../../interfaces';
import { API } from '../../service/api';
import { authWithGoogle } from '../../service/googleAuth/authWithGoogle';
import { signoutWithGoogle } from '../../service/googleAuth/signoutWithGoogle';
import { connectToSockets, disconnectFromSockets } from '../../service/sockets';
import { StatusCodes } from '../../typedef';
import { clearLeadsStore } from '../slices/leads';
import { clearLeadsListsStore } from '../slices/leadsList';
import { clearSessionStore } from '../slices/session';
import { clearUsersStore } from '../slices/users';

export const signinWithGoogle = createAsyncThunk(
    'auth/signinWithGoogle',
    async (_, thunkAPI) => {
        try {
            const { token } = await authWithGoogle();

            await API.session.login(token ?? '');

            const { data: user } = await API.session.getMe();

            if (user) connectToSockets(user.uid);

            return user;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const logoutWithGoogle = createAsyncThunk(
    'auth/logoutWithGoogle',
    async (_, thunkAPI) => {
        try {
            await API.session.logout();

            await signoutWithGoogle();

            thunkAPI.dispatch(clearLeadsListsStore());
            thunkAPI.dispatch(clearLeadsStore());
            thunkAPI.dispatch(clearUsersStore());
            thunkAPI.dispatch(clearSessionStore());

            disconnectFromSockets();
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const getMe = createAsyncThunk(
    'auth/getMe',
    async (firstInit: boolean, thunkAPI) => {
        try {
            const response = await API.session.getMe();

            if (response.data) connectToSockets(response.data.uid);

            return response.data;
        }
        catch (error: any) {
            if (/Network Error/.test(error.message)) {
                return thunkAPI.rejectWithValue({
                    statusCode: StatusCodes.WEB_SERVER_IS_DOWN,
                    message: error.message
                });
            } else {
                if (firstInit) {
                    return thunkAPI.rejectWithValue(firstInit);
                } else {
                    return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
                }
            }
        }
    }
);
