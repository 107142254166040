import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorType } from '../../interfaces';
import { LeadManualVerification } from '../../interfaces/leads';
import { API } from '../../service/api';

export const getEventsLog = createAsyncThunk(
    'leads/getEventsLog',
    async (leadsID: string, thunkAPI) => {
        try {
            const response = await API.leads.getEventsLog(leadsID);
            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const manualVerify = createAsyncThunk(
    'leads/manualVerify',
    async ({ id, verification, callback }: {
        id: string;
        verification: LeadManualVerification;
        callback?: () => void;
    }, thunkAPI) => {
        try {
            const response = await API.leads.manualVerify(id, verification);

            callback && callback();

            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);
