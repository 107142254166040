/* eslint-disable no-shadow */
import { Button, Input, Space, Tooltip } from 'antd';
import React, { Dispatch, FC, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { applyLeadsSearching } from '../../../../store/slices/leads';
import { SocketsKeys } from '../../../../typedef';
import { FilterPanel } from './filterPanel';
import { SortingPanel } from './sortingPanel';

interface Props {
    isFilterOpen: boolean;
    isSortingOpen: boolean;
    setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
    handleToggleFilterPopover: () => void;
    handleToggleSortPopover: () => void;
    handleRefetch: () => void;
}

export const UtilsPanel: FC<Props> = memo((
    {
        isFilterOpen,
        setIsFilterOpen,
        isSortingOpen,
        handleToggleFilterPopover,
        handleToggleSortPopover,
        handleRefetch
    }: Props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        searching,
        filtering: filteringOptions,
        sorting: sortingOptions
    } = useSelector(state => state.leads);

    const [searchQuery, setSearchQuery] = useState<string>(searching);

    useEffect(() => {
        const realTimeLeadsListRestart = (): void => {
            setSearchQuery('');
        };
        window.clientIo?.on(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);

        return () => {
            window.clientIo?.off(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);
        };
    }, []);

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => setSearchQuery(e.target.value);

    const handleReloadLeads = useCallback((): void => {
        handleRefetch();
    }, []);

    const handleSearch = useCallback((value: string): void => {
        searchParams.delete('page');
        setSearchParams(searchParams);

        dispatch(applyLeadsSearching(value));
    }, []);

    return (
        <Space direction="vertical">
            <Space>
                <Tooltip title="Reload leads">
                    <Button
                        onClick={handleReloadLeads}
                        type="ghost"
                        icon={<ArrowClockwise/>}
                    />
                </Tooltip>
                <Input.Search
                    style={{ width: 330 }}
                    placeholder="Find leads.."
                    allowClear
                    enterButton="Search"
                    value={searchQuery}
                    onChange={handleChangeSearch}
                    onSearch={handleSearch}
                />
                <FilterPanel
                    isFilterOpen={isFilterOpen}
                    setIsFilterOpen={setIsFilterOpen}
                    handleToggleFilterPopover={handleToggleFilterPopover}
                    filteringOptions={filteringOptions}
                />
                <SortingPanel
                    isSortingOpen={isSortingOpen}
                    sortingOptions={sortingOptions}
                    handleToggleSortPopover={handleToggleSortPopover}
                />
            </Space>
        </Space>
    );
});
