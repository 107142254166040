import { Typography } from 'antd';
import React, { FC, memo } from 'react';
import { LeadDossier, LeadStatuses } from '../../interfaces/leads';

interface Props {
    reportId: LeadDossier['linkInfo']['linkresearchtools']['report_id'];
    isReportGenerated: LeadDossier['linkInfo']['linkresearchtools']['is_report_generated'];
    status: LeadDossier['status'];
}

export const LRTErrorMessage: FC<Props> = memo(({
                                                    isReportGenerated,
                                                    reportId,
                                                    status
                                                }: Props) => {
    if (
        (!isReportGenerated &&
            !reportId &&
            status === LeadStatuses.SUCCESSFUL)
    ) {
        return <Typography.Text type="secondary" italic>(report is unable to be generated)</Typography.Text>;
    } else if (
        (!isReportGenerated &&
            reportId &&
            status === LeadStatuses.SUCCESSFUL)
    ) {
        return <Typography.Text type="secondary" italic>(waiting for report result)</Typography.Text>;
    } else if (status === LeadStatuses.FAILED || status === LeadStatuses.UNPROCESSABLE) {
        return <Typography.Text type="secondary" italic>(report is unable to be generated because of
            status)</Typography.Text>;
    } else return <></>;
});
