import { Space, Typography } from 'antd';
import React, { memo } from 'react';
import { WebsitesTableDataObj } from '../../../../interfaces/websites';

// interface Props {
//     lrtPowerTrustDom: WebsitesTableDataObj['seoInfo']['lrtPowerTrustDom'];
//     ahrefsDomainRating: WebsitesTableDataObj['seoInfo']['ahrefsDomainRating'];
//     ahrefsTotalTraffic: WebsitesTableDataObj['seoInfo']['ahrefsTotalTraffic'];
// }

const { Text } = Typography;

export const ColumnSeoInfo = memo((
    props: { seoInfo: WebsitesTableDataObj['seoInfo'] }
) => {
    return (
        <div style={{ width: 'fit-content' }}>
            <Space wrap={false} align="center">
                <Text strong>Domain Rating: {props.seoInfo.domainRating}</Text>
            </Space>

            <Space wrap={false} align="center">
                <Text strong>Keywords: {props.seoInfo.keywords}</Text>
            </Space>

            <Space wrap={false} align="center">
                <Text strong>Traffic: {props.seoInfo.traffic}</Text>
            </Space>

            <Space wrap={false} align="center">
                <Text strong>Dtoxrisk: {props.seoInfo.dtoxrisk}</Text>
            </Space>

            <Space wrap={false} align="center">
                <Text strong>Power Trust Dom: {props.seoInfo.powerTrustDom}</Text>
            </Space>

            <Space wrap={false} align="center">
                <Text strong>Power Dom: {props.seoInfo.powerDom}</Text>
            </Space>

            <Space wrap={false} align="center">
                <Text strong>Trust Dom: {props.seoInfo.trustDom}</Text>
            </Space>
        </div>
    );
});
