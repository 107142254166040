import { Descriptions, Tag, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { BoxArrowUpRight, Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import { ConnectionBadge } from '../../../../components/ConnectionBadge';
import { LanguageLabel } from '../../../../components/LanguageLabel';
import { LeadManualVerificationTag } from '../../../../components/LeadManualVerificationTag';
import { TextEmpty } from '../../../../components/TextEmpty';
import { WebsiteNote } from '../../../../components/WebsiteNote';
import { WebsitesContentIndicators } from '../../../../components/WebsitesContentIndicators';
import { WebsiteDossier } from '../../../../interfaces/websites';
import { toLocaleDate } from '../../../../utils/date';
import { TopicsList } from '../../../Topics/TopicsList';

interface Props {
    id: WebsiteDossier['id'];
    isAccessible: WebsiteDossier['isAccessible'];
    baseUrl: WebsiteDossier['baseUrl'];
    domain: WebsiteDossier['domain'];
    manualVerification: WebsiteDossier['manualVerification'];
    manualVerificatedAt: WebsiteDossier['manualVerificatedAt'];
    createdAt: WebsiteDossier['createdAt'];
    checkedAt: WebsiteDossier['checkedAt'];
    connectionStatus: WebsiteDossier['connectionStatus'];
    language: WebsiteDossier['language'];
    topics: WebsiteDossier['topics'];
    note: WebsiteDossier['note'];
    contentIndicators: WebsiteDossier['contentIndicators'];
}

const { Text } = Typography;

export const GeneralBlock: FC<Props> = memo(({
                                                 id,
                                                 isAccessible,
                                                 baseUrl,
                                                 domain,
                                                 manualVerification,
                                                 manualVerificatedAt,
                                                 createdAt,
                                                 checkedAt,
                                                 connectionStatus,
                                                 language,
                                                 topics,
                                                 note,
                                                 contentIndicators
                                             }: Props) => {
    return (
        <Descriptions
            column={1}
            layout="vertical"
            labelStyle={{ marginBottom: '-8px' }}
            size="small"
        >
            <Descriptions.Item label={<Text type="secondary">ID</Text>}>
                <Text
                    copyable={{
                        text: id,
                        icon: [
                            <Clipboard size={12} key="copy-icon"/>,
                            <ClipboardCheck size={12} key="copied-icon"/>
                        ],
                        tooltips: ['Copy', 'Copied!']
                    }}
                >
                    <Tag style={{ marginRight: 0, fontSize: 14 }}>{id}</Tag>
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Accessibility</Text>}>
                {isAccessible ? <Tag color="green">Available</Tag> : <Tag color="red">Not available</Tag>}
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Connection status</Text>}>
                <ConnectionBadge status={connectionStatus}/>
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Language</Text>}>
                <Text>
                    <LanguageLabel lang={language}/>
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Domain</Text>}>
                <Text
                    copyable={{
                        text: domain,
                        icon: [
                            <Clipboard size={12} key="copy-icon"/>,
                            <ClipboardCheck size={12} key="copied-icon"/>
                        ],
                        tooltips: ['Copy', 'Copied!']
                    }}
                >
                    {domain}
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Home page</Text>}>
                <Text
                    copyable={{
                        text: domain,
                        icon: [
                            <Clipboard size={12} key="copy-icon"/>,
                            <ClipboardCheck size={12} key="copied-icon"/>
                        ],
                        tooltips: ['Copy', 'Copied!']
                    }}
                >
                    <a target="_blank" href={`http://${domain}`} rel="noreferrer">
                        http://{domain}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                    </a>
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Base URL</Text>}>
                <Text
                    copyable={{
                        text: baseUrl,
                        icon: [
                            <Clipboard size={12} key="copy-icon"/>,
                            <ClipboardCheck size={12} key="copied-icon"/>
                        ],
                        tooltips: ['Copy', 'Copied!']
                    }}
                >
                    <a target="_blank" href={baseUrl} rel="noreferrer">
                        {baseUrl}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                    </a>
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Created at</Text>}>
                {toLocaleDate(createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Checked at</Text>}>
                {
                    checkedAt
                        ? toLocaleDate(checkedAt)
                        : <TextEmpty/>
                }
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Manual verification</Text>}>
                <LeadManualVerificationTag
                    type="badge"
                    layout="row"
                    withoutDate={false}
                    verificationStatus={manualVerification}
                    verificatedAt={manualVerificatedAt}
                />
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Topics</Text>}>
                <TopicsList showFullAlways websiteID={id} topics={topics}/>
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Content indicators</Text>}>
                <WebsitesContentIndicators websiteID={id} contentIndicators={contentIndicators}/>
            </Descriptions.Item>
            <Descriptions.Item label={<Text type="secondary">Note</Text>}>
                <WebsiteNote websiteID={id} note={note}/>
            </Descriptions.Item>
        </Descriptions>
    );
});
