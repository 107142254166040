/* eslint-disable no-shadow */
import { Button, Input, Space, Tooltip } from 'antd';
import React, { Dispatch, FC, memo, SetStateAction, useCallback } from 'react';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { applyWebsitesSearching } from '../../../../store/slices/websites';
import { FilterPanel } from './filterPanel';
import { SortingPanel } from './sortingPanel';

interface Props {
    currentPage: number;
    isFilterOpen: boolean;
    isSortingOpen: boolean;
    handleToggleFilterPopover: () => void;
    handleToggleSortPopover: () => void;
    handleRefetch: () => void;
    setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
}

export const UtilsPanel: FC<Props> = memo((
    {
        isFilterOpen,
        setIsFilterOpen,
        isSortingOpen,
        handleToggleFilterPopover,
        handleToggleSortPopover,
        handleRefetch
    }: Props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        filtering: filteringOptions,
        sorting: sortingOptions
    } = useSelector(state => state.websites);

    const handleReloadLeads = useCallback((): void => {
        handleRefetch();
    }, []);

    const handleSearch = useCallback((value: string): void => {
        searchParams.delete('page');
        setSearchParams(searchParams);

        dispatch(applyWebsitesSearching(value));
    }, []);

    return (
        <Space direction="vertical">
            <Space>
                <Tooltip title="Reload websites">
                    <Button
                        onClick={handleReloadLeads}
                        type="ghost"
                        icon={<ArrowClockwise/>}
                    />
                </Tooltip>
                <Input.Search
                    style={{ width: 330 }}
                    placeholder="Find websites.."
                    allowClear
                    enterButton="Search"
                    onSearch={handleSearch}
                />
                <FilterPanel
                    isFilterOpen={isFilterOpen}
                    setIsFilterOpen={setIsFilterOpen}
                    handleToggleFilterPopover={handleToggleFilterPopover}
                    filteringOptions={filteringOptions}
                />
                <SortingPanel
                    isSortingOpen={isSortingOpen}
                    sortingOptions={sortingOptions}
                    handleToggleSortPopover={handleToggleSortPopover}
                />
            </Space>
        </Space>
    );
});
