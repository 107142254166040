import { Badge, Button, Popover } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { Dispatch, FC, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Funnel, FunnelFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { LeadManualVerification } from '../../../../../interfaces/leads';
import { useGetLeadsListsNamesQuery } from '../../../../../store/queries/leads';
import { useGetWebsitesLanguageQuery } from '../../../../../store/queries/websites';
import { applyWebsitesFiltering, resetWebsitesFiltering, WebsitesState } from '../../../../../store/slices/websites';
import { Panel } from './panel';

interface Props {
    isFilterOpen: boolean;
    filteringOptions: WebsitesState['filtering'];
    handleToggleFilterPopover: () => void;
    setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
}

export const FilterPanel: FC<Props> = memo((
    {
        isFilterOpen,
        setIsFilterOpen,
        filteringOptions,
        handleToggleFilterPopover
    }: Props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        data: languagesList,
        isFetching: isLanguagesListFetching
    } = useGetWebsitesLanguageQuery(undefined, { skip: !isFilterOpen });
    const {
        data: leadsListsNames,
        isFetching: isLeadsListsNamesFetching
    } = useGetLeadsListsNamesQuery(undefined, { skip: !isFilterOpen });

    const [filterAccessability, setFilterAccessability] = useState<boolean | null>(filteringOptions.accessability);
    const [filterLanguage, setFilterLanguage] = useState<string[]>(filteringOptions.language);
    const [filterLrtPowerTrustDom, setFilterLrtPowerTrustDom] = useState<boolean>(filteringOptions.lrtPowerTrustDom);
    const [filterAhrefsDr, setFilterAhrefsDr] = useState<[number, number]>(filteringOptions.ahrefsDr || [0, 0]);
    const [filterAhrefsTraffic, setFilterAhrefsTraffic] = useState<[number, number]>(filteringOptions.ahrefsTraffic || [0, 0]);
    const [filterManualVerification, setFilterManualVerification] = useState<LeadManualVerification | null>(
        filteringOptions.manualVerification
    );
    const [filterManualVerificationDate, setFilterManualVerificationDate] = useState<[Date, Date] | [null, null]>(
        filteringOptions.manualVerificationDate || [null, null]
    );
    const [filterLastCheckingDate, setFilterLastCheckingDate] = useState<[Date, Date] | [null, null]>(
        filteringOptions.lastCheckingDate || [null, null]
    );
    const [filterCreatingDate, setFilterCreatingDate] = useState<[Date, Date] | [null, null]>(
        filteringOptions.creatingDate || [null, null]
    );
    const [filterByList, setFilterByList] = useState<string | null>(filteringOptions.listID);

    const [showFilterAhrefsDr, setShowFilterAhrefsDr] = useState<boolean>(false);
    const [showFilterAhrefsTraffic, setShowFilterAhrefsTraffic] = useState<boolean>(false);

    const isFilteringEnabled = useMemo(() => !!(
        typeof filterAccessability === 'boolean' ||
        filterLanguage.length ||
        filterLrtPowerTrustDom ||
        showFilterAhrefsDr ||
        showFilterAhrefsTraffic ||
        filterManualVerification ||
        (filterManualVerificationDate[0] || filterManualVerificationDate[1]) ||
        (filterLastCheckingDate[0] || filterLastCheckingDate[1]) ||
        (filterCreatingDate[0] || filterCreatingDate[1]) ||
        filterByList
    ), [
        filterAccessability,
        filterLanguage,
        filterLrtPowerTrustDom,
        showFilterAhrefsDr,
        showFilterAhrefsTraffic,
        filterManualVerification,
        filterManualVerificationDate,
        filterLastCheckingDate,
        filterCreatingDate,
        filterByList
    ]);

    const areFiltersDifferent = useMemo(() => (
        filterAccessability !== filteringOptions.accessability ||
        filterLanguage !== filteringOptions.language ||
        filterLrtPowerTrustDom !== filteringOptions.lrtPowerTrustDom ||
        filterManualVerification !== filteringOptions.manualVerification ||
        ((
                (new Date(filterManualVerificationDate?.[0] ?? '').toString() !==
                    new Date(((filteringOptions.manualVerificationDate && filteringOptions.manualVerificationDate?.[0]) || '') ?? '').toString())
            ) &&
            (
                (new Date(filterManualVerificationDate?.[1] ?? '').toString() !==
                    new Date(((filteringOptions.manualVerificationDate && filteringOptions.manualVerificationDate?.[1]) || '') ?? '').toString())
            )) ||
        ((
                (new Date(filterLastCheckingDate?.[0] ?? '').toString() !==
                    new Date(((filteringOptions.lastCheckingDate && filteringOptions.lastCheckingDate?.[0]) || '') ?? '').toString())
            ) &&
            (
                (new Date(filterLastCheckingDate?.[1] ?? '').toString() !==
                    new Date(((filteringOptions.lastCheckingDate && filteringOptions.lastCheckingDate?.[1]) || '') ?? '').toString())
            )) ||
        ((
                (new Date(filterCreatingDate?.[0] ?? '').toString() !==
                    new Date(((filteringOptions.creatingDate && filteringOptions.creatingDate?.[0]) || '') ?? '').toString())
            ) &&
            (
                (new Date(filterCreatingDate?.[1] ?? '').toString() !==
                    new Date(((filteringOptions.creatingDate && filteringOptions.creatingDate?.[1]) || '') ?? '').toString())
            )) ||
        filterByList !== filteringOptions.listID ||
        showFilterAhrefsTraffic !== filteringOptions.ahrefsTraffic
    ), [
        filterAccessability,
        filterLanguage,
        filterLrtPowerTrustDom,
        filterAhrefsDr,
        filterAhrefsTraffic,
        showFilterAhrefsDr,
        showFilterAhrefsTraffic,
        filterManualVerification,
        filterManualVerificationDate,
        filterLastCheckingDate,
        filterCreatingDate,
        filterByList,
        filteringOptions
    ]);

    useEffect(() => {
        if (!isFilterOpen) {
            setTimeout(() => {
                handleSetFiltersCurrent();
            }, 150);
        }
    }, [isFilterOpen]);

    const handleSetFilterAccessability = useCallback((value: boolean): void => setFilterAccessability(value), []);
    const handleSetFilterLanguage = useCallback((value: string[]): void => setFilterLanguage(value), []);
    const handleSetLrtPowerTrustDom = useCallback((value: boolean): void => setFilterLrtPowerTrustDom(value), []);
    const handleSetAhrefsDrFrom = useCallback((value: number | null): void => setFilterAhrefsDr(prevValue => [value ?? 0, prevValue[1]]), []);
    const handleSetAhrefsDrTo = useCallback((value: number | null): void => setFilterAhrefsDr(prevValue => [prevValue[0], value ?? 0]), []);
    const handleSetAhrefsTrafficFrom = useCallback((value: number | null): void => setFilterAhrefsTraffic(prevValue => [value ?? 0, prevValue[1]]), []);
    const handleSetAhrefsTrafficTo = useCallback((value: number | null): void => setFilterAhrefsTraffic(prevValue => [prevValue[0], value ?? 0]), []);
    const handleSetFilterManualVerification = useCallback((value: LeadManualVerification): void => setFilterManualVerification(value), []);
    const handleSetFilterManualVerificationDate = useCallback((values: RangeValue<Date>): void => {
        if (values && (values[0] && values[1])) {
            setFilterManualVerificationDate([
                values[0],
                values[1]
            ]);
        } else {
            setFilterManualVerificationDate([null, null]);
        }
    }, []);
    const handleSetFilterLastCheckingDate = useCallback((values: RangeValue<Date>): void => {
        if (values && (values[0] && values[1])) {
            setFilterLastCheckingDate([
                values[0],
                values[1]
            ]);
        } else {
            setFilterLastCheckingDate([null, null]);
        }
    }, []);
    const handleSetFilterCreatingDate = useCallback((values: RangeValue<Date>): void => {
        if (values && (values[0] && values[1])) {
            setFilterCreatingDate([
                values[0],
                values[1]
            ]);
        } else {
            setFilterCreatingDate([null, null]);
        }
    }, []);
    const handleSetFilterByList = useCallback((value: string | null): void => setFilterByList(value), []);

    const handleClearFilterByAhrefsDr = useCallback((): void => setFilterAhrefsDr([0, 0]), []);
    const handleClearFilterByAhrefsTraffic = useCallback((): void => setFilterAhrefsTraffic([0, 0]), []);

    const handleShowFilterAhrefsDr = useCallback((value: boolean): void => setShowFilterAhrefsDr(value), []);
    const handleShowFilterAhrefsTraffic = useCallback((value: boolean): void => setShowFilterAhrefsTraffic(value), []);

    const handleResetFilters = useCallback((): void => {
        setFilterAccessability(null);
        setFilterLanguage([]);
        setFilterLrtPowerTrustDom(false);
        setFilterManualVerification(null);
        setFilterAhrefsDr([0, 0]);
        setFilterAhrefsTraffic([0, 0]);
        setFilterManualVerificationDate([null, null]);
        handleSetFilterLastCheckingDate([null, null]);
        handleSetFilterCreatingDate([null, null]);
        handleSetFilterByList(null);

        setShowFilterAhrefsDr(false);
        setShowFilterAhrefsTraffic(false);

        dispatch(resetWebsitesFiltering());

        handleToggleFilterPopover();
    }, [dispatch, handleSetFilterByList, handleSetFilterCreatingDate, handleSetFilterLastCheckingDate, handleToggleFilterPopover]);

    const handleFindByFilters = useCallback((): void => {
        if (areFiltersDifferent) {
            searchParams.delete('page');
            setSearchParams(searchParams);

            dispatch(applyWebsitesFiltering({
                accessability: filterAccessability,
                language: filterLanguage,
                lrtPowerTrustDom: filterLrtPowerTrustDom,
                ahrefsDr: showFilterAhrefsDr ? filterAhrefsDr : false,
                ahrefsTraffic: showFilterAhrefsTraffic ? filterAhrefsTraffic : false,
                manualVerification: filterManualVerification,
                manualVerificationDate: (filterManualVerificationDate[0] && filterManualVerificationDate[1])
                    ? filterManualVerificationDate
                    : [null, null],
                lastCheckingDate: (filterLastCheckingDate[0] && filterLastCheckingDate[1])
                    ? filterLastCheckingDate
                    : [null, null],
                creatingDate: (filterCreatingDate[0] && filterCreatingDate[1])
                    ? filterCreatingDate
                    : [null, null],
                listID: filterByList
            }));

            handleToggleFilterPopover();
        }
    }, [
        dispatch,
        handleToggleFilterPopover,
        setSearchParams,
        areFiltersDifferent,
        filterAccessability,
        filterLanguage,
        filterLrtPowerTrustDom,
        filterAhrefsDr,
        filterAhrefsTraffic,
        showFilterAhrefsDr,
        showFilterAhrefsTraffic,
        filterManualVerification,
        filterManualVerificationDate,
        filterLastCheckingDate,
        filterCreatingDate,
        filterByList,
        searchParams
    ]);

    const handleSetFiltersCurrent = useCallback((): void => {
        setFilterAccessability(filteringOptions.accessability);
        setFilterLanguage(filteringOptions.language);
        setFilterLrtPowerTrustDom(filteringOptions.lrtPowerTrustDom);
        setFilterAhrefsDr(filteringOptions.ahrefsDr || [0, 0]);
        setFilterAhrefsTraffic(filteringOptions.ahrefsTraffic || [0, 0]);
        setFilterManualVerification(filteringOptions.manualVerification);
        setFilterManualVerificationDate(filteringOptions.manualVerificationDate || [null, null]);
        setFilterLastCheckingDate(filteringOptions.lastCheckingDate || [null, null]);
        handleSetFilterCreatingDate(filteringOptions.creatingDate || [null, null]);
        handleSetFilterByList(filteringOptions.listID);

        setShowFilterAhrefsDr(showFilterAhrefsDr);
        setShowFilterAhrefsTraffic(showFilterAhrefsTraffic);
    }, [filteringOptions, handleSetFilterByList, handleSetFilterCreatingDate, showFilterAhrefsDr, showFilterAhrefsTraffic]);

    return (
        <Badge dot={isFilteringEnabled}>
            <Popover
                title="Filter by"
                arrowPointAtCenter
                placement="bottom"
                content={
                    <Panel
                        filterAccessability={filterAccessability}
                        filterLrtPowerTrustDom={filterLrtPowerTrustDom}
                        filterAhrefsDr={filterAhrefsDr}
                        filterAhrefsTraffic={filterAhrefsTraffic}
                        showFilterAhrefsDr={showFilterAhrefsDr}
                        showFilterAhrefsTraffic={showFilterAhrefsTraffic}
                        filterLanguage={filterLanguage}
                        languagesList={languagesList ?? []}
                        filterManualVerification={filterManualVerification}
                        filterManualVerificationDate={filterManualVerificationDate}
                        filterLastCheckingDate={filterLastCheckingDate}
                        filterCreatingDate={filterCreatingDate}
                        languagesListFetching={isLanguagesListFetching}
                        filterByList={filterByList}
                        leadsListsNames={leadsListsNames ?? []}
                        leadsListsNamesFetching={isLeadsListsNamesFetching}
                        handleSetFilterAccessability={handleSetFilterAccessability}
                        handleShowFilterAhrefsDr={handleShowFilterAhrefsDr}
                        handleShowFilterAhrefsTraffic={handleShowFilterAhrefsTraffic}
                        handleClearFilterByAhrefsDr={handleClearFilterByAhrefsDr}
                        handleClearFilterByAhrefsTraffic={handleClearFilterByAhrefsTraffic}
                        handleSetFilterLanguage={handleSetFilterLanguage}
                        handleSetLrtPowerTrustDom={handleSetLrtPowerTrustDom}
                        handleSetAhrefsDrFrom={handleSetAhrefsDrFrom}
                        handleSetAhrefsDrTo={handleSetAhrefsDrTo}
                        handleSetAhrefsTrafficFrom={handleSetAhrefsTrafficFrom}
                        handleSetAhrefsTrafficTo={handleSetAhrefsTrafficTo}
                        handleSetFilterManualVerification={handleSetFilterManualVerification}
                        handleSetFilterManualVerificationDate={handleSetFilterManualVerificationDate}
                        handleSetFilterLastCheckingDate={handleSetFilterLastCheckingDate}
                        handleSetFilterCreatingDate={handleSetFilterCreatingDate}
                        handleResetFilters={handleResetFilters}
                        handleFindByFilters={handleFindByFilters}
                        handleSetFilterByList={handleSetFilterByList}
                        setIsFilterOpen={setIsFilterOpen}
                    />
                }
                trigger="click"
                open={isFilterOpen}
            >
                <Button
                    onClick={handleToggleFilterPopover}
                    icon={isFilterOpen ? <FunnelFill/> : <Funnel/>}
                />
            </Popover>
        </Badge>
    );
});
