import { Badge, Button, Popover } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { Dispatch, FC, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Funnel, FunnelFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    LeadConnectionStatuses,
    LeadContentIndicators,
    LeadManualVerification,
    LeadStatuses
} from '../../../../../interfaces/leads';
import { fetchLeadsByPageCount } from '../../../../../store/actions/leadsLists';
import { useGetLeadsLanguageQuery } from '../../../../../store/queries/leads';
import { applyLeadsFiltering, LeadsState, resetLeadsFiltering } from '../../../../../store/slices/leads';
import { SocketsKeys, StatusCodesInfo } from '../../../../../typedef';
import { Panel } from './panel';

interface Props {
    currentPage: number;
    isFilterOpen: boolean;
    searchingQuery: LeadsState['searching'];
    filteringOptions: LeadsState['filtering'];
    sortingOptions: LeadsState['sorting'];
    listIdParam: string | undefined;
    handleToggleFilterPopover: () => void;
    setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
}

export const FilterPanel: FC<Props> = memo((
    {
        currentPage,
        isFilterOpen,
        setIsFilterOpen,
        searchingQuery,
        filteringOptions,
        sortingOptions,
        listIdParam,
        handleToggleFilterPopover
    }: Props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [filterPublishableStatus, setFilterPublishableStatus] = useState<boolean[]>(filteringOptions.publishableStatus);
    const [filterRepeatStatus, setFilterRepeatStatus] = useState<boolean[]>(filteringOptions.repeatStatus);
    const [filterAttentionStatus, setFilterAttentionStatus] = useState<boolean[]>(filteringOptions.attentionStatus);
    const [filterActiveStatus, setFilterActiveStatus] = useState<boolean[]>(filteringOptions.activeStatus);
    const [filterPluginStatus, setFilterPluginStatus] = useState<boolean[]>(filteringOptions.pluginStatus);
    const [filterStatus, setFilterStatus] = useState<LeadStatuses[]>(filteringOptions.status);
    const [filterConnectionStatus, setFilterConnectionStatus] = useState<LeadConnectionStatuses | null>(filteringOptions.connectionStatus);
    const [filterStatusCode, setFilterStatusCode] = useState<(keyof typeof StatusCodesInfo)[]>(filteringOptions.statusCode);
    const [filterLanguage, setFilterLanguage] = useState<string[]>(filteringOptions.language);
    const [filterLrtPowerTrustDom, setFilterLrtPowerTrustDom] = useState<boolean>(filteringOptions.lrtPowerTrustDom);
    const [filterContentIndicators, setFilterContentIndicators] = useState<LeadContentIndicators[]>(filteringOptions.contentIndicators);
    const [filterAhrefsDr, setFilterAhrefsDr] = useState<[number, number]>(filteringOptions.ahrefsDr || [0, 0]);
    const [filterAhrefsTraffic, setFilterAhrefsTraffic] = useState<[number, number]>(filteringOptions.ahrefsTraffic || [0, 0]);
    const [filterManualVerification, setFilterManualVerification] = useState<LeadManualVerification | null>(
        filteringOptions.manualVerification
    );
    const [filterManualVerificationDate, setFilterManualVerificationDate] = useState<[Date, Date] | [null, null]>(
        filteringOptions.manualVerificationDate || [null, null]
    );
    const [filterCheckingDate, setFilterCheckingDate] = useState<[Date, Date] | [null, null]>(
        filteringOptions.manualVerificationDate || [null, null]
    );

    const [showFilterAhrefsDr, setShowFilterAhrefsDr] = useState<boolean>(false);
    const [showFilterAhrefsTraffic, setShowFilterAhrefsTraffic] = useState<boolean>(false);

    const isFilteringEnabled = useMemo(() => !!(
        filterPublishableStatus.length ||
        filterRepeatStatus.length ||
        filterAttentionStatus.length ||
        filterActiveStatus.length ||
        filterPluginStatus.length ||
        filterStatus.length ||
        filterConnectionStatus ||
        filterStatusCode.length ||
        filterLanguage.length ||
        filterContentIndicators.length ||
        filterLrtPowerTrustDom ||
        showFilterAhrefsDr ||
        showFilterAhrefsTraffic ||
        filterManualVerification ||
        (filterManualVerificationDate[0] || filterManualVerificationDate[1]) ||
        (filterCheckingDate[0] || filterCheckingDate[1])
    ), [
        filterPublishableStatus,
        filterRepeatStatus,
        filterAttentionStatus,
        filterActiveStatus,
        filterPluginStatus,
        filterStatus,
        filterConnectionStatus,
        filterStatusCode,
        filterLanguage,
        filterLrtPowerTrustDom,
        filterManualVerification,
        filterManualVerificationDate,
        filterCheckingDate,
        showFilterAhrefsDr,
        filterContentIndicators,
        showFilterAhrefsTraffic
    ]);

    const areFiltersDifferent = useMemo(() => (
        filterPublishableStatus !== filteringOptions.publishableStatus ||
        filterRepeatStatus !== filteringOptions.repeatStatus ||
        filterAttentionStatus !== filteringOptions.attentionStatus ||
        filterActiveStatus !== filteringOptions.activeStatus ||
        filterPluginStatus !== filteringOptions.pluginStatus ||
        filterStatus !== filteringOptions.status ||
        filterConnectionStatus !== filteringOptions.connectionStatus ||
        filterStatusCode !== filteringOptions.statusCode ||
        filterLanguage !== filteringOptions.language ||
        filterContentIndicators !== filteringOptions.contentIndicators ||
        filterLrtPowerTrustDom !== filteringOptions.lrtPowerTrustDom ||
        filterManualVerification !== filteringOptions.manualVerification ||
        ((
                (new Date(filterManualVerificationDate?.[0] ?? '').toString() !==
                    new Date(((filteringOptions.manualVerificationDate && filteringOptions.manualVerificationDate?.[0]) || '') ?? '').toString())
            ) &&
            (
                (new Date(filterManualVerificationDate?.[1] ?? '').toString() !==
                    new Date(((filteringOptions.manualVerificationDate && filteringOptions.manualVerificationDate?.[1]) || '') ?? '').toString())
            )) ||
        ((
                (new Date(filterCheckingDate?.[0] ?? '').toString() !==
                    new Date(((filteringOptions.checkingDate && filteringOptions.checkingDate?.[0]) || '') ?? '').toString())
            ) &&
            (
                (new Date(filterCheckingDate?.[1] ?? '').toString() !==
                    new Date(((filteringOptions.checkingDate && filteringOptions.checkingDate?.[1]) || '') ?? '').toString())
            )) ||
        showFilterAhrefsTraffic !== filteringOptions.ahrefsTraffic
    ), [
        filterPublishableStatus,
        filterRepeatStatus,
        filterAttentionStatus,
        filterActiveStatus,
        filterPluginStatus,
        filterStatus,
        filterConnectionStatus,
        filterStatusCode,
        filterLanguage,
        filterLrtPowerTrustDom,
        filterAhrefsDr,
        filterAhrefsTraffic,
        showFilterAhrefsDr,
        showFilterAhrefsTraffic,
        filterManualVerification,
        filterManualVerificationDate,
        filterCheckingDate,
        filterContentIndicators,
        filteringOptions
    ]);

    useEffect(() => {
        const realTimeLeadsListRestart = (): void => {
            setFilterPublishableStatus([]);
            setFilterRepeatStatus([]);
            setFilterAttentionStatus([]);
            setFilterActiveStatus([]);
            setFilterPluginStatus([]);
            setFilterStatus([]);
            setFilterConnectionStatus(null);
            setFilterStatusCode([]);
            setFilterLanguage([]);
            setFilterContentIndicators([]);
            setFilterLrtPowerTrustDom(false);
            setFilterManualVerification(null);
            setFilterAhrefsDr([0, 0]);
            setFilterAhrefsTraffic([0, 0]);
            setFilterManualVerificationDate([null, null]);
            handleSetFilterCheckingDate([null, null]);

            setShowFilterAhrefsDr(false);
            setShowFilterAhrefsTraffic(false);
        };
        window.clientIo?.on(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);

        return () => {
            window.clientIo?.off(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);
        };
    }, []);

    useEffect(() => {
        if (!isFilterOpen) {
            setTimeout(() => {
                handleSetFiltersCurrent();
            }, 150);
        }
    }, [isFilterOpen]);

    const {
        data: languagesList,
        isFetching: isLanguagesListFetching
    } = useGetLeadsLanguageQuery(undefined, { skip: !isFilterOpen });

    const handleSetFilterPublishableStatus = useCallback((value: boolean[]): void => setFilterPublishableStatus(value), []);
    const handleSetFilterRepeatStatus = useCallback((value: boolean[]): void => setFilterRepeatStatus(value), []);
    const handleSetFilterAttentionStatus = useCallback((value: boolean[]): void => setFilterAttentionStatus(value), []);
    const handleSetFilterActiveStatus = useCallback((value: boolean[]): void => setFilterActiveStatus(value), []);
    const handleSetFilterPluginStatus = useCallback((value: boolean[]): void => setFilterPluginStatus(value), []);
    const handleSetFilterStatus = useCallback((value: LeadStatuses[]): void => setFilterStatus(value), []);
    const handleSetFilterConnectionStatus = useCallback(
        (value: LeadConnectionStatuses | undefined): void => setFilterConnectionStatus(value ?? null),
        []
    );
    const handleSetFilterStatusCode = useCallback((value: (keyof typeof StatusCodesInfo)[]): void => setFilterStatusCode(value), []);
    const handleSetFilterLanguage = useCallback((value: string[]): void => setFilterLanguage(value), []);
    const handleSetLrtPowerTrustDom = useCallback((value: boolean): void => setFilterLrtPowerTrustDom(value), []);
    const handleSetFilterContentIndicators = useCallback((value: LeadContentIndicators[]): void => setFilterContentIndicators(value), []);
    const handleSetAhrefsDrFrom = useCallback((value: number | null): void => setFilterAhrefsDr(prevValue => [value ?? 0, prevValue[1]]), []);
    const handleSetAhrefsDrTo = useCallback((value: number | null): void => setFilterAhrefsDr(prevValue => [prevValue[0], value ?? 0]), []);
    const handleSetAhrefsTrafficFrom = useCallback((value: number | null): void => setFilterAhrefsTraffic(prevValue => [value ?? 0, prevValue[1]]), []);
    const handleSetAhrefsTrafficTo = useCallback((value: number | null): void => setFilterAhrefsTraffic(prevValue => [prevValue[0], value ?? 0]), []);
    const handleSetFilterManualVerification = useCallback((value: LeadManualVerification): void => setFilterManualVerification(value), []);
    const handleSetFilterManualVerificationDate = useCallback((values: RangeValue<Date>): void => {
        if (values && (values[0] && values[1])) {
            setFilterManualVerificationDate([
                values[0],
                values[1]
            ]);
        } else {
            setFilterManualVerificationDate([null, null]);
        }
    }, []);
    const handleSetFilterCheckingDate = useCallback((values: RangeValue<Date>): void => {
        if (values && (values[0] && values[1])) {
            setFilterCheckingDate([
                values[0],
                values[1]
            ]);
        } else {
            setFilterCheckingDate([null, null]);
        }
    }, []);

    const handleClearFilterByAhrefsDr = useCallback((): void => setFilterAhrefsDr([0, 0]), []);
    const handleClearFilterByAhrefsTraffic = useCallback((): void => setFilterAhrefsTraffic([0, 0]), []);

    const handleShowFilterAhrefsDr = useCallback((value: boolean): void => setShowFilterAhrefsDr(value), []);
    const handleShowFilterAhrefsTraffic = useCallback((value: boolean): void => setShowFilterAhrefsTraffic(value), []);

    const handleResetFilters = useCallback((): void => {
        setFilterPublishableStatus([]);
        setFilterRepeatStatus([]);
        setFilterAttentionStatus([]);
        setFilterActiveStatus([]);
        setFilterPluginStatus([]);
        setFilterStatus([]);
        setFilterConnectionStatus(null);
        setFilterStatusCode([]);
        setFilterLanguage([]);
        setFilterContentIndicators([]);
        setFilterLrtPowerTrustDom(false);
        setFilterManualVerification(null);
        setFilterAhrefsDr([0, 0]);
        setFilterAhrefsTraffic([0, 0]);
        setFilterManualVerificationDate([null, null]);
        handleSetFilterCheckingDate([null, null]);

        setShowFilterAhrefsDr(false);
        setShowFilterAhrefsTraffic(false);

        dispatch(resetLeadsFiltering());

        handleToggleFilterPopover();

        listIdParam && dispatch(fetchLeadsByPageCount({
            id: listIdParam,
            page: currentPage,
            search: searchingQuery,
            sortBy: [sortingOptions.field, sortingOptions.variant],
            filterByPublishableStatus: [],
            filterByRepeatStatus: [],
            filterByAttentionStatus: [],
            filterByActiveStatus: [],
            filterByPluginStatus: [],
            filterByStatus: [],
            filterByConnectionStatus: null,
            filterByStatusCode: [],
            filterByLanguage: [],
            filterByLrtPowerTrustDom: false,
            filterByAhrefsDr: false,
            filterByAhrefsTraffic: false,
            filterByManualVerification: null,
            filterByManualVerificationDate: false,
            filterByCheckingDate: false,
            filterByContentIndicators: []
        }));
    }, [
        listIdParam,
        currentPage,
        isFilterOpen,
        searchingQuery,
        sortingOptions
    ]);

    const handleFindByFilters = useCallback((): void => {
        if (areFiltersDifferent) {
            dispatch(applyLeadsFiltering({
                publishableStatus: filterPublishableStatus,
                repeatStatus: filterRepeatStatus,
                attentionStatus: filterAttentionStatus,
                activeStatus: filterActiveStatus,
                pluginStatus: filterPluginStatus,
                status: filterStatus,
                connectionStatus: filterConnectionStatus,
                statusCode: filterStatusCode,
                language: filterLanguage,
                lrtPowerTrustDom: filterLrtPowerTrustDom,
                ahrefsDr: showFilterAhrefsDr ? filterAhrefsDr : false,
                ahrefsTraffic: showFilterAhrefsTraffic ? filterAhrefsTraffic : false,
                manualVerification: filterManualVerification,
                manualVerificationDate: (filterManualVerificationDate[0] && filterManualVerificationDate[1])
                    ? filterManualVerificationDate
                    : [null, null],
                checkingDate: (filterCheckingDate[0] && filterCheckingDate[1])
                    ? filterCheckingDate
                    : [null, null],
                contentIndicators: filterContentIndicators
            }));

            listIdParam && dispatch(fetchLeadsByPageCount({
                id: listIdParam,
                page: 1,
                search: searchingQuery,
                sortBy: [sortingOptions.field, sortingOptions.variant],
                filterByPublishableStatus: filterPublishableStatus,
                filterByRepeatStatus: filterRepeatStatus,
                filterByAttentionStatus: filterAttentionStatus,
                filterByActiveStatus: filterActiveStatus,
                filterByPluginStatus: filterPluginStatus,
                filterByStatus: filterStatus,
                filterByConnectionStatus: filterConnectionStatus,
                filterByStatusCode: filterStatusCode,
                filterByLanguage: filterLanguage,
                filterByLrtPowerTrustDom: filterLrtPowerTrustDom,
                filterByAhrefsDr: showFilterAhrefsDr ? filterAhrefsDr : false,
                filterByAhrefsTraffic: showFilterAhrefsTraffic ? filterAhrefsTraffic : false,
                filterByManualVerification: filterManualVerification,
                filterByManualVerificationDate: filterManualVerificationDate,
                filterByCheckingDate: filterCheckingDate,
                filterByContentIndicators: filterContentIndicators
            }));

            handleToggleFilterPopover();

            searchParams.delete('page');
            setSearchParams(searchParams);
        }
    }, [
        areFiltersDifferent,
        filterPublishableStatus,
        filterRepeatStatus,
        filterAttentionStatus,
        filterActiveStatus,
        filterPluginStatus,
        filterStatus,
        filterConnectionStatus,
        filterStatusCode,
        filterLanguage,
        filterLrtPowerTrustDom,
        filterAhrefsDr,
        filterAhrefsTraffic,
        showFilterAhrefsDr,
        showFilterAhrefsTraffic,
        filterManualVerification,
        filterManualVerificationDate,
        filterCheckingDate,
        searchParams,
        filterContentIndicators,
        listIdParam
    ]);

    const handleSetFiltersCurrent = useCallback((): void => {
        setFilterPublishableStatus(filteringOptions.publishableStatus);
        setFilterRepeatStatus(filteringOptions.repeatStatus);
        setFilterAttentionStatus(filteringOptions.attentionStatus);
        setFilterActiveStatus(filteringOptions.activeStatus);
        setFilterPluginStatus(filteringOptions.pluginStatus);
        setFilterStatus(filteringOptions.status);
        setFilterConnectionStatus(filteringOptions.connectionStatus);
        setFilterStatusCode(filteringOptions.statusCode);
        setFilterLanguage(filteringOptions.language);
        setFilterContentIndicators(filteringOptions.contentIndicators);
        setFilterLrtPowerTrustDom(filteringOptions.lrtPowerTrustDom);
        setFilterAhrefsDr(filteringOptions.ahrefsDr || [0, 0]);
        setFilterAhrefsTraffic(filteringOptions.ahrefsTraffic || [0, 0]);
        setFilterManualVerification(filteringOptions.manualVerification);
        setFilterManualVerificationDate(filteringOptions.manualVerificationDate || [null, null]);
        setFilterCheckingDate(filteringOptions.checkingDate || [null, null]);

        setShowFilterAhrefsDr(showFilterAhrefsDr);
        setShowFilterAhrefsTraffic(showFilterAhrefsTraffic);
    }, [filteringOptions, showFilterAhrefsDr, showFilterAhrefsTraffic]);

    return (
        <Badge dot={isFilteringEnabled}>
            <Popover
                title="Filter by"
                arrowPointAtCenter
                placement="bottom"
                content={
                    <Panel
                        filterPublishableStatus={filterPublishableStatus}
                        filterRepeatStatus={filterRepeatStatus}
                        filterAttentionStatus={filterAttentionStatus}
                        filterActiveStatus={filterActiveStatus}
                        filterPluginStatus={filterPluginStatus}
                        filterStatus={filterStatus}
                        filterStatusCode={filterStatusCode}
                        filterConnectionStatus={filterConnectionStatus}
                        filterLrtPowerTrustDom={filterLrtPowerTrustDom}
                        filterAhrefsDr={filterAhrefsDr}
                        filterAhrefsTraffic={filterAhrefsTraffic}
                        showFilterAhrefsDr={showFilterAhrefsDr}
                        showFilterAhrefsTraffic={showFilterAhrefsTraffic}
                        filterLanguage={filterLanguage}
                        languagesList={languagesList ?? []}
                        isLanguagesListFetching={isLanguagesListFetching}
                        filterManualVerification={filterManualVerification}
                        filterManualVerificationDate={filterManualVerificationDate}
                        filterCheckingDate={filterCheckingDate}
                        filterContentIndicators={filterContentIndicators}
                        handleSetFilterPublishableStatus={handleSetFilterPublishableStatus}
                        handleSetFilterRepeatStatus={handleSetFilterRepeatStatus}
                        handleSetFilterAttentionStatus={handleSetFilterAttentionStatus}
                        handleSetFilterActiveStatus={handleSetFilterActiveStatus}
                        handleSetFilterPluginStatus={handleSetFilterPluginStatus}
                        handleSetFilterStatus={handleSetFilterStatus}
                        handleSetFilterStatusCode={handleSetFilterStatusCode}
                        handleShowFilterAhrefsDr={handleShowFilterAhrefsDr}
                        handleShowFilterAhrefsTraffic={handleShowFilterAhrefsTraffic}
                        handleClearFilterByAhrefsDr={handleClearFilterByAhrefsDr}
                        handleClearFilterByAhrefsTraffic={handleClearFilterByAhrefsTraffic}
                        handleSetFilterLanguage={handleSetFilterLanguage}
                        handleSetLrtPowerTrustDom={handleSetLrtPowerTrustDom}
                        handleSetAhrefsDrFrom={handleSetAhrefsDrFrom}
                        handleSetAhrefsDrTo={handleSetAhrefsDrTo}
                        handleSetAhrefsTrafficFrom={handleSetAhrefsTrafficFrom}
                        handleSetAhrefsTrafficTo={handleSetAhrefsTrafficTo}
                        handleSetFilterConnectionStatus={handleSetFilterConnectionStatus}
                        handleSetFilterManualVerification={handleSetFilterManualVerification}
                        handleSetFilterManualVerificationDate={handleSetFilterManualVerificationDate}
                        handleSetFilterCheckingDate={handleSetFilterCheckingDate}
                        handleSetFilterContentIndicators={handleSetFilterContentIndicators}
                        handleResetFilters={handleResetFilters}
                        handleFindByFilters={handleFindByFilters}
                        setIsFilterOpen={setIsFilterOpen}
                    />
                }
                trigger="click"
                open={isFilterOpen}
            >
                <Button
                    onClick={handleToggleFilterPopover}
                    icon={isFilterOpen ? <FunnelFill/> : <Funnel/>}
                />
            </Popover>
        </Badge>
    );
});
