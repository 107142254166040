/* eslint-disable no-console */
import socketIOClient from 'socket.io-client';

export const connectToSockets = (userId: string): void => {
    console.log(
        '%cconnected',
        'color: #389e0d; font-weight: bold; font-size: 12px;'
    );
    window.clientIo = socketIOClient(
        process.env.REACT_APP_API_HOST ?? '',
        { multiplex: false, query: { userId } }
    );
};

export const disconnectFromSockets = (): void => {
    console.log(
        '%cdisconnected',
        'color: #cf1322; font-weight: bold; font-size: 12px;'
    );
    window.clientIo?.disconnect();
};
