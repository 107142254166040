import { Modal as ModalAntd, ModalProps } from 'antd';
import React, { FC } from 'react';
import { XCircleFill } from 'react-bootstrap-icons';

interface Props extends ModalProps {
    children: React.ReactNode;
}

export const Modal: FC<Props> = ({ children, ...others }: Props) => {
    return (
        <ModalAntd closeIcon={<XCircleFill size={18}/>} destroyOnClose {...others}>
            {children}
        </ModalAntd>
    );
};
