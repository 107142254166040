import React, { FC, memo } from 'react';
import { LeadLanguage } from '../../interfaces/leads';
import { getFlagEmoji } from '../../utils/flagsEmoji';
import { TextEmpty } from '../TextEmpty';

interface Props {
    lang: LeadLanguage | null;
}

export const LanguageLabel: FC<Props> = memo(({ lang }: Props) => {
    return (
        <>
            {
                lang
                    ? <div className="language-label">
                        <span>{getFlagEmoji(lang.name)}</span>
                        &nbsp;{lang?.iso_name}
                    </div>
                    : <TextEmpty/>
            }
        </>
    );
});
