/* eslint-disable react/jsx-indent-props */
import { SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Descriptions, Divider, message, Row, Tag, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { FC, memo, useState } from 'react';
import { BoxArrowUpRight, Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import { ConnectionBadge } from '../../../../components/ConnectionBadge';
import { ContentIndicators } from '../../../../components/ContentIndicators';
import { LanguageLabel } from '../../../../components/LanguageLabel';
import { LeadEngineTag } from '../../../../components/LeadEngineTag';
import { LeadStatusTag } from '../../../../components/LeadStatusTag';
import { LeadTypeTag } from '../../../../components/LeadTypeTag';
import { PageStatusBadge } from '../../../../components/PageStatusBadge';
import { TextEmpty } from '../../../../components/TextEmpty';
import { LeadDossier, LeadStatuses, LeadTypes } from '../../../../interfaces/leads';
import { API } from '../../../../service/api';
import { Routes } from '../../../../typedef';
import { toLocaleDate } from '../../../../utils/date';
import { LeadManualVerification } from '../../../LeadManualVerification';
import { RecoveryWebsiteAccess } from '../../../RecoveryWebsiteAccess';

type Props = {
    id: LeadDossier['id'];
    status: LeadDossier['status'];
    statusCode: LeadDossier['statusCode'];
    metaTitle: LeadDossier['metaTitle'];
    url: LeadDossier['url'];
    connectionStatus: LeadDossier['connectionStatus'];
    homePage: LeadDossier['homePage'];
    type: LeadDossier['type'];
    language: LeadDossier['language'];
    engine: LeadDossier['engine'];
    domain: LeadDossier['domain'];
    subdomain: LeadDossier['subdomain'];
    blogProbability: LeadDossier['blogProbability'];
    regularUrl?: LeadDossier['regularUrl'];
    regularLogin?: LeadDossier['regularLogin'];
    regularPassword?: LeadDossier['regularPassword'];
    shellyUrl?: LeadDossier['shellyUrl'];
    shellyPassword?: LeadDossier['shellyPassword'];
    errorDescription: LeadDossier['errorDescription'];
    manualVerification: LeadDossier['manualVerification'];
    manualVerificatedAt: LeadDossier['manualVerificatedAt'];
    createdAt: LeadDossier['createdAt'];
    checkedAt: LeadDossier['checkedAt'];
    contentIndicators: LeadDossier['contentIndicators'];
    websiteID: LeadDossier['websiteID'];
    offerId: LeadDossier['offerId'];
    notes: LeadDossier['notes'];
    handleReloadLeads?: () => void;
}

const { Text } = Typography;

const Notes = (props: { defaultValue: string, leadId: string, handleReloadLeads?: () => void }) => {
    const { defaultValue, leadId, handleReloadLeads } = props;

    const [notes, setNotes] = useState<string>(defaultValue);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleOnClickSave = async () => {
        setIsLoading(true);
        await API.leads.updateNotes(leadId, notes);
        setIsLoading(false);
        message.success('Notes has been saved!');
        if (handleReloadLeads) handleReloadLeads();
    };

    return (<>
        <Row>
            <Col span={24}>
                <TextArea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows={4}
                    placeholder="Notes..."
                />
            </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
            <Col offset={20} span={4}>
                <Button
                    type="primary"
                    size={'middle'}
                    icon={<SaveOutlined/>}
                    block
                    loading={isLoading}
                    onClick={handleOnClickSave}
                >
                    Save
                </Button>
            </Col>
        </Row>

        <Divider/>
    </>);
};

export const GeneralBlock: FC<Props> = memo((
    {
        id,
        status,
        metaTitle,
        url,
        blogProbability,
        connectionStatus,
        homePage,
        type,
        engine,
        domain,
        subdomain,
        statusCode,
        regularUrl,
        language,
        regularLogin,
        regularPassword,
        shellyUrl,
        shellyPassword,
        errorDescription,
        manualVerification,
        manualVerificatedAt,
        createdAt,
        checkedAt,
        contentIndicators,
        websiteID,
        offerId,
        notes,
        handleReloadLeads
    }: Props) => {
    return (
        <>
            {
                errorDescription &&
                <>
                    <Alert
                        className="lead-error-alert"
                        type="error"
                        message={errorDescription}
                    />
                    <br/>
                </>
            }
            {websiteID && <>
                <Button type="primary" block href={`${Routes.WEBSITES}/${websiteID}/view`} target="_blank">
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <span>Go to website</span>
                        <BoxArrowUpRight size={12}/>
                    </div>
                </Button>
                <Divider style={{ margin: '16px 0' }}/>
            </>
            }
            <Notes defaultValue={notes ?? ''} leadId={id} handleReloadLeads={handleReloadLeads}/>

            <Descriptions
                column={1}
                layout="vertical"
                labelStyle={{ marginBottom: '-8px' }}
                size="small"
            >
                <Descriptions.Item label={<Text type="secondary">CRM Offer</Text>}>
                    <Text
                        copyable={{
                            text: `https://manager.adzz.com/#/v1_Offer/${offerId}`,
                            icon: [
                                <Clipboard size={12} key="copy-icon"/>,
                                <ClipboardCheck size={12} key="copied-icon"/>,
                            ],
                            tooltips: ['Copy', 'Copied!'],
                        }}
                    >
                        <a target="_blank" href={`https://manager.adzz.com/#/v1_Offer/${offerId}`} rel="noreferrer">
                            {offerId}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                        </a>
                    </Text>
                </Descriptions.Item>

                <Descriptions.Item label={<Text type="secondary">ID</Text>}>
                    <Text
                        copyable={{
                            text: id,
                            icon: [
                                <Clipboard size={12} key="copy-icon"/>,
                                <ClipboardCheck size={12} key="copied-icon"/>,
                            ],
                            tooltips: ['Copy', 'Copied!'],
                        }}
                    >
                        <Tag style={{ marginRight: 0, fontSize: 14 }}>{id}</Tag>
                    </Text>
                </Descriptions.Item>

                <Descriptions.Item label={<Text type="secondary">Recovery Access</Text>}>
                    <RecoveryWebsiteAccess leadId={id}/>
                </Descriptions.Item>

                <Descriptions.Item label={<Text type="secondary">Manual verification</Text>}>
                    <LeadManualVerification
                        leadId={id}
                        manualVerification={manualVerification}
                        manualVerificatedAt={manualVerificatedAt}
                        offerId={offerId}
                        handleReloadLeads={handleReloadLeads}
                    />
                </Descriptions.Item>

                <Descriptions.Item label={<Text type="secondary">Type</Text>}>
                    <Text>
                        <LeadTypeTag type={type}/>
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Access status</Text>}>
                    <Text>
                        <LeadStatusTag status={status}/>
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Connection status</Text>}>
                    <Text>
                        <ConnectionBadge status={connectionStatus}/>
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Page code</Text>}>
                    <Text>
                        <PageStatusBadge statusCode={statusCode}/>
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Engine</Text>}>
                    <Text>
                        <LeadEngineTag engine={engine}/>
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Language</Text>}>
                    <Text>
                        <LanguageLabel lang={language}/>
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Meta title</Text>}>
                    {
                        status === LeadStatuses.FAILED && !metaTitle &&
                        <Text type="secondary" italic>(not available for this lead because of status)</Text>
                    }
                    {
                        (status !== LeadStatuses.FAILED && metaTitle) &&
                        <Text
                            copyable={{
                                text: metaTitle,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>,
                                ],
                                tooltips: ['Copy', 'Copied!'],
                            }}
                        >
                            {metaTitle}
                        </Text>
                    }
                    {
                        (status !== LeadStatuses.FAILED && !metaTitle) && <TextEmpty/>
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Blog probability</Text>}>
                    {
                        status === LeadStatuses.FAILED &&
                        <Text type="secondary" italic>(not available for this lead because of status)</Text>
                    }
                    {
                        status !== LeadStatuses.FAILED && (
                            blogProbability
                                ? 'Detected'
                                : 'Not detected'
                        )
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Created at</Text>}>
                    {toLocaleDate(createdAt)}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Checked at</Text>}>
                    {
                        checkedAt
                            ? toLocaleDate(checkedAt)
                            : <TextEmpty/>
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">URL</Text>}>
                    {
                        <Text
                            copyable={{
                                text: url,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>,
                                ],
                                tooltips: ['Copy', 'Copied!'],
                            }}
                        >
                            <a target="_blank" href={url} rel="noreferrer">
                                {url}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                            </a>
                        </Text>
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Base URL</Text>}>
                    {
                        <Text
                            copyable={{
                                text: regularUrl ?? shellyUrl,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>,
                                ],
                                tooltips: ['Copy', 'Copied!'],
                            }}
                        >
                            <a target="_blank" href={regularUrl ?? shellyUrl} rel="noreferrer">
                                {regularUrl ?? shellyUrl}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                            </a>
                        </Text>
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Home page</Text>}>
                    {
                        <Text
                            copyable={{
                                text: homePage,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>,
                                ],
                                tooltips: ['Copy', 'Copied!'],
                            }}
                        >
                            <a target="_blank" href={homePage} rel="noreferrer">
                                {homePage}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                            </a>
                        </Text>
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Domain</Text>}>
                    {
                        <Text
                            copyable={{
                                text: domain,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>,
                                ],
                                tooltips: ['Copy', 'Copied!'],
                            }}
                        >
                            {domain}
                        </Text>
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Subdomain</Text>}>
                    {
                        <Text
                            copyable={{
                                text: subdomain,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>,
                                ],
                                tooltips: ['Copy', 'Copied!'],
                            }}
                        >
                            {subdomain}
                        </Text>
                    }
                </Descriptions.Item>
                {
                    type === LeadTypes.REGULAR &&
                    <>
                        <Descriptions.Item label={<Text type="secondary">Login</Text>}>
                            <Text
                                copyable={{
                                    text: regularLogin,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>,
                                    ],
                                    tooltips: ['Copy', 'Copied!'],
                                }}
                            >
                                {regularLogin}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Password</Text>}>
                            <Text
                                copyable={{
                                    text: regularPassword,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>,
                                    ],
                                    tooltips: ['Copy', 'Copied!'],
                                }}
                            >
                                {regularPassword}
                            </Text>
                        </Descriptions.Item>
                    </>
                }
                {
                    type === LeadTypes.SHELLY &&
                    <Descriptions.Item label={<Text type="secondary">Password</Text>}>
                        {
                            shellyPassword
                                ? <Text
                                    copyable={{
                                        text: shellyPassword,
                                        icon: [
                                            <Clipboard size={12} key="copy-icon"/>,
                                            <ClipboardCheck size={12} key="copied-icon"/>,
                                        ],
                                        tooltips: ['Copy', 'Copied!'],
                                    }}
                                >
                                    {shellyPassword}
                                </Text>
                                : <TextEmpty/>
                        }
                    </Descriptions.Item>
                }
                <Descriptions.Item label={<Text type="secondary">Content indicator</Text>}>
                    <ContentIndicators leadID={id} contentIndicators={contentIndicators}/>
                </Descriptions.Item>
            </Descriptions>
        </>
    );
});
