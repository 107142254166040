import * as yup from 'yup';
import {
    LeadConnectionStatuses,
    LeadContentIndicators,
    LeadEngines,
    LeadFields,
    LeadManualVerification,
    LeadStatuses,
    LeadTypes
} from '../../interfaces/leads';
import { StatusCodesInfo } from '../../typedef';

export const regularRegex = /((https|http):\/\/([\w.:\\-]+)(\/[\w .\\-]+\/?)*)[#?@|](.*):(.*)/im;
export const shellyRegex = /((https|http):\/\/([\w.:\\-]+)(\/[\w .\\-]+\/?)*)[#?@|](.*)/im;

export const validationSchema = yup.object()
    .shape({
        [LeadFields.URL]: yup.lazy(value => {
            if (regularRegex.test(value)) {
                return yup.string()
                    .matches(regularRegex, 'url must be type REGULAR or SHELLY')
                    .required('url is a required')
                    .url('url must be a valid URL')
                    .min(10, 'url must be at least 10 characters')
                    .max(255, 'url must be not more than 255 characters');
            } else {
                return yup.string()
                    .matches(shellyRegex, 'url must be type REGULAR or SHELLY')
                    .required('url is a required')
                    .url('url must be a valid URL')
                    .min(10, 'url must be at least 10 characters')
                    .max(255, 'url must be not more than 255 characters');
            }
        }),
        [LeadFields.HOME_PAGE]: yup.string()
            .required('home page is a required')
            .url('home page nust be valid URL')
            .min(1, 'home page must be at least 1 characters')
            .max(255, 'home page must be not more than 255 characters'),
        [LeadFields.DOMAIN]: yup.string()
            .required('domain is a required')
            .min(1, 'domain must be at least 1 characters')
            .max(255, 'domain must be not more than 255 characters'),
        [LeadFields.SUBDOMAIN]: yup.string()
            .required('subdomain is a required')
            .min(1, 'subdomain must be at least 1 characters')
            .max(255, 'subdomain must be not more than 255 characters'),
        [LeadFields.TYPE]: yup.string()
            .required('type is required')
            .oneOf([LeadTypes.REGULAR,
                LeadTypes.SHELLY,
                LeadTypes.CUSTOM], 'type must be one of REGULAR or SHELLY or CUSTOM'),

        [LeadFields.REGULAR_LOGIN]: yup.string()
            .min(1, 'regular login must be at least 1 characters')
            .max(255, 'regular login must be not more than 255 characters'),
        [LeadFields.REGULAR_PASSWORD]: yup.string()
            .min(1, 'regular password must be at least 1 characters')
            .max(255, 'regular password must be not more than 255 characters'),
        [LeadFields.REGULAR_URL]: yup.string()
            .min(1, 'regular url must be at least 1 characters')
            .max(255, 'regular url must be not more than 255 characters')
            .url('regular url must be valid URL'),

        [LeadFields.SHELLY_PASSWORD]: yup.string()
            .max(255, 'shelly password must be not more than 255 characters'),
        [LeadFields.SHELLY_URL]: yup.string()
            .min(1, 'shelly url must be at least 1 characters')
            .max(255, 'shelly url must be not more than 255 characters')
            .url('shelly url must be valid URL'),

        [LeadFields.ENGINE]: yup.string()
            .optional()
            .oneOf([LeadEngines.WORDPRESS, LeadEngines.JOOMLA]),
        [LeadFields.STATUS]: yup.string()
            .required('access status is required')
            .oneOf([
                LeadStatuses.SUCCESSFUL,
                LeadStatuses.UNPROCESSABLE,
                LeadStatuses.FAILED
            ]),
        [LeadFields.STATUS_CODE]: yup.string()
            .required('page code is required')
            .oneOf(Object.keys(StatusCodesInfo)),
        [LeadFields.CONNECTION_STATUS]: yup.string()
            .required('connection status is required')
            .oneOf([
                LeadConnectionStatuses.ONLINE,
                LeadConnectionStatuses.OFFLINE
            ]),
        [LeadFields.META_TITLE]: yup.string()
            .max(255, 'shelly password must be not more than 255 characters')
            .optional(),
        [LeadFields.BLOG_PROBABILITY]: yup.boolean(),
        [LeadFields.LANGUAGE]: yup.object({ iso_name: yup.string(), name: yup.string() })
            .optional(),
        [LeadFields.MANUAL_VERIFICATION]: yup.string()
            .oneOf([
                LeadManualVerification.APPROVED,
                LeadManualVerification.DECLINED,
                LeadManualVerification.NOT_CHECKED
            ]),
        [LeadFields.CONTENT_INDICATORS]: yup.array()
            .of(yup.string()
                .oneOf([LeadContentIndicators.B2F, LeadContentIndicators.OUTREACH])),

        [LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM]: yup.string()
            .optional(),
        [LeadFields.LINK_INFO_LRT_POWER_TRUST]: yup.string()
            .optional(),
        [LeadFields.LINK_INFO_LRT_POWER_DOM]: yup.string()
            .optional(),
        [LeadFields.LINK_INFO_LRT_TRUST_DOM]: yup.string()
            .optional(),
        [LeadFields.LINK_INFO_LRT_TITLE_HOME]: yup.string()
            .optional(),
        [LeadFields.LINK_INFO_LRT_IP]: yup.string()
            .matches(/(^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$)|^$/, 'invalid IP address'),
        [LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING]: yup.string()
            .optional(),
        [LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC]: yup.string()
            .optional()
    });
