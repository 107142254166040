import { Drawer as DrawerAntd, DrawerProps } from 'antd';
import React, { FC } from 'react';
import { XCircleFill } from 'react-bootstrap-icons';

interface Props extends DrawerProps {
    children: React.ReactNode;
}

export const Drawer: FC<Props> = ({ children, ...others }: Props) => {
    return (
        <DrawerAntd
            className={others.className ?? 'custom-drawer'}
            closeIcon={<XCircleFill size={18}/>}
            destroyOnClose {...others}
        >
            {children}
        </DrawerAntd>
    );
};
