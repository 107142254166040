const correctEmojiLanguages: Record<string, string> = {
    en: 'gb',
    ja: 'jp',
    he: 'il',
    uk: 'ua',
    el: 'gr',
    sv: 'se',
    da: 'dk',
    fa: 'pe',
    'en-ca': 'ca',
    ca: 'es',
    'sr-Latn': 'rs',
    'sr-Cyrl': 'rs',
    ko: 'kr',
    hi: 'in',
    nb: 'no',
    cs: 'cz',
    ur: 'pk',
    sq: 'al',
    fil: 'ph',
    hy: 'am',
    ka: 'ge',
    'zh hans': 'cn',
    'zh-Hans': 'cn',
    'zh-Hant': 'cn',
    'zh hant': 'cn'
};

export const getFlagEmoji = (countryCode: string): string => {
    const formattedCountryCode = correctEmojiLanguages[countryCode] ?? countryCode;

    if (!formattedCountryCode) return '';

    const codePoints = formattedCountryCode
        .toUpperCase()
        .split('')
        .map((char: string) => 127397 + (char.codePointAt(0) ?? 0));

    return String.fromCodePoint(...codePoints);
};
