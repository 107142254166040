/* eslint-disable react/jsx-indent-props */
import { Button, Card, Col, Empty, message, PageHeader, Result, Row, Spin, Tooltip, Typography } from 'antd';
import { AxiosError } from 'axios';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowLeftCircleFill, InfoCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useInitPageSockets } from '../../../hooks/useInitPageSockets';
import { ErrorType } from '../../../interfaces';
import { SortFields, SortVariants } from '../../../interfaces/leads';
import { HeaderInner } from '../../../modules/Header/headerInner.hoc';
import { LeadsTable } from '../../../modules/Leads/LeadsTable';
import { API } from '../../../service/api';
import { fetchLeadsByPageCount, fetchListById } from '../../../store/actions/leadsLists';
import { resetLeadsFiltering, resetLeadsSearching, resetLeadsSorting } from '../../../store/slices/leads';
import { clearCurrentList, clearCurrentListError, disableLoading } from '../../../store/slices/leadsList';
import { DefaultPageLimits, Routes, RoutesParams, StatusCodes } from '../../../typedef';
import { LeadsListViewingHeaderControls } from './headerControls';
import { Stats } from './stats';

export const ViewListModal: FC = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { list_id: listIdParam } = useParams<RoutesParams.LIST_ID>();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        isLoading: listIsLoading,
        currentListError,
        currentListLeads,
        currentListInfo,
        isLeadsListTableLoading,
        currentDeletingRowId
    } = useSelector((state) => state.leadsLists);
    const {
        sorting: sortingOptions,
        filtering: filteringOptions,
        searching: searchingQuery
    } = useSelector((state) => state.leads);
    const [pageLeadsSize] = useState<number>(DefaultPageLimits.LEADS);
    const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get('page') ?? 1));
    const [firstFetchLeads, setFirstFetchLeads] = useState<boolean>(true);
    const [isCopyLinksLoading, setIsCopyLinksLoading] = useState<boolean>(false);
    const [isLeadsListLinksCopied, setIsLeadsListLinksCopied] = useState<boolean>(false);
    const exportCsvRef = useRef<HTMLAnchorElement>(null);

    const handleClose = (): void => {
        navigate(currentPage > 1 ? `/?page=${currentPage}` : '/');
    };

    useEffect(() => {
        listIdParam && dispatch(fetchListById({ id: listIdParam, page: currentPage }));

        dispatch(resetLeadsFiltering());
        dispatch(resetLeadsSorting());
        dispatch(resetLeadsSearching());

        return () => {
            dispatch(disableLoading());
            dispatch(clearCurrentList());
            dispatch(clearCurrentListError());

            dispatch(resetLeadsFiltering());
            dispatch(resetLeadsSorting());
            dispatch(resetLeadsSearching());
        };
    }, []);

    useInitPageSockets({
        currentPage,
        setCurrentPage,
        handleClose
    });

    useEffect(() => {
        setCurrentPage(Number(searchParams.get('page') ?? 1));
    }, [searchParams.get('page')]);

    useEffect(() => {
        if (!firstFetchLeads && currentListLeads) {
            listIdParam && dispatch(fetchLeadsByPageCount({
                id: listIdParam,
                page: currentPage,
                search: searchingQuery,
                sortBy: [sortingOptions.field, sortingOptions.variant],
                filterByPublishableStatus: filteringOptions.publishableStatus,
                filterByRepeatStatus: filteringOptions.repeatStatus,
                filterByAttentionStatus: filteringOptions.attentionStatus,
                filterByActiveStatus: filteringOptions.activeStatus,
                filterByPluginStatus: filteringOptions.pluginStatus,
                filterByStatus: filteringOptions.status,
                filterByConnectionStatus: filteringOptions.connectionStatus,
                filterByStatusCode: filteringOptions.statusCode,
                filterByLanguage: filteringOptions.language,
                filterByLrtPowerTrustDom: filteringOptions.lrtPowerTrustDom,
                filterByAhrefsDr: filteringOptions.ahrefsDr,
                filterByAhrefsTraffic: filteringOptions.ahrefsTraffic,
                filterByManualVerification: filteringOptions.manualVerification,
                filterByManualVerificationDate: filteringOptions.manualVerificationDate,
                filterByCheckingDate: filteringOptions.checkingDate,
                filterByContentIndicators: filteringOptions.contentIndicators
            }));
        }

        setFirstFetchLeads(false);
    }, [currentPage]);

    const isSortingEnabled = useMemo(() => (
        sortingOptions.variant !== SortVariants.ACS ||
        sortingOptions.field !== SortFields.NUMBER
    ), [sortingOptions]);

    const isFilteringEnabled = useMemo(() => !!(
        filteringOptions.publishableStatus.length ||
        filteringOptions.repeatStatus.length ||
        filteringOptions.attentionStatus.length ||
        filteringOptions.activeStatus.length ||
        filteringOptions.pluginStatus.length ||
        filteringOptions.manualVerification?.length ||
        filteringOptions.status.length ||
        filteringOptions.connectionStatus ||
        filteringOptions.statusCode.length ||
        filteringOptions.language.length ||
        filteringOptions.contentIndicators.length ||
        filteringOptions.lrtPowerTrustDom ||
        (filteringOptions.ahrefsDr && (
            typeof filteringOptions.ahrefsDr[0] === 'number' && typeof filteringOptions.ahrefsDr[1] === 'number'
        )) ||
        (filteringOptions.ahrefsTraffic && (
            typeof filteringOptions.ahrefsTraffic[0] === 'number' && typeof filteringOptions.ahrefsTraffic[1] === 'number'
        )) ||
        (filteringOptions.manualVerificationDate && (
            !!filteringOptions.manualVerificationDate[0] && !!filteringOptions.manualVerificationDate[1]
        )) ||
        (filteringOptions.checkingDate && (
            !!filteringOptions.checkingDate[0] && !!filteringOptions.checkingDate[1]
        ))
    ), [filteringOptions]);

    const handleEditList = useCallback((): void => {
        navigate(`${Routes.LEADS_LISTS}/${listIdParam}/edit`, { state: { backgroundLocation: '/' } });
    }, [listIdParam]);

    const handleChangeCurrentPage = useCallback((page: number): void => {
        if (page === 1) {
            searchParams.delete('page');
            setSearchParams(searchParams);
        } else {
            setSearchParams({ page: page.toString() });
        }
        setCurrentPage(page);
    }, [searchParams]);

    const handleCopyLeadsListLinks = useCallback(async (): Promise<void> => {
        try {
            if (listIdParam) {
                setIsCopyLinksLoading(true);
                const response = await API.leadsLists.getEditData(listIdParam);
                const copiedLink = response.data.links;
                void navigator.clipboard.writeText(copiedLink);
                setIsCopyLinksLoading(false);
                setIsLeadsListLinksCopied(true);
                setTimeout(() => {
                    setIsLeadsListLinksCopied(false);
                }, 2000);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            message.error((error as AxiosError<ErrorType>).response?.data.message ?? error.message);

            setIsCopyLinksLoading(false);
            setIsLeadsListLinksCopied(false);
        }
    }, [listIdParam]);

    return (
        <>
            { /* eslint-disable-next-line jsx-a11y/anchor-has-content */ /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" ref={exportCsvRef} style={{ display: 'none' }}/>
            <HeaderInner
                titleName="Leads list"
                controls={
                    (!listIsLoading && !currentListError && currentListInfo) &&
                    <LeadsListViewingHeaderControls
                        isLeadsListLinksCopied={isLeadsListLinksCopied}
                        isCopyLinksLoading={isCopyLinksLoading}
                        handleCopyLeadsListLinks={handleCopyLeadsListLinks}
                        exportCsvRef={exportCsvRef}
                        currentDeletingRowId={currentDeletingRowId}
                        currentListInfo={currentListInfo}
                        listIsLoading={listIsLoading}
                        handleEditList={handleEditList}
                        handleClose={handleClose}
                    />
                }
            />
            <Card>
                {(listIsLoading && !currentListInfo) && <Spin className="centered-spinner"/>}
                {(!listIsLoading && !currentListError && currentListInfo)
                    ? (
                        <>
                            <PageHeader
                                className="lead-page-header"
                                onBack={(): void => navigate('/')}
                                backIcon={<ArrowLeftCircleFill size={16}/>}
                                tags={
                                    currentListInfo.comment
                                        ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Tooltip
                                                placement="bottom"
                                                overlayInnerStyle={{
                                                    whiteSpace: 'pre',
                                                    minWidth: 150,
                                                    width: 'fit-content'
                                                }}
                                                title={currentListInfo.comment}
                                            >
                                                <InfoCircleFill style={{ cursor: 'help' }} size={20}/>
                                            </Tooltip>
                                        </div>
                                        : undefined
                                }
                                title={
                                    currentListInfo.list_name
                                        ? <>View list: {<Typography.Text
                                            strong
                                        >{currentListInfo.list_name}</Typography.Text>}</>
                                        : 'View list'
                                }
                            />
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Stats
                                        status={currentListInfo.status}
                                        owner={currentListInfo.owner}
                                        createdAt={currentListInfo.created_at}
                                        checkedAt={currentListInfo.checked_at}
                                        statistics={currentListInfo.statistics}
                                    />
                                </Col>
                                <Col span={24} style={{ wordBreak: 'break-all' }}>
                                    <LeadsTable
                                        listID={currentListInfo._id}
                                        isSearchingQuery={!!searchingQuery}
                                        sortVariant={sortingOptions.variant}
                                        isSortingEnabled={isSortingEnabled}
                                        isFilteringEnabled={isFilteringEnabled}
                                        listStatus={currentListInfo.status}
                                        leads={currentListLeads ?? []}
                                        allLeadsCount={currentListInfo.statistics.all_leads_count}
                                        filteredLeadsCount={filteringOptions.leadsCount}
                                        currentPage={currentPage}
                                        pageLeadsSize={pageLeadsSize}
                                        isLoading={isLeadsListTableLoading}
                                        handleChangeCurrentPage={handleChangeCurrentPage}
                                    />
                                </Col>
                            </Row>
                        </>
                    )
                    : (!listIsLoading && currentListError) &&
                    <>
                        {
                            currentListError.statusCode === StatusCodes.NOT_FOUND
                                ? <Empty
                                    className="centered-empty"
                                    description={
                                        <>
                                            <Typography.Paragraph>List not found</Typography.Paragraph>
                                            <Button onClick={(): void => navigate('/')}>Home</Button>
                                        </>
                                    }
                                />
                                : <Result status="error" title={currentListError.message}/>
                        }
                    </>
                }
            </Card>
        </>
    );
});
