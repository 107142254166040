import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Divider, InputNumber, Row, Select, Space, Switch, Tag, Typography } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { Dispatch, FC, memo, SetStateAction } from 'react';
import {
    ArrowRight,
    BackspaceReverseFill,
    CalendarRange,
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight
} from 'react-bootstrap-icons';
import { DateRangePicker, localeDatePicker } from '../../../../../components/DateRangePicker';
import { LanguageLabel } from '../../../../../components/LanguageLabel';
import { LeadManualVerificationTag } from '../../../../../components/LeadManualVerificationTag';
import { LeadManualVerification } from '../../../../../interfaces/leads';
import { LeadsList } from '../../../../../interfaces/leadsLists';
import { AhrefsFileds, LRTReportResultReportColumns } from '../../../../../interfaces/seoApi';
import { WebsiteLanguage } from '../../../../../interfaces/websites';
import { getDisabledDate, getThisDayEndAndStart, getThisMounthEndAndStart } from '../../../../../utils/date';
import { upperFirstLetter } from '../../../../../utils/string';

interface Props {
    filterAccessability: boolean | null;
    filterLrtPowerTrustDom: boolean;
    filterAhrefsDr: [number, number];
    filterAhrefsTraffic: [number, number];
    showFilterAhrefsDr: boolean;
    showFilterAhrefsTraffic: boolean;
    filterLanguage: string[];
    languagesList: WebsiteLanguage[];
    languagesListFetching: boolean;
    filterManualVerification: LeadManualVerification | null;
    filterManualVerificationDate: [Date, Date] | [null, null];
    filterLastCheckingDate: [Date, Date] | [null, null];
    filterCreatingDate: [Date, Date] | [null, null];
    filterByList: string | null;
    leadsListsNames: Pick<LeadsList, '_id' | 'list_name'>[];
    leadsListsNamesFetching: boolean;
    handleSetFilterAccessability: (value: boolean) => void;
    handleSetFilterLanguage: (value: string[]) => void;
    handleSetLrtPowerTrustDom: (value: boolean) => void;
    handleShowFilterAhrefsDr: (value: boolean) => void;
    handleShowFilterAhrefsTraffic: (value: boolean) => void;
    handleClearFilterByAhrefsDr: () => void;
    handleClearFilterByAhrefsTraffic: () => void;
    handleSetAhrefsDrFrom: (value: number | null) => void;
    handleSetAhrefsDrTo: (value: number | null) => void;
    handleSetAhrefsTrafficFrom: (value: number | null) => void;
    handleSetAhrefsTrafficTo: (value: number | null) => void;
    handleSetFilterManualVerification: (value: LeadManualVerification) => void;
    handleSetFilterManualVerificationDate: (values: RangeValue<Date>) => void;
    handleSetFilterLastCheckingDate: (values: RangeValue<Date>) => void;
    handleSetFilterCreatingDate: (values: RangeValue<Date>) => void;
    handleResetFilters: () => void;
    handleFindByFilters: () => void;
    handleSetFilterByList: (value: string | null) => void;
    setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
}

const { Text } = Typography;
const { Option } = Select;

export const Panel: FC<Props> = memo((
    {
        filterAccessability,
        filterLrtPowerTrustDom,
        filterAhrefsDr,
        filterAhrefsTraffic,
        showFilterAhrefsDr,
        showFilterAhrefsTraffic,
        filterLanguage,
        languagesListFetching,
        languagesList,
        filterManualVerification,
        filterManualVerificationDate,
        filterLastCheckingDate,
        filterCreatingDate,
        filterByList,
        leadsListsNames,
        leadsListsNamesFetching,
        handleSetFilterAccessability,
        handleShowFilterAhrefsDr,
        handleShowFilterAhrefsTraffic,
        handleClearFilterByAhrefsDr,
        handleClearFilterByAhrefsTraffic,
        handleSetFilterLanguage,
        handleSetLrtPowerTrustDom,
        handleSetAhrefsDrFrom,
        handleSetAhrefsDrTo,
        handleSetAhrefsTrafficFrom,
        handleSetAhrefsTrafficTo,
        handleSetFilterManualVerification,
        handleSetFilterManualVerificationDate,
        handleSetFilterLastCheckingDate,
        handleSetFilterCreatingDate,
        handleResetFilters,
        handleFindByFilters,
        handleSetFilterByList,
        setIsFilterOpen,
    }: Props) => {
    return (
        <Row gutter={[16, 16]} wrap className="websites-table__filter-panel">
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Language</Text>
                <Select
                    disabled={languagesListFetching}
                    style={{ width: '100%' }}
                    allowClear
                    placeholder="Choose"
                    mode="multiple"
                    value={filterLanguage}
                    onChange={handleSetFilterLanguage}
                >
                    {
                        languagesList.map(lang => <Option
                            key={lang.name}
                            value={lang.name}
                        >
                            <LanguageLabel lang={lang}/>
                        </Option>)
                    }
                </Select>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Accessibility</Text>
                <Select
                    className="dropdown-accessibility"
                    style={{ width: '100%' }}
                    allowClear
                    placeholder="Choose"
                    value={filterAccessability}
                    onChange={handleSetFilterAccessability}
                >
                    <Option value><Tag color="green">Available</Tag></Option>
                    <Option value={false}><Tag color="red">Not available</Tag></Option>
                </Select>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Ahrefs</Text>
                <Space direction="vertical">
                    <Space direction="vertical">
                        <Space>
                            <Tag>
                                <Text style={{ fontSize: 12 }}>{upperFirstLetter(AhrefsFileds.DOMAIN_RATING)}:</Text>
                            </Tag>
                            <Switch size="small" checked={showFilterAhrefsDr} onChange={handleShowFilterAhrefsDr}/>
                        </Space>
                        {
                            showFilterAhrefsDr && <Space>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="from"
                                    min={0}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={filterAhrefsDr[0]}
                                    onChange={handleSetAhrefsDrFrom}
                                />
                                <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="to"
                                    min={0}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={filterAhrefsDr[1]}
                                    onChange={handleSetAhrefsDrTo}
                                />
                                <Button
                                    disabled={!(filterAhrefsDr[0] || filterAhrefsDr[1])}
                                    onClick={handleClearFilterByAhrefsDr}
                                    type="text"
                                    danger
                                    icon={<BackspaceReverseFill/>}
                                />
                            </Space>
                        }
                    </Space>
                    <Space direction="vertical">
                        <Space>
                            <Tag>
                                <Text style={{ fontSize: 12 }}>{upperFirstLetter(AhrefsFileds.TOTAL_TRAFFIC)}:</Text>
                            </Tag>
                            <Switch
                                size="small" checked={showFilterAhrefsTraffic}
                                onChange={handleShowFilterAhrefsTraffic}
                            />
                        </Space>
                        {
                            showFilterAhrefsTraffic && <Space>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="from"
                                    min={0}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={filterAhrefsTraffic[0]}
                                    onChange={handleSetAhrefsTrafficFrom}
                                />
                                <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="to"
                                    min={0}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={filterAhrefsTraffic[1]}
                                    onChange={handleSetAhrefsTrafficTo}
                                />
                                <Button
                                    disabled={!(filterAhrefsTraffic[0] || filterAhrefsTraffic[1])}
                                    onClick={handleClearFilterByAhrefsTraffic}
                                    type="text"
                                    danger
                                    icon={<BackspaceReverseFill/>}
                                />
                            </Space>
                        }
                    </Space>
                </Space>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>LRT</Text>
                <Space align="center">
                    <Tag>
                        <Text style={{ fontSize: 12 }}>
                            {LRTReportResultReportColumns.POWER_TRUST_DOM}:
                        </Text>
                    </Tag>
                    <Switch
                        checked={filterLrtPowerTrustDom}
                        checkedChildren="more than 1"
                        unCheckedChildren="more than 1"
                        onChange={handleSetLrtPowerTrustDom}
                    />
                </Space>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Creating date</Text>
                <DateRangePicker
                    style={{ width: '100%' }}
                    showTime={{ format: 'HH:mm' }}
                    format="DD MMM YYYY, HH:mm"
                    allowClear
                    showNow
                    suffixIcon={<CalendarRange/>}
                    value={filterCreatingDate}
                    separator={<ArrowRight
                        style={{ verticalAlign: 'middle', fill: 'rgba(0,0,0,.45)' }} size={16}
                    />}
                    superPrevIcon={<ChevronDoubleLeft size={14}/>}
                    superNextIcon={<ChevronDoubleRight size={14}/>}
                    prevIcon={<ChevronLeft size={14}/>}
                    nextIcon={<ChevronRight size={14}/>}
                    disabledDate={getDisabledDate}
                    locale={{
                        ...localeDatePicker,
                        lang: {
                            ...localeDatePicker.lang,
                            ok: 'Set'
                        }
                    }}
                    ranges={{
                        Today: [getThisDayEndAndStart('start'), getThisDayEndAndStart('end')],
                        'This mounth': [getThisMounthEndAndStart('start'), getThisMounthEndAndStart('end')]
                    }}
                    onChange={handleSetFilterCreatingDate}
                />
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Last access checking date</Text>
                <DateRangePicker
                    style={{ width: '100%' }}
                    showTime={{ format: 'HH:mm' }}
                    format="DD MMM YYYY, HH:mm"
                    allowClear
                    showNow
                    suffixIcon={<CalendarRange/>}
                    value={filterLastCheckingDate}
                    separator={<ArrowRight
                        style={{ verticalAlign: 'middle', fill: 'rgba(0,0,0,.45)' }} size={16}
                    />}
                    superPrevIcon={<ChevronDoubleLeft size={14}/>}
                    superNextIcon={<ChevronDoubleRight size={14}/>}
                    prevIcon={<ChevronLeft size={14}/>}
                    nextIcon={<ChevronRight size={14}/>}
                    disabledDate={getDisabledDate}
                    locale={{
                        ...localeDatePicker,
                        lang: {
                            ...localeDatePicker.lang,
                            ok: 'Set'
                        }
                    }}
                    ranges={{
                        Today: [getThisDayEndAndStart('start'), getThisDayEndAndStart('end')],
                        'This mounth': [getThisMounthEndAndStart('start'), getThisMounthEndAndStart('end')]
                    }}
                    onChange={handleSetFilterLastCheckingDate}
                />
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Manual verification date</Text>
                <DateRangePicker
                    style={{ width: '100%' }}
                    showTime={{ format: 'HH:mm' }}
                    format="DD MMM YYYY, HH:mm"
                    allowClear
                    showNow
                    suffixIcon={<CalendarRange/>}
                    value={filterManualVerificationDate}
                    separator={<ArrowRight
                        style={{ verticalAlign: 'middle', fill: 'rgba(0,0,0,.45)' }} size={16}
                    />}
                    superPrevIcon={<ChevronDoubleLeft size={14}/>}
                    superNextIcon={<ChevronDoubleRight size={14}/>}
                    prevIcon={<ChevronLeft size={14}/>}
                    nextIcon={<ChevronRight size={14}/>}
                    disabledDate={getDisabledDate}
                    locale={{
                        ...localeDatePicker,
                        lang: {
                            ...localeDatePicker.lang,
                            ok: 'Set'
                        }
                    }}
                    ranges={{
                        Today: [getThisDayEndAndStart('start'), getThisDayEndAndStart('end')],
                        'This mounth': [getThisMounthEndAndStart('start'), getThisMounthEndAndStart('end')]
                    }}
                    onChange={handleSetFilterManualVerificationDate}
                />
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Manual verification type</Text>
                <Select
                    className="dropdown-manual-verification"
                    style={{ width: '100%' }}
                    allowClear
                    placeholder="Choose"
                    value={filterManualVerification}
                    onChange={handleSetFilterManualVerification}
                >
                    <Option value={LeadManualVerification.NOT_CHECKED}>
                        <LeadManualVerificationTag
                            type={'badge'}
                            verificationStatus={LeadManualVerification.NOT_CHECKED}
                            layout={'row'}
                            fullWidth
                            withoutDate
                        />
                    </Option>
                    <Option value={LeadManualVerification.APPROVED}>
                        <LeadManualVerificationTag
                            type={'badge'}
                            verificationStatus={LeadManualVerification.APPROVED}
                            layout={'row'}
                            fullWidth
                            withoutDate
                        />
                    </Option>
                    <Option value={LeadManualVerification.DECLINED}>
                        <LeadManualVerificationTag
                            type={'badge'}
                            verificationStatus={LeadManualVerification.DECLINED}
                            layout={'row'}
                            fullWidth
                            withoutDate
                        />
                    </Option>
                </Select>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Leads lists</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    disabled={leadsListsNamesFetching}
                    placeholder="Choose"
                    value={filterByList}
                    onChange={handleSetFilterByList}
                >
                    {
                        leadsListsNames.map(leadsList => <Option key={leadsList._id} value={leadsList._id}>
                            {leadsList.list_name}
                        </Option>)
                    }
                </Select>
            </Col>
            <Col span={24}><Divider style={{ margin: 0 }}/></Col>
            <Col span={24}>
                <Space direction="vertical">
                    <Button type="default" block onClick={handleResetFilters}>Reset</Button>
                    <Button type="primary" block onClick={handleFindByFilters}>Apply</Button>
                </Space>
            </Col>
            <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => setIsFilterOpen(false)}
                style={{ position: 'absolute', top: 0, right: 0 }}
            />
        </Row>
    );
});
