/* eslint-disable react/jsx-indent-props */
import { Button, Card, Result, Spin } from 'antd';
import React, { FC, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderInner } from '../../../modules/Header/headerInner.hoc';
import { WebsiteView } from '../../../modules/Websites/Website';
import { RTKError } from '../../../store';
import { useGetWebsiteByIdQuery } from '../../../store/queries/websites';
import { RoutesParams } from '../../../typedef';

export const ViewWebsite: FC = memo(() => {
    const navigate = useNavigate();
    const { website_id: websiteIdParam } = useParams<RoutesParams.WEBSITE_ID>();

    const { data: website, error, isLoading, isFetching } = useGetWebsiteByIdQuery(websiteIdParam ?? '', {
        skip: !websiteIdParam
    });

    return (
        <>
            <HeaderInner titleName="Website"/>
            <div className="home-container">
                {
                    error
                        ? <Result
                            status="error"
                            title={(error as RTKError).data.message}
                            subTitle={
                                <Button onClick={(): void => navigate('/')}>Home</Button>
                            }
                        />
                        : <>
                            {((isLoading || isFetching) && !website) &&
                                <Card><Spin className="centered-spinner"/></Card>}
                            {
                                (!isLoading && website) &&
                                <WebsiteView website={website}/>
                            }
                        </>
                }
            </div>
        </>
    );
});
