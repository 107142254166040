import { Descriptions, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { WebsiteDossier } from '../../../../interfaces/websites';

interface Props {
    domainRating: WebsiteDossier['domainRating'],
    keywords: WebsiteDossier['keywords'],
    traffic: WebsiteDossier['traffic'],
    dtoxrisk: WebsiteDossier['dtoxrisk'],
    powerTrustDom: WebsiteDossier['powerTrustDom'],
    powerDom: WebsiteDossier['powerDom'],
    trustDom: WebsiteDossier['trustDom'],
}

const { Text } = Typography;

export const SeoInfoBlock: FC<Props> = memo((
    {
        domainRating,
        keywords,
        traffic,
        dtoxrisk,
        powerTrustDom,
        powerDom,
        trustDom,
    }: Props) => {
    return (
        <Descriptions
            column={1}
            layout="vertical"
            labelStyle={{ marginBottom: '-8px' }}
            size="small"
            title={<Text>SEO Info</Text>}
        >
            <Descriptions.Item label={<Text type="secondary">Domain Rating</Text>}>
                {domainRating}
            </Descriptions.Item>

            <Descriptions.Item label={<Text type="secondary">Keywords</Text>}>
                {keywords}
            </Descriptions.Item>

            <Descriptions.Item label={<Text type="secondary">Traffic</Text>}>
                {traffic}
            </Descriptions.Item>

            <Descriptions.Item label={<Text type="secondary">Dtoxrisk</Text>}>
                {dtoxrisk}
            </Descriptions.Item>

            <Descriptions.Item label={<Text type="secondary">Power Trust Dom</Text>}>
                {powerTrustDom}
            </Descriptions.Item>

            <Descriptions.Item label={<Text type="secondary">Power Dom</Text>}>
                {powerDom}
            </Descriptions.Item>

            <Descriptions.Item label={<Text type="secondary">Trust Dom</Text>}>
                {trustDom}
            </Descriptions.Item>
        </Descriptions>
    );
});
