/* eslint-disable react/jsx-indent-props */
import { Col, Row } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import { Website } from '../../../interfaces/websites';
import { WebsitesLeadsCatalogModal } from '../WebsitesLeadsCatalogModal';
import { GeneralPart } from './Parts/generalPart';
import { InfoPart } from './Parts/infoPart';

interface Props {
    website: Website;
    showBackArrow?: boolean;
}

export const WebsiteView: FC<Props> = memo(({ website, showBackArrow = false }: Props) => {
    const [isVisibleLeadsCatalog, setIsVisibleLeadsCatalog] = useState<boolean>(false);

    const handleViewWebsiteLeadsCatalog = useCallback((): void => {
        setIsVisibleLeadsCatalog(true);
    }, []);

    const handleCloseEventsLogModal = useCallback((): void => {
        setIsVisibleLeadsCatalog(false);
    }, []);

    return (
        <>
            <WebsitesLeadsCatalogModal
                websiteId={website._id}
                isVisible={isVisibleLeadsCatalog}
                handleCloseModal={handleCloseEventsLogModal}
            />
            <Row gutter={[0, 16]} align="top">
                <Col span={16}>
                    <GeneralPart
                        showBackArrow={showBackArrow}
                        id={website._id}
                        isAccessible={website.is_accessible}
                        baseUrl={website.base_url}
                        domain={website.domain}
                        manualVerification={website.manual_verification}
                        manualVerificatedAt={website.manual_verificated_at}
                        createdAt={website.created_at}
                        checkedAt={website.checked_at}
                        connectionStatus={website.connection_status}
                        language={website.language}
                        topics={website.topics}
                        note={website.note}
                        contentIndicators={website.content_indicators}
                        handleViewWebsiteLeadsCatalog={handleViewWebsiteLeadsCatalog}
                    />
                </Col>
                <Col span={8}>
                    <InfoPart
                        crmId={website.crm_id}
                        crmOfferId={website.crm_offer_id}
                        seoInfoLastCheckedAt={website.seo_info_last_checked_at}
                        lrtPowerTrustDom={website.lrt_power_trust_dom}
                        ahrefsDomainRating={website.ahrefs_domain_rating}
                        ahrefsTotalTraffic={website.ahrefs_total_traffic}
                    />
                </Col>
            </Row>
        </>
    );
});
