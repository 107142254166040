import { Descriptions, Tooltip, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { InfoCircle } from 'react-bootstrap-icons';
import { AhrefsDescriptionValue } from '../../../../components/AhrefsDescriptionValue';
import { LeadDossier, LeadStatuses } from '../../../../interfaces/leads';

type Props = {
    status: LeadStatuses;
    ahrefsInfo: LeadDossier['linkInfo']['ahrefs'];
    isLRTReportGenerated: LeadDossier['linkInfo']['linkresearchtools']['is_report_generated'];
    lrtPowerDom: LeadDossier['linkInfo']['linkresearchtools']['power_dom'];
    lrtTrustDom: LeadDossier['linkInfo']['linkresearchtools']['trust_dom'];
}

const { Text } = Typography;

const Title: FC = memo(() => (
    <Text>
        Ahrefs&nbsp;
        <Tooltip title="Ahrefs values can be only received after LinkResearchTools report">
            <InfoCircle size={12}/>
        </Tooltip>
    </Text>
));

export const AhrefsBlock: FC<Props> = memo(({
                                                status,
                                                ahrefsInfo,
                                                lrtPowerDom,
                                                lrtTrustDom,
                                                isLRTReportGenerated
                                            }: Props) => {
    return (
        <>
            <Descriptions
                column={1}
                layout="vertical"
                labelStyle={{ marginBottom: '-8px' }}
                size="small"
                title={<Title/>}
            >
                <Descriptions.Item label={<Text type="secondary">Domain rating</Text>}>
                    <AhrefsDescriptionValue
                        status={status}
                        lrtPowerDom={lrtPowerDom ?? 0}
                        lrtTrustDom={lrtTrustDom ?? 0}
                        isLRTReportGenerated={isLRTReportGenerated}
                        error={ahrefsInfo.domain_rating.error}
                        value={ahrefsInfo.domain_rating.value}
                    />
                </Descriptions.Item>

                <Descriptions.Item label={<Text type="secondary">Total traffic</Text>}>
                    <AhrefsDescriptionValue
                        status={status}
                        lrtPowerDom={lrtPowerDom ?? 0}
                        lrtTrustDom={lrtTrustDom ?? 0}
                        isLRTReportGenerated={isLRTReportGenerated}
                        error={ahrefsInfo.total_traffic.error}
                        value={ahrefsInfo.total_traffic.value}
                    />
                </Descriptions.Item>
            </Descriptions>
        </>
    );
});
