import { Table } from 'antd';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { WebsiteLeadsCatalogDataObj } from '../../../interfaces/leads';
import { WebsitesLead } from '../../../interfaces/websites';
import { ViewingLeadModal } from '../../Leads/ViewingModal';
import { columns } from './columns';

interface Props {
    websitesLeads: WebsitesLead[];
}

export const LeadsCatalogTable: FC<Props> = memo(({ websitesLeads }: Props) => {
    const [leadID, setLeadID] = useState<null | string>(null);
    const [viewLeadModalVisible, setViewLeadModalVisible] = useState(false);

    const generatedDataSource = useMemo((): WebsiteLeadsCatalogDataObj[] => (
        websitesLeads.map((lead, idx): WebsiteLeadsCatalogDataObj => ({
            key: ++idx,
            id: lead._id,
            number: idx,
            baseUrl: (lead.regular_url ?? lead.shelly_url) ?? '',
            date: {
                createdAt: lead.created_at,
                checkedAt: lead.checked_at
            },
            type: lead.type,
            status: lead.status,
            connectionStatus: lead.connection_status,
            statusCode: lead.status_code,
            language: lead.language,
            manualVerification: {
                manualVerification: lead.manual_verification,
                manualVerificatedAt: lead.manual_verificated_at
            },
            listId: lead.list_id,
            url: lead.url
        }))
    ), [websitesLeads]);

    const handleShowTotal = useCallback(
        (total: number, range: [number, number]): React.ReactNode => `${range[0]}-${range[1]} of ${total} items`,
        []
    );

    const handleOpenLeadView = useCallback((id: string) => {
        setLeadID(id);
        setViewLeadModalVisible(true);
    }, []);

    const handleCloseLeadView = useCallback(() => {
        setViewLeadModalVisible(false);
        setLeadID(null);
    }, []);

    return (
        <>
            <ViewingLeadModal isVisible={viewLeadModalVisible} leadID={leadID} handleClose={handleCloseLeadView}/>
            <Table
                className="websites-leads-catalog"
                rowKey={(record): string => record.id}
                size="small"
                pagination={{
                    pageSize: 50,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    total: generatedDataSource.length,
                    size: 'small',
                    showTotal: handleShowTotal
                }}
                dataSource={generatedDataSource}
                columns={columns(handleOpenLeadView)}
                tableLayout="auto"
                onRow={
                    (record): object => ({
                        onDoubleClick: (): void => handleOpenLeadView(record.id)
                    })
                }
            />
        </>
    );
});
