import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Divider, Form, FormInstance, Input, Row, Switch } from 'antd';
import { ValidateStatus } from 'antd/es/form/FormItem';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LeadFields, LeadInputs, LeadLanguage, LeadTypes, LeadValues } from '../../interfaces/leads';
import { GeneralInputs } from './Parts/generalInputs';
import { LinkInfoInputs } from './Parts/linkInfoInputs';
import { UrlInputs } from './Parts/urlInputs';
import { regularRegex, shellyRegex, validationSchema } from './validation';

interface Props {
    formName: string;
    form: FormInstance<any>;
    defaultValues: LeadValues;
    disabled?: boolean;
    leadsLanguagesList: LeadLanguage[],
    onSubmit: SubmitHandler<LeadInputs>;
}

export const LeadForm: FC<Props> = memo(({
                                             formName,
                                             form,
                                             defaultValues,
                                             disabled,
                                             leadsLanguagesList,
                                             onSubmit
                                         }: Props) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm<LeadInputs>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues
    });
    const [leadType, setLeadType] = useState<LeadTypes | undefined>();

    const validationStatusValue = useCallback((field: LeadFields): ValidateStatus => {
        if (errors[field]) {
            return 'error';
        } else if (watch(field)?.toString()) {
            return 'success';
        } else if (disabled) {
            return 'validating';
        } else return '';
    }, [errors, watch, disabled]);

    useEffect(() => {
        if (watch(LeadFields.URL) && !errors[LeadFields.URL]) {
            const link = watch(LeadFields.URL);

            if (regularRegex.test(link)) {
                const matches = link.match(regularRegex);

                if (matches) {
                    const [, url, , domain, , login, password] = matches;
                    const homePage = `${/https/.test(url) ? 'https' : 'http'}://${domain}`;
                    const subdomain = domain.split('.').slice(0, -1).join('.');

                    handleChangeValue(LeadFields.HOME_PAGE, homePage);
                    handleChangeValue(LeadFields.DOMAIN, domain);
                    handleChangeValue(LeadFields.SUBDOMAIN, subdomain);
                    handleChangeValue(LeadFields.REGULAR_URL, url);
                    handleChangeValue(LeadFields.REGULAR_LOGIN, login);
                    handleChangeValue(LeadFields.REGULAR_PASSWORD, password);
                    handleChangeValue(LeadFields.SHELLY_URL, undefined);
                    handleChangeValue(LeadFields.SHELLY_PASSWORD, undefined);

                    setLeadType(LeadTypes.REGULAR);
                }
            } else if (shellyRegex.test(link)) {
                const matches = link.match(shellyRegex);

                if (matches) {
                    const [, url, , domain, , password] = matches;
                    const homePage = `${/https/.test(url) ? 'https' : 'http'}://${domain}`;
                    const subdomain = domain.split('.').slice(0, -1).join('.');

                    handleChangeValue(LeadFields.HOME_PAGE, homePage);
                    handleChangeValue(LeadFields.DOMAIN, domain);
                    handleChangeValue(LeadFields.SUBDOMAIN, subdomain);
                    handleChangeValue(LeadFields.REGULAR_URL, undefined);
                    handleChangeValue(LeadFields.REGULAR_LOGIN, undefined);
                    handleChangeValue(LeadFields.REGULAR_PASSWORD, undefined);
                    handleChangeValue(LeadFields.SHELLY_URL, url);
                    handleChangeValue(LeadFields.SHELLY_PASSWORD, password);

                    setLeadType(LeadTypes.SHELLY);
                }
            }
        } else {
            handleChangeValue(LeadFields.HOME_PAGE, undefined);
            handleChangeValue(LeadFields.DOMAIN, undefined);
            handleChangeValue(LeadFields.SUBDOMAIN, undefined);
            handleChangeValue(LeadFields.REGULAR_URL, undefined);
            handleChangeValue(LeadFields.REGULAR_LOGIN, undefined);
            handleChangeValue(LeadFields.REGULAR_PASSWORD, undefined);
            handleChangeValue(LeadFields.SHELLY_URL, undefined);
            handleChangeValue(LeadFields.SHELLY_PASSWORD, undefined);

            setLeadType(undefined);
        }
    }, [watch(LeadFields.URL), errors[LeadFields.URL]]);

    const handleChangeValue = useCallback((input: LeadFields, value: any) => {
        setValue(input as any, value);
        form.setFieldValue(input, value);
    }, [form]);

    const handleInputChange = useCallback((
        e: (React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) | any,
        input: any
    ): void => {
        setValue(input, e?.target?.value ?? e, {
            shouldTouch: true,
            shouldValidate: true
        });
    }, []);

    const handleInputBlur = useCallback((
        e: (React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) | any,
        input: any
    ): void => {
        setValue(input, e?.target?.value ?? e, {
            shouldTouch: true,
            shouldValidate: true
        });
    }, []);

    return (
        <Form
            form={form}
            name={formName}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ remember: false }}
            onFinish={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <UrlInputs
                errors={errors}
                leadType={leadType}
                disabled={disabled ?? false}
                validationStatusValue={validationStatusValue}
                register={register}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
            />
            {
                leadType === LeadTypes.SHELLY && <>
                    <Divider style={{ marginTop: 0 }}/>
                    <Row gutter={[16, 16]}>
                        {
                            leadType === LeadTypes.SHELLY && <>
                                <Col span={11}>
                                    <Form.Item
                                        label="Is file uploaded?"
                                        name={LeadFields.SHELLY_IS_FILE_UPLOAD}
                                        required
                                        hasFeedback
                                        validateStatus={errors[LeadFields.SHELLY_IS_FILE_UPLOAD] && 'error'}
                                        help={errors[LeadFields.SHELLY_IS_FILE_UPLOAD] && errors[LeadFields.SHELLY_IS_FILE_UPLOAD]?.message}
                                    >
                                        <Switch
                                            checkedChildren={'Yes'}
                                            unCheckedChildren={'No'}
                                            {...register(LeadFields.SHELLY_IS_FILE_UPLOAD)}
                                            onChange={(e): void => handleInputChange(e, LeadFields.SHELLY_IS_FILE_UPLOAD)}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        {
                            `${watch(LeadFields.SHELLY_IS_FILE_UPLOAD)}` === 'true' && <>
                                <Col span={13}>
                                    <Form.Item
                                        label="Path to uploaded file"
                                        name={LeadFields.SHELLY_PATH_TO_FILE}
                                        required
                                        hasFeedback
                                        validateStatus={errors[LeadFields.SHELLY_PATH_TO_FILE] && 'error'}
                                        help={errors[LeadFields.SHELLY_PATH_TO_FILE] && errors[LeadFields.SHELLY_PATH_TO_FILE]?.message}
                                    >
                                        <Input
                                            placeholder="Enter path to uploaded file"
                                            {...register(LeadFields.SHELLY_PATH_TO_FILE)}
                                            onChange={(e): void => handleInputChange(e, LeadFields.SHELLY_PATH_TO_FILE)}
                                            onBlur={(e): void => handleInputBlur(e, LeadFields.SHELLY_PATH_TO_FILE)}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                    </Row>
                </>
            }
            <Divider style={{ marginTop: 0 }}/>
            <GeneralInputs
                errors={errors}
                disabled={disabled ?? false}
                leadsLanguagesList={leadsLanguagesList}
                validationStatusValue={validationStatusValue}
                register={register}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
            />
            <Divider style={{ marginTop: 0 }}/>
            <LinkInfoInputs
                errors={errors}
                disabled={disabled ?? false}
                validationStatusValue={validationStatusValue}
                register={register}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
            />
        </Form>
    );
});
