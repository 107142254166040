import { Badge, Button, Divider, Empty, Input, Segmented, Space, Tooltip, Typography } from 'antd';
import { SegmentedProps, SegmentedValue } from 'antd/lib/segmented';
import React, { FC, memo, useMemo } from 'react';
import { ChevronDown, ChevronUp, Clipboard, ClipboardCheck, Stars, XCircleFill } from 'react-bootstrap-icons';
import { LinksSegments } from '../../typedef';

interface Props {
    links: string[];
    goodLinks: string[];
    badLinks: string[];
    currentSegment: LinksSegments;
    showLinks: boolean;
    currentLinksBySegment: string[];
    handleExpandLinks: () => void;
    handleClearLinks: () => void;
    handleRemoveLinks: () => void;
    handleChangeLinksSegment: (segment: SegmentedValue) => void;
}

export const ValidationPanel: FC<Props> = memo(({
                                                    links,
                                                    goodLinks,
                                                    badLinks,
                                                    currentSegment,
                                                    showLinks,
                                                    currentLinksBySegment,
                                                    handleExpandLinks,
                                                    handleClearLinks,
                                                    handleRemoveLinks,
                                                    handleChangeLinksSegment
                                                }: Props) => {
    const segmentOptions = useMemo((): SegmentedProps['options'] => [
        {
            label: (
                <>All:&nbsp;<Badge
                    showZero
                    count={links.length}
                    overflowCount={Number.MAX_SAFE_INTEGER}
                    style={{ backgroundColor: '#40a9ff' }}
                /></>
            ),
            value: LinksSegments.ALL
        },
        {
            label: (
                <>Good:&nbsp;<Badge
                    showZero
                    count={goodLinks.length}
                    overflowCount={Number.MAX_SAFE_INTEGER}
                    style={{ backgroundColor: '#52c41a' }}
                /></>
            ),
            value: LinksSegments.GOOD
        },
        {
            label: (
                <>Bad:&nbsp;<Badge
                    showZero
                    count={badLinks.length}
                    overflowCount={Number.MAX_SAFE_INTEGER}
                /></>
            ),
            value: LinksSegments.BAD
        }
    ], [links, goodLinks, badLinks]);

    return (
        <>
            <Typography.Text>Links:</Typography.Text>
            <div className="links-validator__result-badge">
                <div className="links-validator__result-badge__result-tags">
                    <div style={{ width: 350 }}>
                        <Segmented
                            block
                            value={currentSegment}
                            onChange={handleChangeLinksSegment}
                            options={segmentOptions}
                        />
                    </div>
                    <Space>
                        <Tooltip title={showLinks ? 'Shrink' : 'Expand'}>
                            <Button
                                className="links-validator__result-badge__result-tags__expand-button"
                                size="small"
                                onClick={handleExpandLinks}
                                type="text"
                            >
                                {showLinks ? <ChevronUp size={16}/> : <ChevronDown size={16}/>}
                            </Button>
                        </Tooltip>
                        <Divider type="vertical"/>
                        <Tooltip title="Clear links">
                            <Button
                                className="links-validator__result-badge__result-tags__clear-button"
                                size="small"
                                onClick={handleClearLinks}
                                type="text"
                                icon={<Stars size={16}/>}
                                disabled={!badLinks.length}
                            />
                        </Tooltip>
                        <Tooltip title="Copy current links">
                            <Button
                                size="small" type="text"
                                className="links-validator__result-badge__result-tags__copy-button"
                            >
                                <Typography.Text
                                    copyable={{
                                        text: currentLinksBySegment.join('\n'),
                                        icon: [
                                            <Clipboard size={16} key="copy-icon"/>,
                                            <ClipboardCheck size={16} key="copied-icon"/>
                                        ],
                                        tooltips: [null, 'Copied!']
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <Divider type="vertical"/>
                        <Tooltip title="Remove links">
                            <Button
                                size="small" onClick={handleRemoveLinks} type="text" danger
                                icon={<XCircleFill size={16}/>}
                            />
                        </Tooltip>
                    </Space>
                </div>
            </div>
            {
                showLinks &&
                (currentLinksBySegment.length
                    ? <Input.TextArea
                        id="create-leads-list_links" readOnly value={currentLinksBySegment.join('\n')}
                    />
                    : <Empty description="No links" image={Empty.PRESENTED_IMAGE_SIMPLE}/>)
            }
        </>
    );
});
