import React, { FC, memo } from 'react';
import { WindowX } from 'react-bootstrap-icons';

export const NotFoundPage: FC = memo(() => {
    return (
        <div className="not-found-page">
            <WindowX size={70}/>
            <p>Page not found</p>
            <span>looks like you entered the wrong URL path</span>
        </div>
    );
});
