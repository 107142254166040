import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React from 'react';
import { ColumnDateLabel } from '../../../../components/ColumnDateLabel';
import { ColumnNumberBadge } from '../../../../components/ColumnNumberBadge';
import { ConnectionBadge } from '../../../../components/ConnectionBadge';
import { LanguageLabel } from '../../../../components/LanguageLabel';
import { LeadManualVerificationTag } from '../../../../components/LeadManualVerificationTag';
import { WebsitesTableDataObj } from '../../../../interfaces/websites';
import { TopicsList } from '../../../Topics/TopicsList';
import { ColumnActions } from './columnActions';
import { ColumnSeoInfo } from './columnSeoInfo';

export const columns = (
    handleShowLeadsCatalog: (websiteId: string) => void,
    handleViewLeadDossier: (websiteId: string) => void
): ColumnsType<WebsitesTableDataObj> => {
    return [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 110,
            render: (value: WebsitesTableDataObj['number']) => (
                <ColumnNumberBadge number={value}/>
            )
        },
        {
            title: <>Created at&nbsp;<b>/</b><br/>Last checked at</>,
            dataIndex: 'date',
            key: 'date',
            width: 140,
            render: (value: WebsitesTableDataObj['date']) => (
                <ColumnDateLabel date={value}/>
            )
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain'
        },
        {
            title: 'Accessibility',
            dataIndex: 'isAccessible',
            key: 'isAccessible',
            render: (value: WebsitesTableDataObj['isAccessible']) => (
                value ? <Tag color="green">Available</Tag> : <Tag color="red">Not available</Tag>
            )
        },
        {
            title: <>Website<br/>connection</>,
            dataIndex: 'connectionStatus',
            key: 'connectionStatus',
            render: (value: WebsitesTableDataObj['connectionStatus']) => (
                <ConnectionBadge status={value}/>
            )
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            render: (value: WebsitesTableDataObj['language']) => (
                <LanguageLabel lang={value}/>
            )
        },
        {
            title: 'Topics',
            dataIndex: 'topics',
            key: 'topics',
            width: 220,
            render: (value: WebsitesTableDataObj['topics'], record: WebsitesTableDataObj) => (
                <TopicsList topics={value} websiteID={record.id}/>
            )
        },
        {
            title: 'Manual verification',
            dataIndex: 'manualVerification',
            key: 'manualVerification',
            render: (value: WebsitesTableDataObj['manualVerification']) => (
                <LeadManualVerificationTag
                    type="badge"
                    verificationStatus={value.manualVerification}
                    verificatedAt={value.manualVerificatedAt}
                    layout="column"
                />
            )
        },
        {
            title: 'SEO Info',
            dataIndex: 'seoInfo',
            key: 'seoInfo',
            render: (seoInfo: WebsitesTableDataObj['seoInfo']) => (
                <ColumnSeoInfo seoInfo={seoInfo}/>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: 100,
            render: (_, record: WebsitesTableDataObj) => (
                <ColumnActions
                    handleShowLeadsCatalog={handleShowLeadsCatalog}
                    handleViewLeadDossier={handleViewLeadDossier}
                    websiteId={record.id}
                />
            )
        }
    ];
};
