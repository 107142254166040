import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchAllListsUnarchived } from '../../../store/actions/leadsLists';
import { DefaultPageLimits, Routes } from '../../../typedef';
import { LeadsListsTable } from '../LeadsListsTable';
import { columns } from './columns';

export const UnarchivedList: FC = memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        lists,
        isTableLoading,
        currentDeletingRowId,
        listsMetadata: { unarchived }
    } = useSelector((state) => state.leadsLists);
    const [pageListsSize] = useState<number>(DefaultPageLimits.LEADS_LISTS);
    const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get('page') ?? 1));
    const [firstFetchLeads, setFirstFetchLeads] = useState<boolean>(true);
    const exportCsvRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (location.pathname === '/' && currentPage > 1) {
            setSearchParams({ page: currentPage.toString() });
        }

        // if (/\\?page=1/.test(location.search)) {
        //   searchParams.delete('page');
        //   setSearchParams(searchParams);
        //   setCurrentPage(1);
        //   dispatch(fetchAllListsUnarchived(1));
        // }
    }, [location.pathname]);

    useEffect(() => {
        if (!firstFetchLeads && lists) {
            dispatch(fetchAllListsUnarchived(currentPage));
        }
        setFirstFetchLeads(false);
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(Number(searchParams.get('page') ?? 1));
    }, [searchParams.get('page')]);

    const handleEditList = useCallback((id: string): void => {
        navigate(`${Routes.LEADS_LISTS}/${id}/edit`, { state: { backgroundLocation: location } });
    }, [location]);

    const handleViewList = useCallback((id: string): void => {
        navigate(`${Routes.LEADS_LISTS}/${id}/view`);
    }, []);

    const handleChangeCurrentPage = useCallback((page: number): void => {
        if (page === 1) {
            searchParams.delete('page');
            setSearchParams(searchParams);
        } else {
            setSearchParams({ page: page.toString() });
        }
        setCurrentPage(page);
    }, [searchParams]);

    return (
        <>
            { /* eslint-disable-next-line jsx-a11y/anchor-has-content */ /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" ref={exportCsvRef} style={{ display: 'none' }}/>
            <LeadsListsTable
                columns={
                    columns({
                        currentDeletingRowId,
                        exportCsvRef,
                        handleEditList,
                        handleViewList
                    })
                }
                currentPage={currentPage}
                pageListsSize={pageListsSize}
                classNameSuffix="unarchived"
                metaData={unarchived}
                isLoading={isTableLoading}
                lists={lists}
                handleChangeCurrentPage={handleChangeCurrentPage}
            />
        </>
    );
});
