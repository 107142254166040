/* eslint-disable indent */
import { Button, Input, message, Space, Typography } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { CheckCircleFill, PencilSquare, XCircleFill } from 'react-bootstrap-icons';
import { Website } from '../../interfaces/websites';
import { RTKError } from '../../store';
import { useEditNoteMutation } from '../../store/queries/websites';
import { TextEmpty } from '../TextEmpty';

interface Props {
    note: Website['note'];
    websiteID: Website['_id'];
}

export const WebsiteNote: FC<Props> = memo(({
                                                note,
                                                websiteID
                                            }: Props) => {
    const [isEditable, setIsEditable] = useState(false);
    const [noteValue, setNoteValue] = useState<string>(note ?? '');

    const [editNotePost, editNotePostResult] = useEditNoteMutation();

    useEffect(() => {
        if (editNotePostResult.isError) {
            message.error({ content: (editNotePostResult.error as RTKError).data.message, key: 'editNotePost' });
        }

        if (editNotePostResult.isSuccess) {
            message.success({ content: 'You have successfully edited a note!', key: 'editNotePost' });
            handleHideNoteEditing();
        }

        if (editNotePostResult.isLoading) {
            message.loading({ content: 'Send request..', key: 'editNotePost' });
        }
    }, [editNotePostResult]);

    const handleShowNoteEditing = useCallback(() => {
        setIsEditable(true);
    }, []);

    const handleHideNoteEditing = useCallback(() => {
        setIsEditable(false);
    }, []);

    const handleEditNote = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNoteValue(e.target.value);
    }, []);

    const handleSendEditedNote = useCallback(() => {
        editNotePost({
            id: websiteID,
            note: noteValue ?? null
        });
    }, [noteValue, websiteID]);

    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }}
            >
                {
                    isEditable
                        ? <Input.TextArea
                            className="edit-note"
                            style={{ width: '100%' }}
                            value={noteValue}
                            onChange={handleEditNote}
                            allowClear
                            placeholder="Write note"
                            maxLength={255}
                            disabled={editNotePostResult.isLoading}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            showCount
                        />
                        : <>
                            {
                                noteValue
                                    ? <Typography.Paragraph className="note-text">{noteValue}</Typography.Paragraph>
                                    : <div style={{ marginRight: 'auto' }}><TextEmpty/></div>
                            }
                        </>
                }
                {
                    !isEditable
                        ? <Button
                            className="edit-note__button"
                            icon={<PencilSquare/>}
                            type="dashed"
                            block
                            size="small"
                            onClick={handleShowNoteEditing}
                            loading={editNotePostResult.isLoading}
                        >
                            Edit note
                        </Button>
                        : <Space style={{ width: 'fit-content', alignSelf: 'flex-end' }}>
                            <Button
                                icon={<CheckCircleFill/>}
                                type="text"
                                onClick={handleSendEditedNote}
                                loading={editNotePostResult.isLoading}
                            />
                            <Button
                                icon={<XCircleFill/>}
                                type="text"
                                onClick={handleHideNoteEditing}
                                loading={editNotePostResult.isLoading}
                            />
                        </Space>
                }
            </div>
        </>
    );
});
