import { Space, Tag, TagProps, Tooltip } from 'antd';
import React, { FC, memo } from 'react';
import { PatchCheck, SlashCircle, XOctagon } from 'react-bootstrap-icons';
import { LeadManualVerification } from '../../interfaces/leads';
import { toLocaleDate } from '../../utils/date';
import { upperFirstLetter } from '../../utils/string';

type Props = (
    {
        type: 'withSwitch';
        children: React.ReactElement;
        layout?: never;
        fullWidth?: never;
        withoutDate?: false;
        verificatedAt: Date | null;
    } |
    {
        type: 'badge';
        children?: never;
        layout: 'column' | 'row';
        fullWidth?: boolean;
        withoutDate?: false;
        verificatedAt: Date | null;
    } |
    {
        type: 'badge';
        children?: never;
        layout: 'column' | 'row';
        fullWidth?: boolean;
        withoutDate: true;
        verificatedAt?: never;
    }
    ) & {
    verificationStatus: LeadManualVerification;
}

export const LeadManualVerificationTag: FC<Props> = memo(({
                                                              type,
                                                              verificationStatus,
                                                              withoutDate = false,
                                                              verificatedAt,
                                                              layout,
                                                              fullWidth = false,
                                                              children,
                                                          }: Props) => {
    let tagStatusColor: TagProps['color'];
    let tagIcon: React.ReactNode;
    let iconSize = 0;

    switch (layout) {
        case 'column':
            iconSize = 16;
            break;
        case 'row':
            iconSize = 14;
            break;
    }

    switch (type) {
        case 'withSwitch':
            iconSize = 26;
            break;
    }

    switch (verificationStatus) {
        case LeadManualVerification.APPROVED:
            tagStatusColor = 'cyan';
            tagIcon = <PatchCheck size={iconSize}/>;
            break;
        case LeadManualVerification.DECLINED:
            tagStatusColor = 'red';
            tagIcon = <XOctagon size={iconSize}/>;
            break;
        case LeadManualVerification.NOT_CHECKED:
            tagStatusColor = 'orange';
            tagIcon = <SlashCircle size={iconSize}/>;
            break;
    }

    return (
        <>
            {
                type === 'withSwitch' && <>
                    <Tag
                        className="lead-manual-verification-tag-with-switch"
                        color={tagStatusColor}
                    >
                        <Space direction="vertical">
                            <div className="lead-manual-verification-tag-with-switch__body">
                                {children}
                                {tagIcon}
                            </div>
                            {verificatedAt && <span>Last verified at {toLocaleDate(verificatedAt)}</span>}
                        </Space>
                    </Tag>
                </>
            }

            {
                type === 'badge' &&
                <Tooltip
                    placement="top"
                    overlayInnerStyle={{ wordBreak: 'keep-all' }}
                    title={
                        !withoutDate && (verificatedAt && verificationStatus !== LeadManualVerification.NOT_CHECKED)
                            ? `${upperFirstLetter(verificationStatus)} at ${toLocaleDate(verificatedAt)}`
                            : false
                    }
                >
                    <Tag
                        className={
                            `lead-manual-verification-tag lead-manual-verification-tag_${
                                verificationStatus
                            } lead-manual-verification-tag_${
                                layout
                            } ${
                                fullWidth ? 'lead-manual-verification-tag_fullwidth' : ''
                            } ${
                                (!withoutDate && verificatedAt)
                                    ? 'lead-manual-verification-tag_withdate'
                                    : ''
                            }`
                        }
                        color={tagStatusColor}
                    >
                        <div className="lead-manual-verification-tag__body">
                            {tagIcon}
                            <span className="lead-manual-verification-tag__title">
                {upperFirstLetter(verificationStatus)}
              </span>
                        </div>
                    </Tag>
                </Tooltip>
            }
        </>
    );
});
