import { Spin } from 'antd';
import React, { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useInitBaseSockets } from './hooks/useInitBaseSockets';
import { AppRouter } from './modules/AppRouter';
import { fetchAllListsUnarchived } from './store/actions/leadsLists';
import { getMe } from './store/actions/session';
import { fetchAllUsers } from './store/actions/users';
import { Routes as RoutesEnum } from './typedef';

export const App: FC = memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const locationState = location.state as { backgroundLocation?: Location };
    const { isLoading, isLoggedIn } = useSelector(state => state.session);

    useEffect(() => {
        if (/leads-lists\/\w+\/(edit)/.test(location.pathname)) {
            const type = location.pathname.split('/')[3];
            const listId = location.pathname.split('/')[2];

            navigate(
                `${RoutesEnum.LEADS_LISTS}/${listId}/${type}`,
                { state: { backgroundLocation: '/' }, replace: true }
            );
        }
        if (/leads-lists\/create/.test(location.pathname)) {
            navigate(
                RoutesEnum.LEADS_LISTS_CREATE,
                { state: { backgroundLocation: '/' }, replace: true }
            );
        }
    }, []);

    useEffect(() => {
        dispatch(getMe(true));
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchAllUsers());
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchAllListsUnarchived(
                location.pathname === '/' ? Number(searchParams.get('page') ?? 1) : 1
            ));
        }
    }, [isLoggedIn]);

    useInitBaseSockets();

    if (isLoading) return <Spin className="centered-spinner" size="large"/>;

    return (
        <>
            <React.StrictMode>
                <AppRouter
                    locationState={locationState}
                    isLoggedIn={isLoggedIn}
                />
            </React.StrictMode>
        </>
    );
});
