import { Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React from 'react';
import { ColumnDateLabel } from '../../../../components/ColumnDateLabel';
import { ColumnNumberBadge } from '../../../../components/ColumnNumberBadge';
import { ConnectionBadge } from '../../../../components/ConnectionBadge';
import { LanguageLabel } from '../../../../components/LanguageLabel';
import { LeadManualVerificationTag } from '../../../../components/LeadManualVerificationTag';
import { LeadStatusTag } from '../../../../components/LeadStatusTag';
import { LeadTypeTag } from '../../../../components/LeadTypeTag';
import { PageStatusBadge } from '../../../../components/PageStatusBadge';
import { ColumnDateLabel as ColumnDateLabelInterface } from '../../../../interfaces';
import { Lead, WebsiteLeadsCatalogDataObj } from '../../../../interfaces/leads';

export const columns = (
    hanldeOpenLeadView: (id: string) => void
): ColumnsType<WebsiteLeadsCatalogDataObj> => {
    return [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 110,
            render: (value: WebsiteLeadsCatalogDataObj['number']) => (
                <ColumnNumberBadge number={value}/>
            )
        },
        {
            title: <>Created at&nbsp;<b>/</b><br/>Last checked at</>,
            dataIndex: 'date',
            key: 'date',
            render: (value: ColumnDateLabelInterface<Lead>) => (
                <ColumnDateLabel date={value}/>
            )
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            render: (value: WebsiteLeadsCatalogDataObj['url']) => (
                <Typography.Text className="websites-leads-catalog__item__text">{value}</Typography.Text>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (value: WebsiteLeadsCatalogDataObj['type']) => (
                <LeadTypeTag type={value}/>
            )
        },
        {
            title: 'Access status',
            dataIndex: 'status',
            key: 'status',
            render: (value: WebsiteLeadsCatalogDataObj['status']) => (
                <LeadStatusTag status={value}/>
            )
        },
        {
            title: <>Website<br/>connection</>,
            dataIndex: 'connectionStatus',
            key: 'connectionStatus',
            render: (value: WebsiteLeadsCatalogDataObj['connectionStatus']) => (
                <ConnectionBadge status={value}/>
            )
        },
        {
            title: <>Page<br/>status</>,
            dataIndex: 'statusCode',
            key: 'statusCode',
            render: (value: WebsiteLeadsCatalogDataObj['statusCode']) => (
                <PageStatusBadge statusCode={value}/>
            )
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            render: (value: WebsiteLeadsCatalogDataObj['language']) => (
                <LanguageLabel lang={value}/>
            )
        },
        {
            title: <>Manual<br/>verification</>,
            dataIndex: 'manualVerification',
            key: 'manualVerification',
            render: (value: WebsiteLeadsCatalogDataObj['manualVerification']) => (
                <LeadManualVerificationTag
                    type="badge"
                    verificationStatus={value.manualVerification}
                    verificatedAt={value.manualVerificatedAt}
                    layout="row"
                />
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            render: (_, record: WebsiteLeadsCatalogDataObj) => (
                <Space direction="vertical">
                    <Button onClick={(): void => hanldeOpenLeadView(record.id)}>
                        Open lead
                    </Button>
                </Space>
            )
        }
    ];
};
