import { WebsitesFilterOptions } from '../interfaces/websites';

export const initFiltersSetting: WebsitesFilterOptions = {
    websitesCount: null,
    accessability: null,
    language: [],
    lrtPowerTrustDom: false,
    ahrefsDr: false,
    ahrefsTraffic: false,
    manualVerification: null,
    manualVerificationDate: false,
    lastCheckingDate: false,
    creatingDate: false,
    listID: null
};
