import { Badge } from 'antd';
import React, { FC, memo } from 'react';

interface Props {
    number: number;
}

export const ColumnNumberBadge: FC<Props> = memo(({ number }: Props) => {
    return (
        <Badge
            className="leads-table__number-badge"
            showZero
            overflowCount={Number.MAX_SAFE_INTEGER}
            count={number}
        />
    );
});
