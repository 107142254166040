import { Select } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React, { FC } from 'react';
import { X } from 'react-bootstrap-icons';
import { LeadContentIndicators } from '../../interfaces/leads';
import { WebsiteContentIndicators } from '../../interfaces/websites';
import { ContentIndicatorBadge } from './badge';

interface Props {
    disabled?: boolean;
    placeholder: string;
    value: (LeadContentIndicators | WebsiteContentIndicators)[];
    handleChange: (value: (LeadContentIndicators | WebsiteContentIndicators)[]) => void;
}

const tagRender = (props: CustomTagProps): React.ReactElement => {
    const { value, onClose } = props;

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span onClick={onClose} className="content-indicator-badge-in-select">
      {value}&nbsp;<X size={16}/>
    </span>
    );
};

export const ContentIndicatorsSelect: FC<Props> = ({
                                                       value,
                                                       placeholder,
                                                       handleChange,
                                                       disabled = false
                                                   }: Props) => {
    return (
        <Select
            mode="multiple"
            style={{ width: '100%' }}
            allowClear
            tagRender={tagRender}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
        >
            <Select.Option value={LeadContentIndicators.OUTREACH}>
                <ContentIndicatorBadge contentIndicator={LeadContentIndicators.OUTREACH}/>
            </Select.Option>
            <Select.Option value={LeadContentIndicators.B2F}>
                <ContentIndicatorBadge contentIndicator={LeadContentIndicators.B2F}/>
            </Select.Option>
        </Select>
    );
};
