import { message, Select, Space, Typography } from 'antd';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    LeadsBulkActions,
    LeadsBulkActionsSamples,
    LeadsBulkActionsTypes,
    LeadsSearchFiltersSortsQueries
} from '../../../interfaces/leads';
import { RTKError } from '../../../store';
import { useBulkActionsRestartMutation, useBulkActionsSeoChecksMutation } from '../../../store/queries/leads';
import { upperFirstLetter } from '../../../utils/string';
import { BulkActionsConfirm } from './bulkActionsConfirm';
import { BulkActionsSelectors } from './bulkActionsSelectors';

interface Props {
    listID?: string | undefined;
    isListUnready?: boolean;
    disablePanel: boolean;
    isFiltersApplied: boolean;
}

const { Option } = Select;

export const LeadsBulkActionsPanel: FC<Props> = memo(({
                                                          listID,
                                                          disablePanel,
                                                          isFiltersApplied,
                                                          isListUnready = false
                                                      }: Props) => {
    const {
        filtering,
        searching
    } = useSelector((state) => state.leads);
    const [actionType, setActionType] = useState<LeadsBulkActions>();
    const [sampleType, setSampleType] = useState<LeadsBulkActionsSamples>();
    const [leadsType, setLeadsType] = useState<LeadsBulkActionsTypes>();
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [withSeoChecks, setWithSeoChecks] = useState(true);

    const [bulkActionsRestart, bulkActionsRestartResult] = useBulkActionsRestartMutation();
    const [bulkActionsSeoChecks, bulkActionsSeoChecksResult] = useBulkActionsSeoChecksMutation();

    useEffect(() => {
        setSampleType(undefined);
    }, [actionType]);

    useEffect(() => {
        setLeadsType(undefined);
    }, [sampleType]);

    useEffect(() => {
        if (bulkActionsRestartResult.isError) {
            void message.error({
                content: (bulkActionsRestartResult.error as RTKError).data.message,
                key: 'bulkActionsRestart'
            });
        }

        if (bulkActionsRestartResult.isSuccess) {
            void message.success({ content: 'Leads successfully restarted!', key: 'bulkActionsRestart' });
            handleResetBulkAction();
        }

        if (bulkActionsRestartResult.isLoading) {
            void message.loading({ content: 'Send request..', key: 'bulkActionsRestart' });
        }
    }, [bulkActionsRestartResult]);

    useEffect(() => {
        if (bulkActionsSeoChecksResult.isError) {
            void message.error({
                content: (bulkActionsSeoChecksResult.error as RTKError).data.message,
                key: 'bulkActionsSeoChecks'
            });
        }

        if (bulkActionsSeoChecksResult.isSuccess) {
            void message.success({ content: 'Leads checking SEO data successfully sent', key: 'bulkActionsSeoChecks' });
            handleResetBulkAction();
        }

        if (bulkActionsSeoChecksResult.isLoading) {
            void message.loading({ content: 'Send request..', key: 'bulkActionsSeoChecks' });
        }
    }, [bulkActionsSeoChecksResult]);

    const isPanelDisabled = useMemo(
        () => disablePanel || bulkActionsRestartResult.isLoading || bulkActionsSeoChecksResult.isLoading || isListUnready,
        [disablePanel, bulkActionsRestartResult.isLoading, bulkActionsSeoChecksResult.isLoading, isListUnready]
    );

    const isBulkActionsSampleTypeReady = useMemo(
        () => (!!sampleType && sampleType === LeadsBulkActionsSamples.BY_TYPE) && (!!leadsType),
        [sampleType, leadsType]
    );
    const isBulkActionsSampleFiltersReady = useMemo(
        () => (!!sampleType && sampleType === LeadsBulkActionsSamples.BY_FILTERS) && isFiltersApplied,
        [sampleType, isFiltersApplied]
    );
    const isBulkActionsSampleSelectedReady = useMemo(() => !!sampleType && false, [sampleType]);

    const isBulkActionRestartReady = useMemo(
        () => (actionType === LeadsBulkActions.RESTART) &&
            (isBulkActionsSampleTypeReady || isBulkActionsSampleSelectedReady || isBulkActionsSampleFiltersReady),
        [actionType, isBulkActionsSampleTypeReady, isBulkActionsSampleSelectedReady, isBulkActionsSampleFiltersReady]
    );
    const isBulkActionSEOChecksReady = useMemo(
        () => (actionType === LeadsBulkActions.SEO_CHECKS) &&
            (isBulkActionsSampleTypeReady || isBulkActionsSampleSelectedReady || isBulkActionsSampleFiltersReady),
        [actionType, isBulkActionsSampleTypeReady, isBulkActionsSampleSelectedReady, isBulkActionsSampleFiltersReady]
    );
    const isBulkActionReady = useMemo(
        () => isBulkActionRestartReady || isBulkActionSEOChecksReady,
        [isBulkActionRestartReady, isBulkActionSEOChecksReady]
    );

    const handleChooseAction = useCallback((value: LeadsBulkActions): void => {
        setActionType(value);
    }, []);

    const handleResetBulkAction = useCallback((): void => setActionType(undefined), []);

    const handleChooseSample = useCallback((value: LeadsBulkActionsSamples): void => setSampleType(value), []);

    const handleSetWithSeoChecks = useCallback((checked: boolean): void => setWithSeoChecks(checked), []);

    const handleChangeLeadsType = useCallback((value: LeadsBulkActionsTypes): void => {
        setLeadsType(value);
    }, []);

    const handleExecuteBulkAction = (): void => {
        if (isBulkActionReady) {
            const queries = {
                [LeadsSearchFiltersSortsQueries.SEARCH]: searching || undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_PUBLISHABLE_STATUS]: filtering.publishableStatus.length ? filtering.publishableStatus.toString() : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_REPEAT_STATUS]: filtering.repeatStatus.length ? filtering.repeatStatus.toString() : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_ATTENTION_STATUS]: filtering.attentionStatus.length ? filtering.attentionStatus.toString() : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_ACTIVE_STATUS]: filtering.activeStatus.length ? filtering.activeStatus.toString() : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_PLUGIN_STATUS]: filtering.pluginStatus.length ? filtering.pluginStatus.toString() : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_STATUS]: filtering.status.length ? filtering.status.toString() : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_CONNECTION]: filtering.connectionStatus || undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_STATUS_CODE]: filtering.statusCode.length ? filtering.statusCode.toString() : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_LANGUAGE]: filtering.language.length ? filtering.language.toString() : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_LRT_POWER_TRUST_DOM]: filtering.lrtPowerTrustDom || undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_AHREFS_DR]: filtering.ahrefsDr || undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_AHREFS_TRAFFIC]: filtering.ahrefsTraffic || undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_MANUAL_VERIFICATION]: filtering.manualVerification || undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_MANUAL_VERIFICATION_DATE]: (filtering.manualVerificationDate &&
                    (filtering.manualVerificationDate[0] &&
                        filtering.manualVerificationDate[1]))
                    ? filtering.manualVerificationDate.toString()
                    : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_CHECKING_DATE]: (filtering.checkingDate &&
                    (filtering.checkingDate[0] &&
                        filtering.checkingDate[1]))
                    ? filtering.checkingDate.toString()
                    : undefined,
                [LeadsSearchFiltersSortsQueries.FILTER_BY_CONTENT_INDICATORS]: filtering.contentIndicators.length
                    ? filtering.contentIndicators.toString()
                    : undefined
            };

            for (const query of Object.keys(LeadsSearchFiltersSortsQueries)) {
                if (queries[query as Exclude<LeadsSearchFiltersSortsQueries, LeadsSearchFiltersSortsQueries.SORT_BY>] === undefined) {
                    delete queries[query as Exclude<LeadsSearchFiltersSortsQueries, LeadsSearchFiltersSortsQueries.SORT_BY>];
                }
            }

            if (actionType === LeadsBulkActions.RESTART) {
                bulkActionsRestart({
                    action: actionType,
                    sample: sampleType!,
                    selectedKeys: sampleType === LeadsBulkActionsSamples.BY_SELECTED ? selectedKeys : [],
                    type: sampleType === LeadsBulkActionsSamples.BY_TYPE ? leadsType : undefined,
                    queries: sampleType === LeadsBulkActionsSamples.BY_FILTERS ? queries : undefined,
                    withSeoChecks,
                    listId: listID
                });
            } else if (actionType === LeadsBulkActions.SEO_CHECKS) {
                bulkActionsSeoChecks({
                    action: actionType,
                    sample: sampleType!,
                    selectedKeys: sampleType === LeadsBulkActionsSamples.BY_SELECTED ? selectedKeys : [],
                    type: sampleType === LeadsBulkActionsSamples.BY_TYPE ? leadsType : undefined,
                    queries: sampleType === LeadsBulkActionsSamples.BY_FILTERS ? queries : undefined,
                    withSeoChecks,
                    listId: listID
                });
            }
        }
    };

    return (
        <>
            <Space>
                <Select
                    style={{ width: 200 }}
                    allowClear
                    placeholder="Bulk actions"
                    value={actionType}
                    onChange={handleChooseAction}
                    disabled={isPanelDisabled}
                >
                    <Option value={LeadsBulkActions.RESTART}>{upperFirstLetter(LeadsBulkActions.RESTART)}</Option>
                    <Option value={LeadsBulkActions.SEO_CHECKS}>SEO checks</Option>
                </Select>

                {
                    (actionType === LeadsBulkActions.RESTART || actionType === LeadsBulkActions.SEO_CHECKS) &&
                    <BulkActionsSelectors
                        isDisabled={isPanelDisabled}
                        actionType={actionType}
                        sampleType={sampleType}
                        leadsType={leadsType}
                        handleChooseSample={handleChooseSample}
                        handleChangeLeadsType={handleChangeLeadsType}
                    />
                }

                {
                    actionType &&
                    <BulkActionsConfirm
                        isDisabled={isPanelDisabled}
                        isLoading={bulkActionsRestartResult.isLoading}
                        actionType={actionType}
                        withSeoChecks={withSeoChecks}
                        isBulkActionReady={isBulkActionReady}
                        handleSetWithSeoChecks={handleSetWithSeoChecks}
                        handleResetBulkAction={handleResetBulkAction}
                        handleExecuteBulkAction={handleExecuteBulkAction}
                    />
                }
            </Space>
            {
                (sampleType === LeadsBulkActionsSamples.BY_FILTERS && !isBulkActionsSampleFiltersReady && !isPanelDisabled)
                &&
                <Typography.Text type="secondary" italic>HINT: define your filters or/and search query to
                    execute bulk
                    action</Typography.Text>
            }
        </>
    );
});
