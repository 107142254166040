import { Tag } from 'antd';
import React, { FC, memo } from 'react';

interface Props {
    status?: boolean;
}

export const LeadActiveStatusTag: FC<Props> = memo(({ status }: Props) => {
    const STATUS_MAP = {
        'true': {
            tagStatusColor: 'success',
            title: 'Yes',
        },
        'false': {
            tagStatusColor: 'error',
            title: 'No',
        },
        'undefined': {
            tagStatusColor: 'processing',
            title: 'Unknown',
        },
        'null': {
            tagStatusColor: 'processing',
            title: 'Unknown',
        }
    };

    return (
        <Tag color={STATUS_MAP[`${status}`].tagStatusColor}>{STATUS_MAP[`${status}`].title}</Tag>
    );
});
