import { Space, Tag, Typography } from 'antd';
import React, { FC, memo, useMemo } from 'react';
import { BoxArrowUpRight, Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import { LeadManualVerificationTag } from '../../../../components/LeadManualVerificationTag';
import { TextEmpty } from '../../../../components/TextEmpty';
import { LeadManualVerification, LeadTableDataObj, LeadTypes } from '../../../../interfaces/leads';
import { handleClickOnLoginUrl } from '../../../../utils/handleClick';

interface Props {
    type: LeadTableDataObj['type'];
    isDuplicate: LeadTableDataObj['isDuplicate'];
    url: LeadTableDataObj['info']['url'];
    regularUrl: LeadTableDataObj['info']['regularUrl'];
    regularLogin: LeadTableDataObj['info']['regularLogin'];
    regularPassword: LeadTableDataObj['info']['regularPassword'];
    shellyUrl: LeadTableDataObj['info']['shellyUrl'];
    shellyPassword: LeadTableDataObj['info']['shellyPassword'];
    homePage: LeadTableDataObj['info']['homePage'];
    manualVerification: LeadTableDataObj['info']['manualVerification'];
    manualVerificatedAt: LeadTableDataObj['info']['manualVerificatedAt'];
    offerId: LeadTableDataObj['offerId'];
}

const { Text } = Typography;

export const ColumnInfo: FC<Props> = memo(({
                                               type,
                                               isDuplicate,
                                               url,
                                               regularUrl,
                                               regularLogin,
                                               regularPassword,
                                               shellyUrl,
                                               shellyPassword,
                                               homePage,
                                               manualVerification,
                                               manualVerificatedAt,
                                               offerId,
                                           }: Props) => {
    const isTagsAvailable = useMemo(() => isDuplicate || manualVerification !== LeadManualVerification.NOT_CHECKED,
        [isDuplicate, manualVerification],
    );

    return (
        <div style={{ maxWidth: 390 }}>
            <Text strong>CRM Offer:&nbsp;</Text>
            <Text
                copyable={{
                    text: `https://manager.adzz.com/#/v1_Offer/${offerId}`,
                    icon: [
                        <Clipboard size={12} key="copy-icon"/>,
                        <ClipboardCheck size={12} key="copied-icon"/>,
                    ],
                    tooltips: ['Copy', 'Copied!'],
                }}
            >
                <a target="_blank" href={`https://manager.adzz.com/#/v1_Offer/${offerId}`} rel="noreferrer">
                    {offerId}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                </a>
            </Text>
            <br/>

            <Text strong>URL:&nbsp;</Text>
            <Text
                copyable={{
                    text: url,
                    icon: [
                        <Clipboard size={12} key="copy-icon"/>,
                        <ClipboardCheck size={12} key="copied-icon"/>,
                    ],
                    tooltips: ['Copy', 'Copied!'],
                }}
            >
                <a target="_blank" href={url} rel="noreferrer" onClick={handleClickOnLoginUrl}>
                    {url}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                </a>
            </Text>
            <br/>
            <Text strong>Base&nbsp;URL:&nbsp;</Text>
            <Text
                copyable={{
                    text: regularUrl ?? shellyUrl,
                    icon: [
                        <Clipboard size={12} key="copy-icon"/>,
                        <ClipboardCheck size={12} key="copied-icon"/>,
                    ],
                    tooltips: ['Copy', 'Copied!'],
                }}
            >
                <a target="_blank" href={regularUrl ?? shellyUrl} rel="noreferrer">
                    {regularUrl ?? shellyUrl}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                </a>
            </Text>
            <br/>
            <Text strong>Home page:&nbsp;</Text>
            <Text
                copyable={{
                    text: homePage,
                    icon: [
                        <Clipboard size={12} key="copy-icon"/>,
                        <ClipboardCheck size={12} key="copied-icon"/>,
                    ],
                    tooltips: ['Copy', 'Copied!'],
                }}
            >
                <a target="_blank" href={homePage} rel="noreferrer">
                    {homePage}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                </a>
            </Text>
            <br/>
            {
                type === LeadTypes.REGULAR && <>
                    <Text strong>Login:&nbsp;</Text>
                    <Text
                        copyable={{
                            text: regularLogin,
                            icon: [
                                <Clipboard size={12} key="copy-icon"/>,
                                <ClipboardCheck size={12} key="copied-icon"/>,
                            ],
                            tooltips: ['Copy', 'Copied!'],
                        }}
                    >
                        {regularLogin}
                    </Text>
                    <br/>
                </>
            }
            <Text strong>Password:&nbsp;</Text>
            <Text
                copyable={
                    (regularPassword ?? shellyPassword)
                        ? {
                            text: regularPassword ?? shellyPassword,
                            icon: [
                                <Clipboard size={12} key="copy-icon"/>,
                                <ClipboardCheck size={12} key="copied-icon"/>,
                            ],
                            tooltips: ['Copy', 'Copied!'],
                        }
                        : undefined
                }
            >
                {regularPassword ?? (shellyPassword || <TextEmpty/>)}
            </Text>
            {
                isTagsAvailable &&
                <Space style={{ marginTop: 5 }}>
                    {isDuplicate && <Tag color="volcano">Duplicate</Tag>}

                    {
                        manualVerification !== LeadManualVerification.NOT_CHECKED &&
                        <LeadManualVerificationTag
                            type="badge"
                            verificationStatus={manualVerification}
                            verificatedAt={manualVerificatedAt}
                            layout="row"
                        />
                    }
                </Space>
            }
        </div>
    );
});
