import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import { ErrorBoundary } from './pages/ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

import 'antd/dist/antd.min.css';
import './index.scss';

const container = document.getElementById('root') ?? document.body;

const root = createRoot(container);

root.render(
    <ErrorBoundary>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </ErrorBoundary>
);

reportWebVitals();
