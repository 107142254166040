export const formatPercentage = (val: number, returnNum = false): string | number => {
    const newVal = new Intl.NumberFormat(
        'en-US',
        {
            style: 'decimal',
            maximumFractionDigits: 2
        }
    ).format(val);

    if (returnNum) {
        return Number(newVal);
    } else {
        return newVal;
    }
};

export const numberWithGrouping = (val: number): string => {
    return new Intl.NumberFormat(
        'en-US',
        {
            style: 'decimal',
            useGrouping: true
        }
    ).format(val);
};
