import { Badge, Tag } from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import React, { FC, memo } from 'react';
import { LeadConnectionStatuses } from '../../interfaces/leads';
import { upperFirstLetter } from '../../utils/string';

interface Props {
    status: LeadConnectionStatuses;
}

export const ConnectionBadge: FC<Props> = memo(({ status }: Props) => {
    let badgeStatusColor: PresetStatusColorType;

    switch (status) {
        case LeadConnectionStatuses.ONLINE:
            badgeStatusColor = 'success';
            break;
        case LeadConnectionStatuses.OFFLINE:
            badgeStatusColor = 'error';
            break;
    }

    return (
        <Tag><Badge status={badgeStatusColor} text={upperFirstLetter(status)}/></Tag>
    );
});
