import { Descriptions, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import { LRTErrorMessage } from '../../../../components/LRTErrorMessage';
import { LRTStepProgress } from '../../../../components/LRTStepProgress';
import { TextEmpty } from '../../../../components/TextEmpty';
import { LeadDossier, LeadStatuses } from '../../../../interfaces/leads';
import { LRTReportResultReportColumns } from '../../../../interfaces/seoApi';
import { toLocaleDate } from '../../../../utils/date';

type Props = {
    linkresearchtoolsInfo: LeadDossier['linkInfo']['linkresearchtools'];
    status: LeadDossier['status'];
}

const { Text } = Typography;

export const LRTBlock: FC<Props> = memo(({
                                             linkresearchtoolsInfo,
                                             status
                                         }: Props) => {
    return (
        <>
            <Descriptions
                column={1}
                layout="vertical"
                labelStyle={{ marginBottom: '-8px' }}
                size="small"
                title="LinkResearchTools"
            >
                {
                    (linkresearchtoolsInfo.report_id &&
                        status === LeadStatuses.SUCCESSFUL) && <>
                        <Descriptions.Item
                            label={<Text type="secondary">Report ID</Text>}
                        >
                            <Text
                                copyable={{
                                    text: linkresearchtoolsInfo.report_id.toString(),
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                {linkresearchtoolsInfo.report_id}
                            </Text>
                        </Descriptions.Item>
                    </>
                }
                {
                    (linkresearchtoolsInfo.report_error && status === LeadStatuses.SUCCESSFUL)
                        ? <>
                            <Descriptions.Item contentStyle={{ marginTop: '-12px' }}>
                                <Text type="danger" italic>{linkresearchtoolsInfo.report_error.errorMessage}</Text>
                            </Descriptions.Item>
                        </>
                        : <>
                            {
                                (linkresearchtoolsInfo.is_report_generated &&
                                    linkresearchtoolsInfo.report_id &&
                                    status === LeadStatuses.SUCCESSFUL)
                                    ? <>
                                        <Descriptions.Item
                                            label={<Text type="secondary">Last checked at</Text>}
                                        >
                                            {
                                                linkresearchtoolsInfo.last_checked_at
                                                    ? <Text>{toLocaleDate(linkresearchtoolsInfo.last_checked_at)}</Text>
                                                    : <TextEmpty/>
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={<Text
                                                type="secondary"
                                            >{LRTReportResultReportColumns.POWER_TRUST_DOM}</Text>}
                                        >
                                            <LRTStepProgress
                                                value={linkresearchtoolsInfo.power_trust_dom}
                                                column={'Power*Trust dom'}
                                            />
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={<Text
                                                type="secondary"
                                            >{LRTReportResultReportColumns.POWER_TRUST}</Text>}
                                        >
                                            <LRTStepProgress
                                                value={linkresearchtoolsInfo.power_trust}
                                                column={'Power*Trust'}
                                            />
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={<Text
                                                type="secondary"
                                            >{LRTReportResultReportColumns.POWER_DOM}</Text>}
                                        >
                                            <LRTStepProgress
                                                value={linkresearchtoolsInfo.power_dom}
                                                column={'Power-dom'}
                                            />
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={<Text
                                                type="secondary"
                                            >{LRTReportResultReportColumns.TRUST_DOM}</Text>}
                                        >
                                            <LRTStepProgress
                                                value={linkresearchtoolsInfo.trust_dom}
                                                column={'Trust-dom'}
                                            />
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={<Text type="secondary">{LRTReportResultReportColumns.THEME}</Text>}
                                        >
                                            <Text>
                                                {linkresearchtoolsInfo.theme || <TextEmpty/>}
                                            </Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={<Text
                                                type="secondary"
                                            >{LRTReportResultReportColumns.TITLE_HOME}</Text>}
                                        >
                                            <Text>
                                                {linkresearchtoolsInfo.title_home || <TextEmpty/>}
                                            </Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={<Text type="secondary">{LRTReportResultReportColumns.IP}</Text>}
                                        >
                                            <Text
                                                copyable={
                                                    !!linkresearchtoolsInfo.ip && {
                                                        text: linkresearchtoolsInfo.ip,
                                                        icon: [
                                                            <Clipboard size={12} key="copy-icon"/>,
                                                            <ClipboardCheck size={12} key="copied-icon"/>
                                                        ],
                                                        tooltips: ['Copy', 'Copied!']
                                                    }
                                                }
                                            >
                                                {linkresearchtoolsInfo.ip || <TextEmpty/>}
                                            </Text>
                                        </Descriptions.Item>
                                    </>
                                    : <Descriptions.Item contentStyle={{ marginTop: '-12px' }}>
                                        <LRTErrorMessage
                                            isReportGenerated={linkresearchtoolsInfo.is_report_generated}
                                            reportId={linkresearchtoolsInfo.report_id}
                                            status={status}
                                        />
                                    </Descriptions.Item>
                            }
                        </>
                }
            </Descriptions>
        </>
    );
});
