import { Affix, Col, Layout, Row, Typography } from 'antd';
import React, { FC, memo } from 'react';

type Props = {
    controls?: React.ReactNode;
    titleName: React.ReactNode | string;
}

export const HeaderInner: FC<Props> = memo(({ controls, titleName }: Props) => {
    return (
        <Affix offsetTop={0}>
            <Layout.Header className="page-header">
                <Row gutter={[16, 0]} justify="space-between" align="middle">
                    <Col>
                        <Typography.Title
                            level={4} style={{ marginBottom: 0, lineHeight: '64px' }}
                        >{titleName}</Typography.Title>
                    </Col>
                    {
                        controls &&
                        <Col>
                            {controls}
                        </Col>
                    }
                </Row>
            </Layout.Header>
        </Affix>
    );
});
