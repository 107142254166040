import { Button, Col, Divider, Row, Select, Space, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { SortAlphaDown, SortAlphaUp, SortNumericDown, SortNumericUp } from 'react-bootstrap-icons';
import { SortFields, SortVariants } from '../../../../../interfaces/leads';
import { upperFirstLetter } from '../../../../../utils/string';

interface Props {
    sortField: SortFields;
    sortVariant: SortVariants;
    isFieldNumberic: boolean;
    handleSetSortField: (value: SortFields) => void;
    handleSetSortVariant: (value: SortVariants) => void;
    handleResetSorts: () => void;
    handleFindBySorting: () => void;
}

const { Text } = Typography;
const { Option } = Select;

export const Panel: FC<Props> = memo(({
                                          sortField,
                                          sortVariant,
                                          isFieldNumberic,
                                          handleSetSortField,
                                          handleSetSortVariant,
                                          handleResetSorts,
                                          handleFindBySorting
                                      }: Props) => {
    return (
        <Row gutter={[16, 16]} wrap className="leads-table__sort-panel">
            <Col span={24}>
                <Text strong style={{ fontSize: 12 }}>Field</Text>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Choose"
                    value={sortField}
                    onChange={handleSetSortField}
                >
                    <Option value={SortFields.NUMBER}>№</Option>
                    <Option value={SortFields.CHECKED_DATE}>{upperFirstLetter(SortFields.CHECKED_DATE)}</Option>
                    <Option value={SortFields.TYPE}>{upperFirstLetter(SortFields.TYPE)}</Option>
                    <Option value={SortFields.STATUS}>Access status</Option>
                    <Option value={SortFields.CONNECTION_STATUS}>Lead connection</Option>
                    <Option value={SortFields.STATUS_CODE}>Page status</Option>
                    <Option value={SortFields.ENGINE}>{upperFirstLetter(SortFields.ENGINE)}</Option>
                    <Option value={SortFields.LANGUAGE}>{upperFirstLetter(SortFields.LANGUAGE)}</Option>
                    <Option value={SortFields.LRT_POWER_TRUST_DOM}>LRT Power*Trust dom</Option>
                </Select>
            </Col>
            <Col span={24}>
                <Text strong style={{ fontSize: 12 }}>Variant</Text>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Choose"
                    value={sortVariant}
                    onChange={handleSetSortVariant}
                >
                    <Option value={SortVariants.ACS}>
                        {
                            isFieldNumberic ? <SortNumericDown/> : <SortAlphaDown/>
                        }&nbsp;Ascending
                    </Option>
                    <Option value={SortVariants.DESC}>
                        {
                            isFieldNumberic ? <SortNumericUp/> : <SortAlphaUp/>
                        }&nbsp;Descending
                    </Option>
                </Select>
            </Col>
            <Col span={24}><Divider style={{ margin: 0 }}/></Col>
            <Col span={24}>
                <Space direction="vertical">
                    <Button type="default" block onClick={handleResetSorts}>Reset</Button>
                    <Button type="primary" block onClick={handleFindBySorting}>Apply</Button>
                </Space>
            </Col>
        </Row>
    );
});
