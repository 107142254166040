import { Button, Empty, Result, Spin } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import { Plus } from 'react-bootstrap-icons';
import { Modal } from '../../../components/Modal';
import { RTKError } from '../../../store';
import { useGetWebsitesLeadsQuery } from '../../../store/queries/websites';
import { CreateLeadModal } from '../../Leads/CreatingModal';
import { LeadsCatalogTable } from './leadsCatalogTable';

interface Props {
    isVisible: boolean;
    websiteId: string | null;
    handleCloseModal: () => void;
}

export const WebsitesLeadsCatalogModal: FC<Props> = memo(({
                                                              isVisible,
                                                              websiteId,
                                                              handleCloseModal
                                                          }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const { data: websitesLeads, error, isLoading } = useGetWebsitesLeadsQuery(websiteId ?? '', {
        skip: !isVisible && !websiteId
    });

    const handleOpenCreateLeadModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleCloseCreateLeadModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return (
        <Modal
            className="websites-leads-catalog-modal"
            width={'85vw'}
            title={<div
                style={{ display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 30px)' }}
            >
        <span>
          Leads catalog
        </span>
                <Button
                    type="default"
                    size="small"
                    icon={<Plus/>}
                    onClick={handleOpenCreateLeadModal}
                >
                    Create lead
                </Button>
            </div>}
            open={isVisible}
            onCancel={handleCloseModal}
            footer={null}
        >
            <CreateLeadModal isVisible={isModalOpen} handleClose={handleCloseCreateLeadModal}/>
            {
                error
                    ? <Result status="error" title={(error as RTKError).data.message}/>
                    : <>
                        {(isLoading && !websitesLeads) && <Spin className="centered-spinner"/>}
                        {
                            (!isLoading && websitesLeads && !!websitesLeads.length) &&
                            <LeadsCatalogTable
                                websitesLeads={websitesLeads}
                            />
                        }
                        {
                            (!isLoading && !error && !websitesLeads?.length) && <Empty
                                description="No websites leads"
                                className="centered-empty"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        }
                    </>
            }
        </Modal>
    );
});
