import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorType } from '../../interfaces';
import { LeadsByPageRequest } from '../../interfaces/leads';
import { LeadsListParams } from '../../interfaces/leadsLists';
import { API } from '../../service/api';
import { setFilteredLeadsCount } from '../slices/leads';

export const fetchAllListsUnarchived = createAsyncThunk(
    'leads-list/fetchAllUnarchived',
    async (page: number, thunkAPI) => {
        try {
            const response = await API.leadsLists.fetchAllUnarchived(page);
            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const refetchAllListsUnarchived = createAsyncThunk(
    'leads-list/refetchAllUnarchived',
    async (page: number, thunkAPI) => {
        try {
            const response = await API.leadsLists.fetchAllUnarchived(page);
            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const fetchLeadsListEditData = createAsyncThunk(
    'leads-list/getEditData',
    async (id: string, thunkAPI) => {
        try {
            const response = await API.leadsLists.getEditData(id);
            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const fetchListById = createAsyncThunk(
    'leads-list/getById',
    async ({ id, page }: { id: string; page: number }, thunkAPI) => {
        try {
            const response = await API.leadsLists.getById(id, page);
            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const fetchLeadsByPageCount = createAsyncThunk(
    'leads-list/leadsByPageCount',
    async (params: LeadsByPageRequest, thunkAPI) => {
        try {
            const response = await API.leadsLists.getLeadsById({
                ...params
            });

            thunkAPI.dispatch(setFilteredLeadsCount(response.data.filtered_leads_count));

            return response.data.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const createList = createAsyncThunk(
    'leads-list/createList',
    async ({ data, handleClose }: { data: LeadsListParams, handleClose: () => void }, thunkAPI) => {
        try {
            await API.leadsLists.create(data);
            handleClose();
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const updateList = createAsyncThunk(
    'leads-list/updateList',
    async ({ id, data, handleClose }: { id: string, data: LeadsListParams, handleClose: () => void }, thunkAPI) => {
        try {
            await API.leadsLists.update(id, data);
            handleClose();
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const deleteList = createAsyncThunk(
    'leads-list/deleteList',
    async ({ id, handleClose }: { id: string, handleClose?: () => void }, thunkAPI) => {
        try {
            const response = await API.leadsLists.delete(id);

            handleClose && handleClose();

            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);
