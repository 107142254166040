import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Modal, Spin, Upload } from 'antd';
import type { RcFile, UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useState } from 'react';
import { FiletypeCsv } from 'react-bootstrap-icons';
import { apiRequest } from '../../service/api/apiRequest';
import { Routes } from '../../typedef';

const { Dragger } = Upload;

const URL = `${process.env.REACT_APP_API_URL}${Routes.WEBSITES}/update-statistics`;

const SUCCESS_MESSAGE = (file: RcFile, count: number) => `${file.name} file uploaded successfully. ${count} websites have been updated.`;

const ERROR_MESSAGE = (file: RcFile) => `${file.name} file upload failed.`;

const ImportWebsiteStatisticsFile = () => {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setOpen(false);
        setIsUploading(false);
    };

    const uploadFile = async (options: RcCustomRequestOptions) => {
        const { file } = options;
        const data = new FormData();
        data.append('file', file);

        try {
            setIsUploading(true);
            const response = await apiRequest.post(URL, data);
            closeModal();
            message.success(SUCCESS_MESSAGE(file as RcFile, response.data?.count));
        } catch (error: any) {
            setIsUploading(false);
            console.log('Error in customFileUploadFunction()', { error });
            message.error(ERROR_MESSAGE(file as RcFile));
        }
    };

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: '.csv',
        customRequest: uploadFile,
        showUploadList: false,
        disabled: isUploading,
    };

    return (
        <>
            <Button
                type="primary"
                onClick={() => setOpen(true)}
                icon={<FiletypeCsv/>}
            >
                Import Statistics
            </Button>
            <Modal
                open={open}
                title="Import Website Statistics"
                onCancel={closeModal}
                centered={true}
                footer={false}
            >
                <>
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            {isUploading ? <Spin size="large"/> : <InboxOutlined />}
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">Support for one CSV file upload.</p>
                    </Dragger>
                </>
            </Modal>
        </>
    );
};

export default ImportWebsiteStatisticsFile;
