/* eslint-disable implicit-arrow-linebreak */
import { message, Tag } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
/* eslint-disable no-nested-ternary */
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { LinksSegments } from '../../typedef';
import { EmptyBadge } from './emptyBadge';
import { ValidationPanel } from './validationPanel';

interface Props {
    setLinksToForm: (links: string) => void;
    getCurrentLinksTypeAndLinks: (links: string[], segment: LinksSegments) => void;
}

export const LinksValidator: FC<Props> = memo(({
                                                   setLinksToForm,
                                                   getCurrentLinksTypeAndLinks
                                               }: Props) => {
    const [links, setLinks] = useState<string[]>([]);
    const [currentSegment, setCurrentSegment] = useState<LinksSegments>(LinksSegments.ALL);
    const [goodLinks, setGoodLinks] = useState<string[]>([]);
    const [badLinks, setBadLinks] = useState<string[]>([]);
    const [showLinks, setShowLinks] = useState<boolean>(false);

    const linkRegex =
        /(((http|ftp|https):\/{2})+(([0-9a-z_-]+\.)+(\w+)(:[0-9]+)?((\/([~0-9a-zA-Z#+%@./_-]+))?(\?[0-9a-zA-Z+%@/&[\];=_-]+)?)?))\b/giu;

    const regularRegex =
        /((https|http):\/\/([a-zA-Z0-9.:\-_]+)(\/[a-zA-Z0-9.\-_ ]+[/]?)*)[#|?|@](.*):(.*)/im;
    const shellyRegex =
        /((https|http):\/\/([a-zA-Z0-9.:\-_]+)(\/[a-zA-Z0-9.\-_ ]+[/]?)*)[#|?|@](.*)/im;

    const currentLinksBySegment = useMemo(() => currentSegment === LinksSegments.ALL
            ? links
            : (currentSegment === LinksSegments.GOOD
                ? goodLinks
                : badLinks),
        [currentSegment, links, goodLinks, badLinks]);

    useEffect(() => {
        handleValidateLinks();
    }, [links]);

    useEffect(() => {
        setLinksToForm(currentLinksBySegment.join('\n'));
    }, [currentLinksBySegment]);

    useEffect(() => {
        getCurrentLinksTypeAndLinks(currentLinksBySegment, currentSegment);
    }, [currentSegment, currentLinksBySegment]);

    const handleValidateLinks = useCallback((): void => {
        if (links.length) {
            setGoodLinks([]);
            setBadLinks([]);

            const linksString = links.join('\n');

            if (!linkRegex.test(linksString)) {
                message.error('Links must contain at least one URL');
                setLinks([]);

                return;
            }

            for (let i = 0; i < links.length; i++) {
                try {
                    if (regularRegex.test(links[i])) {
                        const matches = links[i].match(regularRegex);

                        if (matches) {
                            const [, url, , domain, , login, password] = matches;
                            const subdomain = domain.split('.').slice(0, -1).join('.');

                            if (!url || !password || !login || !domain || !subdomain) {
                                throw new Error();
                            }

                            setGoodLinks(prevLinks => [...prevLinks, links[i]]);
                        } else {
                            throw new Error();
                        }
                    } else if (shellyRegex.test(links[i])) {
                        const matches = links[i].match(shellyRegex);

                        if (matches) {
                            const [, url, , domain, ,] = matches;
                            const subdomain = domain.split('.').slice(0, -1).join('.');

                            if (!url || !domain || !subdomain) {
                                throw new Error();
                            }

                            setGoodLinks(prevLinks => [...prevLinks, links[i]]);
                        } else {
                            throw new Error();
                        }
                    } else {
                        throw new Error();
                    }
                } catch {
                    setBadLinks(prevLinks => [...prevLinks, links[i]]);
                }
            }
        } else {
            setGoodLinks([]);
            setBadLinks([]);
        }
    }, [links]);

    const handlePasteLinks = useCallback(async (): Promise<void> => {
        try {
            const clipboardText = await navigator.clipboard.readText();

            if (clipboardText) {
                setCurrentSegment(LinksSegments.ALL);
                setLinks(Array.from(new Set(
                    clipboardText
                        .trim()
                        .split('\n')
                )));
            } else {
                message.warning(<>It seems like you are trying to insert data not of type <Tag>Text</Tag></>);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (/Read permission denied/gim.test(error.message)) {
                message.error('Please allow to use the clipboard!');
            } else {
                message.error(`${error}`);
            }
        }
    }, [navigator]);

    const handleRemoveLinks = useCallback((): void => {
        setLinks([]);
        setShowLinks(false);
    }, []);

    const handleChangeLinksSegment = useCallback((segment: SegmentedValue): void => {
        setCurrentSegment(segment as LinksSegments);
    }, []);

    const handleExpandLinks = useCallback((): void => setShowLinks(!showLinks), []);

    const handleClearLinks = useCallback((): void => {
        if (badLinks.length) {
            const badLinksString = badLinks.join('\n');

            // removing double-slashes
            const badLinksArray = badLinksString.replaceAll('//', '/').replaceAll(/(http|https):\//gim, '$&/').split('\n');

            setLinks([...goodLinks, ...badLinksArray]);
        }
    }, [badLinks, goodLinks]);

    if (!links.length) return (
        <EmptyBadge handlePasteLinks={handlePasteLinks}/>
    );

    return (
        <ValidationPanel
            links={links}
            goodLinks={goodLinks}
            badLinks={badLinks}
            currentSegment={currentSegment}
            showLinks={showLinks}
            currentLinksBySegment={currentLinksBySegment}
            handleExpandLinks={handleExpandLinks}
            handleClearLinks={handleClearLinks}
            handleRemoveLinks={handleRemoveLinks}
            handleChangeLinksSegment={handleChangeLinksSegment}
        />
    );
});
