import { Divider, Empty } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Drawer } from '../../../components/Drawer';
import { Website, WebsiteDossier } from '../../../interfaces/websites';
import { GeneralBlock } from './Blocks/generalBlock';
import { SeoInfoBlock } from './Blocks/seoInfoBlock';

interface Props {
    isVisible: boolean;
    website: Website | null;
    onClose: () => void;
}

export const WebsiteDossierModal: FC<Props> = (
    {
        isVisible,
        website,
        onClose: handleClose
    }: Props) => {
    const [dossierContent, setDossierContent] = useState<WebsiteDossier | null>(null);

    useEffect(() => {
        if (isVisible && website) {
            const dossier: WebsiteDossier = {
                id: website._id,
                isAccessible: website.is_accessible,
                baseUrl: website.base_url,
                domain: website.domain,
                manualVerification: website.manual_verification,
                manualVerificatedAt: website.manual_verificated_at,
                crmId: website.crm_id,
                crmOfferId: website.crm_offer_id,
                checkedAt: website.checked_at,
                createdAt: website.created_at,
                connectionStatus: website.connection_status,
                topics: website.topics,
                note: website.note,
                language: website.language,
                lrtPowerTrustDom: website.lrt_power_trust_dom,
                ahrefsDomainRating: website.ahrefs_domain_rating,
                ahrefsTotalTraffic: website.ahrefs_total_traffic,
                seoInfoLastCheckedAt: website.seo_info_last_checked_at,
                contentIndicators: website.content_indicators,
                domainRating: website.domain_rating,
                keywords: website.keywords,
                traffic: website.traffic,
                dtoxrisk: website.dtoxrisk,
                powerTrustDom: website.power_trust_dom,
                powerDom: website.power_dom,
                trustDom: website.trust_dom,
            };

            setDossierContent(
                dossier
            );
        }

        return () => {
            setDossierContent(null);
        };
    }, [isVisible, website]);

    return (
        <Drawer
            width={500}
            title={<>
                Dossier for: {dossierContent?.domain ?? ''}
            </>}
            placement="right"
            className="dossier-drawer"
            onClose={handleClose}
            open={isVisible}
        >
            {
                dossierContent
                    ? <>
                        <GeneralBlock
                            id={dossierContent.id}
                            isAccessible={dossierContent.isAccessible}
                            baseUrl={dossierContent.baseUrl}
                            domain={dossierContent.domain}
                            manualVerification={dossierContent.manualVerification}
                            manualVerificatedAt={dossierContent.manualVerificatedAt}
                            createdAt={dossierContent.createdAt}
                            checkedAt={dossierContent.checkedAt}
                            connectionStatus={dossierContent.connectionStatus}
                            language={dossierContent.language}
                            topics={dossierContent.topics}
                            note={dossierContent.note}
                            contentIndicators={dossierContent.contentIndicators}
                        />

                        <Divider style={{ marginTop: 8, marginBottom: 16 }}/>

                        <SeoInfoBlock
                            domainRating={dossierContent.domainRating}
                            keywords={dossierContent.keywords}
                            traffic={dossierContent.traffic}
                            dtoxrisk={dossierContent.dtoxrisk}
                            powerTrustDom={dossierContent.powerTrustDom}
                            powerDom={dossierContent.powerDom}
                            trustDom={dossierContent.trustDom}

                        />
                    </>
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            }
        </Drawer>
    );
};
