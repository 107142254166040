import { Badge, Tooltip } from 'antd';
import React, { FC, memo } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { StatusCodesInfo } from '../../typedef';

interface Props {
    statusCode: number;
}

export const PageStatusBadge: FC<Props> = memo(({ statusCode }: Props) => {
    return (
        <Tooltip placement="top" title={StatusCodesInfo[statusCode]?.info ?? 'unknown'}>
            <Badge
                className="leads-table__status-code-info"
                status={StatusCodesInfo[statusCode]?.badgeType ?? 'default'}
                text={<>{statusCode}&nbsp;<QuestionCircle/></>}
            />
        </Tooltip>
    );
});
