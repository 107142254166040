import React from 'react';
import { EmojiDizzy } from 'react-bootstrap-icons';

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    render(): React.ReactNode {

        if (this.state.hasError) {
            return (
                <div className="error-boundary-page">
                    <EmojiDizzy size={70}/>
                    <p>Fatal error</p>
                </div>
            );
        }

        return this.props.children;
    }
}
