import { Button, Space } from 'antd';
import React, { FC, memo } from 'react';
import { TagFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router';
import { ExportWebsiteListCSV } from '../../../components/ExportWebsiteListCSV';
import ImportWebsiteStatisticsFile from '../../../components/ImportWebsiteStatisticsFile';
import { Routes } from '../../../typedef';
import { WebsiteDashboardHeaderProps } from './dashboardTypes';

export const WebsiteDashboardHeaderControls: FC<WebsiteDashboardHeaderProps> = memo(({ exportListCSVProps }) => {
    const navigate = useNavigate();

    return (
        <>
            {
                <Space size="middle">
                    {exportListCSVProps &&
                        <ExportWebsiteListCSV
                            buttonType={'full'}
                            exportCsvRef={exportListCSVProps.exportCsvRef}
                            loading={exportListCSVProps.listIsLoading}
                        />
                    }

                    <ImportWebsiteStatisticsFile/>

                    <Button
                        type="default"
                        icon={<TagFill/>}
                        onClick={(): void => navigate(Routes.TOPICS_CREATE, {
                            state: { backgroundLocation: Routes.WEBSITES }
                        })}
                    >
                        Create topic
                    </Button>
                </Space>
            }
        </>
    );
});
