import { Button, Popconfirm, Radio, RadioChangeEvent, Tooltip, Typography } from 'antd';
import React, { FC, memo, RefObject, useCallback, useMemo, useState } from 'react';
import { FiletypeCsv } from 'react-bootstrap-icons';
import { AdditionalLeadStatuses, LeadsGenerateCSVTypes, LeadStatuses } from '../../interfaces/leads';
import { LeadsListStatuses } from '../../interfaces/leadsLists';
import { WebsiteConnectionStatuses } from '../../interfaces/websites';
import { generateLeadsListCsv } from '../../service/csv';
import { LeadsListsState } from '../../store/slices/leadsList';
import { upperFirstLetter } from '../../utils/string';

interface Props {
    id: string;
    currentDeletingRowId: LeadsListsState['currentDeletingRowId'];
    status: LeadsListStatuses | WebsiteConnectionStatuses;
    buttonType: 'icon' | 'full';
    exportCsvRef: RefObject<HTMLAnchorElement>;
    loading?: boolean;
}

export const ExportListCSVPopconfirm: FC<Props> = memo((
    {
        id,
        buttonType,
        status,
        currentDeletingRowId,
        exportCsvRef,
        loading
    }: Props
) => {
    const [csvType, setCSVType] = useState<LeadsGenerateCSVTypes>(AdditionalLeadStatuses.ALL);

    const isButtonIcon = useMemo(() => buttonType === 'icon', [buttonType]);
    const isDisabled = useMemo(() => status !== LeadsListStatuses.READY ||
            currentDeletingRowId.includes(id),
        [status, currentDeletingRowId]);

    const handleChangeCSVType = useCallback((e: RadioChangeEvent): void => {
        setCSVType(e.target.value);
    }, []);

    const handleResetCSVType = useCallback((visible: boolean): void => {
        if (!visible) {
            setCSVType(AdditionalLeadStatuses.ALL);
        }
    }, []);

    const handleGenerateCSV = useCallback(async (): Promise<void> => {
        id && await generateLeadsListCsv({ id, ref: exportCsvRef, type: csvType });
        setCSVType(AdditionalLeadStatuses.ALL);
    }, [id, exportCsvRef, csvType]);

    return (
        <Tooltip title={isButtonIcon && 'Generate CSV file'}>
            <Popconfirm
                arrowPointAtCenter
                key="generateCSV"
                placement={isButtonIcon ? 'bottomLeft' : 'bottom'}
                title={
                    <>
                        <Typography.Text strong>
                            What leads to create CSV from?
                            {currentDeletingRowId && '333'}
                        </Typography.Text>
                        <br/>
                        <Radio.Group onChange={handleChangeCSVType} value={csvType}>
                            <Radio
                                value={AdditionalLeadStatuses.ALL}
                            >{upperFirstLetter(AdditionalLeadStatuses.ALL)}</Radio>
                            <Radio value={LeadStatuses.SUCCESSFUL}>{upperFirstLetter(LeadStatuses.SUCCESSFUL)}</Radio>
                            <Radio value={LeadStatuses.FAILED}>{upperFirstLetter(LeadStatuses.FAILED)}</Radio>
                            <br/>
                            <Radio
                                value={LeadStatuses.UNPROCESSABLE}
                            >{upperFirstLetter(LeadStatuses.UNPROCESSABLE)}</Radio>
                            <br/>
                            <Radio value={AdditionalLeadStatuses.BAD}>
                                {`${upperFirstLetter(LeadStatuses.FAILED)} + ${upperFirstLetter(LeadStatuses.UNPROCESSABLE)}`}
                            </Radio>
                        </Radio.Group>
                    </>
                }
                onConfirm={handleGenerateCSV}
                onOpenChange={handleResetCSVType}
                okText="Generate"
                cancelText="Cancel"
                disabled={isDisabled}
            >
                <Button
                    loading={loading ?? false}
                    type={isButtonIcon ? 'link' : 'default'}
                    disabled={isDisabled}
                    icon={<FiletypeCsv size={isButtonIcon ? 20 : 14}/>}
                >
                    {
                        isButtonIcon
                            ? undefined
                            : 'Export to CSV'
                    }
                </Button>
            </Popconfirm>
        </Tooltip>
    );
});
