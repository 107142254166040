import { Progress, Typography } from 'antd';
import React, { FC, memo, useEffect, useState } from 'react';
import { LRTReportResultReportColumns, LRTReportValuesMax } from '../../interfaces/seoApi';

type Props = {
    value: number | null;
    column: 'Trust-dom' |
        'Power-dom' |
        'Power*Trust' |
        'Power*Trust dom';
}

export const LRTStepProgress: FC<Props> = memo(({ value, column }: Props) => {
    const [percentValue, setPercentValue] = useState<number>(0);
    const [strokeColor, setStrokeColor] = useState<string>('#fff');

    useEffect(() => {
        if (value) {
            if (column === LRTReportResultReportColumns.POWER_TRUST_DOM) {
                const res = (value * Number(LRTReportValuesMax.POWER_TRUST_DOM)) / 100;
                setPercentValue(res === 0 ? 0 : Math.max(res, 10));
            }
            if (column === LRTReportResultReportColumns.POWER_TRUST) {
                const res = (value * Number(LRTReportValuesMax.POWER_TRUST)) / 100;
                setPercentValue(res === 0 ? 0 : Math.max(res, 10));
            }
            if (column === LRTReportResultReportColumns.POWER_DOM) {
                setPercentValue(((value * Number(LRTReportValuesMax.POWER_DOM)) / 10) * 10);
            }
            if (column === LRTReportResultReportColumns.TRUST_DOM) {
                setPercentValue(((value * Number(LRTReportValuesMax.TRUST_DOM)) / 10) * 10);
            }
        }
    }, [value, column]);

    useEffect(() => {
        if (percentValue < 30) setStrokeColor('#ff4d4f');
        if (percentValue >= 30 && percentValue <= 70) setStrokeColor('#ffa940');
        if (percentValue > 70) setStrokeColor('#73d13d');
    }, [percentValue]);

    return (
        <div style={{ display: 'inline-flex', alignItems: 'center', width: 'fit-content', flexWrap: 'nowrap' }}>
            <Progress
                showInfo={false}
                percent={percentValue}
                steps={10}
                status="normal"
                size="small"
                strokeColor={strokeColor}
            />
            &nbsp;
            <Typography.Text>{value}</Typography.Text>
        </div>
    );
});
