import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initFiltersSetting } from '../../constants/filters';
import { SortFields, SortVariants } from '../../interfaces/leads';
import { WebsitesFilterOptions, WebsitesSortOptions } from '../../interfaces/websites';

export interface WebsitesState {
    searching: string;
    sorting: WebsitesSortOptions;
    filtering: WebsitesFilterOptions;
}

const initialState: WebsitesState = {
    searching: '',
    sorting: {
        variant: SortVariants.ACS,
        field: SortFields.NUMBER
    },
    filtering: initFiltersSetting
};

export const websitesSlice = createSlice({
    name: 'websites',
    initialState,
    reducers: {
        applyWebsitesSearching: (state, action: PayloadAction<string>) => {
            state.searching = action.payload;
        },
        applyWebsitesSorting: (state, action: PayloadAction<WebsitesSortOptions>) => {
            state.sorting = {
                variant: action.payload.variant,
                field: action.payload.field
            };
        },
        applyWebsitesFiltering: (state, action: PayloadAction<WebsitesFilterOptions>) => {
            state.filtering = {
                accessability: action.payload.accessability,
                language: action.payload.language,
                lrtPowerTrustDom: action.payload.lrtPowerTrustDom,
                ahrefsDr: action.payload.ahrefsDr,
                ahrefsTraffic: action.payload.ahrefsTraffic,
                manualVerification: action.payload.manualVerification,
                manualVerificationDate: action.payload.manualVerificationDate,
                lastCheckingDate: action.payload.lastCheckingDate,
                creatingDate: action.payload.creatingDate,
                listID: action.payload.listID
            };
        },

        setFilteredWebsitesCount: (state, action: PayloadAction<number>) => {
            state.filtering.websitesCount = action.payload;
        },

        resetWebsitesSearching: (state) => {
            state.searching = '';
        },
        resetWebsitesSorting: (state) => {
            state.sorting = {
                variant: SortVariants.ACS,
                field: SortFields.NUMBER
            };
        },
        resetWebsitesFiltering: (state) => {
            state.filtering = {
                websitesCount: null,
                accessability: null,
                language: [],
                lrtPowerTrustDom: false,
                ahrefsDr: false,
                ahrefsTraffic: false,
                manualVerification: null,
                manualVerificationDate: false,
                lastCheckingDate: false,
                creatingDate: false,
                listID: null
            };
        },
        clearWebsitesStore: (state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState;
        }
    }
});

export const {
    applyWebsitesSearching,
    applyWebsitesSorting,
    applyWebsitesFiltering,

    resetWebsitesSearching,
    resetWebsitesSorting,
    resetWebsitesFiltering,

    setFilteredWebsitesCount,

    clearWebsitesStore
} = websitesSlice.actions;

export default websitesSlice.reducer;
