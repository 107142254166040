import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { ErrorType } from '../interfaces';
import { leadsApi } from './queries/leads';
import { topicsApi } from './queries/topics';
import { websitesApi } from './queries/websites';
import leadsSliceReducer from './slices/leads';
import leadsListsReducer from './slices/leadsList';
import sessionReducer from './slices/session';
import usersReducer from './slices/users';
import websitesReducer from './slices/websites';

export const store = configureStore({
    reducer: {
        leadsLists: leadsListsReducer,
        leads: leadsSliceReducer,
        session: sessionReducer,
        users: usersReducer,
        websites: websitesReducer,
        [websitesApi.reducerPath]: websitesApi.reducer,
        [leadsApi.reducerPath]: leadsApi.reducer,
        [topicsApi.reducerPath]: topicsApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [
                'leads/applyFiltering',
                'leads/manualVerify/pending',
                'leads/manualVerify/fulfilled',
                'leadsApi/executeQuery/pending',
                'leadsApi/executeQuery/fulfilled',
                'leadsApi/queries/removeQueryResult',
                'websitesApi/executeQuery/pending',
                'websitesApi/executeQuery/fulfilled',
                'websites/applyWebsitesFiltering'
            ],
            ignoredActionPaths: [
                'leads/applyFiltering',
                'leads/manualVerify/pending',
                'leads/manualVerify/fulfilled',
                'leadsApi/executeQuery/pending',
                'leadsApi/executeQuery/fulfilled',
                'leadsApi/queries/removeQueryResult',
                'websitesApi/executeQuery/pending',
                'websitesApi/executeQuery/fulfilled',
                '__rtkq/focused',
                'meta.baseQueryMeta.request',
                'meta.baseQueryMeta.response',
                'meta.arg.handleClose'
            ],
            ignoredPaths: [
                'leads.filtering.manualVerificationDate',
                'leads.filtering.checkingDate',
                'meta.arg.originalArgs.filterByManualVerificationDate',
                'websites.filtering.lastCheckingDate',
                'websites.filtering.creatingDate'
            ]
        }
    })
        .concat(websitesApi.middleware, leadsApi.middleware),
    devTools: true
});

setupListeners(store.dispatch);

declare module 'react-redux' {
    export interface DefaultRootState extends RootState {
    }
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type RTKError = {
    data: ErrorType
}
