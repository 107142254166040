import { Badge, Button, Space, Tooltip } from 'antd';
import React, { FC, memo } from 'react';
import { BoxArrowUpRight, JournalCode, Receipt, Table } from 'react-bootstrap-icons';
import { LeadStatuses, LeadTableDataObj } from '../../../../interfaces/leads';
import { Routes } from '../../../../typedef';

interface Props {
    id: LeadTableDataObj['id'];
    listId: LeadTableDataObj['listId'];
    status: LeadTableDataObj['status'];
    handleViewEventsLog: (leadID: string) => void;
    handleViewLeadDossier: (leadID: string) => void;
}

export const ColumnActions: FC<Props> = memo((
    {
        id,
        listId,
        status,
        handleViewEventsLog,
        handleViewLeadDossier
    }: Props) => {
    return (
        <Space size="small">
            <Tooltip title="Events log">
                <Badge
                    className="antd-unprocessable-badge"
                    dot={status === LeadStatuses.UNPROCESSABLE}
                    offset={[-7, 7]}
                >
                    <Button
                        onClick={(): void => handleViewEventsLog(id)}
                        type="link"
                        disabled={status === LeadStatuses.IN_PROGRESS || status === LeadStatuses.PENDING}
                        icon={<JournalCode size={20}/>}
                    />
                </Badge>
            </Tooltip>
            <Tooltip title="Dossier">
                <Button
                    onClick={(): void => handleViewLeadDossier(id)}
                    type="link"
                    disabled={status === LeadStatuses.IN_PROGRESS || status === LeadStatuses.PENDING}
                    icon={<Receipt size={20}/>}
                />
            </Tooltip>
            <Tooltip title={<>Go to list&nbsp;<BoxArrowUpRight size={12}/></>}>
                <Button
                    type="link"
                    disabled={!listId}
                    icon={<Table size={20}/>}
                    href={`${Routes.LEADS_LISTS}/${listId}/view`}
                    target="_blank"
                />
            </Tooltip>
        </Space>
    );
});
