import { Col, Layout, Row } from 'antd';
import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';

export const HeaderAuth: FC = memo(() => {
    return (
        <Layout.Header className="auth-header">
            <Row className="inner-container">
                <Col>
                    <Link to="/" className="auth-logo">
                        <img className="auth-logo__image" src="/logo.png" alt="ADZZ"/>
                        <h1 className="auth-logo__title">&nbsp;Sites</h1>
                    </Link>
                </Col>
            </Row>
        </Layout.Header>
    );
});

export default HeaderAuth;
