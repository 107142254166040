/* eslint-disable indent */
import { message, Switch, Typography } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LeadManualVerificationPreview } from '../../components/LeadManualVerificationPreview';
import { LeadManualVerificationTag } from '../../components/LeadManualVerificationTag';
import { LeadDossier, LeadManualVerification as LeadManualVerificationEnum } from '../../interfaces/leads';
import { manualVerify } from '../../store/actions/leads';

interface Props {
    leadId: LeadDossier['id'];
    manualVerification: LeadDossier['manualVerification'];
    manualVerificatedAt: LeadDossier['manualVerificatedAt'];
    offerId: LeadDossier['offerId'];
    handleReloadLeads?: () => void;
}

export const LeadManualVerification: FC<Props> = memo((
    {
        leadId,
        manualVerification,
        manualVerificatedAt,
        offerId,
        handleReloadLeads
    }: Props
) => {
    const dispatch = useDispatch();
    const isLoadingManualVerification = useSelector(state => state.leads.isLoadingManualVerification);
    const [isManualVerificationEnabled, setIsManualVerificationEnabled] = useState<boolean>(
        manualVerification !== LeadManualVerificationEnum.NOT_CHECKED,
    );
    const [isManualVerificated, setIsManualVerificated] = useState<LeadManualVerificationEnum | null>(
        manualVerification === LeadManualVerificationEnum.NOT_CHECKED ? null : manualVerification,
    );
    const [manualVerificatedDate, setManualVerificatedDate] = useState<Date | null>(
        manualVerificatedAt,
    );

    const handleEnableManualVerification = useCallback((verification: LeadManualVerificationEnum): void => {
        dispatch(manualVerify({
            id: leadId,
            verification,
            callback: () => {
                setManualVerificatedDate(new Date());
                setIsManualVerificationEnabled(true);
                setIsManualVerificated(verification);
                void message.success(<>You have successfully {
                    <Typography.Text
                        type={verification === LeadManualVerificationEnum.APPROVED ? 'success' : 'danger'}
                    >
                        {verification}
                    </Typography.Text>
                } this lead</>);
                if (handleReloadLeads) handleReloadLeads();
            },
        }));
    }, [leadId]);

    const handleManualVerification = useCallback((checked: boolean): void => {
        const value = checked ? LeadManualVerificationEnum.APPROVED : LeadManualVerificationEnum.DECLINED;

        dispatch(manualVerify({
            id: leadId,
            verification: value,
            callback: () => {
                setIsManualVerificated(value);
                setManualVerificatedDate(new Date());
                void message.success(<>You have successfully {
                    <Typography.Text
                        type={value === LeadManualVerificationEnum.APPROVED ? 'success' : 'danger'}
                    >
                        {value}
                    </Typography.Text>
                } this lead</>);
                if (handleReloadLeads) handleReloadLeads();
            },
        }));
    }, [leadId]);

    return (
        <div style={{ display: 'flow', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <>
                {
                    !isManualVerificationEnabled
                        ? <LeadManualVerificationPreview
                            handleEnableManualVerification={handleEnableManualVerification}
                            loading={isLoadingManualVerification}
                            offerId={offerId}
                        />
                        : <>
                            <LeadManualVerificationPreview
                                handleEnableManualVerification={handleEnableManualVerification}
                                loading={isLoadingManualVerification}
                                offerId={offerId}
                            />
                            <LeadManualVerificationTag
                                type="withSwitch"
                                verificationStatus={isManualVerificated ?? LeadManualVerificationEnum.NOT_CHECKED}
                                verificatedAt={manualVerificatedDate}
                            >
                                <Switch
                                    className={
                                        isManualVerificated === LeadManualVerificationEnum.APPROVED
                                            ? 'lead-manual-verification__switch_approved'
                                            : 'lead-manual-verification__switch_declined'
                                    }
                                    loading={isLoadingManualVerification}
                                    checkedChildren="Approved"
                                    unCheckedChildren="Declined"
                                    checked={
                                        isManualVerificated === LeadManualVerificationEnum.APPROVED
                                    }
                                    onChange={handleManualVerification}
                                />
                            </LeadManualVerificationTag>
                        </>
                }</>
        </div>
    );
});
