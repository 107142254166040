import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Topic, TopicsParams } from '../../interfaces/topics';
import { Routes } from '../../typedef';

export const topicsApi = createApi({
    reducerPath: 'topicsApi',
    refetchOnMountOrArgChange: true,
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL, credentials: 'include' }),
    endpoints: (builder) => ({
        createTopic: builder.mutation<Topic, TopicsParams>({
            query: (values) => ({
                url: `${Routes.TOPICS_CREATE}`,
                method: 'POST',
                body: values
            })
        }),
        getTopics: builder.query<Topic[], void>({
            query: () => `${Routes.TOPICS}`
        })
    })
});

export const {
    useGetTopicsQuery,
    useCreateTopicMutation
} = topicsApi;
