import { Empty, Table } from 'antd';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { SortVariants } from '../../../interfaces/leads';
import { Website, WebsitesFilterOptions, WebsitesMetadata, WebsitesTableDataObj } from '../../../interfaces/websites';
import { WebsiteDossierModal } from '../WebsiteDossier';
import { WebsitesLeadsCatalogModal } from '../WebsitesLeadsCatalogModal';
import { columns } from './columns';
import { UtilsPanel } from './utilsPanel';

interface Props {
    isSearchingQuery: boolean;
    sortVariant: SortVariants;
    isSortingEnabled: boolean;
    isFilteringEnabled: boolean;
    isLoading: boolean;
    websites: Website[];
    allWebsitesCount: WebsitesMetadata['all_websites_count'];
    filteredWebsitesCount: WebsitesFilterOptions['websitesCount'];
    currentPage: number;
    pageWebsitesSize: number;
    handleChangeCurrentPage: (page: number) => void;
    handleRefetch: () => void;
}

export const WebsitesTable: FC<Props> = memo(({
                                                  isSearchingQuery,
                                                  sortVariant,
                                                  isSortingEnabled,
                                                  isFilteringEnabled,
                                                  isLoading,
                                                  websites,
                                                  allWebsitesCount,
                                                  filteredWebsitesCount,
                                                  currentPage,
                                                  pageWebsitesSize,
                                                  handleChangeCurrentPage,
                                                  handleRefetch
                                              }: Props) => {
    const [isVisibleLeadsCatalog, setIsVisibleLeadsCatalog] = useState<boolean>(false);
    const [isDossierModalVisible, setIsDossierModalVisible] = useState<boolean>(false);
    const [tableRowIndex, setTableRowIndex] = useState<number | null>(null);
    const [chosenDossierWebsite, setChosenDossierWebsite] = useState<Website | null>(null);
    const [websiteIdForLeads, setWebsiteIdForLeads] = useState<string | null>(null);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [isSortingOpen, setIsSortingOpen] = useState<boolean>(false);

    const currentWebsitesCount = useMemo(() => (isFilteringEnabled || isSearchingQuery)
            ? (filteredWebsitesCount ?? 0)
            : allWebsitesCount,
        [isFilteringEnabled, isSearchingQuery, filteredWebsitesCount, allWebsitesCount]
    );

    const generatedDataSource = useMemo((): WebsitesTableDataObj[] => (
        websites.map((website, idx): WebsitesTableDataObj => ({
            key: idx,
            id: website._id,
            number: sortVariant === SortVariants.ACS
                ? ((currentPage * pageWebsitesSize) + ++idx) - pageWebsitesSize
                : currentWebsitesCount - (((currentPage * pageWebsitesSize) + ++idx) - pageWebsitesSize) + 1,
            date: {
                createdAt: website.created_at,
                checkedAt: website.checked_at
            },
            topics: website.topics,
            domain: website.domain,
            isAccessible: website.is_accessible,
            baseUrl: website.base_url,
            crmInfo: {
                crmId: website.crm_id,
                crmOfferId: website.crm_offer_id
            },
            seoInfo: {
                // lrtPowerTrustDom: website.lrt_power_trust_dom,
                // ahrefsDomainRating: website.ahrefs_domain_rating,
                // ahrefsTotalTraffic: website.ahrefs_total_traffic
                domainRating: website.domain_rating,
                keywords: website.keywords,
                traffic: website.traffic,
                dtoxrisk: website.dtoxrisk,
                powerTrustDom: website.power_trust_dom,
                powerDom: website.power_dom,
                trustDom: website.trust_dom,
            },
            manualVerification: {
                manualVerification: website.manual_verification,
                manualVerificatedAt: website.manual_verificated_at
            },
            connectionStatus: website.connection_status,
            language: website.language
        }))
    ), [websites, currentPage, pageWebsitesSize]);

    const handlePageChange = useCallback((page: number): void => handleChangeCurrentPage(page), []);

    const handleShowTotal = useCallback(
        (total: number, range: [number, number]): React.ReactNode => sortVariant === SortVariants.ACS
            ? `${range[0]}-${range[1]} of ${total} items`
            : `${total - range[0] + 1}-${total - range[1] + 1} of ${total} items`,
        [sortVariant]
    );

    const handleViewWebsiteDossier = useCallback((websiteID: string): void => {
        const rowIdx = websites.findIndex(website => website._id === websiteID);

        const currentWebsite = websites.find(website => website._id === websiteID);
        if (currentWebsite) {
            setTableRowIndex(rowIdx !== -1 ? rowIdx : null);

            setChosenDossierWebsite(websites.find(website => website._id === websiteID) ?? null);
            setIsDossierModalVisible(true);
        }
    }, [websites, chosenDossierWebsite]);

    const handleCloseWebsiteDossierModal = useCallback((): void => {
        setIsDossierModalVisible(false);
        setChosenDossierWebsite(null);
        setTableRowIndex(null);
    }, []);

    const handleShowWebsiteCatalog = useCallback((websiteId: string): void => {
        setWebsiteIdForLeads(websiteId);
        setIsVisibleLeadsCatalog(true);
    }, []);

    const handleCloseWebsiteCatalog = useCallback((): void => {
        setIsVisibleLeadsCatalog(false);
        setWebsiteIdForLeads(null);
    }, []);

    const handleToggleFilterPopover = useCallback((): void => {
        if (isSortingOpen) setIsSortingOpen(false);
        setIsFilterOpen(!isFilterOpen);
    }, [isSortingOpen, isFilterOpen]);

    const handleToggleSortPopover = useCallback((): void => {
        if (isFilterOpen) setIsFilterOpen(false);
        setIsSortingOpen(!isSortingOpen);
    }, [isFilterOpen, isSortingOpen]);

    return (
        <>
            <WebsitesLeadsCatalogModal
                websiteId={websiteIdForLeads}
                isVisible={isVisibleLeadsCatalog}
                handleCloseModal={handleCloseWebsiteCatalog}
            />

            <WebsiteDossierModal
                isVisible={isDossierModalVisible}
                onClose={handleCloseWebsiteDossierModal}
                website={chosenDossierWebsite}
            />

            <Table
                loading={isLoading}
                className={
                    `websites-table ${
                        currentWebsitesCount > 50 ? 'websites-table_with-toppag' : ''
                    } ${
                        typeof tableRowIndex === 'number' ? `websites-table_${tableRowIndex}-row-selected` : ''
                    }`
                }
                title={(): React.ReactElement => <UtilsPanel
                    currentPage={currentPage}
                    isFilterOpen={isFilterOpen}
                    isSortingOpen={isSortingOpen}
                    handleToggleFilterPopover={handleToggleFilterPopover}
                    handleToggleSortPopover={handleToggleSortPopover}
                    handleRefetch={handleRefetch}
                    setIsFilterOpen={setIsFilterOpen}
                />}
                locale={{
                    emptyText: (isSortingEnabled || isFilteringEnabled || isSearchingQuery)
                        ? <Empty
                            description="No websites were found for this query"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                        : !websites.length &&
                        <Empty description="No websites" image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                }}
                rowKey={(record): string => record.id}
                size="small"
                onRow={
                    (record): object => ({
                        onDoubleClick: (): void => handleShowWebsiteCatalog(record.id)
                    })
                }
                pagination={{
                    current: currentPage,
                    pageSize: pageWebsitesSize,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    position: currentWebsitesCount > 50 ? ['topRight', 'bottomRight'] : ['bottomRight'],
                    total: currentWebsitesCount,
                    size: 'default',
                    onChange: handlePageChange,
                    showTotal: handleShowTotal
                }}
                scroll={{ x: 1500 }}
                dataSource={websites ? generatedDataSource : []}
                columns={columns(handleShowWebsiteCatalog, handleViewWebsiteDossier)}
                tableLayout="auto"
            />
        </>
    );
});
