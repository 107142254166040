import { Col, message, Row } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { signinWithGoogle } from '../../store/actions/session';
import { StatusCodes } from '../../typedef';

export const Auth: FC = memo(() => {
    const dispatch = useDispatch();
    const { error } = useSelector(state => state.session);

    const responseGoogle = useCallback((): void => {
        if (navigator.onLine && (!error || error.statusCode !== StatusCodes.WEB_SERVER_IS_DOWN)) {
            dispatch(signinWithGoogle());
        } else {
            message.error('Network is offline');
        }
    }, [navigator.onLine, error]);

    return (
        <div className="inner-container auth-buttons">
            <Row justify="center">
                <Col>
                    <GoogleLoginButton
                        align="right"
                        text={'Log In with Google'}
                        onClick={responseGoogle}
                        style={{
                            cursor: (error?.statusCode === StatusCodes.WEB_SERVER_IS_DOWN)
                                ? 'not-allowed'
                                : 'pointer'
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
});
