import { Layout } from 'antd';
import React, { FC, memo } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, } from 'react-router-dom';
import { Auth } from '../../pages/Auth';
import { LeadsListsDomainsCheck } from '../../pages/DomainsCheck';
import { LeadsDashboard } from '../../pages/Leads/Dashboard';
import { ViewLead } from '../../pages/Leads/Viewing';
import { LeadsListsArchive } from '../../pages/LeadsList/Archive';
import { CreateListModal } from '../../pages/LeadsList/Creating';
import { LeadsListsDashboard } from '../../pages/LeadsList/Dashboard';
import { EditListModal } from '../../pages/LeadsList/Editing';
import { ViewListModal } from '../../pages/LeadsList/Viewing';
import { NotFoundPage } from '../../pages/NotFound';
import { CreateTopicsModal } from '../../pages/Topics/Creating';
import { WebsitesListDashboard } from '../../pages/Websites/Dashboard';
import { ViewWebsite } from '../../pages/Websites/Viewing';
import { SessionState } from '../../store/slices/session';
import { Routes as RoutesEnum, RoutesParams } from '../../typedef';
import { HeaderAuth } from '../Header/headerAuth';
import { CreateLeadModal } from '../Leads/CreatingModal';
import { SideMenu } from '../SideMenu';

interface Props {
    isLoggedIn: SessionState['isLoggedIn'];
    locationState: { backgroundLocation?: Location | undefined };
}

export const AppRouter: FC<Props> = memo(({ isLoggedIn, locationState }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            {
                isLoggedIn
                    ? <>
                        <Layout className="layout">
                            <SideMenu/>
                            <Layout className="container">
                                <Layout.Content>
                                    <Routes location={locationState?.backgroundLocation || location}>
                                        { /* Leads lists */}
                                        <Route path="/" element={<LeadsListsDashboard/>}/>
                                        <Route path={RoutesEnum.LEADS_LISTS_ARCHIVE} element={<LeadsListsArchive/>}/>
                                        <Route
                                            path={RoutesEnum.LEADS_LISTS_DOMAINS_CHECK}
                                            element={<LeadsListsDomainsCheck/>}
                                        />
                                        <Route
                                            path={`${RoutesEnum.LEADS_LISTS}/:${RoutesParams.LIST_ID}/view`}
                                            element={<ViewListModal/>}
                                        />

                                        { /* Websites */}
                                        <Route path={RoutesEnum.WEBSITES} element={<WebsitesListDashboard/>}/>

                                        { /* Leads */}
                                        <Route path={RoutesEnum.LEADS} element={<LeadsDashboard/>}/>
                                        <Route
                                            path={`${RoutesEnum.LEADS}/:${RoutesParams.LEAD_ID}/view`}
                                            element={<ViewLead/>}
                                        />

                                        { /* Websites */}
                                        <Route
                                            path={`${RoutesEnum.WEBSITES}/:${RoutesParams.WEBSITE_ID}/view`}
                                            element={<ViewWebsite/>}
                                        />

                                        <Route path="*" element={<NotFoundPage/>}/>
                                    </Routes>
                                </Layout.Content>
                                { /* <Layout.Footer>Footer</Layout.Footer> */}
                            </Layout>
                        </Layout>

                        {locationState?.backgroundLocation && (
                            <Routes>
                                <Route path={RoutesEnum.LEADS_LISTS_CREATE} element={<CreateListModal/>}/>
                                <Route
                                    path={`${RoutesEnum.LEADS_LISTS}/:${RoutesParams.LIST_ID}/edit`}
                                    element={<EditListModal/>}
                                />
                                <Route path={`${RoutesEnum.TOPICS_CREATE}`} element={<CreateTopicsModal/>}/>
                                <Route
                                    path={`${RoutesEnum.LEADS_CREATE}`} element={
                                    <CreateLeadModal
                                        isVisible handleClose={(): void => navigate(RoutesEnum.LEADS)}
                                    />
                                }
                                />

                            </Routes>
                        )}
                    </>
                    : <div className="auth-page">
                        <HeaderAuth/>
                        <Routes>
                            <Route path="/" element={<Auth/>}/>
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </Routes>
                    </div>
            }
        </>
    );
});
