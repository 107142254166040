/* eslint-disable no-shadow */
import { Button, Input, Space, Tooltip } from 'antd';
import React, { Dispatch, FC, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchLeadsByPageCount } from '../../../../store/actions/leadsLists';
import { applyLeadsSearching } from '../../../../store/slices/leads';
import { RoutesParams, SocketsKeys } from '../../../../typedef';
import { FilterPanel } from './filterPanel';
import { SortingPanel } from './sortingPanel';

interface Props {
    currentPage: number;
    isFilterOpen: boolean;
    isSortingOpen: boolean;
    handleToggleFilterPopover: () => void;
    handleToggleSortPopover: () => void;
    setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
}

export const UtilsPanel: FC<Props> = memo((
    {
        currentPage,
        isFilterOpen,
        isSortingOpen,
        handleToggleFilterPopover,
        handleToggleSortPopover,
        setIsFilterOpen,
    }: Props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        searching,
        filtering: filteringOptions,
        sorting: sortingOptions
    } = useSelector(state => state.leads);
    const { list_id: listIdParam } = useParams<RoutesParams.LIST_ID>();

    const [searchQuery, setSearchQuery] = useState<string>(searching);

    useEffect(() => {
        const realTimeLeadsListRestart = (): void => {
            setSearchQuery('');
        };
        window.clientIo?.on(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);

        return () => {
            window.clientIo?.off(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);
        };
    }, []);

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => setSearchQuery(e.target.value);

    const handleReloadLeads = useCallback((): void => {
        listIdParam && dispatch(fetchLeadsByPageCount({
            id: listIdParam,
            page: currentPage,
            search: searchQuery,
            sortBy: [sortingOptions.field, sortingOptions.variant],
            filterByPublishableStatus: filteringOptions.publishableStatus,
            filterByRepeatStatus: filteringOptions.repeatStatus,
            filterByAttentionStatus: filteringOptions.attentionStatus,
            filterByActiveStatus: filteringOptions.activeStatus,
            filterByPluginStatus: filteringOptions.pluginStatus,
            filterByStatus: filteringOptions.status,
            filterByConnectionStatus: filteringOptions.connectionStatus,
            filterByStatusCode: filteringOptions.statusCode,
            filterByLanguage: filteringOptions.language,
            filterByLrtPowerTrustDom: filteringOptions.lrtPowerTrustDom,
            filterByAhrefsDr: filteringOptions.ahrefsDr,
            filterByAhrefsTraffic: filteringOptions.ahrefsTraffic,
            filterByManualVerification: filteringOptions.manualVerification,
            filterByManualVerificationDate: filteringOptions.manualVerificationDate,
            filterByCheckingDate: filteringOptions.checkingDate,
            filterByContentIndicators: filteringOptions.contentIndicators
        }));
    }, [
        listIdParam,
        currentPage,
        searchQuery,
        filteringOptions,
        sortingOptions
    ]);

    const handleSearch = useCallback((value: string): void => {
        setSearchQuery(value);
        dispatch(applyLeadsSearching(value));

        listIdParam && dispatch(fetchLeadsByPageCount({
            id: listIdParam,
            page: 1,
            search: value,
            sortBy: [sortingOptions.field, sortingOptions.variant],
            filterByPublishableStatus: filteringOptions.publishableStatus,
            filterByRepeatStatus: filteringOptions.repeatStatus,
            filterByAttentionStatus: filteringOptions.attentionStatus,
            filterByActiveStatus: filteringOptions.activeStatus,
            filterByPluginStatus: filteringOptions.pluginStatus,
            filterByStatus: filteringOptions.status,
            filterByConnectionStatus: filteringOptions.connectionStatus,
            filterByStatusCode: filteringOptions.statusCode,
            filterByLanguage: filteringOptions.language,
            filterByLrtPowerTrustDom: filteringOptions.lrtPowerTrustDom,
            filterByAhrefsDr: filteringOptions.ahrefsDr,
            filterByAhrefsTraffic: filteringOptions.ahrefsTraffic,
            filterByManualVerification: filteringOptions.manualVerification,
            filterByManualVerificationDate: filteringOptions.manualVerificationDate,
            filterByCheckingDate: filteringOptions.checkingDate,
            filterByContentIndicators: filteringOptions.contentIndicators
        }));

        searchParams.delete('page');
        setSearchParams(searchParams);

    }, [
        listIdParam,
        currentPage,
        filteringOptions,
        sortingOptions
    ]);

    return (
        <Space direction="vertical">
            <Space>
                <Tooltip title="Reload leads">
                    <Button
                        onClick={handleReloadLeads}
                        type="ghost"
                        icon={<ArrowClockwise/>}
                    />
                </Tooltip>
                <Input.Search
                    style={{ width: 330 }}
                    placeholder="Find leads.."
                    allowClear
                    enterButton="Search"
                    value={searchQuery}
                    onChange={handleChangeSearch}
                    onSearch={handleSearch}
                />
                <FilterPanel
                    currentPage={currentPage}
                    isFilterOpen={isFilterOpen}
                    setIsFilterOpen={setIsFilterOpen}
                    searchingQuery={searching}
                    listIdParam={listIdParam}
                    handleToggleFilterPopover={handleToggleFilterPopover}
                    filteringOptions={filteringOptions}
                    sortingOptions={sortingOptions}
                />
                <SortingPanel
                    currentPage={currentPage}
                    listIdParam={listIdParam}
                    isSortingOpen={isSortingOpen}
                    searchingQuery={searching}
                    filteringOptions={filteringOptions}
                    sortingOptions={sortingOptions}
                    handleToggleSortPopover={handleToggleSortPopover}
                />
            </Space>
        </Space>
    );
});
