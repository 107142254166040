import React, { FC, memo } from 'react';
import { HeaderInner } from '../../modules/Header/headerInner.hoc';

export const LeadsListsDomainsCheck: FC = memo(() => {
    return (
        <>
            <HeaderInner titleName="Domain Check"/>
            <div className="home-container">
                <h1>Domains Check</h1>
            </div>
        </>
    );
});
