import { Tag } from 'antd';
import React, { FC, memo } from 'react';
import { LeadTypes } from '../../interfaces/leads';

interface Props {
    type: LeadTypes;
}

export const LeadTypeTag: FC<Props> = memo(({ type }: Props) => {
    return <Tag style={{ marginRight: 0, fontSize: 14, lineHeight: '22px' }}>
        {type.toUpperCase()}
    </Tag>;
});
