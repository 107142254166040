/* eslint-disable quote-props */
/* eslint-disable no-nested-ternary */
import { RefObject } from 'react';
import { LeadsGenerateCSVTypes } from '../interfaces/leads';

interface LeadsListToCSVProps {
    ref: RefObject<HTMLAnchorElement>; // link html element
    csvBody: string;
    listName: string;
    listID: string;
    type?: LeadsGenerateCSVTypes;
}

const generateFileName = (listName: string, listID?: string, type?: LeadsGenerateCSVTypes): string => {
    let name = `${listName.replaceAll(/[ ,.]/gi, '_')}`;
    if (listID) name += `_${listID}`;
    if (type) name += `${name}_${type}`;
    return name;
};

export const exportLeadsListToCSV = ({ ref, csvBody, listName, listID, type }: LeadsListToCSVProps): void => {
    window.URL = window.URL || window.webkitURL;
    const blob = new Blob([csvBody], { type: 'text/csv' });
    const blobURL = window.URL.createObjectURL(blob);
    const csvFilename = generateFileName(listName, listID, type);

    if (ref.current) {
        ref.current?.setAttribute('download', csvFilename);
        ref.current.href = blobURL;
        ref.current?.click();
    }
};

interface WebsitesListToCSVProps {
    ref: RefObject<HTMLAnchorElement>; // link html element
    csvBody: string;
    listName: string;
}

export const exportWebsitesListToCSV = ({ ref, csvBody, listName }: WebsitesListToCSVProps): void => {
    window.URL = window.URL || window.webkitURL;
    const blob = new Blob([csvBody], { type: 'text/csv' });
    const blobURL = window.URL.createObjectURL(blob);
    const csvFilename = generateFileName(listName);

    if (ref.current) {
        ref.current?.setAttribute('download', csvFilename);
        ref.current.href = blobURL;
        ref.current?.click();
    }
};
