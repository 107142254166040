/* eslint-disable no-shadow */
export enum TopicsFields {
    VALUE = 'value',
    DESCRIPTION = 'description',
}

export interface Topic {
    _id: string;
    value: string;
    description: string | null;
    created_at?: Date;
    updated_at?: Date;
}

export interface TopicsParams {
    [TopicsFields.VALUE]: string;
    [TopicsFields.DESCRIPTION]: string | undefined;
}

export type TopicsValues = Partial<TopicsParams>

export type TopicsInputs = {
    [TopicsFields.VALUE]: string;
    [TopicsFields.DESCRIPTION]: string;
}
