import { Badge, Button, Popover } from 'antd';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FilterCircle, FilterCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SortFields, SortVariants } from '../../../../../interfaces/leads';
import { fetchLeadsByPageCount } from '../../../../../store/actions/leadsLists';
import { applyLeadsSorting, LeadsState, resetLeadsSorting } from '../../../../../store/slices/leads';
import { SocketsKeys } from '../../../../../typedef';
import { Panel } from './panel';

interface Props {
    currentPage: number;
    listIdParam: string | undefined;
    isSortingOpen: boolean;
    searchingQuery: LeadsState['searching'];
    filteringOptions: LeadsState['filtering'];
    sortingOptions: LeadsState['sorting'];
    handleToggleSortPopover: () => void;
}

export const SortingPanel: FC<Props> = memo(({
                                                 currentPage,
                                                 listIdParam,
                                                 searchingQuery,
                                                 isSortingOpen,
                                                 filteringOptions,
                                                 sortingOptions,
                                                 handleToggleSortPopover
                                             }: Props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [sortField, setSortField] = useState<SortFields>(sortingOptions.field);
    const [sortVariant, setSortVariant] = useState<SortVariants>(sortingOptions.variant);

    const isFieldNumberic = useMemo(() => (
        sortField === SortFields.NUMBER ||
        sortField === SortFields.CHECKED_DATE ||
        sortField === SortFields.STATUS_CODE
    ), [sortField]);

    const isSortingEnabled = useMemo(() => (
        sortVariant !== SortVariants.ACS ||
        sortField !== SortFields.NUMBER
    ), [sortVariant, sortField]);

    const areSortsDifferent = useMemo(() => (
        sortField !== sortingOptions.field ||
        sortVariant !== sortingOptions.variant
    ), [sortVariant, sortField, sortingOptions]);

    useEffect(() => {
        const realTimeLeadsListRestart = (): void => {
            setSortField(SortFields.NUMBER);
            setSortVariant(SortVariants.ACS);
        };
        window.clientIo?.on(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);

        return () => {
            window.clientIo?.off(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);
        };
    }, []);

    useEffect(() => {
        if (!isSortingOpen) {
            setTimeout(() => handleSetSortsCurrent(), 150);
        }
    }, [isSortingOpen]);

    const handleSetSortVariant = useCallback((value: SortVariants): void => setSortVariant(value), []);
    const handleSetSortField = useCallback((value: SortFields): void => setSortField(value), []);

    const handleFindBySorting = useCallback((): void => {
        if (areSortsDifferent) {
            dispatch(applyLeadsSorting({
                variant: sortVariant,
                field: sortField
            }));

            listIdParam && dispatch(fetchLeadsByPageCount({
                id: listIdParam,
                page: currentPage,
                search: searchingQuery,
                sortBy: [sortField, sortVariant],
                filterByPublishableStatus: filteringOptions.publishableStatus,
                filterByRepeatStatus: filteringOptions.repeatStatus,
                filterByAttentionStatus: filteringOptions.attentionStatus,
                filterByActiveStatus: filteringOptions.activeStatus,
                filterByPluginStatus: filteringOptions.pluginStatus,
                filterByStatus: filteringOptions.status,
                filterByConnectionStatus: filteringOptions.connectionStatus,
                filterByStatusCode: filteringOptions.statusCode,
                filterByLanguage: filteringOptions.language,
                filterByLrtPowerTrustDom: filteringOptions.lrtPowerTrustDom,
                filterByAhrefsDr: filteringOptions.ahrefsDr,
                filterByAhrefsTraffic: filteringOptions.ahrefsTraffic,
                filterByManualVerification: filteringOptions.manualVerification,
                filterByManualVerificationDate: filteringOptions.manualVerificationDate
            }));

            handleToggleSortPopover();

            searchParams.delete('page');
            setSearchParams(searchParams);
        }
    }, [areSortsDifferent, sortVariant, sortField, listIdParam]);

    const handleResetSorts = useCallback((): void => {
        setSortField(SortFields.NUMBER);
        setSortVariant(SortVariants.ACS);

        dispatch(resetLeadsSorting());

        handleToggleSortPopover();

        listIdParam && dispatch(fetchLeadsByPageCount({
            id: listIdParam,
            page: currentPage,
            search: searchingQuery,
            sortBy: [SortFields.NUMBER, SortVariants.ACS],
            filterByPublishableStatus: filteringOptions.publishableStatus,
            filterByRepeatStatus: filteringOptions.repeatStatus,
            filterByAttentionStatus: filteringOptions.attentionStatus,
            filterByActiveStatus: filteringOptions.activeStatus,
            filterByPluginStatus: filteringOptions.pluginStatus,
            filterByStatus: filteringOptions.status,
            filterByConnectionStatus: filteringOptions.connectionStatus,
            filterByStatusCode: filteringOptions.statusCode,
            filterByLanguage: filteringOptions.language,
            filterByLrtPowerTrustDom: filteringOptions.lrtPowerTrustDom,
            filterByAhrefsDr: filteringOptions.ahrefsDr,
            filterByAhrefsTraffic: filteringOptions.ahrefsTraffic,
            filterByManualVerification: filteringOptions.manualVerification,
            filterByManualVerificationDate: filteringOptions.manualVerificationDate
        }));
    }, [
        listIdParam,
        currentPage,
        searchingQuery,
        filteringOptions,
        isSortingOpen,
        searchParams
    ]);

    const handleSetSortsCurrent = useCallback((): void => {
        setSortField(sortingOptions.field);
        setSortVariant(sortingOptions.variant);
    }, [sortingOptions]);

    return (
        <Badge dot={isSortingEnabled}>
            <Popover
                title="Sort by"
                arrowPointAtCenter
                placement="bottom"
                content={
                    <Panel
                        sortField={sortField}
                        sortVariant={sortVariant}
                        isFieldNumberic={isFieldNumberic}
                        handleSetSortField={handleSetSortField}
                        handleSetSortVariant={handleSetSortVariant}
                        handleResetSorts={handleResetSorts}
                        handleFindBySorting={handleFindBySorting}
                    />
                }
                trigger="click"
                open={isSortingOpen}
            >
                <Button
                    onClick={handleToggleSortPopover}
                    icon={isSortingOpen ? <FilterCircleFill/> : <FilterCircle/>}
                />
            </Popover>
        </Badge>
    );
});
