import { message } from 'antd';
import { AxiosError } from 'axios';
import { RefObject } from 'react';
import { ErrorType } from '../../interfaces';
import { LeadsGenerateCSVTypes } from '../../interfaces/leads';
import { exportLeadsListToCSV, exportWebsitesListToCSV } from '../../utils/exportCsv';
import { API } from '../api';

interface LeadsListCsvProps {
    id: string;
    ref: RefObject<HTMLAnchorElement>;
    type: LeadsGenerateCSVTypes;
}

interface WebsiteListCsvProps {
    websitesFilterQuery: string;
    ref: RefObject<HTMLAnchorElement>;
}

export const generateLeadsListCsv = async ({ id, ref, type }: LeadsListCsvProps): Promise<void> => {
    try {
        message.loading({ content: 'Send request..', key: 'generateLeadsListsCSV', duration: 0 });

        const { data } = await API.leadsLists.generateCsv(id, type);

        exportLeadsListToCSV({ ref, csvBody: data.csvBody, listName: data.listName, listID: data._id, type });
        message.success({ content: 'CSV generated', key: 'generateLeadsListsCSV' });
    }
    catch (error: unknown) {
        message.error({
            content: (error as AxiosError<ErrorType>).response?.data.message ?? error,
            key: 'generateLeadsListsCSV'
        });
    }
};

export const generateWebsiteListCsv = async ({ websitesFilterQuery, ref }: WebsiteListCsvProps): Promise<void> => {
    try {
        message.loading({ content: 'Send request..', key: 'generateLeadsListsCSV', duration: 0 });

        const { data } = await API.websites.generateCsv(websitesFilterQuery);

        exportWebsitesListToCSV({ ref, csvBody: data.csvBody, listName: data.listName });
        message.success({ content: 'CSV generated', key: 'generateLeadsListsCSV' });
    }
    catch (error: unknown) {
        message.error({
            content: (error as AxiosError<ErrorType>).response?.data.message ?? error,
            key: 'generateLeadsListsCSV'
        });
    }
};
