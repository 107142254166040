/* eslint-disable react/jsx-indent-props */
import { Button, Result, Spin } from 'antd';
import React, { FC, memo } from 'react';
import { Modal } from '../../../components/Modal';
import { RTKError } from '../../../store';
import { useGetLeadByIdQuery } from '../../../store/queries/leads';
import { Lead } from '../Lead';

interface Props {
    isVisible: boolean;
    leadID: string | null;
    handleClose: () => void;
}

export const ViewingLeadModal: FC<Props> = memo(({ isVisible, leadID, handleClose }) => {
    const { data: lead, error, isLoading, isFetching } = useGetLeadByIdQuery(leadID ?? '', {
        skip: !leadID
    });

    return (
        <Modal
            width={1500}
            title="Add new lead"
            open={isVisible}
            onCancel={handleClose}
            footer={false}
        >
            {
                error
                    ? <Result
                        status="error"
                        title={(error as RTKError).data.message}
                        subTitle={
                            <Button onClick={handleClose}>Close</Button>
                        }
                    />
                    : <>
                        {((isLoading || isFetching) && !lead) && <Spin className="centered-spinner"/>}
                        {
                            (!isLoading && lead) &&
                            <Lead lead={lead} showBackArrow={false}/>
                        }
                    </>
            }
        </Modal>
    );
});
