import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React, { FC, memo, useMemo } from 'react';
import { InfoCircle, InfoCircleFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { LeadsListStatuses, LeadsListsUnarchivedMetadata, LeadsListTableDataObj } from '../../../interfaces/leadsLists';
import { LeadsListsState } from '../../../store/slices/leadsList';

interface Props {
    columns: ColumnsType<LeadsListTableDataObj>;
    metaData: LeadsListsUnarchivedMetadata;
    isLoading: LeadsListsState['isTableLoading'];
    lists: LeadsListsState['lists'];
    classNameSuffix: string;
    currentPage: number;
    pageListsSize: number;
    handleChangeCurrentPage: (page: number) => void;
}

interface ExpandIconProps {
    expanded: boolean;
    record: LeadsListTableDataObj;
    onExpand: (record: LeadsListTableDataObj, e: React.MouseEvent<HTMLElement>) => void;
}

const renderExpandIcon = ({ expanded, onExpand, record }: ExpandIconProps): React.ReactElement | undefined => {
    const handleCommentExpand = (
        recordValue: LeadsListTableDataObj,
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ): void => {
        onExpand(recordValue, e);
    };

    return (
        record.comment
            ? <>
        <span
            className="leads-list-table__comment-tip__icon"
            onKeyDown={(): null => null}
            role="button"
            tabIndex={0}
            onClick={(e): void => handleCommentExpand(record, e)}
        >
          {expanded ? <InfoCircleFill/> : <InfoCircle/>}
        </span>
            </>
            : undefined
    );
};

export const LeadsListsTable: FC<Props> = memo((
    {
        columns,
        metaData,
        lists,
        isLoading,
        classNameSuffix,
        pageListsSize,
        currentPage,
        handleChangeCurrentPage
    }: Props) => {
    const navigate = useNavigate();
    const { users } = useSelector(state => state.users);

    const generatedDataSource = useMemo((): LeadsListTableDataObj[] => (
        lists.map((list, idx): LeadsListTableDataObj => ({
            key: idx,
            id: list._id,
            number: metaData.all_lists_count - (((currentPage * pageListsSize) + idx) - pageListsSize),
            date: {
                createdAt: list.created_at,
                checkedAt: list.checked_at
            },
            name: list.list_name,
            statistics: {
                allLeadsCount: list.statistics.all_leads_count,
                inProgressLeadsCount: list.statistics.in_progress_leads_count,
                failedLeadsCount: list.statistics.failed_leads_count,
                succeededLeadsCount: list.statistics.succeeded_leads_count,
                pendingLeadsCount: list.statistics.pending_leads_count,
                processedLeadsCount: list.statistics.processed_leads_count,
                unprocessableLeadsCount: list.statistics.unprocessable_leads_count,
                duplicatedLeadsCount: list.statistics.duplicated_leads_count,
                approvedLeadsCount: list.statistics.approved_leads_count,
                activeLeadsCount: list.statistics.active_leads_count,
                requiresAttentionLeadsCount: list.statistics.requires_attention_leads_count
            },
            comment: list.comment,
            initiator: list.owner,
            status: list.status && list.status as LeadsListStatuses
        }))
    ), [lists, metaData]);

    const goToViewLeadsList = (id: string): void => navigate(`/leads-lists/${id}/view`);

    return (
        <>
            <Table
                className={
                    `leads-list-table leads-list-table__${
                        classNameSuffix
                    }`
                }
                rowKey={(record): string => record.id}
                size="small"
                pagination={{
                    current: currentPage,
                    pageSize: pageListsSize,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    position: metaData.all_lists_count > 20 ? ['topRight', 'bottomRight'] : ['bottomRight'],
                    total: metaData.all_lists_count,
                    onChange: (page: number) => handleChangeCurrentPage(page),
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                }}
                dataSource={users.length ? generatedDataSource : []}
                columns={columns}
                expandable={{
                    defaultExpandAllRows: true,
                    expandedRowRender: record => <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        italic
                    >
                        <span style={{ whiteSpace: 'pre-wrap', fontSize: 12 }}>{record.comment ?? ''}</span>
                    </Typography.Paragraph>,
                    rowExpandable: record => !!record.comment,
                    expandIcon: renderExpandIcon
                }}
                loading={!users.length || isLoading}
                tableLayout="auto"
                onRow={
                    (record): object => ({
                        onDoubleClick: (): void => goToViewLeadsList(record.id)
                    })
                }
                scroll={{ x: 1500 }}
            />
        </>
    );
});
