import { Button, Space } from 'antd';
import React, { FC, memo } from 'react';

interface Props {
    handlePasteLinks: () => void;
}

export const EmptyBadge: FC<Props> = memo(({ handlePasteLinks }: Props) => {
    return (
        <div className="links-validator__empty-badge">
            <Space align="center" direction="vertical" size="large">
                <span>For validation paste links first</span>
                <Button onClick={handlePasteLinks} shape="round" type="dashed">Paste</Button>
            </Space>
        </div>
    );
});
