import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorType } from '../../interfaces';
import { User } from '../../interfaces/users';
import { fetchAllUsers, fetchUserById } from '../actions/users';

export interface UserState {
    users: User[],
    user: User | null,
    isLoading: boolean;
    error: ErrorType | null;
}

const initialState: UserState = {
    users: [],
    user: null,
    isLoading: false,
    error: null
};

export const usersSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        clearCurrentUser(state) {
            state.user = null;
        },

        clearUsersStore: (state) => {
            state.users = [];
            state.user = null;
            state.isLoading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
            state.users = action.payload;
            state.isLoading = false;
            state.error = null;
        })
            .addCase(fetchAllUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAllUsers.rejected, (state, action) => {
                if (action.payload) {
                    state.error = action.payload as ErrorType;
                    state.isLoading = false;
                    message.error(state.error.message);
                }
            });

        builder.addCase(fetchUserById.fulfilled, (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
            state.error = null;
        })
            .addCase(fetchUserById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.error = action.payload as ErrorType;
                state.isLoading = false;
                message.error(state.error.message);
            });
    }
});

export const {
    clearCurrentUser,
    clearUsersStore
} = usersSlice.actions;

export default usersSlice.reducer;
