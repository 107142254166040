import { Descriptions, Divider, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { BoxArrowUpRight, Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import { LeadDossier, LeadStatuses } from '../../../../interfaces/leads';

type Props = {
    status: LeadDossier['status'];
    shellyIsFileUpload: LeadDossier['shellyIsFileUpload'];
    shellyPathToFile: LeadDossier['shellyPathToFile'];
}

const { Text } = Typography;

export const ShellyFileBlock: FC<Props> = memo(({
                                                    status,
                                                    shellyIsFileUpload,
                                                    shellyPathToFile
                                                }: Props) => {
    return (
        <>
            <Divider style={{ marginTop: 8, marginBottom: 8 }}/>

            <Descriptions
                column={1}
                layout="vertical"
                labelStyle={{ marginBottom: '-8px' }}
                size="small"
            >
                <Descriptions.Item label={<Text type="secondary">Is file uploaded</Text>}>
                    <>
                        {
                            status === LeadStatuses.FAILED &&
                            <Text type="secondary" italic>(not available for this lead because of status)</Text>
                        }
                        {
                            status !== LeadStatuses.FAILED &&
                            <Text
                            >
                                {shellyIsFileUpload ? 'Yes' : 'No'}
                            </Text>
                        }
                    </>
                </Descriptions.Item>
                {
                    (shellyIsFileUpload &&
                        status !== LeadStatuses.FAILED) &&
                    <Descriptions.Item label={<Text type="secondary">Path to file</Text>}>
                        {
                            shellyPathToFile &&
                            <Text
                                copyable={{
                                    text: shellyPathToFile,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                <a target="_blank" href={shellyPathToFile} rel="noreferrer">
                                    {shellyPathToFile}&nbsp;<BoxArrowUpRight size={12}/>
                                </a>
                            </Text>
                        }
                    </Descriptions.Item>
                }
            </Descriptions>
        </>
    );
});
