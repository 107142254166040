import { Col, Result, Row, Space } from 'antd';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { HeaderInner } from '../../../modules/Header/headerInner.hoc';
import { UnarchivedList } from '../../../modules/LeadsList/UnarchivedList';
import { LeadsListsDashboardHeaderControls } from './headerControls';

export const LeadsListsDashboard: FC = memo(() => {
    const {
        lists,
        isTableLoading,
        listsError
    } = useSelector((state) => state.leadsLists);

    return (
        <>
            <HeaderInner titleName="Leads lists" controls={<LeadsListsDashboardHeaderControls/>}/>
            <div className="home-container">
                <Space size="large" direction="vertical">
                    <Row>
                        <Col span={24}>
                            {
                                (!listsError)
                                    ? <UnarchivedList/>
                                    : (!isTableLoading && listsError && !lists.length) &&
                                    <Result status="error" title={listsError?.message}/>
                            }
                        </Col>
                    </Row>
                </Space>
            </div>
        </>
    );
});
