/* eslint-disable react/jsx-indent-props */
import { Button, Card, Result, Spin } from 'antd';
import React, { FC, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderInner } from '../../../modules/Header/headerInner.hoc';
import { Lead } from '../../../modules/Leads/Lead';
import { RTKError } from '../../../store';
import { useGetLeadByIdQuery } from '../../../store/queries/leads';
import { RoutesParams } from '../../../typedef';

export const ViewLead: FC = memo(() => {
    const navigate = useNavigate();
    const { lead_id: leadIdParam } = useParams<RoutesParams.LEAD_ID>();

    const { data: lead, error, isLoading, isFetching } = useGetLeadByIdQuery(leadIdParam ?? '', {
        skip: !leadIdParam
    });

    return (
        <>
            <HeaderInner titleName="Lead"/>
            <div className="home-container">
                {
                    error
                        ? <Result
                            status="error"
                            title={(error as RTKError).data.message}
                            subTitle={
                                <Button onClick={(): void => navigate('/')}>Home</Button>
                            }
                        />
                        : <>
                            {((isLoading || isFetching) && !lead) && <Card><Spin className="centered-spinner"/></Card>}
                            {
                                (!isLoading && lead) &&
                                <Lead lead={lead}/>
                            }
                        </>
                }
            </div>
        </>
    );
});
