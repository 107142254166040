import { Badge } from 'antd';
import React, { FC, memo } from 'react';
import { LeadContentIndicators } from '../../interfaces/leads';

interface Props {
    contentIndicator: LeadContentIndicators;
}

export const ContentIndicatorBadge: FC<Props> = memo(({ contentIndicator }) => {
    return (
        <Badge count={contentIndicator}/>
    );
});
