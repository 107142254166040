import * as yup from 'yup';
import { LeadsListFields, LeadsListParams, LeadsListValues } from '../../interfaces/leadsLists';

export const validationSchema = yup.object()
    .shape({
        [LeadsListFields.LIST_NAME]: yup
            .string()
            .required('name is a required')
            .min(3, 'name must be at least 3 characters')
            .max(35, 'name must be not more than 35 characters'),
        [LeadsListFields.COMMENT]: yup
            .string()
            .notRequired()
            .max(255, 'comment must be not more than 255 characters'),
        [LeadsListFields.LINKS]: yup
            .string()
            .required('links are a required')
            .min(5, 'links must be at least 5 characters')
            .matches(
                // eslint-disable-next-line max-len
                /(((http|ftp|https):\/{2})+(([0-9a-z_-]+\.)+(\w+)(:[0-9]+)?((\/([~0-9a-zA-Z#+%@./_-]+))?(\?[0-9a-zA-Z+%@/&[\];=_-]+)?)?))\b/giu,
                'links must contain at least one URL'
            )
    });

export const getChangedValues = (
    initialValues: LeadsListValues,
    formValues: LeadsListParams
): LeadsListValues => {
    const changedValues = {} as LeadsListValues;

    if (initialValues[LeadsListFields.LIST_NAME] !== formValues.list_name) {
        changedValues[LeadsListFields.LIST_NAME] = formValues.list_name;
    }

    if (initialValues[LeadsListFields.LINKS] !== formValues.links) {
        changedValues[LeadsListFields.LINKS] = formValues.links;
    }

    if (initialValues[LeadsListFields.COMMENT] !== formValues.comment) {
        changedValues[LeadsListFields.COMMENT] = formValues.comment;
    }
    return changedValues;
};
