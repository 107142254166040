import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormInstance, Input } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TopicsFields, TopicsInputs, TopicsValues } from '../../interfaces/topics';
import { validationSchema } from './validation';

interface Props {
    formName: string;
    form: FormInstance<any>;
    defaultValues: TopicsValues;
    disabled?: boolean;
    onSubmit: SubmitHandler<TopicsInputs>;
}

export const TopicsForm: FC<Props> = memo(({
                                               formName,
                                               form,
                                               defaultValues,
                                               disabled,
                                               onSubmit
                                           }: Props) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<TopicsInputs>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, input: TopicsFields): void => {
        setValue(input, e.target.value, {
            shouldTouch: true,
            shouldValidate: true
        });
    }, []);

    const handleInputBlur = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, input: TopicsFields): void => {
        setValue(input, e.target.value, {
            shouldTouch: true,
            shouldValidate: true
        });
    }, []);

    return (
        <Form
            form={form}
            name={formName}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            initialValues={{ remember: true }}
            onFinish={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <Form.Item
                label="Value"
                name={TopicsFields.VALUE}
                required
                hasFeedback
                validateStatus={errors[TopicsFields.VALUE] && 'error'}
                help={errors[TopicsFields.VALUE] && errors[TopicsFields.VALUE]?.message}
            >
                <Input
                    disabled={disabled ?? false}
                    placeholder="Enter value"
                    {...register(TopicsFields.VALUE)}
                    onChange={(e): void => handleInputChange(e, TopicsFields.VALUE)}
                    onBlur={(e): void => handleInputBlur(e, TopicsFields.VALUE)}
                    allowClear
                />
            </Form.Item>

            <Form.Item
                label="Description"
                validateStatus={errors[TopicsFields.DESCRIPTION] && 'error'}
                help={errors[TopicsFields.DESCRIPTION] && errors[TopicsFields.DESCRIPTION]?.message}
                name={TopicsFields.DESCRIPTION}
            >
                <Input.TextArea
                    disabled={disabled ?? false}
                    placeholder={'Enter description (if you want)'}
                    {...register(TopicsFields.DESCRIPTION)}
                    allowClear
                    onChange={(e): void => handleInputChange(e, TopicsFields.DESCRIPTION)}
                    onBlur={(e): void => handleInputBlur(e, TopicsFields.DESCRIPTION)}
                />
            </Form.Item>
        </Form>
    );
});
