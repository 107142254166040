import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { DateTime } from 'luxon';

export const getShortDate = (date: Date): string => {
    return DateTime.fromJSDate(new Date(date))
        .setLocale('uk-UA')
        .toLocaleString(DateTime.DATE_SHORT);
};

export const getListProccessTime = (created_at: Date, updated_at: Date): string => {
    const date1 = DateTime.fromISO(new Date(updated_at).toISOString());
    const date2 = DateTime.fromISO(new Date(created_at).toISOString());
    const diff = date1.diff(date2, ['hours']);
    const minutes = (diff.toObject().hours ?? 0) * 60;
    return DateTime.fromMillis(minutes * 60000)
        .toFormat('mm:ss');
};

export const toLocaleDate = (date: Date): string => {
    return DateTime.fromJSDate(new Date(date))
        .setLocale('en-GB')
        .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
};

export const getThisDayEndAndStart = (part: 'start' | 'end'): Date => {
    if (part === 'start') {
        return DateTime.now()
            .setLocale('en-GB')
            .startOf('day')
            .toJSDate();
    } else {
        return DateTime.now()
            .setLocale('en-GB')
            .endOf('day')
            .toJSDate();
    }
};

export const getThisMounthEndAndStart = (part: 'start' | 'end'): Date => {
    if (part === 'start') {
        return DateTime.now()
            .setLocale('en-GB')
            .startOf('month')
            .toJSDate();
    } else {
        return DateTime.now()
            .setLocale('en-GB')
            .endOf('day')
            .toJSDate();
    }
};

export const getDisabledDate: RangePickerProps<Date>['disabledDate'] = current => {
    return current && current > DateTime.now()
        .setLocale('en-GB')
        .endOf('day')
        .toJSDate();
};
