import { Button, message, Select, Spin, Tag } from 'antd';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CheckCircleFill, Pencil, XCircleFill } from 'react-bootstrap-icons';
import { TextEmpty } from '../../../components/TextEmpty';
import { Topic } from '../../../interfaces/topics';
import { Website } from '../../../interfaces/websites';
import { RTKError } from '../../../store';
import { useGetTopicsQuery } from '../../../store/queries/topics';
import { useChangeTopicsMutation } from '../../../store/queries/websites';

type Props = {
    topics: Website['topics'];
    websiteID: string;
    showFullAlways?: boolean;
}

export const TopicsList: FC<Props> = memo(({
                                               websiteID,
                                               topics,
                                               showFullAlways = false
                                           }: Props) => {
    const [topicsList, setTopicsList] = useState(topics);
    const [showBlur, setShowBlur] = useState(false);
    const [showTopicsSelect, setShowTopicsSelect] = useState(false);
    const [selectedTopics, setSelectedTopics] = useState<Topic[]>(topics);
    const ref = useRef<HTMLDivElement | null>(null);

    const {
        data: allTopics,
        isLoading: isAllTopicsLoading
    } = useGetTopicsQuery(undefined, { skip: !showTopicsSelect });

    const [changeWebsiteTopicsPost, changeWebsiteTopicsPostResult] = useChangeTopicsMutation();

    useEffect(() => {
        if (changeWebsiteTopicsPostResult.isError) {
            message.error({
                content: (changeWebsiteTopicsPostResult.error as RTKError).data.message,
                key: 'changeWebsiteTopicsPost'
            });
        }

        if (changeWebsiteTopicsPostResult.isSuccess) {
            message.success({ content: 'You have successfully changed topics!', key: 'changeWebsiteTopicsPost' });
            handleHideTopicsSelect();

            setSelectedTopics(changeWebsiteTopicsPostResult.data);
            setTopicsList(changeWebsiteTopicsPostResult.data);
        }

        if (changeWebsiteTopicsPostResult.isLoading) {
            message.loading({ content: 'Send request..', key: 'changeWebsiteTopicsPost' });
        }
    }, [changeWebsiteTopicsPostResult]);

    useEffect(() => {
        if (!showFullAlways && ref.current) {
            if (ref.current.clientHeight >= 88) {
                setShowBlur(true);
            } else {
                setShowBlur(false);
            }
        }
    }, [ref.current?.clientHeight, showFullAlways]);

    const isLoading = useMemo(
        () => isAllTopicsLoading || changeWebsiteTopicsPostResult.isLoading,
        [isAllTopicsLoading, changeWebsiteTopicsPostResult.isLoading]
    );

    const handleShowTopicsSelect = useCallback(() => {
        setShowTopicsSelect(true);
    }, []);

    const handleHideTopicsSelect = useCallback(() => {
        setShowTopicsSelect(false);
        setSelectedTopics(topicsList);
    }, [topicsList]);

    const handleChangeTopics = useCallback((value: string[]) => {
        setSelectedTopics(value.map(el => JSON.parse(el)));
    }, []);

    const handleSaveTopics = useCallback(() => {
        if (showFullAlways) {
            changeWebsiteTopicsPost({
                id: websiteID,
                topicsIDs: selectedTopics.map(topic => topic._id)
            });
        }
    }, [selectedTopics, showFullAlways]);

    return (
        <div
            className={
                `topics-list ${
                    !showFullAlways && showBlur ? 'topics-list_blured-bottom' : ''
                } ${
                    showFullAlways ? 'topics-list_show-all-always' : ''
                }`
            }
            ref={ref}
        >
            {
                showTopicsSelect
                    ? <>
                        <div
                            style={{
                                width: '100%',
                                gap: 8,
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                            }}
                        >
                            {
                                (isLoading)
                                    ? <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    ><Spin size="small"/></div>
                                    : <>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%', maxHeight: 128, overflowY: 'scroll' }}
                                            placeholder="Select topics"
                                            loading={isLoading}
                                            value={selectedTopics.map(el => JSON.stringify(el))}
                                            onChange={handleChangeTopics}
                                        >
                                            {
                                                allTopics
                                                    ? allTopics.map((topic) => <Select.Option
                                                        key={topic._id} value={JSON.stringify(topic)}
                                                    >{topic.value}</Select.Option>)
                                                    : undefined
                                            }
                                        </Select>
                                    </>
                            }
                            <Button
                                type="text"
                                loading={isLoading}
                                onClick={handleSaveTopics}
                                icon={<CheckCircleFill/>}
                            />
                            <Button
                                type="text"
                                loading={isLoading}
                                onClick={handleHideTopicsSelect}
                                icon={<XCircleFill/>}
                            />
                        </div>
                    </>
                    : <>
                        {
                            showFullAlways
                                ? <>
                                    {
                                        topicsList.length
                                            ? topicsList.map((topic) => <Tag key={topic._id}>{topic.value}</Tag>)
                                            : undefined
                                    }
                                </>
                                : <>
                                    {
                                        topics.length
                                            ? topics.map((topic) => <Tag key={topic._id}>{topic.value}</Tag>)
                                            : <TextEmpty/>
                                    }
                                </>
                        }
                        {
                            showFullAlways &&
                            <Tag className="topics-list__add" onClick={handleShowTopicsSelect}>
                                <Pencil size={12}/>&nbsp;Edit topics
                            </Tag>
                        }
                    </>
            }
        </div>
    );
});
