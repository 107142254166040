import { Typography } from 'antd';
import React, { FC, memo, useMemo } from 'react';
import { LeadStatuses } from '../../interfaces/leads';

interface Props {
    status: LeadStatuses;
    error: string | null;
    value: number | null;
    isLRTReportGenerated: boolean;
    lrtPowerDom: number;
    lrtTrustDom: number;
}

export const AhrefsDescriptionValue: FC<Props> = memo(({
                                                           status,
                                                           error,
                                                           value,
                                                           isLRTReportGenerated,
                                                           lrtPowerDom,
                                                           lrtTrustDom
                                                       }: Props) => {
    const areRequirementsSatisfied = useMemo(() => isLRTReportGenerated && (lrtPowerDom > 1 || lrtTrustDom > 1),
        [isLRTReportGenerated, lrtPowerDom, lrtTrustDom]
    );

    if (!isLRTReportGenerated || status === LeadStatuses.FAILED) {
        return <Typography.Text type="secondary" italic>
            (LinkResearchTools report not received)
        </Typography.Text>;
    } else {
        if (areRequirementsSatisfied) {
            if ((error && !value)) {
                return <Typography.Text type="danger" italic>{error}</Typography.Text>;
            } else if ((!error && value)) {
                return <Typography.Text>{value}</Typography.Text>;
            } else {
                return <Typography.Text>{value}</Typography.Text>;
            }
        } else {
            return <Typography.Text type="secondary" italic>
                (value cannot be retrieved because it does not meet LRT requirements: «Power-dom or Trust-dom value more
                than 1»)
            </Typography.Text>;
        }
    }
});
