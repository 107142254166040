import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { LeadsList, LeadsListResponseWithMeta, LeadsListsUnarchivedMetadata } from '../interfaces/leadsLists';
import { refetchAllListsUnarchived } from '../store/actions/leadsLists';
import { addLeadsList, removeCurrentLeadsList, updateLeadsList } from '../store/slices/leadsList';
import { SocketsKeys } from '../typedef';

export const useInitBaseSockets = (): void => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { isLoggedIn } = useSelector(state => state.session);
    const { isTableLoading, listsError } = useSelector(state => state.leadsLists);

    useEffect(() => {
        if (isLoggedIn && !isTableLoading && !listsError) {
            const realTimeAddLeadsList = (leadsList: LeadsList): void => {
                if ((Number(searchParams.get('page')) || 1) === 1) {
                    dispatch(addLeadsList(leadsList));
                }
            };
            window.clientIo?.on(SocketsKeys.ADD_LEADS_LIST, realTimeAddLeadsList);

            const realTimeUpdateLeadsList = (leadsListsById: LeadsList, leadsListssMetadata: LeadsListsUnarchivedMetadata): void => {
                dispatch(updateLeadsList({ list: leadsListsById, meta: leadsListssMetadata }));
            };
            window.clientIo?.on(SocketsKeys.UPDATE_LEADS_LIST, realTimeUpdateLeadsList);

            const realTimeDeleteLeadsList = (id: string): void => {
                dispatch(removeCurrentLeadsList({ id }));
                dispatch(refetchAllListsUnarchived(Number(searchParams.get('page') ?? 1)));
            };
            window.clientIo?.on(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);

            const realTimeLeadsListRestart = (data: LeadsListResponseWithMeta): void => {
                dispatch(updateLeadsList({ list: data.data, meta: data.meta }));
            };
            window.clientIo?.on(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);

            return (): void => {
                window.clientIo?.off(SocketsKeys.ADD_LEADS_LIST, realTimeAddLeadsList);
                window.clientIo?.off(SocketsKeys.UPDATE_LEADS_LIST, realTimeUpdateLeadsList);
                window.clientIo?.off(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);

            };
        }
    }, [isLoggedIn, isTableLoading, listsError]);
};
