/* eslint-disable no-shadow */
import { ColumnDateLabel, ExplicitError } from '.';
import { LeadRegular, LeadShelly } from './leads';

export enum LeadsListFields {
    LIST_NAME = 'list_name',
    COMMENT = 'comment',
    LINKS = 'links'
}

export enum LeadsListStatuses {
    READY = 'ready',
    FAILED = 'failed',
    IN_PROGRESS = 'in_progress',
    PENDING = 'pending',
    PAUSED = 'paused'
}

export type LeadsListLeads = (LeadRegular | LeadShelly)[]

export interface LeadsListsResponse {
    data: LeadsList[];
    meta: LeadsListsUnarchivedMetadata;
}

export interface LeadsListsMetadata {
    all_lists_count: number;
    ready_lists_count: number;
    failed_lists_count: number;
    in_progress_lists_count: number;
    pending_lists_count: number;
}

export type LeadsListsUnarchivedMetadata = LeadsListsMetadata;

export interface LeadsListStatistics {
    all_leads_count: number;
    in_progress_leads_count: number;
    failed_leads_count: number;
    succeeded_leads_count: number;
    pending_leads_count: number;
    unprocessable_leads_count: number;
    processed_leads_count: number;
    duplicated_leads_count: number;
    approved_leads_count: number;
    active_leads_count: number;
    requires_attention_leads_count: number;
}

export interface LeadsListLRT {
    report_error: ExplicitError | false;
    report_id: number | null;
    is_report_generated: boolean;
}

export interface LeadsListSeoTools {
    linkresearchtools: LeadsListLRT;
}

export interface LeadsList {
    _id: string;
    list_name: string;
    created_at: Date;
    updated_at: Date;
    checked_at: Date | null;
    owner: string;
    comment: string | null;
    is_archived: boolean;
    is_publishable: boolean;
    is_repeat: boolean;
    requires_attention: boolean;
    is_active: boolean;
    has_plugin: boolean;
    status: LeadsListStatuses;
    statistics: LeadsListStatistics;
}

export type LeadsListTableDataObj = {
    id: string;
    key: number;
    number: number;
    comment: LeadsList['comment'],
    status: LeadsList['status'],
    statistics: {
        allLeadsCount: LeadsList['statistics']['all_leads_count'],
        inProgressLeadsCount: LeadsList['statistics']['in_progress_leads_count'],
        failedLeadsCount: LeadsList['statistics']['failed_leads_count'],
        succeededLeadsCount: LeadsList['statistics']['succeeded_leads_count'],
        pendingLeadsCount: LeadsList['statistics']['pending_leads_count'],
        processedLeadsCount: LeadsList['statistics']['processed_leads_count'],
        unprocessableLeadsCount: LeadsList['statistics']['unprocessable_leads_count'],
        duplicatedLeadsCount: LeadsList['statistics']['duplicated_leads_count'],
        approvedLeadsCount: LeadsList['statistics']['approved_leads_count'],
        activeLeadsCount: LeadsList['statistics']['active_leads_count']
        requiresAttentionLeadsCount: LeadsList['statistics']['requires_attention_leads_count'],
    },
    date: ColumnDateLabel<LeadsList>;
    name: string;
    initiator: string;
}

export interface LeadsListResponseWithMeta {
    data: LeadsList;
    meta: LeadsListsMetadata;
}

export type LeadsListInputs = {
    [LeadsListFields.LIST_NAME]: string;
    [LeadsListFields.COMMENT]: string;
    [LeadsListFields.LINKS]: string;
}

export type LeadsListEditData = Pick<
    LeadsList,
    'comment' | 'list_name' | 'status' | '_id'
> & { links: string };

export type LeadsListFull = LeadsList & { leads: LeadsListLeads };

export interface LeadsListParams {
    list_name: string;
    comment: string | undefined;
    links: string;
}

export type LeadsListValues = Partial<LeadsListParams>

export interface LeadsListCSVResponse {
    _id: string;
    csvBody: string;
    listName: string;
}
