import { message } from 'antd';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { firebaseApp } from '../firebase';

interface Return {
    token: string | null;
}

export const authWithGoogle = async (): Promise<Return> => {
    try {
        const firebaseAuth = getAuth(firebaseApp);

        const googleAuth = await signInWithPopup(firebaseAuth, new GoogleAuthProvider());

        const token = await googleAuth.user.getIdToken();

        return { token };
    }
    catch (error: any) {
        message.error(error.message);
        return { token: null };
    }
};
