import * as yup from 'yup';
import { TopicsFields, TopicsParams, TopicsValues } from '../../interfaces/topics';

export const validationSchema = yup.object()
    .shape({
        [TopicsFields.VALUE]: yup
            .string()
            .required('value is a required')
            .min(3, 'value must be at least 3 characters')
            .max(80, 'value must be not more than 80 characters'),
        [TopicsFields.DESCRIPTION]: yup
            .string()
            .notRequired()
            .max(255, 'description must be not more than 255 characters')
    });

export const getChangedValues = (
    initialValues: TopicsValues,
    formValues: TopicsParams
): TopicsValues => {
    const changedValues = {} as TopicsValues;

    if (initialValues[TopicsFields.VALUE] !== formValues[TopicsFields.VALUE]) {
        changedValues[TopicsFields.VALUE] = formValues[TopicsFields.VALUE];
    }

    if (initialValues[TopicsFields.DESCRIPTION] !== formValues[TopicsFields.DESCRIPTION]) {
        changedValues[TopicsFields.DESCRIPTION] = formValues[TopicsFields.DESCRIPTION];
    }
    return changedValues;
};
