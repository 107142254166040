import { LinkOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Space } from 'antd';
import { FC, memo, useCallback, useState } from 'react';
import { PatchCheck, XOctagon } from 'react-bootstrap-icons';
import { LeadManualVerification } from '../../interfaces/leads';

interface Props {
    loading: boolean;
    handleEnableManualVerification: (verification: LeadManualVerification) => void;
    offerId: number;
}

const ApproveButton: FC<{ onClick: () => void; loading: boolean }> = ({ onClick, loading }) => (
    <Button
        className="lead-manual-verification__preview__button lead-manual-verification__preview__button_approve"
        shape="round"
        type="primary"
        icon={<PatchCheck/>}
        onClick={onClick}
        loading={loading}
    >
        Approve
    </Button>
);

const DeclineButton: FC<{ onClick: () => void; loading: boolean }> = ({ onClick, loading }) => (
    <Button
        className="lead-manual-verification__preview__button lead-manual-verification__preview__button_decline"
        shape="round"
        type="primary"
        danger
        icon={<XOctagon/>}
        onClick={onClick}
        loading={loading}
    >
        Decline
    </Button>
);

const ConfirmModal: FC<{
    isModalOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    offerId: number
}> = ({ isModalOpen, onOk, onCancel, offerId }) => (
    <Modal
        title="Attention! Are you sure you want to deactivate this offer?"
        open={isModalOpen}
        onOk={onOk}
        okText="Yes, deactivate"
        onCancel={onCancel}
        centered
        footer={[
            <Button key="submit" type="primary" danger onClick={onOk}>
                Yes, decline the offer and deactivate posts
            </Button>,
            <Button key="back" onClick={onCancel}>
                Cancel
            </Button>
        ]}
    >
        <>
            {offerId &&
                <>
                    <p>
                        This action will unlink posts with <strong>In Progress</strong> status from this Offer.<br/>
                        No <strong>Live Posts</strong> will be updated.
                    </p>

                    <Divider/>

                    <p>
                        Active posts with status <strong>Content Requested</strong> will be deactivated.
                    </p>

                    <p>
                        <Button
                            href={`https://manager.adzz.com/#/v1_Posts?filter={%22OfferId%22:${offerId},%22new_PostStatus%22:100000000,%22new_ContentOrderStatus%22:100000006,%22_and@_or%22:[{%22new_PostOnlineStatus%22:{%22_is_null%22:true}},{%22new_PostOnlineStatus%22:%20{%22_neq%22:100000000}}]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            type="primary"
                            icon={<LinkOutlined/>}
                            block
                        >
                            See the list of active Content Requested posts
                        </Button>
                    </p>

                    <Divider/>

                    <p>
                        The rest of the unfinished posts will not be deactivated but will have the status <strong>Reassign
                        a website</strong>.
                    </p>

                    <p>
                        <Button
                            href={`https://manager.adzz.com/#/v1_Posts?filter={%22OfferId%22:${offerId},%22new_PostStatus%22:100000000,%22new_ContentOrderStatus@_neq%22:100000006,%22_and@_or%22:[{%22new_PostOnlineStatus%22:{%22_is_null%22:true}},{%22new_PostOnlineStatus%22:%20{%22_neq%22:100000000}}]}`}

                            target="_blank"
                            rel="noopener noreferrer"
                            type="primary"
                            icon={<LinkOutlined/>}
                            block
                        >
                            See the list of other posts
                        </Button>
                    </p>
                </>
            }

            {!offerId &&
                <>
                    <p>
                        This Lead does not have an Offer. When Approve or Decline it will be created in CRM.
                    </p>
                </>
            }
        </>
    </Modal>
);

export const LeadManualVerificationPreview: FC<Props> = memo(({ loading, handleEnableManualVerification, offerId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => setIsModalOpen(true);
    const hideModal = () => setIsModalOpen(false);

    const handleApprove = useCallback(() => {
        handleEnableManualVerification(LeadManualVerification.APPROVED);
    }, [handleEnableManualVerification]);

    const handleDecline = useCallback(() => {
        handleEnableManualVerification(LeadManualVerification.DECLINED);
        hideModal();
    }, [handleEnableManualVerification]);

    return (
        <Space className="lead-manual-verification__preview">
            <ApproveButton onClick={handleApprove} loading={loading}/>
            <DeclineButton onClick={showModal} loading={loading}/>
            <ConfirmModal
                isModalOpen={isModalOpen}
                onOk={handleDecline}
                onCancel={hideModal}
                offerId={offerId}
            />
        </Space>
    );
});
