import { Button, Form, message, Space } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../../components/Modal';
import { TopicsForm } from '../../../components/TopicsForm';
import { TopicsFields, TopicsInputs, TopicsParams, TopicsValues } from '../../../interfaces/topics';
import { RTKError } from '../../../store';
import { useCreateTopicMutation } from '../../../store/queries/topics';
import { Routes } from '../../../typedef';

export const CreateTopicsModal: FC = memo(() => {
    const navigate = useNavigate();
    const [form] = Form.useForm<TopicsInputs>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [createTopicPost, createTopicPostResult] = useCreateTopicMutation();

    const initialValues: TopicsValues = {
        [TopicsFields.VALUE]: undefined,
        [TopicsFields.DESCRIPTION]: undefined
    };

    useEffect(() => {
        setIsOpen(true);
        return () => {
            setIsOpen(false);
        };
    }, []);

    useEffect(() => {
        if (createTopicPostResult.isError) {
            message.error({ content: (createTopicPostResult.error as RTKError).data.message, key: 'createTopicPost' });
        }

        if (createTopicPostResult.isSuccess) {
            message.success({ content: 'New topic was created successfully!', key: 'createTopicPost' });
            handleClose();
        }

        if (createTopicPostResult.isLoading) {
            message.loading({ content: 'Send request..', key: 'createTopicPost' });
        }
    }, [createTopicPostResult]);

    const handleClose = useCallback((): void => {
        setIsOpen(false);
        setTimeout(() => {
            form.resetFields();
            navigate(Routes.WEBSITES);
        }, 170);
    }, []);

    const onSubmit: SubmitHandler<TopicsInputs> = useCallback((data: TopicsParams) => {
        createTopicPost(data);
    }, []);

    const handleCreate = useCallback((): void => {
        form.submit();
    }, []);

    return (
        <Modal
            width={600}
            title="Add new topic"
            open={isOpen}
            onCancel={handleClose}
            confirmLoading={createTopicPostResult.isLoading}
            footer={[
                <Space style={{ marginLeft: 'auto', width: 'fit-content' }} key="footer">
                    <Button key="cancle" onClick={handleClose} danger>Cancel</Button>
                    <Button
                        loading={createTopicPostResult.isLoading} onClick={handleCreate} type="primary"
                    >Create</Button>
                </Space>
            ]}
        >
            <TopicsForm
                formName="topics-create"
                form={form}
                defaultValues={initialValues}
                disabled={createTopicPostResult.isLoading}
                onSubmit={onSubmit}
            />
        </Modal>
    );
});
