import { Button, Space } from 'antd';
import React, { FC, memo } from 'react';
import { BookmarksFill, Calendar2PlusFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../../../service/api/apiRequest';
import { Routes } from '../../../typedef';

export const LeadsListsDashboardHeaderControls: FC = memo(() => {
    const navigate = useNavigate();

    return (
        <Space>
            <Button
                type="primary"
                icon={<Calendar2PlusFill/>}
                onClick={(): void => navigate(Routes.LEADS_LISTS_CREATE, {
                    state: { backgroundLocation: '/' }
                })}
            >
                Create list
            </Button>

            <Button
                type="primary"
                icon={<BookmarksFill/>}
                onClick={async (): Promise<void> => await apiRequest.post(Routes.MARK_DUPLICATES)}
            >
                Mark Duplicates
            </Button>
        </Space>
    );
});
