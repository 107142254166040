import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    AllLeadsResponse,
    LeadChangeContentIndicatorRequest,
    LeadLanguage,
    LeadOverall,
    LeadsBulkActionRestartData,
    LeadsBulkActions,
    LeadsBulkActionSeoChecksData,
    LeadsByPageRequest,
    LeadsSearchFiltersSortsQueries
} from '../../interfaces/leads';
import { LeadsList } from '../../interfaces/leadsLists';
import { Routes } from '../../typedef';
import { resetLeadsFiltering, resetLeadsSearching, resetLeadsSorting } from '../slices/leads';
import { changeLeadContentIndicator } from '../slices/leadsList';

export const leadsApi = createApi({
    reducerPath: 'leadsApi',
    refetchOnMountOrArgChange: true,
    tagTypes: ['AllLeads', 'AllWebsites'],
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL, credentials: 'include' }),
    endpoints: (builder) => ({
        getLeadById: builder.query<LeadOverall, string>({
            query: (id: string) => `${Routes.LEADS}/view/${id}`
        }),
        getAllLeads: builder.query<AllLeadsResponse, Omit<LeadsByPageRequest, 'id'>>({
            query: ({
                page,
                search,
                sortBy,
                filterByStatus,
                filterByPublishableStatus,
                filterByRepeatStatus,
                filterByAttentionStatus,
                filterByActiveStatus,
                filterByPluginStatus,
                filterByConnectionStatus,
                filterByStatusCode,
                filterByLanguage,
                filterByLrtPowerTrustDom,
                filterByAhrefsDr,
                filterByAhrefsTraffic,
                filterByManualVerification,
                filterByManualVerificationDate,
                filterByCheckingDate,
                filterByContentIndicators
            }) => `${
                Routes.LEADS
            }?page=${
                page
            }${
                search
                    ? `&${LeadsSearchFiltersSortsQueries.SEARCH}=${search.replace('://', '[||]')}`
                    : ''
            }${
                (sortBy && sortBy.length && sortBy.toString() !== 'number,asc')
                    ? `&${LeadsSearchFiltersSortsQueries.SORT_BY}=${sortBy.toString()}`
                    : ''
            }${
                filterByPublishableStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_PUBLISHABLE_STATUS}=${filterByPublishableStatus.toString()}`
                    : ''
            }${
                filterByRepeatStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_REPEAT_STATUS}=${filterByRepeatStatus.toString()}`
                    : ''
            }${
                filterByAttentionStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_ATTENTION_STATUS}=${filterByAttentionStatus.toString()}`
                    : ''
            }${
                filterByActiveStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_ACTIVE_STATUS}=${filterByActiveStatus.toString()}`
                    : ''
            }${
                filterByPluginStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_PLUGIN_STATUS}=${filterByPluginStatus.toString()}`
                    : ''
            }${
                filterByStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_STATUS}=${filterByStatus.toString()}`
                    : ''
            }${
                filterByConnectionStatus
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_CONNECTION}=${filterByConnectionStatus}`
                    : ''
            }${
                filterByStatusCode?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_STATUS_CODE}=${filterByStatusCode.toString()}`
                    : ''
            }${
                filterByLanguage?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_LANGUAGE}=${filterByLanguage}`
                    : ''
            }${
                filterByLrtPowerTrustDom
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_LRT_POWER_TRUST_DOM}=${filterByLrtPowerTrustDom}`
                    : ''
            }${
                filterByAhrefsDr
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_AHREFS_DR}=${filterByAhrefsDr.toString()}`
                    : ''
            }${
                filterByAhrefsTraffic
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_AHREFS_TRAFFIC}=${filterByAhrefsTraffic.toString()}`
                    : ''
            }${
                filterByManualVerification
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_MANUAL_VERIFICATION}=${filterByManualVerification.toString()}`
                    : ''
            }${
                (filterByManualVerificationDate &&
                    (filterByManualVerificationDate[0] &&
                        filterByManualVerificationDate[1])
                )
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_MANUAL_VERIFICATION_DATE}=${
                        filterByManualVerificationDate.map(date => new Date(date).toISOString())
                            .toString()
                    }`
                    : ''
            }${
                (filterByCheckingDate &&
                    (filterByCheckingDate[0] &&
                        filterByCheckingDate[1])
                )
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_CHECKING_DATE}=${
                        filterByCheckingDate.map(date => new Date(date).toISOString())
                            .toString()
                    }`
                    : ''
            }${
                filterByContentIndicators?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_CONTENT_INDICATORS}=${filterByContentIndicators.toString()}`
                    : ''
            }`,
            providesTags: ['AllLeads']
        }),
        getLeadsLanguage: builder.query<LeadLanguage[], void>({
            query: () => `${Routes.LEADS}/languages`
        }),
        bulkActionsRestart: builder.mutation<void, LeadsBulkActionRestartData>({
            query: (values) => ({
                url: `${Routes.LEADS}/bulk-actions/${LeadsBulkActions.RESTART}/run`,
                method: 'POST',
                body: values
            }),
            onQueryStarted(_, { dispatch }) {
                dispatch(resetLeadsFiltering());
                dispatch(resetLeadsSorting());
                dispatch(resetLeadsSearching());
            }
        }),
        bulkActionsSeoChecks: builder.mutation<void, LeadsBulkActionSeoChecksData>({
            query: (values) => ({
                url: `${Routes.LEADS}/bulk-actions/${LeadsBulkActions.SEO_CHECKS}/run`,
                method: 'POST',
                body: values
            })
        }),
        changeContentIndicators: builder.mutation<void, LeadChangeContentIndicatorRequest>({
            query: (values) => ({
                url: `${Routes.LEADS}/change-content-indicator`,
                method: 'POST',
                body: values
            }),
            onQueryStarted(arg, { dispatch }) {
                dispatch(changeLeadContentIndicator({ leadID: arg.id, contentIndicators: arg.contentIndicators }));
            },
            invalidatesTags: ['AllLeads']
        }),
        getLeadsListsNames: builder.query<Pick<LeadsList, '_id' | 'list_name'>[], void>({
            query: () => `${Routes.LEADS}/lists-names`
        })
    })
});

export const {
    useGetAllLeadsQuery,
    useGetLeadsLanguageQuery,
    useGetLeadByIdQuery,
    useBulkActionsRestartMutation,
    useBulkActionsSeoChecksMutation,
    useChangeContentIndicatorsMutation,
    useGetLeadsListsNamesQuery
} = leadsApi;
