/* eslint-disable react/jsx-indent-props */
import { Button, Divider, Empty, Tooltip, Typography } from 'antd';
import React, { FC, memo, useEffect, useState } from 'react';
import { Box, BoxArrowUpRight } from 'react-bootstrap-icons';
import { Drawer } from '../../../components/Drawer';
import { LeadDossier, LeadOverall, LeadRegular, LeadShelly, LeadTypes } from '../../../interfaces/leads';
import { Routes } from '../../../typedef';
import { AhrefsBlock } from './Blocks/ahrefsBlock';
import { GeneralBlock } from './Blocks/generalBlock';
import { LRTBlock } from './Blocks/lrtBlock';
import { ShellyFileBlock } from './Blocks/shellyFileBlock';

type Props = {
    isVisible: boolean;
    lead: LeadOverall | null;
    onClose: () => void;
    handleReloadLeads?: () => void;
}

const { Text } = Typography;

export const LeadDossierModal: FC<Props> = memo((
    {
        isVisible,
        lead,
        onClose: handleClose,
        handleReloadLeads
    }: Props) => {
    const [dossierContent, setDossierContent] = useState<LeadDossier | null>(null);

    useEffect(() => {
        if (isVisible && lead) {
            const dossier: LeadDossier = {
                id: lead._id ?? '',
                metaTitle: lead.meta_title ?? '',
                linkInfo: lead.link_info,
                blogProbability: lead.blog_probability,
                name: lead.domain ?? '',
                type: lead.type ?? '',
                domain: lead.domain ?? '',
                subdomain: lead.subdomain ?? '',
                homePage: lead.home_page,
                language: lead.language,
                connectionStatus: lead.connection_status,
                url: lead.url,
                createdAt: lead.created_at,
                checkedAt: lead.checked_at,
                status: lead.status,
                engine: lead.engine,
                statusCode: lead.status_code,
                regularUrl: LeadTypes.REGULAR ? (lead as LeadRegular).regular_url : '',
                regularPassword: LeadTypes.REGULAR ? (lead as LeadRegular).regular_password : '',
                regularLogin: LeadTypes.REGULAR ? (lead as LeadRegular).regular_login : '',
                shellyUrl: LeadTypes.SHELLY ? (lead as LeadShelly).shelly_url : '',
                shellyPassword: LeadTypes.SHELLY ? (lead as LeadShelly).shelly_password : '',
                shellyPathToFile: lead.type === LeadTypes.SHELLY ? (lead as LeadShelly).shelly_path_to_file : '',
                shellyIsFileUpload: lead.type === LeadTypes.SHELLY ? (lead as LeadShelly).shelly_is_file_upload : false,
                errorDescription: lead.error_description,
                manualVerification: lead.manual_verification,
                manualVerificatedAt: lead.manual_verificated_at,
                contentIndicators: lead.content_indicators,
                websiteID: lead.website_id,
                offerId: lead.offer_id,
                notes: lead.notes,
            };

            setDossierContent(
                dossier,
            );
        }

        return () => {
            setDossierContent(null);
        };
    }, [isVisible, lead]);

    return (
        <Drawer
            width={500}
            title={<>Dossier for: <Text strong>{dossierContent?.name ?? ''}</Text></>}
            placement="right"
            extra={
                <Tooltip
                    placement="topLeft"
                    title={
                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>Go to lead&nbsp;<Box
                            size={12}
                        /></span>
                    }
                >
                    <Button
                        type="link"
                        icon={<BoxArrowUpRight/>}
                        href={`${Routes.LEADS}/${lead?._id}/view`}
                        target="_blank"
                    />
                </Tooltip>
            }
            className="dossier-drawer"
            onClose={handleClose}
            open={isVisible}
        >
            {
                dossierContent
                    ? <>
                        <GeneralBlock
                            id={dossierContent.id}
                            status={dossierContent.status}
                            statusCode={dossierContent.statusCode}
                            metaTitle={dossierContent.metaTitle}
                            url={dossierContent.url}
                            domain={dossierContent.domain}
                            connectionStatus={dossierContent.connectionStatus}
                            engine={dossierContent.engine}
                            language={dossierContent.language}
                            type={dossierContent.type}
                            subdomain={dossierContent.subdomain}
                            blogProbability={dossierContent.blogProbability}
                            homePage={dossierContent.homePage}
                            regularUrl={dossierContent.regularUrl}
                            regularLogin={dossierContent.regularLogin}
                            regularPassword={dossierContent.regularPassword}
                            shellyUrl={dossierContent.shellyUrl}
                            shellyPassword={dossierContent.shellyPassword}
                            errorDescription={dossierContent.errorDescription}
                            manualVerification={dossierContent.manualVerification}
                            manualVerificatedAt={dossierContent.manualVerificatedAt}
                            createdAt={dossierContent.createdAt}
                            checkedAt={dossierContent.checkedAt}
                            contentIndicators={dossierContent.contentIndicators}
                            websiteID={dossierContent.websiteID}
                            offerId={dossierContent.offerId}
                            notes={dossierContent.notes}
                            handleReloadLeads={handleReloadLeads}
                        />

                        {
                            dossierContent.type === LeadTypes.SHELLY &&
                            <ShellyFileBlock
                                status={dossierContent.status}
                                shellyIsFileUpload={dossierContent.shellyIsFileUpload}
                                shellyPathToFile={dossierContent.shellyPathToFile}
                            />
                        }

                        <Divider style={{ marginTop: 8, marginBottom: 16 }}/>

                        <LRTBlock
                            linkresearchtoolsInfo={dossierContent.linkInfo.linkresearchtools}
                            status={dossierContent.status}
                        />

                        <AhrefsBlock
                            status={dossierContent.status}
                            isLRTReportGenerated={dossierContent.linkInfo.linkresearchtools.is_report_generated}
                            lrtPowerDom={dossierContent.linkInfo.linkresearchtools.power_dom}
                            lrtTrustDom={dossierContent.linkInfo.linkresearchtools.trust_dom}
                            ahrefsInfo={dossierContent.linkInfo.ahrefs}
                        />
                    </>
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            }
        </Drawer>
    );
});
