/* eslint-disable no-shadow */
export enum LRTReportResultReportColumns {
    POWER_TRUST_DOM = 'Power*Trust dom',
    POWER_TRUST = 'Power*Trust',
    POWER_DOM = 'Power-dom',
    TRUST_DOM = 'Trust-dom',
    TITLE_HOME = 'Title-home',
    THEME = 'Theme',
    IP = 'IP',
}

export enum AhrefsFileds {
    TOTAL_TRAFFIC = 'total_traffic',
    DOMAIN_RATING = 'domain_rating'
}

export enum LRTReportValuesMax {
    POWER_TRUST_DOM = 100,
    POWER_TRUST = 100,
    POWER_DOM = 10,
    TRUST_DOM = 10,
}
