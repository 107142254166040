import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Divider, InputNumber, Row, Select, Space, Switch, Tag, Typography } from 'antd';
import React, { FC, memo } from 'react';
import {
    ArrowRight,
    BackspaceReverseFill,
    CalendarRange,
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight,
} from 'react-bootstrap-icons';
import { ConnectionBadge } from '../../../../../components/ConnectionBadge';
import { ContentIndicatorsSelect } from '../../../../../components/ContentIndicators/select';
import { DateRangePicker, localeDatePicker } from '../../../../../components/DateRangePicker';
import { LanguageLabel } from '../../../../../components/LanguageLabel';
import { LeadAttentionStatusTag } from '../../../../../components/LeadAttentionStatusTag';
import { LeadManualVerificationTag } from '../../../../../components/LeadManualVerificationTag';
import { LeadPluginStatusTag } from '../../../../../components/LeadPluginStatusTag';
import { LeadPublishableStatusTag } from '../../../../../components/LeadPublishableStatusTag';
import { LeadRepeatStatusTag } from '../../../../../components/LeadRepeatStatusTag';
import { LeadStatusTag } from '../../../../../components/LeadStatusTag';
import { FilterPanelProps } from '../../../../../interfaces/filter-panel-props';
import { LeadConnectionStatuses, LeadManualVerification, LeadStatuses, } from '../../../../../interfaces/leads';
import { AhrefsFileds, LRTReportResultReportColumns } from '../../../../../interfaces/seoApi';
import { LeadActiveStatusTag } from '../../../../../LeadActiveStatusTag';
import { StatusCodesInfo } from '../../../../../typedef';
import { getDisabledDate, getThisDayEndAndStart, getThisMounthEndAndStart } from '../../../../../utils/date';
import { upperFirstLetter } from '../../../../../utils/string';

const { Text } = Typography;
const { Option } = Select;

export const Panel: FC<FilterPanelProps> = memo((
    {
        filterPublishableStatus,
        filterRepeatStatus,
        filterAttentionStatus,
        filterActiveStatus,
        filterPluginStatus,
        filterStatus,
        filterStatusCode,
        filterConnectionStatus,
        filterLrtPowerTrustDom,
        filterAhrefsDr,
        filterAhrefsTraffic,
        showFilterAhrefsDr,
        showFilterAhrefsTraffic,
        filterLanguage,
        isLanguagesListFetching,
        languagesList,
        filterManualVerification,
        filterManualVerificationDate,
        filterCheckingDate,
        filterContentIndicators,
        handleSetFilterPublishableStatus,
        handleSetFilterRepeatStatus,
        handleSetFilterAttentionStatus,
        handleSetFilterActiveStatus,
        handleSetFilterPluginStatus,
        handleSetFilterStatus,
        handleSetFilterStatusCode,
        handleShowFilterAhrefsDr,
        handleShowFilterAhrefsTraffic,
        handleClearFilterByAhrefsDr,
        handleClearFilterByAhrefsTraffic,
        handleSetFilterLanguage,
        handleSetLrtPowerTrustDom,
        handleSetAhrefsDrFrom,
        handleSetAhrefsDrTo,
        handleSetAhrefsTrafficFrom,
        handleSetAhrefsTrafficTo,
        handleSetFilterConnectionStatus,
        handleSetFilterManualVerification,
        handleSetFilterManualVerificationDate,
        handleSetFilterCheckingDate,
        handleSetFilterContentIndicators,
        handleResetFilters,
        handleFindByFilters,
        setIsFilterOpen,
    }: FilterPanelProps) => {
    return (
        <Row gutter={[16, 16]} wrap className="leads-table__filter-panel">
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Lead connection status</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    placeholder="Choose"
                    value={filterConnectionStatus}
                    onChange={handleSetFilterConnectionStatus}
                >
                    <Option value={LeadConnectionStatuses.ONLINE}>
                        <ConnectionBadge status={LeadConnectionStatuses.ONLINE}/>
                    </Option>
                    <Option value={LeadConnectionStatuses.OFFLINE}>
                        <ConnectionBadge status={LeadConnectionStatuses.OFFLINE}/>
                    </Option>
                </Select>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Language</Text>
                <Select
                    disabled={isLanguagesListFetching}
                    style={{ width: '100%' }}
                    allowClear
                    placeholder="Choose"
                    mode="multiple"
                    value={filterLanguage}
                    onChange={handleSetFilterLanguage}
                >
                    {
                        languagesList.map(lang => <Option
                            key={lang.name}
                            value={lang.name}
                        >
                            <LanguageLabel lang={lang}/>
                        </Option>)
                    }
                </Select>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Ahrefs</Text>
                <Space direction="vertical">
                    <Space direction="vertical">
                        <Space>
                            <Tag>
                                <Text style={{ fontSize: 12 }}>{upperFirstLetter(AhrefsFileds.DOMAIN_RATING)}:</Text>
                            </Tag>
                            <Switch size="small" checked={showFilterAhrefsDr} onChange={handleShowFilterAhrefsDr}/>
                        </Space>
                        {
                            showFilterAhrefsDr && <Space>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="from"
                                    min={0}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={filterAhrefsDr[0]}
                                    onChange={handleSetAhrefsDrFrom}
                                />
                                <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="to"
                                    min={0}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={filterAhrefsDr[1]}
                                    onChange={handleSetAhrefsDrTo}
                                />
                                <Button
                                    disabled={!(filterAhrefsDr[0] || filterAhrefsDr[1])}
                                    onClick={handleClearFilterByAhrefsDr}
                                    type="text"
                                    danger
                                    icon={<BackspaceReverseFill/>}
                                />
                            </Space>
                        }
                    </Space>
                    <Space direction="vertical">
                        <Space>
                            <Tag>
                                <Text style={{ fontSize: 12 }}>{upperFirstLetter(AhrefsFileds.TOTAL_TRAFFIC)}:</Text>
                            </Tag>
                            <Switch
                                size="small" checked={showFilterAhrefsTraffic}
                                onChange={handleShowFilterAhrefsTraffic}
                            />
                        </Space>
                        {
                            showFilterAhrefsTraffic && <Space>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="from"
                                    min={0}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={filterAhrefsTraffic[0]}
                                    onChange={handleSetAhrefsTrafficFrom}
                                />
                                <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore="to"
                                    min={0}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={filterAhrefsTraffic[1]}
                                    onChange={handleSetAhrefsTrafficTo}
                                />
                                <Button
                                    disabled={!(filterAhrefsTraffic[0] || filterAhrefsTraffic[1])}
                                    onClick={handleClearFilterByAhrefsTraffic}
                                    type="text"
                                    danger
                                    icon={<BackspaceReverseFill/>}
                                />
                            </Space>
                        }
                    </Space>
                </Space>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>LRT</Text>
                <Space align="center">
                    <Tag>
                        <Text style={{ fontSize: 12 }}>
                            {LRTReportResultReportColumns.POWER_TRUST_DOM}:
                        </Text>
                    </Tag>
                    <Switch
                        checked={filterLrtPowerTrustDom}
                        checkedChildren="more than 1"
                        unCheckedChildren="more than 1"
                        onChange={handleSetLrtPowerTrustDom}
                    />
                </Space>
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Manual verification date</Text>
                <DateRangePicker
                    style={{ width: '100%' }}
                    showTime={{ format: 'HH:mm' }}
                    format="DD MMM YYYY, HH:mm"
                    allowClear
                    showNow
                    suffixIcon={<CalendarRange/>}
                    value={filterManualVerificationDate}
                    separator={<ArrowRight
                        style={{ verticalAlign: 'middle', fill: 'rgba(0,0,0,.45)' }} size={16}
                    />}
                    superPrevIcon={<ChevronDoubleLeft size={14}/>}
                    superNextIcon={<ChevronDoubleRight size={14}/>}
                    prevIcon={<ChevronLeft size={14}/>}
                    nextIcon={<ChevronRight size={14}/>}
                    disabledDate={getDisabledDate}
                    locale={{
                        ...localeDatePicker,
                        lang: {
                            ...localeDatePicker.lang,
                            ok: 'Set',
                        },
                    }}
                    ranges={{
                        Today: [getThisDayEndAndStart('start'), getThisDayEndAndStart('end')],
                        'This mounth': [getThisMounthEndAndStart('start'), getThisMounthEndAndStart('end')],
                    }}
                    onChange={handleSetFilterManualVerificationDate}
                />
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Access checking date</Text>
                <DateRangePicker
                    style={{ width: '100%' }}
                    showTime={{ format: 'HH:mm' }}
                    format="DD MMM YYYY, HH:mm"
                    allowClear
                    showNow
                    suffixIcon={<CalendarRange/>}
                    value={filterCheckingDate}
                    separator={<ArrowRight
                        style={{ verticalAlign: 'middle', fill: 'rgba(0,0,0,.45)' }} size={16}
                    />}
                    superPrevIcon={<ChevronDoubleLeft size={14}/>}
                    superNextIcon={<ChevronDoubleRight size={14}/>}
                    prevIcon={<ChevronLeft size={14}/>}
                    nextIcon={<ChevronRight size={14}/>}
                    disabledDate={getDisabledDate}
                    locale={{
                        ...localeDatePicker,
                        lang: {
                            ...localeDatePicker.lang,
                            ok: 'Set',
                        },
                    }}
                    ranges={{
                        Today: [getThisDayEndAndStart('start'), getThisDayEndAndStart('end')],
                        'This mounth': [getThisMounthEndAndStart('start'), getThisMounthEndAndStart('end')],
                    }}
                    onChange={handleSetFilterCheckingDate}
                />
            </Col>
            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Manual verification type</Text>
                <Select
                    className="dropdown-manual-verification"
                    style={{ width: '100%' }}
                    allowClear
                    placeholder="Choose"
                    value={filterManualVerification}
                    onChange={handleSetFilterManualVerification}
                >
                    <Option value={LeadManualVerification.NOT_CHECKED}>
                        <LeadManualVerificationTag
                            type={'badge'}
                            verificationStatus={LeadManualVerification.NOT_CHECKED}
                            layout={'row'}
                            fullWidth
                            withoutDate
                        />
                    </Option>
                    <Option value={LeadManualVerification.APPROVED}>
                        <LeadManualVerificationTag
                            type={'badge'}
                            verificationStatus={LeadManualVerification.APPROVED}
                            layout={'row'}
                            fullWidth
                            withoutDate
                        />
                    </Option>
                    <Option value={LeadManualVerification.DECLINED}>
                        <LeadManualVerificationTag
                            type={'badge'}
                            verificationStatus={LeadManualVerification.DECLINED}
                            layout={'row'}
                            fullWidth
                            withoutDate
                        />
                    </Option>
                </Select>
            </Col>

            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Requires Attention</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    placeholder="Choose"
                    value={filterAttentionStatus}
                    onChange={handleSetFilterAttentionStatus}
                >
                    <Option value="true">
                        <LeadAttentionStatusTag status={true}/>
                    </Option>
                    <Option value="false">
                        <LeadAttentionStatusTag status={false}/>
                    </Option>
                    <Option value="null">
                        <LeadAttentionStatusTag status={undefined}/>
                    </Option>
                </Select>
            </Col>

            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Active status</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    placeholder="Choose"
                    value={filterActiveStatus}
                    onChange={handleSetFilterActiveStatus}
                >
                    <Option value="true">
                        <LeadActiveStatusTag status={true}/>
                    </Option>
                    <Option value="false">
                        <LeadActiveStatusTag status={false}/>
                    </Option>
                    <Option value="null">
                        <LeadActiveStatusTag status={undefined}/>
                    </Option>
                </Select>
            </Col>

            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Access status</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    placeholder="Choose"
                    value={filterStatus}
                    onChange={handleSetFilterStatus}
                >
                    <Option value={LeadStatuses.SUCCESSFUL}>
                        <LeadStatusTag status={LeadStatuses.SUCCESSFUL}/>
                    </Option>
                    <Option value={LeadStatuses.FAILED}>
                        <LeadStatusTag status={LeadStatuses.FAILED}/>
                    </Option>
                    <Option value={LeadStatuses.PENDING}>
                        <LeadStatusTag status={LeadStatuses.PENDING}/>
                    </Option>
                    <Option value={LeadStatuses.IN_PROGRESS}>
                        <LeadStatusTag status={LeadStatuses.IN_PROGRESS}/>
                    </Option>
                    <Option value={LeadStatuses.UNPROCESSABLE}>
                        <LeadStatusTag status={LeadStatuses.UNPROCESSABLE}/>
                    </Option>
                </Select>
            </Col>

            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Publishable status</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    placeholder="Choose"
                    value={filterPublishableStatus}
                    onChange={handleSetFilterPublishableStatus}
                >
                    <Option value="true">
                        <LeadPublishableStatusTag status={true}/>
                    </Option>
                    <Option value="false">
                        <LeadPublishableStatusTag status={false}/>
                    </Option>
                    <Option value="null">
                        <LeadPublishableStatusTag status={undefined}/>
                    </Option>
                </Select>
            </Col>

            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Repeat status</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    placeholder="Choose"
                    value={filterRepeatStatus}
                    onChange={handleSetFilterRepeatStatus}
                >
                    <Option value="true">
                        <LeadRepeatStatusTag status={true}/>
                    </Option>
                    <Option value="false">
                        <LeadRepeatStatusTag status={false}/>
                    </Option>
                    <Option value="null">
                        <LeadRepeatStatusTag status={undefined}/>
                    </Option>
                </Select>
            </Col>

            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Plugin status</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    placeholder="Choose"
                    value={filterPluginStatus}
                    onChange={handleSetFilterPluginStatus}
                >
                    <Option value="true">
                        <LeadPluginStatusTag status={true}/>
                    </Option>
                    <Option value="false">
                        <LeadPluginStatusTag status={false}/>
                    </Option>
                    <Option value="null">
                        <LeadPluginStatusTag status={undefined}/>
                    </Option>
                </Select>
            </Col>

            <Col span={12}>
                <Text strong style={{ fontSize: 12 }}>Content indicators</Text>
                <ContentIndicatorsSelect
                    value={filterContentIndicators}
                    placeholder="Choose"
                    handleChange={handleSetFilterContentIndicators}
                />
            </Col>
            <Col span={24}>
                <Text strong style={{ fontSize: 12 }}>Page status</Text>
                <Select
                    style={{ width: '100%' }}
                    allowClear
                    mode="multiple"
                    placeholder="Choose"
                    value={filterStatusCode}
                    onChange={handleSetFilterStatusCode}
                >
                    {
                        Object.entries(StatusCodesInfo).map((code) => {
                            return (<Option
                                key={+code[0]}
                                value={+code[0]}
                            >
                                <Tag>{code[0]}</Tag> {code[1].info}
                            </Option>);
                        })
                    }
                </Select>
            </Col>
            <Col span={24}><Divider style={{ margin: 0 }}/></Col>
            <Col span={24}>
                <Space direction="vertical">
                    <Button type="default" block onClick={handleResetFilters}>Reset</Button>
                    <Button type="primary" block onClick={handleFindByFilters}>Apply</Button>
                </Space>
            </Col>
            <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => setIsFilterOpen(false)}
                style={{ position: 'absolute', top: 0, right: 0 }}
            />
        </Row>
    );
});
