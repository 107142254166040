import { Col, Row, Statistic } from 'antd';
import React, { FC, memo } from 'react';
import { InitiatorsAvatar } from '../../../components/InitiatorsAvatar';
import { LeadsListStatisticsHider } from '../../../components/LeadsListStatisticsHider';
import { ListStatusTag } from '../../../components/ListStatusTag';
import { TextEmpty } from '../../../components/TextEmpty';
import { LeadsList, LeadsListStatuses } from '../../../interfaces/leadsLists';
import { toLocaleDate } from '../../../utils/date';
import { StatsOfStatuses } from './statsOfStatuses';

interface Props {
    status: LeadsList['status'];
    owner: LeadsList['owner'];
    createdAt: LeadsList['created_at'];
    checkedAt: LeadsList['checked_at'];
    statistics: LeadsList['statistics'];
}

export const Stats: FC<Props> = memo(({
                                          status,
                                          owner,
                                          createdAt,
                                          checkedAt,
                                          statistics
                                      }: Props) => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={8}>
                <Row gutter={[16, 16]}>
                    <Col span={10} style={{ wordBreak: 'break-all' }}>
                        <Statistic
                            title={'Status'} formatter={
                            (value): React.ReactNode => <>
                                <ListStatusTag isBig status={value as LeadsListStatuses}/>
                            </>
                        }
                            value={status}
                        />
                    </Col>
                    <Col span={14} style={{ wordBreak: 'break-all' }}>
                        <Statistic
                            title={'Initiator'} formatter={
                            (value): React.ReactNode => (
                                <InitiatorsAvatar initiatorId={value as string}/>
                            )
                        }
                            value={owner}
                        />
                    </Col>
                    <Col
                        span={24}
                        style={{ wordBreak: 'break-all' }}
                    >
                        <Statistic title={'Created at'} value={toLocaleDate(createdAt)}/>
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                {
                    status !== LeadsListStatuses.PENDING
                        ? <StatsOfStatuses statistics={statistics} status={status}/>
                        : <LeadsListStatisticsHider size="large" allLeadsCount={statistics.all_leads_count}/>
                }
            </Col>
            <Col span={24} style={{ wordBreak: 'break-all' }}>
                <Row gutter={[16, 16]}>
                    {
                        status !== LeadsListStatuses.PENDING &&
                        <Col
                            span={8}
                            style={{ wordBreak: 'break-all' }}
                        >
                            <Statistic
                                title={'Checked at'}
                                formatter={
                                    (value): React.ReactNode => <>
                                        {value || <TextEmpty/>}
                                    </>
                                }
                                value={
                                    checkedAt
                                        ? toLocaleDate(checkedAt)
                                        : undefined
                                }
                            />
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    );
});
