import { message } from 'antd';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../firebase';

export const signoutWithGoogle = async (): Promise<void> => {
    try {
        const firebaseAuth = getAuth(firebaseApp);

        await firebaseAuth.signOut();

    }
    catch (error: any) {
        message.error(error.message);
    }
};
