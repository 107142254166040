import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorType } from '../../interfaces';
import { API } from '../../service/api';

export const fetchAllUsers = createAsyncThunk(
    'users/fetchAll',
    async (_, thunkAPI) => {
        try {
            const response = await API.users.getAll();

            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);

export const fetchUserById = createAsyncThunk(
    'users/fetchById',
    async (id: string, thunkAPI) => {
        try {
            const response = await API.users.getById(id);

            return response.data;
        }
        catch (error: unknown) {
            return thunkAPI.rejectWithValue((error as AxiosError<ErrorType>).response?.data ?? error);
        }
    }
);
