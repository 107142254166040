import { Col, Form, Input, Row, Select, Switch, Tag } from 'antd';
import { ValidateStatus } from 'antd/es/form/FormItem';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React, { FC } from 'react';
import { X } from 'react-bootstrap-icons';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import {
    LeadConnectionStatuses,
    LeadContentIndicators,
    LeadEngines,
    LeadFields,
    LeadInputs,
    LeadLanguage,
    LeadManualVerification as LeadManualVerificationEnum,
    LeadStatuses
} from '../../../interfaces/leads';
import { StatusCodesInfo } from '../../../typedef';
import { ConnectionBadge } from '../../ConnectionBadge';
import { ContentIndicatorBadge } from '../../ContentIndicators/badge';
import { LanguageLabel } from '../../LanguageLabel';
import { LeadEngineTag } from '../../LeadEngineTag';
import { LeadManualVerificationTag } from '../../LeadManualVerificationTag';
import { LeadStatusTag } from '../../LeadStatusTag';

interface Props {
    errors: FieldErrorsImpl<LeadInputs>;
    disabled: boolean;
    leadsLanguagesList: LeadLanguage[];
    validationStatusValue: (field: LeadFields) => ValidateStatus;
    register: UseFormRegister<LeadInputs>;
    handleInputChange: (e: (React.ChangeEvent<HTMLInputElement>) | any, input: any) => void;
    handleInputBlur: (e: (React.ChangeEvent<HTMLInputElement>) | any, input: any) => void;
}

const { Option } = Select;

const tagRender = (props: CustomTagProps): React.ReactElement => {
    const { value, onClose } = props;

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span onClick={onClose} className="content-indicator-badge-in-select">
      {value}&nbsp;<X size={16}/>
    </span>
    );
};

export const GeneralInputs: FC<Props> = ({
                                             errors,
                                             disabled,
                                             leadsLanguagesList,
                                             validationStatusValue,
                                             register,
                                             handleInputChange,
                                             handleInputBlur
                                         }: Props) => {
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item
                        label="Engine"
                        name={LeadFields.ENGINE}
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.ENGINE)}
                        help={errors[LeadFields.ENGINE] && errors[LeadFields.ENGINE]?.message}
                    >
                        <Select
                            disabled={disabled ?? false}
                            placeholder="Choose engine"
                            {...register(LeadFields.ENGINE)}
                            onChange={(e): void => handleInputChange(e, LeadFields.ENGINE)}
                            allowClear
                        >
                            <Option value={LeadEngines.WORDPRESS}><LeadEngineTag
                                engine={LeadEngines.WORDPRESS}
                            /></Option>
                            <Option value={LeadEngines.JOOMLA}><LeadEngineTag engine={LeadEngines.JOOMLA}/></Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Access status"
                        name={LeadFields.STATUS}
                        required
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.STATUS)}
                        help={errors[LeadFields.STATUS] && errors[LeadFields.STATUS]?.message}
                    >
                        <Select
                            disabled={disabled ?? false}
                            placeholder="Choose access status"
                            {...register(LeadFields.STATUS)}
                            onChange={(e): void => handleInputChange(e, LeadFields.STATUS)}
                            allowClear
                        >
                            <Option value={LeadStatuses.SUCCESSFUL}><LeadStatusTag
                                status={LeadStatuses.SUCCESSFUL}
                            /></Option>
                            <Option value={LeadStatuses.UNPROCESSABLE}><LeadStatusTag
                                status={LeadStatuses.UNPROCESSABLE}
                            /></Option>
                            <Option value={LeadStatuses.FAILED}><LeadStatusTag
                                status={LeadStatuses.FAILED}
                            /></Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Page code"
                        name={LeadFields.STATUS_CODE}
                        hasFeedback
                        required
                        validateStatus={validationStatusValue(LeadFields.STATUS_CODE)}
                        help={errors[LeadFields.STATUS_CODE] && errors[LeadFields.STATUS_CODE]?.message}
                    >
                        <Select
                            disabled={disabled ?? false}
                            placeholder="Choose page code"
                            {...register(LeadFields.STATUS_CODE)}
                            onChange={(e): void => handleInputChange(e, LeadFields.STATUS_CODE)}
                            allowClear
                        >
                            {
                                Object.entries(StatusCodesInfo).map((code) => {
                                    return (<Option
                                        key={+code[0]}
                                        value={+code[0]}
                                    >
                                        <Tag>{code[0]}</Tag> {code[1].info}
                                    </Option>);
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Сonnection status"
                        name={LeadFields.CONNECTION_STATUS}
                        hasFeedback
                        required
                        validateStatus={validationStatusValue(LeadFields.CONNECTION_STATUS)}
                        help={errors[LeadFields.CONNECTION_STATUS] && errors[LeadFields.CONNECTION_STATUS]?.message}
                    >
                        <Select
                            disabled={disabled ?? false}
                            placeholder="Choose connection status"
                            {...register(LeadFields.CONNECTION_STATUS)}
                            onChange={(e): void => handleInputChange(e, LeadFields.CONNECTION_STATUS)}
                            allowClear
                        >
                            <Option value={LeadConnectionStatuses.ONLINE}>
                                <ConnectionBadge status={LeadConnectionStatuses.ONLINE}/>
                            </Option>
                            <Option value={LeadConnectionStatuses.OFFLINE}>
                                <ConnectionBadge status={LeadConnectionStatuses.OFFLINE}/>
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Meta title"
                        name={LeadFields.META_TITLE}
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.META_TITLE)}
                        help={errors[LeadFields.META_TITLE] && errors[LeadFields.META_TITLE]?.message}
                    >
                        <Input
                            placeholder="Enter meta title"
                            disabled={disabled ?? false}
                            {...register(LeadFields.META_TITLE)}
                            onChange={(e): void => handleInputChange(e, LeadFields.META_TITLE)}
                            onBlur={(e): void => handleInputBlur(e, LeadFields.META_TITLE)}
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Blog probability"
                        name={LeadFields.BLOG_PROBABILITY}
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.BLOG_PROBABILITY)}
                        help={errors[LeadFields.BLOG_PROBABILITY] && errors[LeadFields.BLOG_PROBABILITY]?.message}
                    >
                        <Switch
                            disabled={disabled ?? false}
                            checkedChildren={'Detected'}
                            unCheckedChildren={'Not detected'}
                            {...register(LeadFields.BLOG_PROBABILITY)}
                            onChange={(e): void => handleInputChange(e, LeadFields.BLOG_PROBABILITY)}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Language"
                        name={LeadFields.LANGUAGE}
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.LANGUAGE)}
                        help={errors[LeadFields.LANGUAGE] && errors[LeadFields.LANGUAGE]?.message}
                    >
                        <Select
                            disabled={disabled || !leadsLanguagesList.length}
                            placeholder="Choose language"
                            {...register(LeadFields.LANGUAGE)}
                            onChange={(e): void => handleInputChange(e, LeadFields.LANGUAGE)}
                            allowClear
                        >
                            {
                                leadsLanguagesList.map(lang => <Option
                                    key={lang.name}
                                    // value={ lang.name }
                                    value={JSON.stringify(lang)}
                                >
                                    <LanguageLabel lang={lang}/>
                                </Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Verification status"
                        name={LeadFields.MANUAL_VERIFICATION}
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.MANUAL_VERIFICATION)}
                        help={errors[LeadFields.MANUAL_VERIFICATION] && errors[LeadFields.MANUAL_VERIFICATION]?.message}
                    >
                        <Select
                            className="dropdown-manual-verification"
                            disabled={disabled ?? false}
                            placeholder="Choose manual verification status"
                            {...register(LeadFields.MANUAL_VERIFICATION)}
                            onChange={(e): void => handleInputChange(e, LeadFields.MANUAL_VERIFICATION)}
                            allowClear
                        >
                            <Option value={LeadManualVerificationEnum.APPROVED}>
                                <LeadManualVerificationTag
                                    type="badge"
                                    layout="row"
                                    withoutDate
                                    verificationStatus={LeadManualVerificationEnum.APPROVED}
                                />
                            </Option>
                            <Option value={LeadManualVerificationEnum.DECLINED}>
                                <LeadManualVerificationTag
                                    type="badge"
                                    layout="row"
                                    withoutDate
                                    verificationStatus={LeadManualVerificationEnum.DECLINED}
                                />
                            </Option>
                            <Option value={LeadManualVerificationEnum.NOT_CHECKED}>
                                <LeadManualVerificationTag
                                    type="badge"
                                    layout="row"
                                    withoutDate
                                    verificationStatus={LeadManualVerificationEnum.NOT_CHECKED}
                                />
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Content indicator"
                        name={LeadFields.CONTENT_INDICATORS}
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.CONTENT_INDICATORS)}
                        help={errors[LeadFields.CONTENT_INDICATORS] && errors[LeadFields.CONTENT_INDICATORS]?.message}
                    >
                        <Select
                            mode="multiple"
                            disabled={disabled ?? false}
                            placeholder="Choose content indicator"
                            tagRender={tagRender}
                            {...register(LeadFields.CONTENT_INDICATORS)}
                            onChange={(e): void => handleInputChange(e, LeadFields.CONTENT_INDICATORS)}
                            allowClear
                        >
                            <Option value={LeadContentIndicators.OUTREACH}>
                                <ContentIndicatorBadge contentIndicator={LeadContentIndicators.OUTREACH}/>
                            </Option>
                            <Option value={LeadContentIndicators.B2F}>
                                <ContentIndicatorBadge contentIndicator={LeadContentIndicators.B2F}/>
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
