import { Card, Col, Row, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { LeadOverall } from '../../../../interfaces/leads';
import { LeadManualVerification } from '../../../LeadManualVerification';

interface Props {
    lead: LeadOverall;
    handleReloadLeads?: () => void;
}

const { Title } = Typography;

export const ManualVerificationPart: FC<Props> = memo(({ lead, handleReloadLeads }: Props) => {
    return (
        <Card>
            <Row gutter={[0, 0]}>
                <Col span={24}>
                    <Title level={3}>Manual verification</Title>
                    <LeadManualVerification
                        leadId={lead._id}
                        manualVerification={lead.manual_verification}
                        manualVerificatedAt={lead.manual_verificated_at}
                        offerId={lead.offer_id}
                        handleReloadLeads={handleReloadLeads}
                    />
                </Col>
            </Row>
        </Card>
    );
});
