import { Button, Empty, Form, Popconfirm, Spin } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LeadsListForm } from '../../../components/LeadsListForm';
import { getChangedValues } from '../../../components/LeadsListForm/validation';
import { Modal } from '../../../components/Modal';
import {
    LeadsListFields,
    LeadsListInputs,
    LeadsListParams,
    LeadsListStatuses,
    LeadsListValues
} from '../../../interfaces/leadsLists';
import { deleteList, fetchLeadsListEditData, updateList } from '../../../store/actions/leadsLists';
import { clearCurrentError, clearCurrentListEditData } from '../../../store/slices/leadsList';
import { RoutesParams, SocketsKeys, StatusCodes } from '../../../typedef';

export const EditListModal: FC = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { list_id: listIdParam } = useParams<RoutesParams.LIST_ID>();
    const { isFormLoading, listEditData, error } = useSelector((state) => state.leadsLists);
    const [form] = Form.useForm<LeadsListInputs>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsOpen(true);
        return () => {
            setIsOpen(false);
        };
    }, []);

    useEffect(() => {
        listIdParam && dispatch(fetchLeadsListEditData(listIdParam));
        return () => {
            dispatch(clearCurrentListEditData());
            dispatch(clearCurrentError());
        };
    }, []);

    const initialValues: LeadsListValues = {
        [LeadsListFields.LIST_NAME]: listEditData?.[LeadsListFields.LIST_NAME] ?? undefined,
        [LeadsListFields.COMMENT]: listEditData?.[LeadsListFields.COMMENT] ?? undefined,
        [LeadsListFields.LINKS]: listEditData?.[LeadsListFields.LINKS] ?? undefined
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [listEditData]);

    useEffect(() => {
        if (listEditData) {
            const realTimeDeleteLeadsList = (): void => {
                handleClose();
            };
            window.clientIo?.on(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);

            return () => {
                window.clientIo?.off(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);
            };
        }
    }, [listEditData]);

    const handleClose = useCallback((): void => {
        setIsOpen(false);
        setTimeout(() => {
            form.resetFields();
            navigate('/');
        }, 170);
    }, []);

    const onSubmit: SubmitHandler<LeadsListInputs> = useCallback((data: LeadsListParams) => {
        const changedValues = getChangedValues(initialValues, data);
        if (Object.keys(changedValues).length > 0) {
            listIdParam && dispatch(updateList({ id: listIdParam, data, handleClose }));
        }
    }, [initialValues, listIdParam, handleClose]);

    const handleDelete = useCallback((): void => {
        listIdParam && dispatch(deleteList({ id: listIdParam, handleClose }));
    }, [listIdParam, handleClose]);

    const handleOk = useCallback((): void => {
        form.submit();
    }, []);

    return (
        <Modal
            width={760}
            title="Edit list"
            open={isOpen}
            onCancel={handleClose}
            footer={[
                listEditData &&
                <Popconfirm
                    key="delete"
                    title="Are you sure to delete this list and leads?"
                    onConfirm={handleDelete}
                    okText="Yes"
                    disabled={
                        listEditData?.status === LeadsListStatuses.IN_PROGRESS ||
                        listEditData?.status === LeadsListStatuses.PENDING
                    }
                    cancelText="No"
                    style={{ marginRight: 'auto' }}
                >
                    <Button
                        loading={isFormLoading}
                        icon={<Trash/>}
                        danger
                        type="primary"
                        style={{ marginRight: 'auto' }}
                        disabled={
                            listEditData?.status === LeadsListStatuses.IN_PROGRESS ||
                            listEditData?.status === LeadsListStatuses.PENDING
                        }
                    >
                        Delete
                    </Button>
                </Popconfirm>,
                <Button danger key="back" onClick={handleClose}>Cancel</Button>,
                listEditData &&
                <Button
                    key="submit"
                    type="primary"
                    loading={isFormLoading}
                    onClick={handleOk}
                >
                    Save
                </Button>
            ]}
        >
            {isFormLoading && <Spin className="centered-spinner"/>}
            {(!isFormLoading && error?.statusCode !== StatusCodes.NOT_FOUND && listEditData) ? (
                <LeadsListForm
                    formName="edit-leads-list"
                    form={form}
                    formType="edit"
                    onSubmit={onSubmit}
                    defaultValues={initialValues}
                />
            ) : ((!isFormLoading && error && error.statusCode === StatusCodes.NOT_FOUND) || (!isFormLoading && !listEditData)) &&
                <Empty description="List not found"/>}
        </Modal>
    );
});
