import { Progress } from 'antd';
import { ProgressType } from 'antd/lib/progress/progress';
import React, { FC, memo } from 'react';
import { LeadsListStatuses } from '../../interfaces/leadsLists';

interface Props {
    status: LeadsListStatuses;
    type: ProgressType;
    allLeadsCount: number;
    processedLeadsCount: number;
    failedLeadsCount: number;
    unprocessableLeadsCount: number;
}

export const ListProgressBar: FC<Props> = memo(({
                                                    type,
                                                    status,
                                                    allLeadsCount,
                                                    processedLeadsCount,
                                                    failedLeadsCount,
                                                    unprocessableLeadsCount
                                                }: Props) => {
    return (
        <Progress
            status={
                (status === LeadsListStatuses.READY && 'success') ||
                (status === LeadsListStatuses.IN_PROGRESS && 'active') ||
                (status === LeadsListStatuses.FAILED && 'exception') ||
                'normal'
            }
            strokeColor={'#52c41a'}
            type={type}
            percent={
                Math.floor(
                    (processedLeadsCount * 100) / allLeadsCount
                )
            }
            // the failed % instead "success" despite prop name
            success={{
                percent: Math.round(
                    ((failedLeadsCount + unprocessableLeadsCount) * 100) / allLeadsCount
                ),
                strokeColor: '#ff4d4f'
            }}
        />
    );
});
