import { Card, Col, Divider, Row, Tooltip, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { Clipboard, ClipboardCheck, InfoCircle } from 'react-bootstrap-icons';
import { AhrefsDescriptionValue } from '../../../../components/AhrefsDescriptionValue';
import { LRTErrorMessage } from '../../../../components/LRTErrorMessage';
import { LRTStepProgress } from '../../../../components/LRTStepProgress';
import { TextEmpty } from '../../../../components/TextEmpty';
import { LeadLinkInfo, LeadStatuses } from '../../../../interfaces/leads';
import { LRTReportResultReportColumns } from '../../../../interfaces/seoApi';
import { toLocaleDate } from '../../../../utils/date';

interface Props {
    leadLinkInfo: LeadLinkInfo;
    leadStatus: LeadStatuses;
}

const { Title, Text } = Typography;

export const SEOPart: FC<Props> = memo(({ leadLinkInfo, leadStatus }: Props) => {
    return (
        <Card>
            <Row gutter={[0, 0]}>
                <Col span={24}>
                    <Title level={3}>LinkResearchTools information</Title>
                    <Row gutter={[16, 16]}>
                        {
                            (leadLinkInfo.linkresearchtools.report_id &&
                                leadStatus === LeadStatuses.SUCCESSFUL) &&
                            <>
                                <Col span={24}>
                                    <Text type="secondary">Report ID:</Text>
                                    <br/>
                                    <Text
                                        copyable={{
                                            text: leadLinkInfo.linkresearchtools.report_id.toString(),
                                            icon: [
                                                <Clipboard size={12} key="copy-icon"/>,
                                                <ClipboardCheck size={12} key="copied-icon"/>
                                            ],
                                            tooltips: ['Copy', 'Copied!']
                                        }}
                                    >
                                        {leadLinkInfo.linkresearchtools.report_id}
                                    </Text>
                                </Col>
                                <Col span={24}>
                                    <Text type="secondary">Last checked at:</Text>
                                    <br/>
                                    {
                                        leadLinkInfo.linkresearchtools.last_checked_at
                                            ?
                                            <Text>{toLocaleDate(leadLinkInfo.linkresearchtools.last_checked_at)}</Text>
                                            : <TextEmpty/>
                                    }
                                </Col>
                            </>
                        }
                        {
                            (leadLinkInfo.linkresearchtools.report_error && leadStatus === LeadStatuses.SUCCESSFUL)
                                ? <>
                                    <Col span={24}>
                                        <Text
                                            type="danger" italic
                                        >{leadLinkInfo.linkresearchtools.report_error.errorMessage}</Text>
                                    </Col>
                                </>
                                : <>
                                    {
                                        (leadLinkInfo.linkresearchtools.is_report_generated &&
                                            leadLinkInfo.linkresearchtools.report_id &&
                                            leadStatus === LeadStatuses.SUCCESSFUL)
                                            ? <>
                                                <Col span={24}>
                                                    <Text
                                                        type="secondary"
                                                    >{LRTReportResultReportColumns.POWER_TRUST_DOM}:</Text>
                                                    <br/>
                                                    <LRTStepProgress
                                                        value={leadLinkInfo.linkresearchtools.power_trust_dom}
                                                        column={'Power*Trust dom'}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Text type="secondary">{LRTReportResultReportColumns.POWER_TRUST}:</Text>
                                                    <br/>
                                                    <LRTStepProgress
                                                        value={leadLinkInfo.linkresearchtools.power_trust}
                                                        column={'Power*Trust'}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Text type="secondary">{LRTReportResultReportColumns.POWER_DOM}:</Text>
                                                    <br/>
                                                    <LRTStepProgress
                                                        value={leadLinkInfo.linkresearchtools.power_dom}
                                                        column={'Power-dom'}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Text type="secondary">{LRTReportResultReportColumns.TRUST_DOM}:</Text>
                                                    <br/>
                                                    <LRTStepProgress
                                                        value={leadLinkInfo.linkresearchtools.trust_dom}
                                                        column={'Trust-dom'}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Text type="secondary">{LRTReportResultReportColumns.THEME}:</Text>
                                                    <br/>
                                                    <Text>
                                                        {leadLinkInfo.linkresearchtools.theme || <TextEmpty/>}
                                                    </Text>
                                                </Col>
                                                <Col span={24}>
                                                    <Text type="secondary">{LRTReportResultReportColumns.TITLE_HOME}:</Text>
                                                    <br/>
                                                    <Text>
                                                        {leadLinkInfo.linkresearchtools.title_home || <TextEmpty/>}
                                                    </Text>
                                                </Col>
                                                <Col span={24}>
                                                    <Text type="secondary">{LRTReportResultReportColumns.IP}:</Text>
                                                    <br/>
                                                    <Text
                                                        copyable={
                                                            !!leadLinkInfo.linkresearchtools.ip && {
                                                                text: leadLinkInfo.linkresearchtools.ip,
                                                                icon: [
                                                                    <Clipboard size={12} key="copy-icon"/>,
                                                                    <ClipboardCheck size={12} key="copied-icon"/>
                                                                ],
                                                                tooltips: ['Copy', 'Copied!']
                                                            }
                                                        }
                                                    >
                                                        {leadLinkInfo.linkresearchtools.ip || <TextEmpty/>}
                                                    </Text>
                                                </Col>
                                            </>
                                            : <Col span={24}>
                                                <LRTErrorMessage
                                                    isReportGenerated={leadLinkInfo.linkresearchtools.is_report_generated}
                                                    reportId={leadLinkInfo.linkresearchtools.report_id}
                                                    status={leadStatus}
                                                />
                                            </Col>
                                    }
                                </>
                        }
                    </Row>
                </Col>
                <Divider/>
                <Col span={24}>
                    <Title level={3}>
                        <Tooltip title="Ahrefs values can be only received after LinkResearchTools report">
                            Ahrefs information&nbsp;<InfoCircle size={16}/>
                        </Tooltip>
                    </Title>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Text type="secondary">Domain rating:</Text>
                            <br/>
                            <AhrefsDescriptionValue
                                status={leadStatus}
                                lrtPowerDom={leadLinkInfo.linkresearchtools.power_dom ?? 0}
                                lrtTrustDom={leadLinkInfo.linkresearchtools.power_dom ?? 0}
                                isLRTReportGenerated={leadLinkInfo.linkresearchtools.is_report_generated}
                                error={leadLinkInfo.ahrefs.domain_rating.error}
                                value={leadLinkInfo.ahrefs.domain_rating.value}
                            />
                        </Col>
                        <Col span={24}>
                            <Text type="secondary">Total traffic:</Text>
                            <br/>
                            <AhrefsDescriptionValue
                                status={leadStatus}
                                lrtPowerDom={leadLinkInfo.linkresearchtools.power_dom ?? 0}
                                lrtTrustDom={leadLinkInfo.linkresearchtools.power_dom ?? 0}
                                isLRTReportGenerated={leadLinkInfo.linkresearchtools.is_report_generated}
                                error={leadLinkInfo.ahrefs.total_traffic.error}
                                value={leadLinkInfo.ahrefs.total_traffic.value}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
});
