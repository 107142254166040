import { Button, Card, Col, PageHeader, Row, Tag, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { ArrowLeftCircleFill, BoxArrowUpRight, Clipboard, ClipboardCheck, JournalArrowUp } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router';
import { ConnectionBadge } from '../../../../components/ConnectionBadge';
import { LanguageLabel } from '../../../../components/LanguageLabel';
import { LeadManualVerificationTag } from '../../../../components/LeadManualVerificationTag';
import { TextEmpty } from '../../../../components/TextEmpty';
import { WebsiteNote } from '../../../../components/WebsiteNote';
import { WebsitesContentIndicators } from '../../../../components/WebsitesContentIndicators';
import { WebsiteDossier } from '../../../../interfaces/websites';
import { toLocaleDate } from '../../../../utils/date';
import { TopicsList } from '../../../Topics/TopicsList';

interface Props {
    id: WebsiteDossier['id'];
    isAccessible: WebsiteDossier['isAccessible'];
    baseUrl: WebsiteDossier['baseUrl'];
    domain: WebsiteDossier['domain'];
    manualVerification: WebsiteDossier['manualVerification'];
    manualVerificatedAt: WebsiteDossier['manualVerificatedAt'];
    createdAt: WebsiteDossier['createdAt'];
    checkedAt: WebsiteDossier['checkedAt'];
    connectionStatus: WebsiteDossier['connectionStatus'];
    language: WebsiteDossier['language'];
    topics: WebsiteDossier['topics'];
    note: WebsiteDossier['note'];
    contentIndicators: WebsiteDossier['contentIndicators'];
    showBackArrow: boolean;
    handleViewWebsiteLeadsCatalog: () => void;
}

const { Text, Title } = Typography;

export const GeneralPart: FC<Props> = memo(({
                                                id,
                                                isAccessible,
                                                baseUrl,
                                                domain,
                                                manualVerification,
                                                manualVerificatedAt,
                                                createdAt,
                                                checkedAt,
                                                connectionStatus,
                                                language,
                                                topics,
                                                note,
                                                contentIndicators,
                                                showBackArrow,
                                                handleViewWebsiteLeadsCatalog
                                            }: Props) => {
    const navigate = useNavigate();

    return (
        <Card style={{ height: '100%' }}>
            <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                onBack={showBackArrow ? (): void => navigate('/') : undefined}
                backIcon={<ArrowLeftCircleFill size={16}/>}
                title={domain}
                extra={[
                    <Button
                        key="1" icon={<JournalArrowUp/>} type="default"
                        onClick={handleViewWebsiteLeadsCatalog}
                    >
                        Leads catalog
                    </Button>
                ]}
            >
                <Title level={3}>Website general information</Title>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Text type="secondary">ID</Text>
                        <br/>
                        <Text
                            copyable={{
                                text: id,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>
                                ],
                                tooltips: ['Copy', 'Copied!']
                            }}
                        >
                            <Tag style={{ marginRight: 0, fontSize: 14 }}>{id}</Tag>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Accessibility</Text>
                        <br/>
                        {isAccessible ? <Tag color="green">Available</Tag> : <Tag color="red">Not available</Tag>}
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Connection status</Text>
                        <br/>
                        <ConnectionBadge status={connectionStatus}/>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Language</Text>
                        <br/>
                        <Text>
                            <LanguageLabel lang={language}/>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Domain</Text>
                        <br/>
                        <Text
                            copyable={{
                                text: domain,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>
                                ],
                                tooltips: ['Copy', 'Copied!']
                            }}
                        >
                            {domain}
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Home page</Text>
                        <br/>
                        <Text
                            copyable={{
                                text: domain,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>
                                ],
                                tooltips: ['Copy', 'Copied!']
                            }}
                        >
                            <a target="_blank" href={`http://${domain}`} rel="noreferrer">
                                http://{domain}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                            </a>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Base URL</Text>
                        <br/>
                        <Text
                            copyable={{
                                text: baseUrl,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>
                                ],
                                tooltips: ['Copy', 'Copied!']
                            }}
                        >
                            <a target="_blank" href={baseUrl} rel="noreferrer">
                                {baseUrl}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                            </a>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Created at</Text>
                        <br/>
                        <Text>
                            {toLocaleDate(createdAt)}
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Checked at</Text>
                        <br/>
                        <Text>
                            {
                                checkedAt
                                    ? toLocaleDate(checkedAt)
                                    : <TextEmpty/>
                            }
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Manual verification</Text>
                        <br/>
                        <LeadManualVerificationTag
                            type="badge"
                            layout="row"
                            withoutDate={false}
                            verificationStatus={manualVerification}
                            verificatedAt={manualVerificatedAt}
                        />
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Topics</Text>
                        <br/>
                        <TopicsList showFullAlways websiteID={id} topics={topics}/>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Content indicators</Text>
                        <br/>
                        <WebsitesContentIndicators websiteID={id} contentIndicators={contentIndicators}/>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Note</Text>
                        <br/>
                        <WebsiteNote websiteID={id} note={note}/>
                    </Col>
                </Row>
            </PageHeader>
        </Card>
    );
});
