import { Avatar, Dropdown, Layout, Menu, Tag, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { BoxArrowUpRight, Boxes, ChevronDown, ClockHistory, MenuButtonWideFill, Table } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { logoutWithGoogle } from '../../store/actions/session';
import { Routes, SiderMenuItems } from '../../typedef';

export const SideMenu: FC = memo(() => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { currentUser } = useSelector(state => state.session);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [currentSelectedKey, setCurrentSelectedKey] = useState<SiderMenuItems | null>(null);
    const [currentSelectedItem, setCurrentSelectedItem] = useState<SiderMenuItems | null>(null);

    useEffect(() => {
        if (/\//.test(location.pathname)) setCurrentSelectedKey(SiderMenuItems.LEADS_LISTS);
    }, []);

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setCurrentSelectedItem(SiderMenuItems.LEADS_LISTS);
                break;
            case Routes.LEADS_LISTS_ARCHIVE:
                setCurrentSelectedItem(SiderMenuItems.ARCHIVE);
                break;
            case Routes.WEBSITES:
                setCurrentSelectedItem(SiderMenuItems.WEBSITES);
                break;
            case Routes.LEADS:
                setCurrentSelectedItem(SiderMenuItems.LEADS);
                break;
            case Routes.LEADS_LISTS_DOMAINS_CHECK:
                setCurrentSelectedItem(SiderMenuItems.DOMAINS_CHECK);
                break;
            default:
                if (/\/leads-lists\//.test(location.pathname)) {
                    setCurrentSelectedItem(SiderMenuItems.LEADS_LISTS);
                } else if (/\/leads\//.test(location.pathname)) {
                    setCurrentSelectedItem(SiderMenuItems.LEADS);
                } else if (/\/websites\//.test(location.pathname)) {
                    setCurrentSelectedItem(SiderMenuItems.WEBSITES);
                } else {
                    setCurrentSelectedItem(null);
                }
                break;
        }

    }, [location.pathname]);

    useEffect(() => {
        const bodyEl = document.body;
        bodyEl?.setAttribute('sider', isCollapsed ? 'close' : 'open');
    }, [isCollapsed]);

    const handleCollapse = useCallback((): void => {
        setIsCollapsed(!isCollapsed);
    }, [isCollapsed]);

    const handleLogout = useCallback((): void => {
        dispatch(logoutWithGoogle());
    }, []);

    const sideMenu = useMemo((): ItemType[] => [
        {
            key: SiderMenuItems.LEADS_LISTS,
            label: <Link to="/">{SiderMenuItems.LEADS_LISTS}</Link>,
            icon: <Table/>
        },
        {
            key: SiderMenuItems.WEBSITES,
            label: <Link to={Routes.WEBSITES}>{SiderMenuItems.WEBSITES}</Link>,
            icon: <MenuButtonWideFill/>
        },
        {
            key: SiderMenuItems.LEADS,
            label: <Link to={Routes.LEADS}>{SiderMenuItems.LEADS}</Link>,
            icon: <Boxes/>
        }
    ], []);

    const dropdownMenu = useMemo((): ItemType[] => [
        {
            key: 'd1',
            label: 'Log Out',
            danger: true,
            icon: <ClockHistory/>,
            onClick: handleLogout
        }
    ], [handleLogout]);

    return (
        <Layout.Sider
            theme="dark"
            className="sidemenu"
            collapsible
            collapsed={isCollapsed}
            onCollapse={handleCollapse}
        >
            <Link to="/" className="sidemenu-logo">
                <img src="/logo.png" alt="ADZZ"/>
            </Link>
            <Tag color="red" className="sidemenu__version-tag">BETA</Tag>

            <Dropdown placement="bottomRight" arrow menu={{ items: dropdownMenu }}>
                <div className="current-user-dropdown">
                    <div className="current-user-dropdown__inner">
                        <Avatar
                            className="current-user-dropdown__avatar"
                            size="small"
                            src={currentUser?.photoURL ?? '#'}
                        />
                        &ensp;
                        <Typography.Text className="current-user-dropdown__name" ellipsis>
                            {currentUser?.displayName ?? ''}
                        </Typography.Text>
                    </div>
                    <ChevronDown size={16}/>
                </div>
            </Dropdown>

            <Menu
                theme="dark"
                defaultSelectedKeys={currentSelectedKey ? [currentSelectedKey] : []}
                selectedKeys={currentSelectedItem ? [currentSelectedItem] : []}
                mode="inline"
                items={sideMenu}
            />
        </Layout.Sider>
    );
});

// <Menu.Item key={ SiderMenuItems.ARCHIVE } icon={ <Archive/> }>
//   <Link to={ Routes.LEADS_LISTS_ARCHIVE }>{ SiderMenuItems.ARCHIVE }</Link>
// </Menu.Item>
// <Menu.Item key={ SiderMenuItems.DOMAINS_CHECK } icon={ <UiChecks/> }>
//   <Link to={ Routes.LEADS_LISTS_DOMAINS_CHECK }>{ SiderMenuItems.DOMAINS_CHECK }</Link>
// </Menu.Item>
