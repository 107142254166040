import { Avatar, Space, Spin, Typography } from 'antd';
import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TextEmpty } from '../TextEmpty';

interface Props {
    initiatorId: string;
}

export const InitiatorsAvatar: FC<Props> = memo(({ initiatorId }: Props) => {
    const { isLoading: usersIsLoading, users } = useSelector((state) => state.users);

    const initiator = useMemo(() => {
        return users.find(user => user.uid === initiatorId);
    }, [users, initiatorId]);

    return (
        <>
            {usersIsLoading && <Spin/>}
            {(!usersIsLoading && !initiatorId) && <TextEmpty/>}
            {(!usersIsLoading && initiatorId && users) && <>
                <Space>
                    <Avatar
                        size="large"
                        shape="circle"
                        src={initiator?.photoURL ?? '#'}
                    />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            fontSize: 14
                        }}
                    >
                        <Typography.Text strong>{initiator?.displayName ?? '…'}</Typography.Text>
                        <Typography.Text type="secondary">{initiator?.email ?? '…'}</Typography.Text>
                    </div>
                </Space>
            </>
            }
        </>
    );
});
