import { Select, Space } from 'antd';
import React, { FC } from 'react';
import { LeadsBulkActions, LeadsBulkActionsSamples, LeadsBulkActionsTypes } from '../../../interfaces/leads';
import { upperFirstLetter } from '../../../utils/string';

interface Props {
    isDisabled: boolean;
    actionType: LeadsBulkActions;
    sampleType: LeadsBulkActionsSamples | undefined;
    leadsType: LeadsBulkActionsTypes | undefined;
    handleChooseSample: (value: LeadsBulkActionsSamples) => void;
    handleChangeLeadsType: (value: LeadsBulkActionsTypes) => void;
}

const { Option } = Select;

export const BulkActionsSelectors: FC<Props> = ({
                                                    isDisabled,
                                                    actionType,
                                                    sampleType,
                                                    leadsType,
                                                    handleChooseSample,
                                                    handleChangeLeadsType
                                                }: Props) => {
    return (
        <Space>
            <Select
                style={{ width: 200 }}
                allowClear
                placeholder="Apply"
                value={sampleType}
                onChange={handleChooseSample}
                disabled={isDisabled}
            >
                <Option
                    value={LeadsBulkActionsSamples.BY_TYPE}
                >{upperFirstLetter(LeadsBulkActionsSamples.BY_TYPE)}</Option>
                <Option
                    value={LeadsBulkActionsSamples.BY_FILTERS}
                >{upperFirstLetter(LeadsBulkActionsSamples.BY_FILTERS)}</Option>
                { /* <Option value={ LeadsBulkActionsSamples.BY_SELECTED }>{ upperFirstLetter(LeadsBulkActionsSamples.BY_SELECTED) }</Option> */}
            </Select>

            {
                sampleType === LeadsBulkActionsSamples.BY_TYPE &&
                <Select
                    style={{ width: 200 }}
                    allowClear
                    placeholder="Choose type"
                    value={leadsType}
                    onChange={handleChangeLeadsType}
                    disabled={isDisabled}
                >
                    {
                        actionType === LeadsBulkActions.RESTART &&
                        <>
                            <Option
                                value={LeadsBulkActionsTypes.ALL}
                            >{upperFirstLetter(LeadsBulkActionsTypes.ALL)}</Option>
                            <Option
                                value={LeadsBulkActionsTypes.FAILED}
                            >{upperFirstLetter(LeadsBulkActionsTypes.FAILED)}</Option>
                            <Option
                                value={LeadsBulkActionsTypes.UNPROCESSABLE}
                            >{upperFirstLetter(LeadsBulkActionsTypes.UNPROCESSABLE)}</Option>
                            <Option value={LeadsBulkActionsTypes.BAD}>
                                {`${upperFirstLetter(LeadsBulkActionsTypes.FAILED)} + ${upperFirstLetter(LeadsBulkActionsTypes.UNPROCESSABLE)}`}
                            </Option>
                            <Option
                                value={LeadsBulkActionsTypes.IN_PROGRESS}
                            >{upperFirstLetter(LeadsBulkActionsTypes.IN_PROGRESS)}</Option>
                        </>
                    }
                    {
                        actionType === LeadsBulkActions.SEO_CHECKS &&
                        <>
                            <Option
                                value={LeadsBulkActionsTypes.ALL}
                            >{upperFirstLetter(LeadsBulkActionsTypes.ALL)}</Option>
                            <Option
                                value={LeadsBulkActionsTypes.SUCCESSFUL}
                            >{upperFirstLetter(LeadsBulkActionsTypes.SUCCESSFUL)}</Option>
                        </>
                    }
                </Select>
            }
        </Space>
    );
};
