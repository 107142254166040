import React from 'react';

export const handleClickOnLoginUrl = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href') ?? '';
    const hashIndex = href.indexOf('#');
    if (hashIndex !== -1) {
        const hashPart = href.substring(hashIndex + 1);
        await navigator.clipboard.writeText(hashPart);
    }
    window.open(href, '_blank', 'noopener,noreferrer');
};
