/* eslint-disable indent */
import { Button, message, Space, Typography } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { CheckCircleFill, PencilSquare, XCircleFill } from 'react-bootstrap-icons';
import { LeadContentIndicators } from '../../interfaces/leads';
import { WebsiteContentIndicators } from '../../interfaces/websites';
import { RTKError } from '../../store';
import { useChangeContentIndicatorsMutation } from '../../store/queries/websites';
import { ContentIndicatorBadge } from './badge';
import { ContentIndicatorsSelect } from './select';

interface Props {
    websiteID: string;
    contentIndicators: WebsiteContentIndicators[];
}

export const WebsitesContentIndicators: FC<Props> = memo(({
                                                              websiteID,
                                                              contentIndicators
                                                          }: Props) => {
    const [choosenContentIndicators, setChoosenContentIndicators] = useState(contentIndicators);
    const [showContentIndicatorsSelect, setShowContentIndicatorsSelect] = useState(false);

    const [changeContentIndicatorsPost, changeContentIndicatorsPostResult] = useChangeContentIndicatorsMutation();

    useEffect(() => {
        if (changeContentIndicatorsPostResult.isError) {
            message.error({
                content: (changeContentIndicatorsPostResult.error as RTKError).data.message,
                key: 'changeContentIndicator'
            });
        }

        if (changeContentIndicatorsPostResult.isSuccess) {
            message.success({
                content: 'You have successfully changed content indicator!',
                key: 'changeContentIndicator'
            });
            handleHideContentIndicatorSelect();
        }

        if (changeContentIndicatorsPostResult.isLoading) {
            message.loading({ content: 'Send request..', key: 'changeContentIndicator' });
        }
    }, [changeContentIndicatorsPostResult]);

    const handleShowContentIndicatorsSelect = useCallback(() => {
        setShowContentIndicatorsSelect(true);
    }, []);

    const handleHideContentIndicatorSelect = useCallback(() => {
        setShowContentIndicatorsSelect(false);
    }, []);

    const handleChooseContentIndicator = useCallback((value: (LeadContentIndicators | WebsiteContentIndicators)[]) => {
        setChoosenContentIndicators(value as WebsiteContentIndicators[]);
    }, []);

    const handleChangeContentIndicator = useCallback(() => {
        changeContentIndicatorsPost({
            id: websiteID,
            contentIndicators: choosenContentIndicators
        });
    }, [websiteID, choosenContentIndicators]);

    return (
        <div style={{ width: '100%' }}>
            {
                !showContentIndicatorsSelect
                    ?
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        {
                            choosenContentIndicators.length
                                ? <Space>
                                    {
                                        choosenContentIndicators.map((indicator) => <ContentIndicatorBadge
                                            key={indicator}
                                            contentIndicator={indicator}
                                        />)
                                    }
                                </Space>
                                : <Typography.Text type="secondary" italic>(none)</Typography.Text>
                        }
                        <Button
                            type="text"
                            icon={<PencilSquare/>}
                            loading={changeContentIndicatorsPostResult.isLoading}
                            onClick={handleShowContentIndicatorsSelect}
                        />
                    </div>
                    : <div
                        style={{
                            width: '100%',
                            gap: 8,
                            display: 'flex',
                            flexWrap: 'nowrap',
                            alignItems: 'center'
                        }}
                    >
                        <ContentIndicatorsSelect
                            placeholder="Select content indicators"
                            disabled={changeContentIndicatorsPostResult.isLoading}
                            value={choosenContentIndicators}
                            handleChange={handleChooseContentIndicator}
                        />
                        <Button
                            icon={<CheckCircleFill/>}
                            type="text"
                            loading={changeContentIndicatorsPostResult.isLoading}
                            onClick={handleChangeContentIndicator}
                        />
                        <Button
                            icon={<XCircleFill/>}
                            type="text"
                            loading={changeContentIndicatorsPostResult.isLoading}
                            onClick={handleHideContentIndicatorSelect}
                        />
                    </div>
            }
        </div>
    );
});
