import { ColumnsType } from 'antd/lib/table/interface';
import React, { RefObject } from 'react';
import { ColumnDateLabel } from '../../../../components/ColumnDateLabel';
import { ColumnNumberBadge } from '../../../../components/ColumnNumberBadge';
import { InitiatorsAvatar } from '../../../../components/InitiatorsAvatar';
import { ListStatusTag } from '../../../../components/ListStatusTag';
import { ColumnDateLabel as ColumnDateLabelInterface } from '../../../../interfaces';
import { LeadsList, LeadsListTableDataObj } from '../../../../interfaces/leadsLists';
import { LeadsListsState } from '../../../../store/slices/leadsList';
import { ColumnActions } from './columnActions';
import { ColumnStats } from './columnStats';

interface Props {
    exportCsvRef: RefObject<HTMLAnchorElement>;
    currentDeletingRowId: LeadsListsState['currentDeletingRowId'];
    handleEditList: (id: string) => void;
    handleViewList: (id: string) => void;
}

export const columns = ({
                            currentDeletingRowId,
                            exportCsvRef,
                            handleEditList,
                            handleViewList
                        }: Props): ColumnsType<LeadsListTableDataObj> => {
    return [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 90,
            render: (value: LeadsListTableDataObj['number']) => (<>
                    <ColumnNumberBadge number={value}/>
                </>
            )
        },
        {
            title: (
                <>
                    Created at&nbsp;<b>/</b>
                    <br/>
                    Checked at
                </>
            ),
            dataIndex: 'date',
            key: 'date',
            width: 170,
            render: (value: ColumnDateLabelInterface<LeadsList>) => (
                <ColumnDateLabel date={value}/>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Initiator',
            dataIndex: 'initiator',
            key: 'initiator',
            render: (value: LeadsListTableDataObj['initiator']) => (
                <InitiatorsAvatar initiatorId={value}/>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 190,
            render: (value: LeadsListTableDataObj['status']) => (
                <ListStatusTag status={value}/>
            )
        },
        {
            title: 'Statistics',
            dataIndex: 'statistics',
            key: 'statistics',
            width: 300,
            render: (
                value: LeadsListTableDataObj['statistics'],
                record: LeadsListTableDataObj
            ) => (
                <ColumnStats statistics={value} status={record.status}/>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            fixed: 'right',
            render: (_, record: LeadsListTableDataObj) => (
                <ColumnActions
                    currentDeletingRowId={currentDeletingRowId}
                    status={record.status}
                    id={record.id}
                    exportCsvRef={exportCsvRef}
                    handleEditList={handleEditList}
                    handleViewList={handleViewList}
                />
            )
        }
    ];
};
