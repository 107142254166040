import { Card, Result, Spin } from 'antd';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SortFields, SortVariants } from '../../../interfaces/leads';
import { HeaderInner } from '../../../modules/Header/headerInner.hoc';
import { AllLeadsTable } from '../../../modules/Leads/AllLeadsTable';
import { RTKError } from '../../../store';
import { useGetAllLeadsQuery } from '../../../store/queries/leads';
import { resetLeadsFiltering, resetLeadsSearching, resetLeadsSorting } from '../../../store/slices/leads';
import { clearCurrentList, clearCurrentListError, disableLoading } from '../../../store/slices/leadsList';
import { DefaultPageLimits, SocketsKeys } from '../../../typedef';
import { LeadsDashboardHeaderControls } from './headerControls';

export const LeadsDashboard: FC = memo(() => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        sorting: sortingOptions,
        filtering: filteringOptions,
        searching: searchingQuery
    } = useSelector((state) => state.leads);
    const [pageLeadsSize] = useState<number>(DefaultPageLimits.LEADS);
    const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get('page') ?? 1));

    const {
        data: allLeads,
        isLoading,
        isFetching,
        error,
        refetch
    } = useGetAllLeadsQuery({
        page: currentPage,
        search: searchingQuery,
        sortBy: [sortingOptions.field, sortingOptions.variant],
        filterByPublishableStatus: filteringOptions.publishableStatus,
        filterByRepeatStatus: filteringOptions.repeatStatus,
        filterByAttentionStatus: filteringOptions.attentionStatus,
        filterByActiveStatus: filteringOptions.activeStatus,
        filterByPluginStatus: filteringOptions.pluginStatus,
        filterByStatus: filteringOptions.status,
        filterByConnectionStatus: filteringOptions.connectionStatus,
        filterByStatusCode: filteringOptions.statusCode,
        filterByLanguage: filteringOptions.language,
        filterByLrtPowerTrustDom: filteringOptions.lrtPowerTrustDom,
        filterByAhrefsDr: filteringOptions.ahrefsDr,
        filterByAhrefsTraffic: filteringOptions.ahrefsTraffic,
        filterByManualVerification: filteringOptions.manualVerification,
        filterByManualVerificationDate: filteringOptions.manualVerificationDate,
        filterByCheckingDate: filteringOptions.checkingDate,
        filterByContentIndicators: filteringOptions.contentIndicators
    });

    useEffect(() => {
        dispatch(resetLeadsFiltering());
        dispatch(resetLeadsSorting());
        dispatch(resetLeadsSearching());

        return () => {
            dispatch(disableLoading());
            dispatch(clearCurrentList());
            dispatch(clearCurrentListError());

            dispatch(resetLeadsFiltering());
            dispatch(resetLeadsSorting());
            dispatch(resetLeadsSearching());
        };
    }, []);

    useEffect(() => {
        setCurrentPage(Number(searchParams.get('page') ?? 1));
    }, [searchParams.get('page')]);

    useEffect(() => {
        const realTimeUpdateLeadManualVerification = (): void => {
            handleRefetch();
        };
        window.clientIo?.on(SocketsKeys.UPDATE_LEAD_MANUAL_VERIFICATION, realTimeUpdateLeadManualVerification);

        const realTimeLeadsListRestart = (): void => {
            if (currentPage === 1) {
                handleRefetch();
            } else {
                setCurrentPage(1);
            }
        };
        window.clientIo?.on(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);

        const realTimeDeleteLeadsList = (): void => {
            handleRefetch();
        };
        window.clientIo?.on(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);

        return () => {
            window.clientIo?.off(SocketsKeys.UPDATE_LEAD_MANUAL_VERIFICATION, realTimeUpdateLeadManualVerification);
            window.clientIo?.off(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);
            window.clientIo?.off(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);
        };
    }, []);

    const isSortingEnabled = useMemo(() => (
        sortingOptions.variant !== SortVariants.ACS ||
        sortingOptions.field !== SortFields.NUMBER
    ), [sortingOptions]);

    const isFilteringEnabled = useMemo(() => !!(
        filteringOptions.publishableStatus.length ||
        filteringOptions.repeatStatus.length ||
        filteringOptions.attentionStatus.length ||
        filteringOptions.activeStatus.length ||
        filteringOptions.pluginStatus.length ||
        filteringOptions.manualVerification?.length ||
        filteringOptions.status.length ||
        filteringOptions.connectionStatus ||
        filteringOptions.statusCode.length ||
        filteringOptions.language.length ||
        filteringOptions.contentIndicators.length ||
        filteringOptions.lrtPowerTrustDom ||
        (filteringOptions.ahrefsDr && (
            typeof filteringOptions.ahrefsDr[0] === 'number' && typeof filteringOptions.ahrefsDr[1] === 'number'
        )) ||
        (filteringOptions.ahrefsTraffic && (
            typeof filteringOptions.ahrefsTraffic[0] === 'number' && typeof filteringOptions.ahrefsTraffic[1] === 'number'
        )) ||
        (filteringOptions.manualVerificationDate && (
            !!filteringOptions.manualVerificationDate[0] && !!filteringOptions.manualVerificationDate[1]
        )) ||
        (filteringOptions.checkingDate && (
            !!filteringOptions.checkingDate[0] && !!filteringOptions.checkingDate[1]
        ))
    ), [filteringOptions]);

    const handleChangeCurrentPage = useCallback((page: number): void => {
        if (page === 1) {
            searchParams.delete('page');
            setSearchParams(searchParams);
        } else {
            setSearchParams({ page: page.toString() });
        }
        setCurrentPage(page);
    }, [searchParams]);

    const handleRefetch = useCallback((): void => refetch(), []);

    return (
        <>
            <HeaderInner
                titleName="Leads"
                controls={<LeadsDashboardHeaderControls/>}
            />
            <div className="home-container">
                {
                    error
                        ? <Result status="error" title={(error as RTKError).data.message}/>
                        : <Card>
                            {(isLoading && !allLeads) && <Spin className="centered-spinner"/>}
                            {
                                (!isLoading && allLeads?.data) &&
                                <AllLeadsTable
                                    isSearchingQuery={!!searchingQuery}
                                    sortVariant={sortingOptions.variant}
                                    isSortingEnabled={isSortingEnabled}
                                    isFilteringEnabled={isFilteringEnabled}
                                    leads={allLeads?.data ?? []}
                                    filteredLeadsCount={allLeads?.meta.filtered_leads_count ?? 0}
                                    currentPage={currentPage}
                                    pageLeadsSize={pageLeadsSize}
                                    allLeadsCount={allLeads?.meta.all_leads_count ?? 0}
                                    isLoading={isFetching}
                                    handleChangeCurrentPage={handleChangeCurrentPage}
                                    handleRefetch={handleRefetch}
                                />
                            }
                        </Card>
                }
            </div>
        </>
    );
});
