import { Button, message, Popconfirm, Tooltip, Typography } from 'antd';
import _ from 'lodash';
import React, { FC, memo, RefObject, useCallback, useMemo, useState } from 'react';
import { FiletypeCsv } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { initFiltersSetting } from '../../constants/filters';
import { AdditionalLeadStatuses, LeadsGenerateCSVTypes } from '../../interfaces/leads';
import { generateWebsiteListCsv } from '../../service/csv';
import { generateQueryForFilterWebsites } from '../../utils/query';

interface Props {
    buttonType: 'icon' | 'full';
    exportCsvRef: RefObject<HTMLAnchorElement>;
    loading?: boolean;
}

export const ExportWebsiteListCSV: FC<Props> = memo((
    {
        buttonType,
        exportCsvRef,
        loading
    }: Props
) => {
    const [csvType, setCSVType] = useState<LeadsGenerateCSVTypes>(AdditionalLeadStatuses.ALL);
    const { filtering: filteringOptions } = useSelector((state) => state.websites);

    const disabled = _.isEqual(initFiltersSetting, filteringOptions);

    const isButtonIcon = useMemo(() => buttonType === 'icon', [buttonType]);

    const handleResetCSVType = useCallback((visible: boolean): void => {
        if (!visible) {
            setCSVType(AdditionalLeadStatuses.ALL);
        }
    }, []);

    const handleGenerateCSV = useCallback(async (): Promise<void> => {
        const filterParams = {
            filterByAccessability: filteringOptions.accessability,
            filterByLanguage: filteringOptions.language,
            filterByLrtPowerTrustDom: filteringOptions.lrtPowerTrustDom,
            filterByAhrefsDr: filteringOptions.ahrefsDr,
            filterByAhrefsTraffic: filteringOptions.ahrefsTraffic,
            filterByManualVerification: filteringOptions.manualVerification,
            filterByManualVerificationDate: filteringOptions.manualVerificationDate,
            filterByLastCheckingDate: filteringOptions.lastCheckingDate,
            filterByCreatingDate: filteringOptions.creatingDate,
            filterByList: filteringOptions.listID
        };

        const findWebsitesFilterQuery = generateQueryForFilterWebsites(filterParams);
        if (findWebsitesFilterQuery.length < 1) message.error({ content: 'Error', key: 'generateLeadsListsCSV' });

        filteringOptions && findWebsitesFilterQuery !== '' && await generateWebsiteListCsv({
            websitesFilterQuery: findWebsitesFilterQuery,
            ref: exportCsvRef
        });
    }, [exportCsvRef, filteringOptions]);

    return (
        <Tooltip title={isButtonIcon && 'Generate CSV file'}>
            { /* eslint-disable-next-line jsx-a11y/anchor-has-content */ /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" ref={exportCsvRef} style={{ display: 'none' }}/>
            <Popconfirm
                arrowPointAtCenter
                key="generateCSV"
                placement={isButtonIcon ? 'bottomLeft' : 'bottom'}
                title={
                    <Typography.Text strong>
                        Export websites?
                    </Typography.Text>
                }
                onConfirm={handleGenerateCSV}
                onOpenChange={handleResetCSVType}
                okText="Generate"
                cancelText="Cancel"
                disabled={disabled}
            >
                <Button
                    loading={loading ?? false}
                    type={isButtonIcon ? 'link' : 'default'}
                    disabled={disabled}
                    icon={<FiletypeCsv size={isButtonIcon ? 20 : 14}/>}
                >
                    {
                        isButtonIcon
                            ? undefined
                            : 'Export to CSV'
                    }
                </Button>
            </Popconfirm>
        </Tooltip>
    );
});
