import { Button, Space } from 'antd';
import React, { FC, memo } from 'react';
import { Box } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router';
import { Routes } from '../../../typedef';

export const LeadsDashboardHeaderControls: FC = memo(() => {
    const navigate = useNavigate();

    return (
        <>
            {
                <Space size="middle">
                    <Button
                        type="default"
                        icon={<Box/>}
                        onClick={(): void => navigate(Routes.LEADS_CREATE, {
                            state: { backgroundLocation: Routes.LEADS }
                        })}
                    >
                        Create lead
                    </Button>
                </Space>
            }
        </>
    );
});
