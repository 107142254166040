import { Button, Popconfirm, Tooltip } from 'antd';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { LeadsListStatuses } from '../../interfaces/leadsLists';
import { deleteList } from '../../store/actions/leadsLists';
import { LeadsListsState } from '../../store/slices/leadsList';

interface Props {
    id: string;
    currentDeletingRowId: LeadsListsState['currentDeletingRowId'];
    status: LeadsListStatuses;
    buttonType: 'icon' | 'full';
    loading?: boolean;
    handleClose?: () => void;
}

export const DeleteListPopconfirm: FC<Props> = memo(({
                                                         id,
                                                         buttonType,
                                                         status,
                                                         currentDeletingRowId,
                                                         loading,
                                                         handleClose
                                                     }: Props) => {
    const dispatch = useDispatch();

    const isButtonIcon = useMemo(() => buttonType === 'icon', [buttonType]);
    const isDisabled = useMemo(() => status === LeadsListStatuses.IN_PROGRESS ||
            status === LeadsListStatuses.PENDING ||
            currentDeletingRowId.includes(id),
        [status, currentDeletingRowId]);

    const handleDelete = useCallback((): void => {
        id && dispatch(deleteList({ id, handleClose: handleClose ?? undefined }));
    }, [id, handleClose]);

    return (
        <Tooltip title={isButtonIcon && 'Delete'}>
            <Popconfirm
                arrowPointAtCenter
                placement="bottomLeft"
                key="delete"
                title="Are you sure to delete this list and leads?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
                disabled={isDisabled}
            >
                <Button
                    loading={loading ?? false}
                    type={isButtonIcon ? 'link' : 'primary'}
                    disabled={isDisabled}
                    danger
                    icon={<Trash size={isButtonIcon ? 20 : 14}/>}
                >
                    {
                        isButtonIcon
                            ? undefined
                            : 'Delete'
                    }
                </Button>
            </Popconfirm>
        </Tooltip>
    );
});
