/* eslint-disable react/jsx-indent-props */
import { Card, Col, Row, Tag, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import { LRTStepProgress } from '../../../../components/LRTStepProgress';
import { TextEmpty } from '../../../../components/TextEmpty';
import { LRTReportResultReportColumns } from '../../../../interfaces/seoApi';
import { WebsiteDossier } from '../../../../interfaces/websites';
import { toLocaleDate } from '../../../../utils/date';

interface Props {
    crmId: WebsiteDossier['crmId'];
    crmOfferId: WebsiteDossier['crmOfferId'];
    seoInfoLastCheckedAt: WebsiteDossier['seoInfoLastCheckedAt'];
    lrtPowerTrustDom: WebsiteDossier['lrtPowerTrustDom'];
    ahrefsDomainRating: WebsiteDossier['ahrefsDomainRating'];
    ahrefsTotalTraffic: WebsiteDossier['ahrefsTotalTraffic'];
}

const { Text, Title } = Typography;

export const InfoPart: FC<Props> = memo(({
                                             crmId,
                                             crmOfferId,
                                             seoInfoLastCheckedAt,
                                             lrtPowerTrustDom,
                                             ahrefsDomainRating,
                                             ahrefsTotalTraffic
                                         }: Props) => {
    return (
        <Card>
            <Title level={3}>CRM information</Title>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Text type="secondary">ID</Text>
                    <br/>
                    {
                        crmId
                            ? <Text
                                copyable={{
                                    text: crmId,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                <Tag style={{ marginRight: 0, fontSize: 14 }}>{crmId}</Tag>
                            </Text>
                            : <TextEmpty/>
                    }
                </Col>
                <Col span={24}>
                    <Text type="secondary">Offer ID</Text>
                    <br/>
                    {
                        crmOfferId
                            ? <Text
                                copyable={{
                                    text: crmOfferId,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                <Tag style={{ marginRight: 0, fontSize: 14 }}>{crmOfferId}</Tag>
                            </Text>
                            : <TextEmpty/>
                    }
                </Col>
            </Row>
            <br/>
            <Title level={3}>SEO information</Title>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Text type="secondary">Last checked at</Text>
                    <br/>
                    {
                        seoInfoLastCheckedAt
                            ? <Text>{toLocaleDate(seoInfoLastCheckedAt)}</Text>
                            : <TextEmpty/>
                    }
                </Col>
                <Col span={24}>
                    <Text type="secondary">{LRTReportResultReportColumns.POWER_TRUST_DOM}</Text>
                    <br/>
                    {
                        typeof lrtPowerTrustDom.value === 'number'
                            ? <LRTStepProgress
                                value={lrtPowerTrustDom.value}
                                column={'Power*Trust dom'}
                            />
                            : <TextEmpty/>
                    }
                </Col>
                <Col span={24}>
                    <Text type="secondary">Domain rating</Text>
                    <br/>
                    {
                        typeof ahrefsDomainRating.value === 'number'
                            ? <Typography.Text>{ahrefsDomainRating.value}</Typography.Text>
                            : <TextEmpty/>
                    }
                </Col>
                <Col span={24}>
                    <Text type="secondary">Total traffic</Text>
                    <br/>
                    {
                        typeof ahrefsTotalTraffic.value === 'number'
                            ? <Typography.Text>{ahrefsTotalTraffic.value}</Typography.Text>
                            : <TextEmpty/>
                    }
                </Col>
            </Row>
        </Card>
    );
});
