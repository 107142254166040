import { Col, Form, Input, Row, Select } from 'antd';
import { ValidateStatus } from 'antd/es/form/FormItem';
import React, { FC } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { LeadFields, LeadInputs, LeadTypes } from '../../../interfaces/leads';
import { LeadTypeTag } from '../../LeadTypeTag';

interface Props {
    errors: FieldErrorsImpl<LeadInputs>;
    disabled: boolean;
    leadType: LeadTypes | undefined;
    register: UseFormRegister<LeadInputs>;
    validationStatusValue: (field: LeadFields) => ValidateStatus;
    handleInputChange: (e: (React.ChangeEvent<HTMLInputElement>) | any, input: any) => void;
    handleInputBlur: (e: (React.ChangeEvent<HTMLInputElement>) | any, input: any) => void;
}

const { Option } = Select;

export const UrlInputs: FC<Props> = ({
                                         errors,
                                         disabled,
                                         leadType,
                                         validationStatusValue,
                                         register,
                                         handleInputChange,
                                         handleInputBlur
                                     }: Props) => {
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label="URL"
                        name={LeadFields.URL}
                        required
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 21 }}
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.URL)}
                        help={errors[LeadFields.URL] && errors[LeadFields.URL]?.message}
                    >
                        <Input
                            disabled={disabled ?? false}
                            placeholder="Enter URL"
                            {...register(LeadFields.URL)}
                            onChange={(e): void => handleInputChange(e, LeadFields.URL)}
                            onBlur={(e): void => handleInputBlur(e, LeadFields.URL)}
                            allowClear
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item
                        label="Home page"
                        name={LeadFields.HOME_PAGE}
                        required
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.HOME_PAGE)}
                        help={errors[LeadFields.HOME_PAGE] && errors[LeadFields.HOME_PAGE]?.message}
                    >
                        <Input
                            disabled
                            placeholder="Enter home page"
                            {...register(LeadFields.HOME_PAGE)}
                            onChange={(e): void => handleInputChange(e, LeadFields.HOME_PAGE)}
                            onBlur={(e): void => handleInputBlur(e, LeadFields.HOME_PAGE)}
                            allowClear
                        />
                    </Form.Item>

                    <Form.Item
                        label="Domain"
                        name={LeadFields.DOMAIN}
                        required
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.DOMAIN)}
                        help={errors[LeadFields.DOMAIN] && errors[LeadFields.DOMAIN]?.message}
                    >
                        <Input
                            disabled
                            placeholder="Enter domain"
                            {...register(LeadFields.DOMAIN)}
                            onChange={(e): void => handleInputChange(e, LeadFields.DOMAIN)}
                            onBlur={(e): void => handleInputBlur(e, LeadFields.DOMAIN)}
                            allowClear
                        />
                    </Form.Item>

                    <Form.Item
                        label="Subdomain"
                        name={LeadFields.SUBDOMAIN}
                        required
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.SUBDOMAIN)}
                        help={errors[LeadFields.SUBDOMAIN] && errors[LeadFields.SUBDOMAIN]?.message}
                    >
                        <Input
                            disabled
                            placeholder="Enter subdomain"
                            {...register(LeadFields.SUBDOMAIN)}
                            onChange={(e): void => handleInputChange(e, LeadFields.SUBDOMAIN)}
                            onBlur={(e): void => handleInputBlur(e, LeadFields.SUBDOMAIN)}
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Type"
                        name={LeadFields.TYPE}
                        required
                        hasFeedback
                        validateStatus={validationStatusValue(LeadFields.TYPE)}
                        help={errors[LeadFields.TYPE] && errors[LeadFields.TYPE]?.message}
                    >
                        <Select
                            placeholder="Enter type"
                            {...register(LeadFields.TYPE)}
                            onChange={(e): void => handleInputChange(e, LeadFields.TYPE)}
                            allowClear
                        >
                            <Option value={LeadTypes.REGULAR}><LeadTypeTag type={LeadTypes.REGULAR}/></Option>
                            <Option value={LeadTypes.SHELLY}><LeadTypeTag type={LeadTypes.SHELLY}/></Option>
                            <Option value={LeadTypes.CUSTOM}><LeadTypeTag type={LeadTypes.CUSTOM}/></Option>
                        </Select>
                    </Form.Item>
                    {
                        leadType === LeadTypes.REGULAR &&
                        <>
                            <Form.Item
                                label="Regular login"
                                name={LeadFields.REGULAR_LOGIN}
                                required
                                hasFeedback
                                validateStatus={validationStatusValue(LeadFields.REGULAR_LOGIN)}
                                help={errors[LeadFields.REGULAR_LOGIN] && errors[LeadFields.REGULAR_LOGIN]?.message}
                            >
                                <Input
                                    disabled
                                    placeholder="Enter regular login"
                                    {...register(LeadFields.REGULAR_LOGIN)}
                                    onChange={(e): void => handleInputChange(e, LeadFields.REGULAR_LOGIN)}
                                    onBlur={(e): void => handleInputBlur(e, LeadFields.REGULAR_LOGIN)}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Regular URL"
                                name={LeadFields.REGULAR_URL}
                                required
                                hasFeedback
                                validateStatus={validationStatusValue(LeadFields.REGULAR_URL)}
                                help={errors[LeadFields.REGULAR_URL] && errors[LeadFields.REGULAR_URL]?.message}
                            >
                                <Input
                                    disabled
                                    placeholder="Enter regular URL"
                                    {...register(LeadFields.REGULAR_URL)}
                                    onChange={(e): void => handleInputChange(e, LeadFields.REGULAR_URL)}
                                    onBlur={(e): void => handleInputBlur(e, LeadFields.REGULAR_URL)}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Regular password"
                                name={LeadFields.REGULAR_PASSWORD}
                                required
                                hasFeedback
                                validateStatus={validationStatusValue(LeadFields.REGULAR_PASSWORD)}
                                help={errors[LeadFields.REGULAR_PASSWORD] && errors[LeadFields.REGULAR_PASSWORD]?.message}
                            >
                                <Input
                                    disabled
                                    placeholder="Enter regular password"
                                    {...register(LeadFields.REGULAR_PASSWORD)}
                                    onChange={(e): void => handleInputChange(e, LeadFields.REGULAR_PASSWORD)}
                                    onBlur={(e): void => handleInputBlur(e, LeadFields.REGULAR_PASSWORD)}
                                    allowClear
                                />
                            </Form.Item>
                        </>
                    }
                    {
                        leadType === LeadTypes.SHELLY &&
                        <>
                            <Form.Item
                                label="Shelly URL"
                                name={LeadFields.SHELLY_URL}
                                hasFeedback
                                required
                                validateStatus={validationStatusValue(LeadFields.SHELLY_URL)}
                                help={errors[LeadFields.SHELLY_URL] && errors[LeadFields.SHELLY_URL]?.message}
                            >
                                <Input
                                    disabled
                                    placeholder="Enter shelly URL"
                                    {...register(LeadFields.SHELLY_URL)}
                                    onChange={(e): void => handleInputChange(e, LeadFields.SHELLY_URL)}
                                    onBlur={(e): void => handleInputBlur(e, LeadFields.SHELLY_URL)}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Shelly login"
                                name={LeadFields.SHELLY_PASSWORD}
                                required
                                hasFeedback
                                validateStatus={validationStatusValue(LeadFields.SHELLY_PASSWORD)}
                                help={errors[LeadFields.SHELLY_PASSWORD] && errors[LeadFields.SHELLY_PASSWORD]?.message}
                            >
                                <Input
                                    disabled
                                    placeholder="Enter shelly login"
                                    {...register(LeadFields.SHELLY_PASSWORD)}
                                    onChange={(e): void => handleInputChange(e, LeadFields.SHELLY_PASSWORD)}
                                    onBlur={(e): void => handleInputBlur(e, LeadFields.SHELLY_PASSWORD)}
                                    allowClear
                                />
                            </Form.Item>
                        </>
                    }
                </Col>
            </Row>
        </>
    );
};
