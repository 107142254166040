/* eslint-disable indent */
import { Empty, Spin, Table, Typography } from 'antd';
import React, { FC, Key, memo, useCallback, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../components/Modal';
import { EventsLogTableDataObj } from '../../../interfaces/leads';
import { getEventsLog } from '../../../store/actions/leads';
import { clearLeadsEventsLog } from '../../../store/slices/leads';
import { columns } from './columns';

type Props = {
    isVisible: boolean;
    leadName: string;
    leadID: string;
    onClose: () => void;
}

export const LeadEventsLogModal: FC<Props> = memo((
    {
        isVisible,
        leadName,
        leadID,
        onClose: handleClose
    }: Props) => {
    const dispatch = useDispatch();
    const { leadEventsLog, isLoading } = useSelector(state => state.leads);
    const [expandedRowsKeys, setExpandedRowsKeys] = useState<readonly Key[]>([]);

    useEffect(() => {
        if (isVisible) {
            dispatch(getEventsLog(leadID));
        } else {
            dispatch(clearLeadsEventsLog());
        }
    }, [isVisible]);

    const generateDataSource = useCallback((iteration: number): EventsLogTableDataObj[] => {
        if (leadEventsLog.length) {
            return leadEventsLog[iteration].map((log, idx): EventsLogTableDataObj => ({
                key: idx,
                number: ++idx,
                type: log.type,
                status: log.status,
                description: log.description,
                context: log.context,
                date: log.date,
                leadName
            }));
        } else {
            return [];
        }
    }, [leadEventsLog]);

    const handleCloseModal = (): void => {
        handleClose();
        setExpandedRowsKeys([]);
    };

    return (
        <Modal
            className="events-log-modal"
            title={<>Events log for: <Typography.Text strong>{leadName}</Typography.Text></>}
            open={isVisible}
            onCancel={handleCloseModal}
            footer={null}
            centered
        >
            {isLoading && <Spin className="centered-spinner"/>}
            {
                (!isLoading && leadEventsLog.length)
                    ? leadEventsLog.map((_, idx) => (
                        <Table
                            key={idx}
                            className="events-log-table"
                            bordered
                            expandable={{
                                expandedRowRender: record => <>
                                    {typeof record.context === 'string' &&
                                        <div dangerouslySetInnerHTML={{ __html: record.context }}/>}
                                    {
                                        typeof record.context !== 'string' && (
                                            <ReactJson
                                                style={{
                                                    fontFamily: [
                                                        '-apple-system',
                                                        'BlinkMacSystemFont',
                                                        '"Segoe UI"',
                                                        '"Roboto"',
                                                        '"Oxygen"',
                                                        '"Ubuntu"',
                                                        '"Cantarell"',
                                                        '"Fira Sans"',
                                                        '"Droid Sans"',
                                                        '"Helvetica Neue"',
                                                        'sans-serif'
                                                    ].join(', ')
                                                }}
                                                displayDataTypes={false}
                                                enableClipboard={false}
                                                name={false}
                                                src={record.context}
                                            />
                                        )
                                    }
                                </>,
                                rowExpandable: record => !!record.context,
                                onExpandedRowsChange: (key): void => {
                                    setExpandedRowsKeys(key);
                                },
                                showExpandColumn: false,
                                expandRowByClick: true
                            }}
                            pagination={false}
                            rowKey={(record): string => record.key.toString()}
                            dataSource={leadEventsLog.length ? generateDataSource(idx) : []}
                            columns={columns(expandedRowsKeys)}
                            tableLayout="fixed"
                        />
                    ))
                    : !isLoading && <Empty
                    className="centered-empty" description="No Events Logs" image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            }
        </Modal>
    );
});
