import React, { FC, memo } from 'react';
import { HeaderInner } from '../../../modules/Header/headerInner.hoc';

export const LeadsListsArchive: FC = memo(() => {
    return (
        <>
            <HeaderInner titleName="Archive"/>
            <div className="home-container">
                <h1>Archive</h1>
            </div>
        </>
    );
});
