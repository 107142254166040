import { Col, Form, Input, InputNumber, Row } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import React, { FC } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { LeadFields, LeadInputs } from '../../../interfaces/leads';

interface Props {
    errors: FieldErrorsImpl<LeadInputs>;
    disabled: boolean;
    validationStatusValue: (field: LeadFields) => ValidateStatus;
    register: UseFormRegister<LeadInputs>;
    handleInputChange: (e: (React.ChangeEvent<HTMLInputElement>) | any, input: any) => void;
    handleInputBlur: (e: (React.ChangeEvent<HTMLInputElement>) | any, input: any) => void;
}

export const LinkInfoInputs: FC<Props> = ({
                                              errors,
                                              disabled,
                                              validationStatusValue,
                                              register,
                                              handleInputChange,
                                              handleInputBlur
                                          }: Props) => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Form.Item
                    label="Power*trust dom"
                    name={LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM)}
                    help={errors[LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM] && errors[LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM]?.message}
                >
                    <InputNumber
                        disabled={disabled}
                        style={{ width: '100%' }}
                        placeholder="Enter power*trust dom"
                        {...register(LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM)}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Title home"
                    name={LeadFields.LINK_INFO_LRT_TITLE_HOME}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_LRT_TITLE_HOME)}
                    help={errors[LeadFields.LINK_INFO_LRT_TITLE_HOME] && errors[LeadFields.LINK_INFO_LRT_TITLE_HOME]?.message}
                >
                    <Input
                        disabled={disabled}
                        placeholder="Enter title home"
                        {...register(LeadFields.LINK_INFO_LRT_TITLE_HOME)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_LRT_TITLE_HOME)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_LRT_TITLE_HOME)}
                        allowClear
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Power*trust"
                    name={LeadFields.LINK_INFO_LRT_POWER_TRUST}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_LRT_POWER_TRUST)}
                    help={errors[LeadFields.LINK_INFO_LRT_POWER_TRUST] && errors[LeadFields.LINK_INFO_LRT_POWER_TRUST]?.message}
                >
                    <InputNumber
                        disabled={disabled}
                        style={{ width: '100%' }}
                        placeholder="Enter power*trust"
                        {...register(LeadFields.LINK_INFO_LRT_POWER_TRUST)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_LRT_POWER_TRUST)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_LRT_POWER_TRUST)}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Theme"
                    name={LeadFields.LINK_INFO_LRT_THEME}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_LRT_THEME)}
                    help={errors[LeadFields.LINK_INFO_LRT_THEME] && errors[LeadFields.LINK_INFO_LRT_THEME]?.message}
                >
                    <Input
                        disabled={disabled}
                        placeholder="Enter theme"
                        {...register(LeadFields.LINK_INFO_LRT_THEME)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_LRT_THEME)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_LRT_THEME)}
                        allowClear
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Power dom"
                    name={LeadFields.LINK_INFO_LRT_POWER_DOM}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_LRT_POWER_DOM)}
                    help={errors[LeadFields.LINK_INFO_LRT_POWER_DOM] && errors[LeadFields.LINK_INFO_LRT_POWER_DOM]?.message}
                >
                    <InputNumber
                        disabled={disabled}
                        style={{ width: '100%' }}
                        placeholder="Enter power dom"
                        {...register(LeadFields.LINK_INFO_LRT_POWER_DOM)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_LRT_POWER_DOM)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_LRT_POWER_DOM)}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="IP"
                    name={LeadFields.LINK_INFO_LRT_IP}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_LRT_IP)}
                    help={errors[LeadFields.LINK_INFO_LRT_IP] && errors[LeadFields.LINK_INFO_LRT_IP]?.message}
                >
                    <Input
                        disabled={disabled}
                        placeholder="Enter IP address"
                        {...register(LeadFields.LINK_INFO_LRT_IP)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_LRT_IP)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_LRT_IP)}
                        allowClear
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Trust dom"
                    name={LeadFields.LINK_INFO_LRT_TRUST_DOM}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_LRT_TRUST_DOM)}
                    help={errors[LeadFields.LINK_INFO_LRT_TRUST_DOM] && errors[LeadFields.LINK_INFO_LRT_TRUST_DOM]?.message}
                >
                    <InputNumber
                        disabled={disabled}
                        style={{ width: '100%' }}
                        placeholder="Enter trust dom"
                        {...register(LeadFields.LINK_INFO_LRT_TRUST_DOM)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_LRT_TRUST_DOM)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_LRT_TRUST_DOM)}
                    />
                </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
                <Form.Item
                    label="Domain rating"
                    name={LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING)}
                    help={errors[LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING] && errors[LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING]?.message}
                >
                    <InputNumber
                        disabled={disabled}
                        style={{ width: '100%' }}
                        placeholder="Enter domain rating"
                        {...register(LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING)}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Total traffic"
                    name={LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC}
                    hasFeedback
                    validateStatus={validationStatusValue(LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC)}
                    help={errors[LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC] && errors[LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC]?.message}
                >
                    <InputNumber
                        disabled={disabled}
                        style={{ width: '100%' }}
                        placeholder="Enter total traffic"
                        {...register(LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC)}
                        onChange={(e): void => handleInputChange(e, LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC)}
                        onBlur={(e): void => handleInputBlur(e, LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC)}
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};
