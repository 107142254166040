import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorType } from '../../interfaces';
import { LeadEventsLog, LeadsFilterOptions, LeadsSortOptions, SortFields, SortVariants } from '../../interfaces/leads';
import { getEventsLog, manualVerify } from '../actions/leads';

export interface LeadsState {
    leadEventsLog: LeadEventsLog[][];
    isLoading: boolean;
    error: ErrorType | null;
    searching: string;
    sorting: LeadsSortOptions;
    filtering: LeadsFilterOptions;
    isLoadingManualVerification: boolean;
}

const initialState: LeadsState = {
    leadEventsLog: [],
    isLoading: false,
    error: null,
    searching: '',
    sorting: {
        variant: SortVariants.ACS,
        field: SortFields.NUMBER
    },
    filtering: {
        publishableStatus: [],
        repeatStatus: [],
        attentionStatus: [],
        activeStatus: [],
        pluginStatus: [],
        leadsCount: null,
        status: [],
        connectionStatus: null,
        statusCode: [],
        language: [],
        lrtPowerTrustDom: false,
        ahrefsDr: false,
        ahrefsTraffic: false,
        manualVerification: null,
        manualVerificationDate: false,
        checkingDate: false,
        contentIndicators: []
    },
    isLoadingManualVerification: false
};

export const leadsSlice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        applyLeadsSearching: (state, action: PayloadAction<string>) => {
            state.searching = action.payload;
        },
        applyLeadsSorting: (state, action: PayloadAction<LeadsSortOptions>) => {
            state.sorting = {
                variant: action.payload.variant,
                field: action.payload.field
            };
        },
        applyLeadsFiltering: (state, action: PayloadAction<LeadsFilterOptions>) => {
            state.filtering = {
                publishableStatus: action.payload.publishableStatus,
                repeatStatus: action.payload.repeatStatus,
                attentionStatus: action.payload.attentionStatus,
                activeStatus: action.payload.activeStatus,
                pluginStatus: action.payload.pluginStatus,
                status: action.payload.status,
                connectionStatus: action.payload.connectionStatus,
                statusCode: action.payload.statusCode,
                language: action.payload.language,
                lrtPowerTrustDom: action.payload.lrtPowerTrustDom,
                ahrefsDr: action.payload.ahrefsDr,
                ahrefsTraffic: action.payload.ahrefsTraffic,
                manualVerification: action.payload.manualVerification,
                manualVerificationDate: action.payload.manualVerificationDate,
                checkingDate: action.payload.checkingDate,
                contentIndicators: action.payload.contentIndicators
            };
        },

        setFilteredLeadsCount: (state, action: PayloadAction<number>) => {
            state.filtering.leadsCount = action.payload;
        },

        resetLeadsSearching: (state) => {
            state.searching = '';
        },
        resetLeadsSorting: (state) => {
            state.sorting = {
                variant: SortVariants.ACS,
                field: SortFields.NUMBER
            };
        },
        resetLeadsFiltering: (state) => {
            state.filtering = {
                publishableStatus: [],
                repeatStatus: [],
                attentionStatus: [],
                activeStatus: [],
                pluginStatus: [],
                leadsCount: null,
                status: [],
                connectionStatus: null,
                statusCode: [],
                language: [],
                lrtPowerTrustDom: false,
                ahrefsDr: false,
                ahrefsTraffic: false,
                manualVerification: null,
                manualVerificationDate: false,
                checkingDate: false,
                contentIndicators: []
            };
        },

        clearLeadsEventsLog: (state) => {
            state.leadEventsLog = [];
        },

        clearLeadsStore: (state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEventsLog.fulfilled, (state, action: PayloadAction<LeadEventsLog[][]>) => {
            state.leadEventsLog = action.payload;
            state.isLoading = false;
        })
            .addCase(getEventsLog.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getEventsLog.rejected, (state, action) => {
                state.isLoading = false;
                message.error((action.payload as ErrorType).message);
            });

        builder.addCase(manualVerify.fulfilled, (state) => {
            state.isLoadingManualVerification = false;
        })
            .addCase(manualVerify.pending, (state) => {
                state.isLoadingManualVerification = true;
            })
            .addCase(manualVerify.rejected, (state, action) => {
                state.isLoadingManualVerification = false;
                message.error((action.payload as ErrorType).message);
            });
    }
});

export const {
    applyLeadsSearching,
    applyLeadsSorting,
    applyLeadsFiltering,

    resetLeadsSearching,
    resetLeadsSorting,
    resetLeadsFiltering,

    setFilteredLeadsCount,

    clearLeadsEventsLog,

    clearLeadsStore
} = leadsSlice.actions;

export default leadsSlice.reducer;
