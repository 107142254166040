import { Button, Space } from 'antd';
import React, { FC, memo, RefObject } from 'react';
import { Clipboard, Clipboard2Check, PencilSquare } from 'react-bootstrap-icons';
import { DeleteListPopconfirm } from '../../../components/DeleteListPopconfirm';
import { ExportListCSVPopconfirm } from '../../../components/ExportListCSVPopconfirm';
import { LeadsList } from '../../../interfaces/leadsLists';
import { LeadsListsState } from '../../../store/slices/leadsList';

interface Props {
    isLeadsListLinksCopied: boolean;
    isCopyLinksLoading: boolean;
    exportCsvRef: RefObject<HTMLAnchorElement>;
    currentListInfo: LeadsList | null;
    listIsLoading: LeadsListsState['isLoading'];
    currentDeletingRowId: LeadsListsState['currentDeletingRowId'];
    handleEditList: () => void;
    handleClose: () => void;
    handleCopyLeadsListLinks: () => void;
}

export const LeadsListViewingHeaderControls: FC<Props> = memo(({
                                                                   isLeadsListLinksCopied,
                                                                   isCopyLinksLoading,
                                                                   currentListInfo,
                                                                   listIsLoading,
                                                                   exportCsvRef,
                                                                   currentDeletingRowId,
                                                                   handleEditList,
                                                                   handleClose,
                                                                   handleCopyLeadsListLinks
                                                               }: Props) => {
    return (
        <>
            {
                currentListInfo &&
                <Space size="middle">
                    <Button
                        className={isLeadsListLinksCopied ? 'copy-links-button_copied' : ''}
                        loading={listIsLoading || isCopyLinksLoading}
                        onClick={handleCopyLeadsListLinks}
                        icon={isLeadsListLinksCopied ? <Clipboard2Check/> : <Clipboard/>}
                        type="default"
                        disabled={currentDeletingRowId.includes(currentListInfo._id)}
                    >
                        {isLeadsListLinksCopied ? 'Copied!' : 'Copy links'}
                    </Button>
                    <ExportListCSVPopconfirm
                        id={currentListInfo._id}
                        status={currentListInfo.status}
                        buttonType={'full'}
                        currentDeletingRowId={currentDeletingRowId}
                        exportCsvRef={exportCsvRef}
                        loading={listIsLoading}
                    />
                    <Button
                        loading={listIsLoading}
                        onClick={handleEditList}
                        icon={<PencilSquare/>}
                        type="primary"
                        disabled={currentDeletingRowId.includes(currentListInfo._id)}
                    >
                        Edit
                    </Button>
                    <DeleteListPopconfirm
                        id={currentListInfo._id}
                        currentDeletingRowId={currentDeletingRowId}
                        status={currentListInfo.status}
                        buttonType={'full'}
                        loading={listIsLoading}
                        handleClose={handleClose}
                    />
                </Space>
            }
        </>
    );
});
