import { Tag, TagProps } from 'antd';
import React, { FC, memo } from 'react';
import { LeadStatuses } from '../../interfaces/leads';
import { upperFirstLetter } from '../../utils/string';

interface Props {
    status: LeadStatuses;
}

export const LeadStatusTag: FC<Props> = memo(({ status }: Props) => {
    let tagStatusColor: TagProps['color'];

    switch (status) {
        case LeadStatuses.PENDING:
            tagStatusColor = 'gold';
            break;
        case LeadStatuses.UNPROCESSABLE:
            tagStatusColor = 'geekblue';
            break;
        case LeadStatuses.IN_PROGRESS:
            tagStatusColor = 'processing';
            break;
        case LeadStatuses.SUCCESSFUL:
            tagStatusColor = 'success';
            break;
        case LeadStatuses.FAILED:
            tagStatusColor = 'error';
            break;
    }

    return (
        <Tag color={tagStatusColor}>{upperFirstLetter(status)}</Tag>
    );
});
