import { WebsitesByPageRequest, WebsitesSearchFiltersSortsQueries } from '../interfaces/websites';

export const generateQueryForFilterWebsites = ({
    filterByLanguage,
    filterByAccessability,
    filterByLrtPowerTrustDom,
    filterByAhrefsDr,
    filterByAhrefsTraffic,
    filterByManualVerification,
    filterByManualVerificationDate,
    filterByLastCheckingDate,
    filterByCreatingDate,
    filterByList
}: Omit<WebsitesByPageRequest, 'id' | 'page' | 'search' | 'sortBy'>): string => {
    // rewrite to URLSearchParams
    // const searchParams = new URLSearchParams();
    // if (filterByAccessability !== null) {

    // }
    return `${
        filterByAccessability !== null
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_ACCESSABILITY}=${filterByAccessability + ''}`
            : ''
    }${
        filterByLanguage?.length
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_LANGUAGE}=${filterByLanguage}`
            : ''
    }${
        filterByLrtPowerTrustDom
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_LRT_POWER_TRUST_DOM}=${filterByLrtPowerTrustDom}`
            : ''
    }${
        filterByAhrefsDr
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_AHREFS_DR}=${filterByAhrefsDr.toString()}`
            : ''
    }${
        filterByAhrefsTraffic
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_AHREFS_TRAFFIC}=${filterByAhrefsTraffic.toString()}`
            : ''
    }${
        filterByManualVerification
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_MANUAL_VERIFICATION}=${filterByManualVerification.toString()}`
            : ''
    }${
        (filterByManualVerificationDate &&
            (filterByManualVerificationDate[0] &&
                filterByManualVerificationDate[1])
        )
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_MANUAL_VERIFICATION_DATE}=${
                filterByManualVerificationDate.map(date => new Date(date).toISOString())
                    .toString()
            }`
            : ''
    }${
        (filterByLastCheckingDate &&
            (filterByLastCheckingDate[0] &&
                filterByLastCheckingDate[1])
        )
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_LAST_CHECKING_DATE}=${
                filterByLastCheckingDate.map(date => new Date(date).toISOString())
                    .toString()
            }`
            : ''
    }${
        (filterByCreatingDate &&
            (filterByCreatingDate[0] &&
                filterByCreatingDate[1])
        )
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_LAST_CHECKING_DATE}=${
                filterByCreatingDate.map(date => new Date(date).toISOString())
                    .toString()
            }`
            : ''
    }${
        filterByList
            ? `&${WebsitesSearchFiltersSortsQueries.FILTER_BY_LIST_NAME}=${filterByList}`
            : ''
    }`;
};
