import { Card, message, Result, Spin } from 'antd';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SortFields, SortVariants } from '../../../interfaces/leads';
import { HeaderInner } from '../../../modules/Header/headerInner.hoc';
import { WebsitesTable } from '../../../modules/Websites/WebsitesTable';
import { RTKError } from '../../../store';
import { useGetAllWebsitesQuery } from '../../../store/queries/websites';
import { resetWebsitesFiltering, resetWebsitesSearching, resetWebsitesSorting } from '../../../store/slices/websites';
import { DefaultPageLimits, SocketsKeys } from '../../../typedef';
import { WebsiteDashboardHeaderControls } from './headerControls';

export const WebsitesListDashboard: FC = memo(() => {
    const dispatch = useDispatch();
    const {
        sorting: sortingOptions,
        filtering: filteringOptions,
        searching: searchingQuery
    } = useSelector((state) => state.websites);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageWebsitesSize] = useState<number>(DefaultPageLimits.WEBSITES);
    const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get('page') ?? 1));
    const exportCsvRef = useRef<HTMLAnchorElement>(null);

    const { data: websites, error, isLoading, isError, isFetching, refetch } = useGetAllWebsitesQuery({
        page: currentPage,
        search: searchingQuery,
        sortBy: [sortingOptions.field, sortingOptions.variant],
        filterByAccessability: filteringOptions.accessability,
        filterByLanguage: filteringOptions.language,
        filterByLrtPowerTrustDom: filteringOptions.lrtPowerTrustDom,
        filterByAhrefsDr: filteringOptions.ahrefsDr,
        filterByAhrefsTraffic: filteringOptions.ahrefsTraffic,
        filterByManualVerification: filteringOptions.manualVerification,
        filterByManualVerificationDate: filteringOptions.manualVerificationDate,
        filterByLastCheckingDate: filteringOptions.lastCheckingDate,
        filterByCreatingDate: filteringOptions.creatingDate,
        filterByList: filteringOptions.listID
    });

    useEffect(() => {
        isError && message.error((error as RTKError).data.message);
    }, [isError]);

    useEffect(() => {
        dispatch(resetWebsitesFiltering());
        dispatch(resetWebsitesSorting());
        dispatch(resetWebsitesSearching());

        return () => {
            dispatch(resetWebsitesFiltering());
            dispatch(resetWebsitesSorting());
            dispatch(resetWebsitesSearching());
        };
    }, []);

    useEffect(() => {
        setCurrentPage(Number(searchParams.get('page') ?? 1));
    }, [searchParams.get('page')]);

    useEffect(() => {
        const realTimeUpdateLeadManualVerification = (): void => {
            handleRefetch();
        };
        window.clientIo?.on(SocketsKeys.UPDATE_LEAD_MANUAL_VERIFICATION, realTimeUpdateLeadManualVerification);

        const realTimeLeadsListRestart = (): void => {
            if (currentPage === 1) {
                handleRefetch();
            } else {
                setCurrentPage(1);
            }
        };
        window.clientIo?.on(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);

        const realTimeDeleteLeadsList = (): void => {
            handleRefetch();
        };
        window.clientIo?.on(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);

        return () => {
            window.clientIo?.off(SocketsKeys.UPDATE_LEAD_MANUAL_VERIFICATION, realTimeUpdateLeadManualVerification);
            window.clientIo?.off(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);
            window.clientIo?.off(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);
        };
    }, []);

    const isSortingEnabled = useMemo(() => (
        sortingOptions.variant !== SortVariants.ACS ||
        sortingOptions.field !== SortFields.NUMBER
    ), [sortingOptions]);

    const isFilteringEnabled = useMemo(() => !!(
        filteringOptions.accessability !== null ||
        filteringOptions.language.length ||
        filteringOptions.lrtPowerTrustDom ||
        (filteringOptions.ahrefsDr && (
            typeof filteringOptions.ahrefsDr[0] === 'number' && typeof filteringOptions.ahrefsDr[1] === 'number'
        )) ||
        (filteringOptions.ahrefsTraffic && (
            typeof filteringOptions.ahrefsTraffic[0] === 'number' && typeof filteringOptions.ahrefsTraffic[1] === 'number'
        )) ||
        (filteringOptions.manualVerificationDate && (
            !!filteringOptions.manualVerificationDate[0] && !!filteringOptions.manualVerificationDate[1]
        )) ||
        (filteringOptions.lastCheckingDate && (
            !!filteringOptions.lastCheckingDate[0] && !!filteringOptions.lastCheckingDate[1]
        )) ||
        (filteringOptions.creatingDate && (
            !!filteringOptions.creatingDate[0] && !!filteringOptions.creatingDate[1]
        )) ||
        filteringOptions.listID
    ), [filteringOptions]);

    const handleChangeCurrentPage = useCallback((page: number): void => {
        if (page === 1) {
            searchParams.delete('page');
            setSearchParams(searchParams);
        } else {
            setSearchParams({ page: page.toString() });
        }
        setCurrentPage(page);
    }, [searchParams]);

    const handleRefetch = useCallback((): void => refetch(), []);

    return (
        <>
            <HeaderInner
                titleName="Websites" controls={
                <WebsiteDashboardHeaderControls
                    exportListCSVProps={{
                        exportCsvRef,
                        listIsLoading: isLoading && !websites
                    }}
                />
            }
            />
            <div className="home-container">
                {
                    error
                        ? <Result status="error" title={(error as RTKError).data.message}/>
                        : <Card>
                            {(isLoading && !websites) && <Spin className="centered-spinner"/>}
                            {
                                (!isLoading && websites && websites?.data) &&
                                <WebsitesTable
                                    isSearchingQuery={!!searchingQuery}
                                    sortVariant={sortingOptions.variant}
                                    isSortingEnabled={isSortingEnabled}
                                    isFilteringEnabled={isFilteringEnabled}
                                    isLoading={isFetching}
                                    websites={websites?.data ?? []}
                                    allWebsitesCount={websites?.meta.all_websites_count ?? 0}
                                    filteredWebsitesCount={websites?.meta.filtered_websites_count ?? 0}
                                    currentPage={currentPage}
                                    pageWebsitesSize={pageWebsitesSize}
                                    handleChangeCurrentPage={handleChangeCurrentPage}
                                    handleRefetch={handleRefetch}
                                />
                            }
                        </Card>
                }
            </div>
        </>
    );
});
