/* eslint-disable no-shadow */
import { ColumnDateLabel } from '.';
import {
    LeadConnectionStatuses,
    LeadLanguage,
    LeadManualVerification,
    LeadOverall,
    SortFields,
    SortVariants
} from './leads';
import { Topic } from './topics';

export enum WebsiteContentIndicators {
    OUTREACH = 'Outreach',
    B2F = 'B2F'
}

export interface Website {
    _id: string;
    is_accessible: boolean;
    base_url: string;
    domain: string;
    manual_verification: WebsiteManualVerification;
    manual_verificated_at: Date | null;
    crm_id: string | null;
    crm_offer_id: string | null;
    checked_at: Date | null;
    created_at: Date;
    updated_at: Date;
    connection_status: WebsiteConnectionStatuses;
    topics: Topic[];
    note: string | null;
    language: WebsiteLanguage | null;
    content_indicators: WebsiteContentIndicators[];
    seo_info_last_checked_at: Date | null;
    lrt_power_trust_dom: {
        value: number | null;
        error: string | null;
    };
    ahrefs_domain_rating: {
        value: number | null;
        error: string | null;
    };
    ahrefs_total_traffic: {
        value: number | null;
        error: string | null;
    };
    domain_rating: number;
    keywords: number;
    traffic: number;
    dtoxrisk: string;
    power_trust_dom: number;
    power_dom: number;
    trust_dom: number;
}

export type WebsiteLanguage = LeadLanguage;

export type WebsiteConnectionStatuses = LeadConnectionStatuses;

export type WebsiteManualVerification = LeadManualVerification;

export interface WebsitesMetadata {
    all_websites_count: number;
    filtered_websites_count: number;
}

export type WebsitesTableDataObj = {
    id: Website['_id'];
    key: number;
    number: number;
    domain: Website['domain'];
    isAccessible: Website['is_accessible'];
    baseUrl: Website['base_url'];
    topics: Website['topics'];
    manualVerification: {
        manualVerification: Website['manual_verification'];
        manualVerificatedAt: Website['manual_verificated_at'];
    }
    crmInfo: {
        crmId: Website['crm_id'];
        crmOfferId: Website['crm_offer_id'];
    }
    seoInfo: {
        // lrtPowerTrustDom: Website['lrt_power_trust_dom'];
        // ahrefsDomainRating: Website['ahrefs_domain_rating'];
        // ahrefsTotalTraffic: Website['ahrefs_total_traffic'];
        domainRating: Website['domain_rating'];
        keywords: Website['keywords'];
        traffic: Website['traffic'];
        dtoxrisk: Website['dtoxrisk'];
        powerTrustDom: Website['power_trust_dom'];
        powerDom: Website['power_dom'];
        trustDom: Website['trust_dom'];
    },
    date: ColumnDateLabel<Website>,
    connectionStatus: Website['connection_status'];
    language: Website['language'];
}

export interface WebsiteGetAllResponse {
    data: Website[];
    meta: WebsitesMetadata;
}

export type WebsitesLead = Pick<
    LeadOverall,
    '_id'
    | 'created_at'
    | 'checked_at'
    | 'regular_url'
    | 'shelly_url'
    | 'status'
    | 'status_code'
    | 'language'
    | 'manual_verification'
    | 'manual_verificated_at'
    | 'connection_status'
    | 'type'
    | 'url'
    | 'list_id'
>

export type WebsiteGetLeadsResponse = WebsitesLead[]

export enum WebsitesSearchFiltersSortsQueries {
    SEARCH = 'search',
    SORT_BY = 'sort_by',
    FILTER_BY_ACCESSABILITY = 'filter_by_accessability',
    FILTER_BY_LANGUAGE = 'filter_by_language',
    FILTER_BY_LRT_POWER_TRUST_DOM = 'filter_by_lrt-power-trust-dom',
    FILTER_BY_AHREFS_DR = 'filter_by_ahrefs-dr',
    FILTER_BY_AHREFS_TRAFFIC = 'filter_by_ahrefs-traffic',
    FILTER_BY_MANUAL_VERIFICATION = 'filter_by_manual-verification',
    FILTER_BY_MANUAL_VERIFICATION_DATE = 'filter_by_manual-verification-date',
    FILTER_BY_LAST_CHECKING_DATE = 'filter_by_last-checking-date',
    FILTER_BY_CREATING_DATE = 'filter_by_creating-date',
    FILTER_BY_LIST_NAME = 'filter_by_list-name'
}

export interface WebsitesSortOptions {
    variant: SortVariants;
    field: SortFields;
}

export interface WebsitesFilterOptions {
    websitesCount?: number | null;
    accessability: boolean | null;
    language: string[];
    lrtPowerTrustDom: boolean;
    ahrefsDr: [number, number] | false;
    ahrefsTraffic: [number, number] | false;
    manualVerification: LeadManualVerification | null;
    manualVerificationDate: ([Date, Date] | [null, null]) | false;
    lastCheckingDate: ([Date, Date] | [null, null]) | false;
    creatingDate: ([Date, Date] | [null, null]) | false;
    listID: string | null;
}

export interface WebsitesByPageRequest {
    id: string;
    page: number;
    search?: string;
    sortBy?: [SortFields, SortVariants];
    filterByAccessability?: boolean | null;
    filterByLanguage?: string[];
    filterByLrtPowerTrustDom?: boolean;
    filterByAhrefsDr?: [number, number] | false;
    filterByAhrefsTraffic?: [number, number] | false;
    filterByManualVerification?: LeadManualVerification | null;
    filterByManualVerificationDate?: ([Date, Date] | [null, null]) | false;
    filterByLastCheckingDate?: ([Date, Date] | [null, null]) | false;
    filterByCreatingDate?: ([Date, Date] | [null, null]) | false;
    filterByList?: string | null;
}

export interface WebsiteDossier {
    id: Website['_id'];
    isAccessible: Website['is_accessible'];
    baseUrl: Website['base_url'];
    domain: Website['domain'];
    manualVerification: Website['manual_verification'];
    manualVerificatedAt: Website['manual_verificated_at'];
    crmId: Website['crm_id'];
    crmOfferId: Website['crm_offer_id'];
    checkedAt: Website['checked_at'];
    createdAt: Website['created_at'];
    connectionStatus: Website['connection_status'];
    note: Website['note'];
    topics: Website['topics'];
    seoInfoLastCheckedAt: Website['seo_info_last_checked_at'];
    language: Website['language'];
    lrtPowerTrustDom: Website['lrt_power_trust_dom'];
    ahrefsDomainRating: Website['ahrefs_domain_rating'];
    ahrefsTotalTraffic: Website['ahrefs_total_traffic'];
    contentIndicators: Website['content_indicators'];
    domainRating: Website['domain_rating'],
    keywords: Website['keywords'],
    traffic: Website['traffic'],
    dtoxrisk: Website['dtoxrisk'],
    powerTrustDom: Website['power_trust_dom'],
    powerDom: Website['power_dom'],
    trustDom: Website['trust_dom'],
}

export interface WebsiteChangeTopicsRequest {
    id: string;
    topicsIDs: string[];
}

export interface WebsiteEditNoteRequest {
    id: string;
    note: string | null;
}

export interface WebsiteChangeContentIndicatorRequest {
    id: string;
    contentIndicators: WebsiteContentIndicators[];
}

export interface WebsitesListCSVResponse {
    csvBody: string;
    listName: string;
}

export interface WebsitesUpdateStatisticsResponse {
    result: boolean;
}
