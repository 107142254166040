import { Col, Row, Statistic, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { ListProgressBar } from '../../../components/ListProgressBar';
import { LeadsListStatistics, LeadsListStatuses } from '../../../interfaces/leadsLists';
import { formatPercentage, numberWithGrouping } from '../../../utils/numbers';

interface Props {
    statistics: LeadsListStatistics;
    status: LeadsListStatuses;
}

const { Text } = Typography;

export const StatsOfStatuses: FC<Props> = memo(({ statistics, status }: Props) => {
    return (
        <Row gutter={[16, 16]} justify="space-between">
            <Col span={20}>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Statistic
                            title={'Processed'}
                            value={statistics.processed_leads_count}
                            suffix={
                                <>
                                    /&nbsp;
                                    {
                                        numberWithGrouping(statistics.all_leads_count)
                                    }
                                    &nbsp;
                                    <Text code>
                                        {
                                            formatPercentage((statistics.processed_leads_count * 100) / statistics.all_leads_count)
                                        }
                                        %
                                    </Text>
                                </>
                            }
                        />
                    </Col>

                    <Col span={8}>
                        <Statistic
                            title={'In progress/Pending'}
                            value={`${
                                numberWithGrouping(statistics.in_progress_leads_count)
                            } / ${
                                statistics.pending_leads_count
                            }`}
                        />
                    </Col>

                    <Col span={8}>
                        <Statistic
                            title={'Approved'}
                            value={statistics.approved_leads_count}
                            suffix={
                                <>
                                    /&nbsp;
                                    {numberWithGrouping(statistics.all_leads_count)}
                                    &nbsp;
                                    <Text code>
                                        {formatPercentage((statistics.approved_leads_count * 100) / statistics.all_leads_count)}%
                                    </Text>
                                </>
                            }
                        />
                    </Col>

                    <Col span={8}>
                        <Statistic
                            title={'Successfully'}
                            value={statistics.succeeded_leads_count}
                            suffix={
                                <>
                                    /&nbsp;
                                    {numberWithGrouping(statistics.all_leads_count)}
                                    &nbsp;
                                    <Text code>
                                        {formatPercentage((statistics.succeeded_leads_count * 100) / statistics.all_leads_count)}%
                                    </Text>
                                </>
                            }
                        />
                    </Col>

                    <Col span={8}>
                        <Statistic
                            title={'Failed'}
                            value={statistics.failed_leads_count}
                            suffix={
                                <>
                                    /&nbsp;
                                    {numberWithGrouping(statistics.all_leads_count)}
                                    <Text code>
                                        {formatPercentage((statistics.failed_leads_count * 100) / statistics.all_leads_count)}%
                                    </Text>
                                </>
                            }
                        />
                    </Col>

                    <Col span={8}>
                        <Statistic
                            title={'Unprocessable'}
                            value={statistics.unprocessable_leads_count}
                            suffix={
                                <>
                                    /&nbsp;{
                                    numberWithGrouping(statistics.all_leads_count)
                                } <Text code>{
                                    formatPercentage((statistics.unprocessable_leads_count * 100) / statistics.all_leads_count)
                                }%</Text>
                                </>
                            }
                        />
                    </Col>
                </Row>
            </Col>

            <Col span={4}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '100%'
                    }}
                >
                    <ListProgressBar
                        type="dashboard"
                        status={status}
                        allLeadsCount={statistics.all_leads_count}
                        processedLeadsCount={statistics.processed_leads_count}
                        failedLeadsCount={statistics.failed_leads_count}
                        unprocessableLeadsCount={statistics.unprocessable_leads_count}
                    />
                </div>
            </Col>
        </Row>
    );
});
