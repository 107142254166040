import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorType } from '../../interfaces';
import { AuthWithGoogleCredentials, GoogleAuthUser } from '../../interfaces/users';
import { StatusCodes } from '../../typedef';
import { getMe, logoutWithGoogle, signinWithGoogle } from '../actions/session';

export interface SessionState {
    isLoading: boolean;
    error: ErrorType | null;
    isLoggedIn: boolean;
    currentUser: GoogleAuthUser | null;
}

const initialState: SessionState = {
    isLoading: true,
    error: null,
    isLoggedIn: false,
    currentUser: null
};

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        clearSessionStore: (state) => {
            state.isLoading = true;
            state.error = null;
            state.isLoggedIn = false;
            state.currentUser = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signinWithGoogle.fulfilled, (state, action: PayloadAction<GoogleAuthUser>) => {
            state.currentUser = action.payload;
            state.isLoggedIn = true;
            state.isLoading = false;
        })
            .addCase(signinWithGoogle.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(signinWithGoogle.rejected, (state, action) => {
                state.error = action.payload as ErrorType;
                state.isLoading = false;
                message.error(state.error.message);
            });

        builder.addCase(getMe.fulfilled, (state, action: PayloadAction<AuthWithGoogleCredentials['user']>) => {
            state.currentUser = action.payload;
            state.isLoggedIn = true;
            state.isLoading = false;
        })
            .addCase(getMe.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMe.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload !== 'boolean') {
                    state.error = action.payload as ErrorType;
                    message.error(
                        state.error.message,
                        state.error.statusCode === StatusCodes.WEB_SERVER_IS_DOWN
                            ? 0
                            : 3
                    );
                }
            });

        builder.addCase(logoutWithGoogle.fulfilled, state => {
            state.currentUser = null;
            state.isLoggedIn = false;
            state.isLoading = false;
        })
            .addCase(logoutWithGoogle.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(logoutWithGoogle.rejected, (state, action) => {
                state.error = action.payload as ErrorType;
                state.isLoading = false;
                message.error(state.error.message);
            });
    }
});

export const {
    clearSessionStore
} = sessionSlice.actions;

export default sessionSlice.reducer;
