/* eslint-disable react/jsx-indent-props */
import { Col, Row } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import { LeadOverall } from '../../../interfaces/leads';
import { LeadEventsLogModal } from '../LeadEventsLogModal';
import { GeneralPart } from './Parts/general';
import { ManualVerificationPart } from './Parts/manualVerification';
import { SEOPart } from './Parts/seo';

interface Props {
    lead: Omit<LeadOverall, 'events_log'>;
    showBackArrow?: boolean;
}

export const Lead: FC<Props> = memo(({ lead, showBackArrow }: Props) => {
    const [eventsLogContent, setEventsLogProps] = useState<{
        leadName: string,
        leadID: string,
    } | null>(null);
    const [isEventsLogModalVisible, setIsEventsLogModalVisible] = useState<boolean>(false);

    const handleViewEventsLog = useCallback((): void => {
        setEventsLogProps({
            leadName: lead.domain ?? '',
            leadID: lead._id
        });
        setIsEventsLogModalVisible(true);
    }, [lead, eventsLogContent]);

    const handleCloseEventsLogModal = useCallback((): void => {
        setIsEventsLogModalVisible(false);
        setEventsLogProps(null);
    }, []);

    return (
        <>
            <LeadEventsLogModal
                isVisible={isEventsLogModalVisible}
                onClose={handleCloseEventsLogModal}
                leadName={eventsLogContent?.leadName ?? ''}
                leadID={eventsLogContent?.leadID ?? ''}
            />
            <Row gutter={[0, 16]} align="top">
                <Col span={16}>
                    <GeneralPart
                        handleViewEventsLog={handleViewEventsLog} lead={lead} showBackArrow={showBackArrow}
                    />
                </Col>
                <Col span={8}>
                    <ManualVerificationPart lead={lead}/>
                    <SEOPart leadLinkInfo={lead.link_info} leadStatus={lead.status}/>
                </Col>
            </Row>
        </>
    );
});
