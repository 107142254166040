import { Tag, TagProps } from 'antd';
import React, { FC, memo, useMemo } from 'react';
import { ArrowRepeat, CheckCircle, PauseFill, RecordCircle, XCircle } from 'react-bootstrap-icons';
import { LeadsListStatuses } from '../../interfaces/leadsLists';

interface Props {
    status: LeadsListStatuses;
    isBig?: boolean;
}

export const ListStatusTag: FC<Props> = memo(({ status, isBig = false }: Props) => {
    const styles = useMemo(() => ({ fontSize: 24, lineHeight: 1.5715 }), []);
    let tagStatusColor: TagProps['color'];
    let tagIcon: React.ReactNode;
    let classname = '';

    switch (status) {
        case LeadsListStatuses.PENDING:
            tagStatusColor = 'gold';
            tagIcon = <RecordCircle size={isBig ? 24 : 12}/>;
            classname = 'ant-tag-pending';
            break;
        case LeadsListStatuses.IN_PROGRESS:
            tagStatusColor = 'processing';
            tagIcon = <ArrowRepeat size={isBig ? 24 : 12}/>;
            break;
        case LeadsListStatuses.READY:
            tagStatusColor = 'success';
            tagIcon = <CheckCircle size={isBig ? 24 : 12}/>;
            break;
        case LeadsListStatuses.FAILED:
            tagStatusColor = 'error';
            tagIcon = <XCircle size={isBig ? 24 : 12}/>;
            break;
        case LeadsListStatuses.PAUSED:
            tagStatusColor = 'purple';
            tagIcon = <PauseFill size={isBig ? 24 : 12}/>;
            break;
    }

    return (
        <Tag
            className={classname}
            icon={tagIcon}
            color={tagStatusColor}
            style={isBig ? styles : undefined}
        >
            {status.replace('_', ' ')}
        </Tag>
    );
});
