import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorType } from '../../interfaces';
import { LeadContentIndicators, LeadManualVerification } from '../../interfaces/leads';
import {
    LeadsList,
    LeadsListEditData,
    LeadsListFull,
    LeadsListLeads,
    LeadsListsResponse,
    LeadsListsUnarchivedMetadata
} from '../../interfaces/leadsLists';
import {
    createList,
    deleteList,
    fetchAllListsUnarchived,
    fetchLeadsByPageCount,
    fetchLeadsListEditData,
    fetchListById,
    refetchAllListsUnarchived,
    updateList
} from '../actions/leadsLists';

export interface LeadsListsState {
    isLoading: boolean;
    isFormLoading: boolean;
    isTableLoading: boolean;
    currentDeletingRowId: string[];
    error: ErrorType | null;
    listsError: ErrorType | null;
    lists: LeadsList[];
    listsMetadata: {
        unarchived: LeadsListsUnarchivedMetadata;
    };
    currentListError: ErrorType | null;
    currentListInfo: LeadsList | null;
    currentListLeads: LeadsListLeads;
    isLeadsListTableLoading: boolean;
    listEditData: LeadsListEditData | null;
    pausedLeadsLists: string[];
}

const initialState: LeadsListsState = {
    isLoading: false,
    isFormLoading: false,
    isTableLoading: false,
    currentDeletingRowId: [],
    error: null,
    listsError: null,
    lists: [],
    listsMetadata: {
        unarchived: {
            all_lists_count: 0,
            ready_lists_count: 0,
            failed_lists_count: 0,
            in_progress_lists_count: 0,
            pending_lists_count: 0
        }
    },
    currentListError: null,
    currentListInfo: null,
    currentListLeads: [],
    isLeadsListTableLoading: false,
    listEditData: null,
    pausedLeadsLists: []
};

export const leadsListsSlice = createSlice({
    name: 'leadsList',
    initialState,
    reducers: {
        manualVerifyLead: (state, action: PayloadAction<{
            leadID: string,
            verification: LeadManualVerification,
            verificatedAt: Date
        }>) => {
            if (state.currentListLeads && !!state.currentListLeads.length) {
                const idx = state.currentListLeads.findIndex(lead => lead._id === action.payload.leadID);

                if (idx !== -1) {
                    state.currentListLeads[idx].manual_verification = action.payload.verification;
                    state.currentListLeads[idx].manual_verificated_at = action.payload.verificatedAt;
                }
            }
        },

        changeLeadContentIndicator: (
            state,
            action: PayloadAction<{ leadID: string, contentIndicators: LeadContentIndicators[] }>
        ) => {
            if (state.currentListLeads && !!state.currentListLeads.length) {
                const idx = state.currentListLeads.findIndex(lead => lead._id === action.payload.leadID);

                if (idx !== -1) {
                    state.currentListLeads[idx].content_indicators = action.payload.contentIndicators;
                }
            }
        },

        addLeadsList: (state, action: PayloadAction<LeadsList>) => {
            state.lists.unshift(action.payload);
            state.listsMetadata.unarchived.all_lists_count += 1;
            state.listsMetadata.unarchived.pending_lists_count += 1;

            if (state.lists.length >= 20) {
                state.lists.pop();
            }
        },
        updateLeadsList: (state, action: PayloadAction<{
            list: LeadsList;
            meta: LeadsListsUnarchivedMetadata;
        }>) => {
            const idx = state.lists.findIndex(list => list._id === action.payload.list._id);

            state.listsMetadata.unarchived = action.payload.meta;

            if (idx !== -1) {
                state.lists[idx] = action.payload.list;

                if (state.currentListInfo && state.currentListInfo._id === action.payload.list._id) {
                    state.currentListInfo = action.payload.list;
                }
            }
        },
        removeCurrentLeadsList: (state, action: PayloadAction<{ id: string }>) => {
            if (state.currentListInfo && state.currentListInfo._id === action.payload.id) {
                state.currentListInfo = null;
                state.currentListLeads = [];
            }
        },

        clearCurrentList: (state) => {
            state.currentListInfo = null;
            state.currentListLeads = [];
        },
        clearCurrentListEditData: (state) => {
            state.listEditData = null;
        },
        clearCurrentError: (state) => {
            state.error = null;
        },
        clearCurrentListError: (state) => {
            state.currentListError = null;
        },
        disableLoading: (state) => {
            state.isLoading = false;
        },

        clearLeadsListsStore: (state) => {
            state.isLoading = false;
            state.isFormLoading = false;
            state.isTableLoading = false;
            state.currentDeletingRowId = [];
            state.error = null;
            state.listsError = null;
            state.lists = [];
            state.listsMetadata = {
                unarchived: {
                    all_lists_count: 0,
                    ready_lists_count: 0,
                    failed_lists_count: 0,
                    in_progress_lists_count: 0,
                    pending_lists_count: 0
                }
            };
            state.currentListError = null;
            state.currentListInfo = null;
            state.currentListLeads = [];
            state.isLeadsListTableLoading = false;
            state.listEditData = null;
            // pausedLeadsLists need save old
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllListsUnarchived.fulfilled, (
            state,
            action: PayloadAction<LeadsListsResponse>
        ) => {
            state.lists = action.payload.data;
            state.listsMetadata.unarchived = action.payload.meta;
            state.isTableLoading = false;
            state.listsError = null;
        })
            .addCase(fetchAllListsUnarchived.pending, (state) => {
                state.isTableLoading = true;
            })
            .addCase(fetchAllListsUnarchived.rejected, (state, action) => {
                state.listsError = action.payload as ErrorType;
                state.isTableLoading = false;
                message.error((action.payload as ErrorType).message);
            });

        builder.addCase(refetchAllListsUnarchived.fulfilled, (
            state,
            action: PayloadAction<LeadsListsResponse>
        ) => {
            state.lists = action.payload.data;
            state.listsMetadata.unarchived = action.payload.meta;
            state.currentDeletingRowId = [];
            state.listsError = null;
        })
            .addCase(refetchAllListsUnarchived.rejected, (state, action) => {
                state.listsError = action.payload as ErrorType;
                message.error((action.payload as ErrorType).message);
            });

        builder.addCase(createList.fulfilled, (state) => {
            state.isFormLoading = false;
            state.error = null;
        })
            .addCase(createList.pending, (state) => {
                state.isFormLoading = true;
            })
            .addCase(createList.rejected, (state, action) => {
                state.error = action.payload as ErrorType;
                state.isFormLoading = false;
                message.error(state.error.message);
            });

        builder.addCase(fetchLeadsListEditData.fulfilled, (state, action: PayloadAction<LeadsListEditData>) => {
            state.listEditData = action.payload;
            state.isFormLoading = false;
            state.error = null;
        })
            .addCase(fetchLeadsListEditData.pending, (state) => {
                state.isFormLoading = true;
            })
            .addCase(fetchLeadsListEditData.rejected, (state, action) => {
                state.error = action.payload as ErrorType;
                state.isFormLoading = false;
                message.error(state.error.message);
            });

        builder.addCase(fetchListById.fulfilled, (state, action: PayloadAction<LeadsListFull>) => {
            const { leads, ...info } = action.payload;

            state.currentListInfo = info;
            state.currentListLeads = leads;
            state.isLoading = false;
            state.currentListError = null;
        })
            .addCase(fetchListById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchListById.rejected, (state, action) => {
                state.currentListError = action.payload as ErrorType;
                state.isLoading = false;
                message.error(state.currentListError.message);
            });

        builder.addCase(updateList.fulfilled, (state) => {
            state.isFormLoading = false;
            state.error = null;
        })
            .addCase(updateList.pending, (state) => {
                state.isFormLoading = true;
            })
            .addCase(updateList.rejected, (state, action) => {
                state.error = action.payload as ErrorType;
                state.isFormLoading = false;
                message.error(state.error.message);
            });

        builder.addCase(fetchLeadsByPageCount.fulfilled, (state, action: PayloadAction<LeadsListLeads>) => {
            state.currentListLeads = action.payload;

            state.isLeadsListTableLoading = false;
            state.currentListError = null;
        })
            .addCase(fetchLeadsByPageCount.pending, (state) => {
                state.isLeadsListTableLoading = true;
            })
            .addCase(fetchLeadsByPageCount.rejected, (state, action) => {
                state.currentListError = action.payload as ErrorType;
                state.isLeadsListTableLoading = false;
                message.error(state.currentListError.message);
            });

        builder.addCase(deleteList.fulfilled, (state) => {
            state.isFormLoading = false;
            state.error = null;
        })
            .addCase(deleteList.pending, (state, action) => {
                state.isFormLoading = true;
                state.currentDeletingRowId.push(action.meta.arg.id);
            })
            .addCase(deleteList.rejected, (state, action) => {
                state.error = action.payload as ErrorType;
                state.isFormLoading = false;
                message.error(state.error.message);
            });
    }
});

export const {
    addLeadsList,
    updateLeadsList,
    removeCurrentLeadsList,
    clearCurrentList,
    clearCurrentListEditData,

    changeLeadContentIndicator,
    manualVerifyLead,

    disableLoading,

    clearCurrentError,
    clearCurrentListError,

    clearLeadsListsStore
} = leadsListsSlice.actions;

export default leadsListsSlice.reducer;
