import { Tag } from 'antd';
import React, { FC, memo } from 'react';
import { LeadEngine } from '../../interfaces/leads';
import { TextEmpty } from '../TextEmpty';

interface Props {
    engine: LeadEngine;
}

export const LeadEngineTag: FC<Props> = memo(({ engine }: Props) => {
    return (
        <>
            {
                engine
                    ? <Tag style={{ marginRight: 0, fontSize: 14 }}>{engine}</Tag>
                    : <TextEmpty/>
            }
        </>
    );
});
