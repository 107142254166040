import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Form, FormInstance, Input } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LeadsListFields, LeadsListInputs, LeadsListValues } from '../../interfaces/leadsLists';
import { LinksValidator } from '../../modules/LinksValidator';
import { LinksSegments } from '../../typedef';
import { validationSchema } from './validation';

interface Props {
    formName: string;
    form: FormInstance<any>;
    formType: 'edit' | 'create';
    defaultValues: LeadsListValues;
    disabled?: boolean;
    passCurrentLinksType?: (links: string[], segment: LinksSegments) => void;
    onSubmit: SubmitHandler<LeadsListInputs>;
}

export const LeadsListForm: FC<Props> = memo(({
                                                  formName,
                                                  form,
                                                  defaultValues,
                                                  formType,
                                                  disabled,
                                                  passCurrentLinksType,
                                                  onSubmit
                                              }: Props) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<LeadsListInputs>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, input: LeadsListFields): void => {
        setValue(input, e.target.value, {
            shouldTouch: true,
            shouldValidate: true
        });
    }, []);

    const handleInputBlur = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, input: LeadsListFields): void => {
        setValue(input, e.target.value, {
            shouldTouch: true,
            shouldValidate: true
        });
    }, []);

    const handleSetLinksToForm = useCallback((links: string): void => {
        setValue(LeadsListFields.LINKS, links, {
            shouldTouch: true,
            shouldValidate: true
        });
    }, []);

    const getCurrentLinksTypeAndLinks = useCallback((links: string[], segmnets: LinksSegments): void => {
        passCurrentLinksType && passCurrentLinksType(links, segmnets);
    }, [passCurrentLinksType]);

    return (
        <Form
            form={form}
            name={formName}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            initialValues={{ remember: true }}
            onFinish={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <Form.Item
                label="List name"
                name={LeadsListFields.LIST_NAME}
                required
                hasFeedback
                validateStatus={errors.list_name && 'error'}
                help={errors[LeadsListFields.LIST_NAME] && errors[LeadsListFields.LIST_NAME]?.message}
            >
                <Input
                    disabled={disabled ?? false}
                    placeholder="Enter list name"
                    {...register(LeadsListFields.LIST_NAME)}
                    onChange={(e): void => handleInputChange(e, LeadsListFields.LIST_NAME)}
                    onBlur={(e): void => handleInputBlur(e, LeadsListFields.LIST_NAME)}
                    allowClear
                />
            </Form.Item>

            <Form.Item
                label="Comment"
                validateStatus={errors.comment && 'error'}
                help={errors.comment && errors.comment.message}
                name={LeadsListFields.COMMENT}
            >
                <Input.TextArea
                    disabled={disabled ?? false}
                    placeholder={'Comment'}
                    {...register(LeadsListFields.COMMENT)}
                    allowClear
                    onChange={(e): void => handleInputChange(e, LeadsListFields.COMMENT)}
                    onBlur={(e): void => handleInputBlur(e, LeadsListFields.COMMENT)}
                />
            </Form.Item>

            {
                formType === 'create'
                    ? <>
                        <Divider/>
                        <LinksValidator
                            getCurrentLinksTypeAndLinks={getCurrentLinksTypeAndLinks}
                            setLinksToForm={handleSetLinksToForm}
                        />
                    </>
                    : <>
                        <Form.Item
                            label="Links"
                            name={LeadsListFields.LINKS}
                            required
                            validateStatus={errors.links && 'error'}
                            help={errors.links && errors.links.message}
                        >
                            <Input.TextArea
                                disabled
                                {...register(LeadsListFields.LINKS)}
                            />
                        </Form.Item>
                    </>
            }
        </Form>
    );
});
