import { Tooltip } from 'antd';
import React, { FC, memo } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { ColumnDateLabel as ColumnDateLabelInterface } from '../../interfaces';
import { Lead } from '../../interfaces/leads';
import { LeadsList } from '../../interfaces/leadsLists';
import { Website } from '../../interfaces/websites';
import { getShortDate, toLocaleDate } from '../../utils/date';
import { TextEmpty } from '../TextEmpty';

interface Props {
    date: ColumnDateLabelInterface<Website | Lead | LeadsList>;
}

export const ColumnDateLabel: FC<Props> = memo(({ date }: Props) => {
    return (
        <div>
            <Tooltip placement="top" title={toLocaleDate(date.createdAt)}>
        <span style={{ cursor: 'help' }}>
          {getShortDate(date.createdAt)}&nbsp;<QuestionCircle size={12}/>
        </span>
            </Tooltip>
            &nbsp;<b>/</b>
            <br/>
            {
                date.checkedAt
                    ? <Tooltip placement="bottom" title={toLocaleDate(date.checkedAt)}>
            <span style={{ cursor: 'help' }}>
              {getShortDate(date.checkedAt)}&nbsp;<QuestionCircle size={12}/>
            </span>
                    </Tooltip>
                    : <TextEmpty/>
            }
        </div>
    );
});
