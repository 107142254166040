import { Card, Tooltip, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { FileEarmarkBarGraphFill, QuestionCircleFill } from 'react-bootstrap-icons';

type Props = {
    size?: 'small' | 'large';
    allLeadsCount: number;
}

const { Text } = Typography;

export const LeadsListStatisticsHider: FC<Props> = memo(({ size, allLeadsCount }: Props) => {
    let sizeValue: [number, number] = [0, 0];

    switch (size) {
        case 'small':
            sizeValue = [14, 30];
            break;
        case 'large':
            sizeValue = [18, 40];
            break;
    }

    return (
        <Card className="leads-list-stats-hider">
            <div
                className={
                    `leads-list-stats-hider__inner ${
                        size === 'small'
                            ? 'leads-list-stats-hider__inner_small'
                            : 'leads-list-stats-hider__inner_large'
                    }`
                }
            >
        <span className="leads-list-stats-hider__tooltip">
          <Tooltip
              title="Statistics will be available after the start of list processing"
              placement={size === 'small' ? 'top' : 'leftTop'}
          >
            <Text
                className="leads-list-stats-hider__hint"
                type="secondary"
            >
              <QuestionCircleFill size={sizeValue[0]}/>
            </Text>
          </Tooltip>
        </span>
                <Text type="secondary"><FileEarmarkBarGraphFill size={sizeValue[1]}/></Text>
                <Text code>All leads: {allLeadsCount}</Text>
            </div>
        </Card>
    );
});
