import { Button, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React, { Key } from 'react';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import { EventsLogTableDataObj } from '../../../interfaces/leads';
import { toLocaleDate } from '../../../utils/date';

export const columns = (
    expandedRowsKeys: readonly Key[]
): ColumnsType<EventsLogTableDataObj> => {
    return [
        {
            title: 'Step',
            dataIndex: 'number',
            key: 'number',
            width: 70
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 200,
            render: (value: EventsLogTableDataObj['type']) => <Tag>{value} </Tag>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: (value: EventsLogTableDataObj['status']) => <>
                <Tag
                    color={
                        (value === 'info' && '#40a9ff') ||
                        (value === 'error' && '#ff4d4f') ||
                        (value === 'warning' && 'warning') ||
                        (value === 'success' && '#73d13d') ||
                        'default'
                    }
                >
                    {value}
                </Tag>
            </>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (value: string, record: EventsLogTableDataObj) => (
                <>
                    {new RegExp(record.leadName).test(value) && <><b>{record.leadName}</b></>}
                    {value.replace(record.leadName, '')}
                </>
            )
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 250,
            render: (value: Date) => toLocaleDate(value)
        },
        {
            title: 'Context',
            dataIndex: 'context',
            key: 'context',
            width: 85,
            render: (value: EventsLogTableDataObj['context'], record: EventsLogTableDataObj) => (
                value && <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        type="text" icon={
                        (expandedRowsKeys.includes(record.key.toString()))
                            ? <ChevronDown/>
                            : <ChevronRight/>
                    }
                    />
                </div>
            )
        }
    ];
};
