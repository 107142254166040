import { Button, Form, Popconfirm, Space } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LeadsListForm } from '../../../components/LeadsListForm';
import { Modal } from '../../../components/Modal';
import { LeadsListFields, LeadsListInputs, LeadsListParams, LeadsListValues } from '../../../interfaces/leadsLists';
import { createList } from '../../../store/actions/leadsLists';
import { LinksSegments } from '../../../typedef';

export const CreateListModal: FC = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isFormLoading } = useSelector((state) => state.leadsLists);
    const [form] = Form.useForm<LeadsListInputs>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOkButtonDisabled, setIsOkButtonDisabled] = useState<boolean>(false);

    const initialValues: LeadsListValues = {
        [LeadsListFields.LIST_NAME]: undefined,
        [LeadsListFields.COMMENT]: undefined,
        [LeadsListFields.LINKS]: undefined
    };

    useEffect(() => {
        setIsOpen(true);
        return () => {
            setIsOpen(false);
        };
    }, []);

    const handleClose = useCallback((): void => {
        setIsOpen(false);
        setTimeout(() => {
            form.resetFields();
            navigate('/');
        }, 170);
    }, []);

    const onSubmit: SubmitHandler<LeadsListInputs> = useCallback((data: LeadsListParams) => {
        const cleanData: LeadsListParams = {
            ...data,
            links: data.links
                .split('\n')
                .map(link => link.trim())
                .filter(link => link !== '')
                .join('\n')
        };
        dispatch(createList({ data: cleanData, handleClose }));
    }, []);

    const setSaveButtonDisabled = useCallback((links: string[], segment: LinksSegments): void => {
        setIsOkButtonDisabled(
            (segment === LinksSegments.BAD) ||
            (segment === LinksSegments.GOOD && !links.length) ||
            !links.length
        );
    }, []);

    const handleCreate = useCallback((): void => {
        form.submit();
    }, []);

    return (
        <Modal
            width={760}
            title="Add new list"
            open={isOpen}
            onCancel={handleClose}
            confirmLoading={isFormLoading}
            footer={[
                <Space style={{ marginLeft: 'auto', width: 'fit-content' }} key="footer">
                    <Button key="cancle" onClick={handleClose} danger>Cancel</Button>
                    <Popconfirm
                        key="create"
                        arrowPointAtCenter
                        placement="top"
                        title="Are you sure to create this list?"
                        onConfirm={handleCreate}
                        okText="Yes"
                        cancelText="No"
                        disabled={isOkButtonDisabled}
                    >
                        <Button
                            loading={isFormLoading} type="primary" disabled={isOkButtonDisabled}
                        >Create</Button>
                    </Popconfirm>
                </Space>
            ]}
        >
            <LeadsListForm
                passCurrentLinksType={setSaveButtonDisabled}
                formType="create"
                formName="create-leads-list"
                form={form}
                onSubmit={onSubmit}
                defaultValues={initialValues}
            />
        </Modal>
    );
});
