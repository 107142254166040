import { Button, Space, Tooltip } from 'antd';
import React, { FC, memo } from 'react';
import { JournalArrowUp, Receipt } from 'react-bootstrap-icons';
import { WebsitesTableDataObj } from '../../../../interfaces/websites';

interface Props {
    websiteId: WebsitesTableDataObj['id'];
    handleShowLeadsCatalog: (websiteId: string) => void;
    handleViewLeadDossier: (websiteId: string) => void;
}

export const ColumnActions: FC<Props> = memo(({
                                                  websiteId,
                                                  handleShowLeadsCatalog,
                                                  handleViewLeadDossier
                                              }: Props) => {
    return (
        <Space style={{ width: 'fit-content' }} size="middle">
            <Tooltip title="View leads">
                <Button
                    type="link"
                    onClick={(): void => handleShowLeadsCatalog(websiteId)}
                    icon={<JournalArrowUp size={20}/>}
                />
            </Tooltip>
            <Tooltip title="View dossier">
                <Button
                    type="link"
                    onClick={(): void => handleViewLeadDossier(websiteId)}
                    icon={<Receipt size={20}/>}
                />
            </Tooltip>
        </Space>
    );
});
