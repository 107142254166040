import { Typography } from 'antd';
import React, { FC, memo } from 'react';
import { LeadsListStatisticsHider } from '../../../../components/LeadsListStatisticsHider';
import { ListProgressBar } from '../../../../components/ListProgressBar';
import { LeadsListStatuses, LeadsListTableDataObj } from '../../../../interfaces/leadsLists';
import { formatPercentage, numberWithGrouping } from '../../../../utils/numbers';

interface Props {
    statistics: LeadsListTableDataObj['statistics'];
    status: LeadsListTableDataObj['status'];
}

const { Text } = Typography;

export const ColumnStats: FC<Props> = memo((
    {
        statistics,
        status
    }: Props
) => {
    return (
        <>
            {status === LeadsListStatuses.PENDING ? (
                <LeadsListStatisticsHider size="small" allLeadsCount={statistics.allLeadsCount}/>
            ) : (
                <>
                    {/*Processed*/}
                    <Text strong>Processed:&nbsp;</Text>
                    {numberWithGrouping(statistics.processedLeadsCount)}/
                    {numberWithGrouping(statistics.allLeadsCount)}
                    <Text code>
                        {formatPercentage(
                            (statistics.processedLeadsCount * 100) / statistics.allLeadsCount
                        )}
                        %
                    </Text>
                    <br/>

                    {/*In Progress*/}
                    {(!!statistics.inProgressLeadsCount || !!statistics.pendingLeadsCount) && (
                        <>
                            <Text strong>In progress:&nbsp;</Text>
                            {numberWithGrouping(statistics.inProgressLeadsCount)}
                            <br/>
                        </>
                    )}

                    {/*Pending*/}
                    {(!!statistics.pendingLeadsCount || !!statistics.inProgressLeadsCount) && (
                        <>
                            <Text strong>Pending:&nbsp;</Text>
                            {numberWithGrouping(statistics.pendingLeadsCount)}
                            <br/>
                        </>
                    )}

                    {/*Unprocessable*/}
                    <Text strong>Unprocessable:&nbsp;</Text>
                    {numberWithGrouping(statistics.unprocessableLeadsCount)}/
                    {numberWithGrouping(statistics.allLeadsCount)}
                    <Text code>
                        {formatPercentage((statistics.unprocessableLeadsCount * 100) / statistics.allLeadsCount)}%
                    </Text>
                    <br/>

                    {/*Failed*/}
                    <Text strong>Failed:&nbsp;</Text>
                    {numberWithGrouping(statistics.failedLeadsCount)}/
                    {numberWithGrouping(statistics.allLeadsCount)}
                    <Text code>
                        {formatPercentage((statistics.failedLeadsCount * 100) / statistics.allLeadsCount)}%
                    </Text>
                    <br/>

                    {/*Successfully*/}
                    <Text strong>Successfully:&nbsp;</Text>
                    {numberWithGrouping(statistics.succeededLeadsCount)}/
                    {numberWithGrouping(statistics.allLeadsCount)}
                    <Text code>
                        {formatPercentage((statistics.succeededLeadsCount * 100) / statistics.allLeadsCount)}%
                    </Text>
                    <br/>

                    {/*Active*/}
                    <Text strong>Active:&nbsp;</Text>
                    {numberWithGrouping(statistics.activeLeadsCount)}/
                    {numberWithGrouping(statistics.allLeadsCount)}
                    <Text code>
                        {formatPercentage((statistics.activeLeadsCount * 100) / statistics.allLeadsCount)}%
                    </Text>
                    <br/>

                    {/*Approved*/}
                    <Text strong>Approved:&nbsp;</Text>
                    {numberWithGrouping(statistics.approvedLeadsCount)}/
                    {numberWithGrouping(statistics.allLeadsCount)}
                    <Text code>
                        {formatPercentage((statistics.approvedLeadsCount * 100) / statistics.allLeadsCount)}%
                    </Text>
                    <br/>

                    {/*Requires Attention*/}
                    <Text strong>Attention:&nbsp;</Text>
                    {numberWithGrouping(statistics.requiresAttentionLeadsCount)}/
                    {numberWithGrouping(statistics.allLeadsCount)}
                    <Text code>
                        {formatPercentage((statistics.requiresAttentionLeadsCount * 100) / statistics.allLeadsCount)}%
                    </Text>
                    <br/>

                    {/*Progress Bar*/}
                    <ListProgressBar
                        type="line"
                        status={status}
                        allLeadsCount={statistics.allLeadsCount}
                        processedLeadsCount={statistics.processedLeadsCount}
                        failedLeadsCount={statistics.failedLeadsCount}
                        unprocessableLeadsCount={statistics.unprocessableLeadsCount}
                    />
                </>
            )}
        </>
    );
});
