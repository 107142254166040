/* eslint-disable no-shadow */
import { ColumnDateLabel, ExplicitError } from '.';
import { StatusCodesInfo } from '../typedef';

export enum AdditionalLeadStatuses {
    ALL = 'all',
    BAD = 'failed-unprocessable',
    DUPLICATED = 'duplicated',
    PROCESSED = 'processed'
}

export enum LeadManualVerification {
    APPROVED = 'approved',
    NOT_CHECKED = 'not_checked',
    DECLINED = 'declined'
}

export enum LeadStatuses {
    SUCCESSFUL = 'successful',
    PENDING = 'pending',
    IN_PROGRESS = 'in_progress',
    FAILED = 'failed',
    UNPROCESSABLE = 'unprocessable'
}

export enum LeadConnectionStatuses {
    ONLINE = 'online',
    OFFLINE = 'offline'
}

export enum LeadEngines {
    WORDPRESS = 'Wordpress',
    JOOMLA = 'Joomla'
}

export enum LeadTypes {
    CUSTOM = 'custom',
    SHELLY = 'shelly',
    REGULAR = 'regular'
}

export enum LeadCustomTypes {
    CUSTOM_SHELLY = 'custom_shelly',
    CUSTOM_REGULAR = 'custom_regular'
}

export enum LeadEventsLogTypes {
    PARSING = 'parsing',
    CHECKING = 'checking'
}

export enum LeadEventsLogStatuses {
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning'
}

export enum LeadContentIndicators {
    OUTREACH = 'Outreach',
    B2F = 'B2F'
}

export interface LeadEventsLog {
    type: LeadEventsLogTypes;
    status: LeadEventsLogStatuses;
    description: string;
    date: Date;
    context: '' | ExplicitError;
}

export type EventsLogTableDataObj = LeadEventsLog & {
    leadName: string;
    key: number;
    number: number;
}

export type LeadsGenerateCSVTypes =
    | AdditionalLeadStatuses.ALL
    | LeadStatuses.SUCCESSFUL
    | LeadStatuses.UNPROCESSABLE
    | AdditionalLeadStatuses.BAD
    | LeadStatuses.FAILED;

export interface Regular {
    regular_url: string;
    regular_login: string;
    regular_password: string;
    regular_old_credentials: {
        login: string;
        password: string;
    }[];
}

export interface Shelly {
    shelly_url: string;
    shelly_password: string;
    shelly_path_to_file: string;
    shelly_is_file_upload: boolean;
    shelly_old_credentials: {
        password: string;
    }[];
}

export type LeadLanguage = {
    name: string;
    iso_name: string;
};

export type LeadLRT = {
    last_checked_at: Date | null;
    report_error: ExplicitError | false;
    is_report_generated: boolean;
    report_id: number | null;
    power_trust_dom: number | null;
    power_trust: number | null;
    power_dom: number | null;
    trust_dom: number | null;
    title_home: string | null;
    theme: string | null;
    ip: string | null;
};

export type LeadAhrefs = {
    total_traffic: {
        error: string | null;
        value: number | null;
    };
    domain_rating: {
        error: string | null;
        value: number | null;
    };
};

export type LeadLinkInfo = {
    linkresearchtools: LeadLRT;
    ahrefs: LeadAhrefs;
};

export interface Lead {
    _id: string;
    url: string;
    domain: string;
    subdomain: string;
    type: LeadTypes;
    engine: LeadEngine;
    home_page: string;
    language: LeadLanguage | null;
    list_id: string | null;
    status: LeadStatuses;
    created_at: Date;
    updated_at: Date;
    status_code: number;
    connection_status: LeadConnectionStatuses;
    is_duplicate: boolean;
    checked_at: Date | null;
    meta_title: string | null;
    blog_probability: boolean;
    manual_verification: LeadManualVerification;
    manual_verificated_at: Date | null;
    link_info: LeadLinkInfo;
    error_description: string | null;
    website_id: string | null;
    content_indicators: LeadContentIndicators[];
    offer_id: number;
    is_publishable: boolean;
    is_repeat: boolean;
    requires_attention: boolean;
    is_active: boolean;
    has_plugin: boolean;
    notes: string | null;
}

export type LeadOverall = Lead & Partial<Regular> & Partial<Shelly>;

export type LeadRegular = Lead & Regular;

export type LeadShelly = Lead & Shelly;

export type LeadEngine = LeadEngines.WORDPRESS | LeadEngines.JOOMLA | null;

export interface LeadDossier {
    id: LeadOverall['_id'];
    name: LeadOverall['domain'];
    homePage: LeadOverall['home_page'];
    language: LeadOverall['language'];
    metaTitle: LeadOverall['meta_title'];
    linkInfo: LeadOverall['link_info'];
    domain: LeadOverall['domain'];
    connectionStatus: LeadOverall['connection_status'];
    subdomain: LeadOverall['subdomain'];
    createdAt: LeadRegular['created_at'];
    checkedAt: LeadRegular['checked_at'];
    regularUrl?: LeadRegular['regular_url'];
    regularLogin?: LeadRegular['regular_login'];
    regularPassword?: LeadRegular['regular_password'];
    shellyUrl?: LeadShelly['shelly_url'];
    engine: LeadOverall['engine'];
    shellyPassword?: LeadShelly['shelly_password'];
    shellyPathToFile?: LeadShelly['shelly_path_to_file'];
    shellyIsFileUpload?: LeadShelly['shelly_is_file_upload'];
    blogProbability: LeadOverall['blog_probability'];
    type: LeadOverall['type'];
    url: LeadOverall['url'];
    status: LeadOverall['status'];
    statusCode: LeadOverall['status_code'];
    errorDescription: LeadOverall['error_description'];
    manualVerification: LeadOverall['manual_verification'];
    manualVerificatedAt: LeadOverall['manual_verificated_at'];
    contentIndicators: LeadOverall['content_indicators'];
    websiteID: LeadOverall['website_id'];
    offerId: LeadOverall['offer_id'];
    notes?: LeadOverall['notes'];
}

export type LeadTableDataObj = {
    id: Lead['_id'];
    key: number;
    number: number;
    info: {
        url: Lead['url'];
        homePage: Lead['home_page'];
        regularUrl?: LeadRegular['regular_url'] | undefined;
        regularLogin?: LeadRegular['regular_login'] | undefined;
        regularPassword?: LeadRegular['regular_password'] | undefined;
        shellyUrl?: LeadShelly['shelly_url'] | undefined;
        shellyPassword?: LeadShelly['shelly_password'] | undefined;
        manualVerification: LeadOverall['manual_verification'];
        manualVerificatedAt: LeadOverall['manual_verificated_at'];
    };
    listId: Lead['list_id'];
    language: Lead['language'];
    domain: Lead['domain'];
    subdomain: Lead['subdomain'];
    type: LeadTypes;
    date: ColumnDateLabel<Lead>;
    isDuplicate: Lead['is_duplicate'];
    status: LeadStatuses;
    statusCode: Lead['status_code'];
    connectionStatus: Lead['connection_status'];
    engine: LeadEngine;
    errorDescription: Lead['error_description'];
    offerId: Lead['offer_id'];
    publishableStatus: Lead['is_publishable'];
    repeatStatus: Lead['is_repeat'];
    attentionStatus: Lead['requires_attention'];
    activeStatus: Lead['is_active'];
    pluginStatus: Lead['has_plugin'];
}

export enum SortVariants {
    ACS = 'asc',
    DESC = 'desc'
}

export enum SortFields {
    NUMBER = 'number',
    CREATED_AT = 'created_at',
    CHECKED_DATE = 'checked_at',
    LAST_CHECKED_DATE = 'last_checked_at',
    TYPE = 'type',
    STATUS = 'status',
    CONNECTION_STATUS = 'connection_status',
    STATUS_CODE = 'status_code',
    ENGINE = 'engine',
    LANGUAGE = 'language',
    ACCESSABILITY = 'accessability',
    DOMAIN = 'domain',
    BASE_URL = 'base_url',
    MANUAL_VERIFICATION = 'manual_verification',
    LRT_POWER_TRUST_DOM = 'lrt_power_trust_dom'
}

export interface LeadsSortOptions {
    variant: SortVariants;
    field: SortFields;
}

export interface LeadsFilterOptions {
    publishableStatus: boolean[];
    repeatStatus: boolean[];
    attentionStatus: boolean[];
    activeStatus: boolean[];
    pluginStatus: boolean[];
    leadsCount?: number | null;
    status: LeadStatuses[];
    connectionStatus: LeadConnectionStatuses | null;
    statusCode: (keyof typeof StatusCodesInfo)[];
    language: string[];
    lrtPowerTrustDom: boolean;
    ahrefsDr: [number, number] | false;
    ahrefsTraffic: [number, number] | false;
    manualVerification: LeadManualVerification | null;
    manualVerificationDate: ([Date, Date] | [null, null]) | false;
    checkingDate: ([Date, Date] | [null, null]) | false;
    contentIndicators: LeadContentIndicators[];
}

export interface LeadsByPageRequest {
    id: string;
    page: number;
    search?: string;
    sortBy?: [SortFields, SortVariants];
    filterByPublishableStatus?: boolean[];
    filterByRepeatStatus?: boolean[];
    filterByAttentionStatus?: boolean[];
    filterByActiveStatus?: boolean[];
    filterByPluginStatus?: boolean[];
    filterByStatus?: LeadStatuses[];
    filterByConnectionStatus?: LeadConnectionStatuses | null;
    filterByStatusCode?: (keyof typeof StatusCodesInfo)[];
    filterByLanguage?: string[];
    filterByLrtPowerTrustDom?: boolean;
    filterByAhrefsDr?: [number, number] | false;
    filterByAhrefsTraffic?: [number, number] | false;
    filterByManualVerification?: LeadManualVerification | null;
    filterByManualVerificationDate?: ([Date, Date] | [null, null]) | false;
    filterByCheckingDate?: ([Date, Date] | [null, null]) | false;
    filterByContentIndicators?: LeadContentIndicators[];
}

export interface WebsiteLeadsCatalogDataObj {
    id: Lead['_id'];
    key: number;
    number: number;
    baseUrl: LeadRegular['regular_url'] | LeadShelly['shelly_url'];
    date: ColumnDateLabel<Lead>;
    type: Lead['type'];
    status: Lead['status'];
    connectionStatus: Lead['connection_status'];
    statusCode: Lead['status_code'];
    language: Lead['language'];
    manualVerification: {
        manualVerification: Lead['manual_verification'];
        manualVerificatedAt: Lead['manual_verificated_at'];
    };
    listId: Lead['list_id'];
    url: Lead['url'];
}

export interface AllLeadsMetadata {
    all_leads_count: number;
    filtered_leads_count: number;
}

export interface AllLeadsResponse {
    data: LeadOverall[];
    meta: AllLeadsMetadata;
}

export enum LeadsSearchFiltersSortsQueries {
    SEARCH = 'search',
    SORT_BY = 'sort_by',
    FILTER_BY_PUBLISHABLE_STATUS = 'filter_by_publishable_status',
    FILTER_BY_REPEAT_STATUS = 'filter-by-repeat-status',
    FILTER_BY_ATTENTION_STATUS = 'filter_by_requires_attention',
    FILTER_BY_ACTIVE_STATUS = 'filter-by-active-status',
    FILTER_BY_PLUGIN_STATUS = 'filter_by_plugin_status',
    FILTER_BY_STATUS = 'filter_by_status',
    FILTER_BY_CONNECTION = 'filter_by_connection',
    FILTER_BY_STATUS_CODE = 'filter_by_status-code',
    FILTER_BY_LANGUAGE = 'filter_by_language',
    FILTER_BY_LRT_POWER_TRUST_DOM = 'filter_by_lrt-power-trust-dom',
    FILTER_BY_AHREFS_DR = 'filter_by_ahrefs-dr',
    FILTER_BY_AHREFS_TRAFFIC = 'filter_by_ahrefs-traffic',
    FILTER_BY_MANUAL_VERIFICATION = 'filter_by_manual-verification',
    FILTER_BY_MANUAL_VERIFICATION_DATE = 'filter_by_manual-verification-date',
    FILTER_BY_CHECKING_DATE = 'filter_by_checking-date',
    FILTER_BY_CONTENT_INDICATORS = 'filter_by_content-indicators'
}

export enum LeadsBulkActions {
    RESTART = 'restart',
    SEO_CHECKS = 'seo_checks'
}

export enum LeadsBulkActionsSamples {
    BY_FILTERS = 'by_filters',
    BY_TYPE = 'by_type',
    BY_SELECTED = 'by_selected'
}

export enum LeadsBulkActionsTypes {
    ALL = 'all',
    BAD = 'failed-unprocessable',
    UNPROCESSABLE = 'unprocessable',
    FAILED = 'failed',
    IN_PROGRESS = 'in_progress',
    SUCCESSFUL = 'successful'
}

export interface LeadsBulkActionData {
    action: LeadsBulkActions;
    sample: LeadsBulkActionsSamples;
    selectedKeys: string[];
    queries: Record<Exclude<LeadsSearchFiltersSortsQueries, LeadsSearchFiltersSortsQueries.SORT_BY>, any> | undefined;
    withSeoChecks: boolean;
    listId: string | undefined;
}

export interface LeadsBulkActionRestartData extends LeadsBulkActionData {
    action: LeadsBulkActions.RESTART;
    type: Omit<LeadsBulkActionsTypes, LeadsBulkActionsTypes.SUCCESSFUL> | undefined;
}

export interface LeadsBulkActionSeoChecksData extends LeadsBulkActionData {
    action: LeadsBulkActions.SEO_CHECKS;
    type: Omit<
        LeadsBulkActionsTypes,
        LeadsBulkActionsTypes.BAD |
        LeadsBulkActionsTypes.FAILED |
        LeadsBulkActionsTypes.IN_PROGRESS |
        LeadsBulkActionsTypes.UNPROCESSABLE
    > | undefined;
}

export interface LeadChangeContentIndicatorRequest {
    id: string;
    contentIndicators: LeadContentIndicators[];
}

export type LeadAddNewRequest = Omit<
    LeadOverall,
    '_id' |
    'list_id' |
    'created_at' |
    'updated_at' |
    'website_id'
>

export enum LeadFields {
    URL = 'url',
    DOMAIN = 'domain',
    SUBDOMAIN = 'subdomain',
    TYPE = 'type',
    ENGINE = 'engine',
    HOME_PAGE = 'home_page',
    LANGUAGE = 'language',
    STATUS = 'status',
    STATUS_CODE = 'status_code',
    CONNECTION_STATUS = 'connection_status',
    META_TITLE = 'meta_title',
    BLOG_PROBABILITY = 'blog_probability',
    MANUAL_VERIFICATION = 'manual_verification',
    CONTENT_INDICATORS = 'content_indicators',

    LINK_INFO_LRT_POWER_TRUST_DOM = 'link_info_lrt_power_trust_dom',
    LINK_INFO_LRT_POWER_TRUST = 'link_info_lrt_power_trust',
    LINK_INFO_LRT_POWER_DOM = 'link_info_lrt_power_dom',
    LINK_INFO_LRT_TRUST_DOM = 'link_info_lrt_trust_dom',
    LINK_INFO_LRT_TITLE_HOME = 'link_info_lrt_title_home',
    LINK_INFO_LRT_THEME = 'link_info_lrt_theme',
    LINK_INFO_LRT_IP = 'link_info_lrt_ip',

    LINK_INFO_AHREFS_TOTAL_TRAFFIC = 'link_info_ahrefs_total_traffic',
    LINK_INFO_AHREFS_DOMAIN_RATING = 'link_info_ahrefs_domain_rating',

    REGULAR_URL = 'regular_url',
    REGULAR_LOGIN = 'regular_login',
    REGULAR_PASSWORD = 'regular_password',

    SHELLY_URL = 'shelly_url',
    SHELLY_PASSWORD = 'shelly_password',
    SHELLY_PATH_TO_FILE = 'shelly_path_to_file',
    SHELLY_IS_FILE_UPLOAD = 'shelly_is_file_upload',
}

export interface LeadParams {
    [LeadFields.URL]: string;
    [LeadFields.DOMAIN]: string;
    [LeadFields.SUBDOMAIN]: string;
    [LeadFields.TYPE]: LeadTypes;
    [LeadFields.ENGINE]: LeadEngine;
    [LeadFields.HOME_PAGE]: string;
    [LeadFields.LANGUAGE]: LeadLanguage | undefined;
    [LeadFields.STATUS]: LeadStatuses;
    [LeadFields.STATUS_CODE]: number;
    [LeadFields.CONNECTION_STATUS]: LeadConnectionStatuses;
    [LeadFields.META_TITLE]: string;
    [LeadFields.BLOG_PROBABILITY]: boolean;
    [LeadFields.MANUAL_VERIFICATION]: LeadManualVerification;
    [LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM]: LeadLinkInfo['linkresearchtools']['power_trust_dom'];
    [LeadFields.LINK_INFO_LRT_POWER_TRUST]: LeadLinkInfo['linkresearchtools']['power_trust'];
    [LeadFields.LINK_INFO_LRT_POWER_DOM]: LeadLinkInfo['linkresearchtools']['power_dom'];
    [LeadFields.LINK_INFO_LRT_TRUST_DOM]: LeadLinkInfo['linkresearchtools']['trust_dom'];
    [LeadFields.LINK_INFO_LRT_TITLE_HOME]: LeadLinkInfo['linkresearchtools']['title_home'];
    [LeadFields.LINK_INFO_LRT_THEME]: LeadLinkInfo['linkresearchtools']['theme'];
    [LeadFields.LINK_INFO_LRT_IP]: LeadLinkInfo['linkresearchtools']['ip'];
    [LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING]: LeadLinkInfo['ahrefs']['domain_rating']['value'];
    [LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC]: LeadLinkInfo['ahrefs']['total_traffic']['value'];
    [LeadFields.CONTENT_INDICATORS]: LeadContentIndicators[];

    [LeadFields.REGULAR_URL]: string;
    [LeadFields.REGULAR_LOGIN]: string;
    [LeadFields.REGULAR_PASSWORD]: string;

    [LeadFields.SHELLY_URL]: string;
    [LeadFields.SHELLY_PASSWORD]: string;
    [LeadFields.SHELLY_PATH_TO_FILE]: string;
    [LeadFields.SHELLY_IS_FILE_UPLOAD]: boolean;
}

export type LeadValues = Partial<LeadParams>

export type LeadInputs = {
    [LeadFields.URL]: string;
    [LeadFields.DOMAIN]: string;
    [LeadFields.SUBDOMAIN]: string;
    [LeadFields.TYPE]: LeadTypes;
    [LeadFields.ENGINE]: LeadEngine;
    [LeadFields.HOME_PAGE]: string;
    [LeadFields.LANGUAGE]: LeadLanguage;
    [LeadFields.STATUS]: LeadStatuses;
    [LeadFields.STATUS_CODE]: number;
    [LeadFields.CONNECTION_STATUS]: LeadConnectionStatuses;
    [LeadFields.META_TITLE]: string;
    [LeadFields.BLOG_PROBABILITY]: boolean;
    [LeadFields.MANUAL_VERIFICATION]: LeadManualVerification;
    [LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM]: LeadLinkInfo['linkresearchtools']['power_trust_dom'];
    [LeadFields.LINK_INFO_LRT_POWER_TRUST]: LeadLinkInfo['linkresearchtools']['power_trust'];
    [LeadFields.LINK_INFO_LRT_POWER_DOM]: LeadLinkInfo['linkresearchtools']['power_dom'];
    [LeadFields.LINK_INFO_LRT_TRUST_DOM]: LeadLinkInfo['linkresearchtools']['trust_dom'];
    [LeadFields.LINK_INFO_LRT_TITLE_HOME]: LeadLinkInfo['linkresearchtools']['title_home'];
    [LeadFields.LINK_INFO_LRT_THEME]: LeadLinkInfo['linkresearchtools']['theme'];
    [LeadFields.LINK_INFO_LRT_IP]: LeadLinkInfo['linkresearchtools']['ip'];
    [LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING]: LeadLinkInfo['ahrefs']['domain_rating']['value'];
    [LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC]: LeadLinkInfo['ahrefs']['total_traffic']['value'];
    [LeadFields.CONTENT_INDICATORS]: LeadContentIndicators[];

    [LeadFields.REGULAR_URL]: string;
    [LeadFields.REGULAR_LOGIN]: string;
    [LeadFields.REGULAR_PASSWORD]: string;

    [LeadFields.SHELLY_URL]: string;
    [LeadFields.SHELLY_PASSWORD]: string;
    [LeadFields.SHELLY_PATH_TO_FILE]: string;
    [LeadFields.SHELLY_IS_FILE_UPLOAD]: boolean;
}
