import { Button, Divider, Space, Tooltip } from 'antd';
import React, { FC, memo, RefObject } from 'react';
import { EyeFill, PencilSquare } from 'react-bootstrap-icons';
import { DeleteListPopconfirm } from '../../../../components/DeleteListPopconfirm';
import { ExportListCSVPopconfirm } from '../../../../components/ExportListCSVPopconfirm';
import { LeadsListTableDataObj } from '../../../../interfaces/leadsLists';
import { LeadsListsState } from '../../../../store/slices/leadsList';

interface Props {
    status: LeadsListTableDataObj['status'];
    id: LeadsListTableDataObj['id'];
    exportCsvRef: RefObject<HTMLAnchorElement>;
    currentDeletingRowId: LeadsListsState['currentDeletingRowId'];
    handleEditList: (id: string) => void;
    handleViewList: (id: string) => void;
}

export const ColumnActions: FC<Props> = memo((
    {
        currentDeletingRowId,
        status,
        id,
        exportCsvRef,
        handleEditList,
        handleViewList
    }: Props) => {
    return (
        <>
            <Space size="small" align="center">
                <Tooltip title="Edit">
                    <Button
                        disabled={currentDeletingRowId.includes(id)}
                        onClick={(): void => handleEditList(id)}
                        type="link"
                        icon={<PencilSquare size={20}/>}
                    />
                </Tooltip>
                <Tooltip title="View">
                    <Button
                        disabled={currentDeletingRowId.includes(id)}
                        onClick={(): void => handleViewList(id)}
                        type="link"
                        icon={<EyeFill size={20}/>}
                    />
                </Tooltip>
            </Space>

            <Divider/>

            <Space size="small" align="center">
                <ExportListCSVPopconfirm
                    id={id}
                    status={status}
                    buttonType={'icon'}
                    currentDeletingRowId={currentDeletingRowId}
                    exportCsvRef={exportCsvRef}
                />
                <DeleteListPopconfirm
                    id={id}
                    currentDeletingRowId={currentDeletingRowId}
                    status={status}
                    buttonType={'icon'}
                />
            </Space>
        </>
    );
});
